import React, { Component } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { withTranslation } from 'react-i18next';

// Functions
import { getClientDocSection, getClientDocsData } from './ClientsDocs';

// Components
import DocsText from './DocsText';
import DocsCode from './DocsCode';

// CSS
import './MarkdownStyles.scoped.css';


const styles = {
  jumpSpace: {
    paddingTop: '1%',
    scrollMarginTop: 65,
  },
  textSpace: {
    p: 2,
  },
  divider: {
    borderBottomWidth: 2,
    bgcolor: 'black',
  },
  textBody: {
    py: 1,
  },
  code: {
    py: 2,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 32,
  },
  subtitle: {
    fontWeight: 'bold',
    fontSize: 24,
  },
};

class CustomPage extends Component {
  render() {
    const {
      title,
      hasSubsection,
      hasCode,
      codeTitle,
      hasReturns,
      returnsTitle,
      notification,
      notificationType,
      hasImage,
      t,
      selectedClient,
    } = this.props;

    const sectionChange = getClientDocSection(selectedClient.name)
    const specificData = getClientDocsData(selectedClient.name, t)

    return (
      <div id={'markdown'}>
        <section id={title} style={styles.jumpSpace}>
          {hasSubsection ? (
            <>
              {/* Title */}
              <Typography sx={[styles.textSpace, styles.title]} variant="h4">
                {t('cws_app.documentation.' + title)}
              </Typography>
              <Divider sx={styles.divider} />
            </>
          ) : (
            <>
              {/* Subtitle */}
              <Divider sx={styles.divider} />
              <Typography sx={[styles.textSpace, styles.subtitle]} variant="h5">
                {t('cws_app.documentation.' + title)}
              </Typography>
            </>
          )}

          {/* Body */}
          <Grid sx={styles.textSpace}>
            {/* Text */}
            <Grid sx={styles.textBody}>
              <DocsText
                title={title}
                notification={notification}
                notificationType={notificationType}
                hasImage={hasImage}
                t={t}
                selectedClient={selectedClient}
                sectionChange={sectionChange}
                specificData={specificData}
              />
            </Grid>

            {/* Code */}
            <Grid sx={styles.code}>
              {hasCode && (
                <DocsCode
                  title={title}
                  codeTitle={codeTitle}
                  hasReturns={hasReturns}
                  returnsTitle={returnsTitle}
                  t={t}
                />
              )}
            </Grid>
          </Grid>
        </section>
      </div>
    );
  }
}

export default withTranslation()(CustomPage);
