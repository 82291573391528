import { createReducer } from './utils';
import { handleRequest, handleAvailable, handleUnavailable } from '../reducerHandlers'
import {
    SNACK_SET,
    STORES_REQUEST,
    STORES_AVAILABLE,
    STORES_UNAVAILABLE,
    STORE_FLOOR_AVAILABLE
} from './constants';

const defaultState = {
    loading_brands: false,
    loading_categories: false,
    isStoreFloorLoading: false,
    loading_number_formatting: false,
    categories: {},
    storeCategories: [],  // TODO: delete on deprecation of old selector
    brandList: [],
    numberFormatting: 'en-US',
    numberDecimals: 0,
    numberCurrencySymbol: '$',
    storesFloor: null,
};

export const reducer = createReducer(defaultState, {
    [STORES_REQUEST]: handleRequest,
    [STORES_AVAILABLE]: handleAvailable,
    [STORES_UNAVAILABLE]: handleUnavailable,
    [STORE_FLOOR_AVAILABLE]: handleStoreFloorAvailable,
});

function getStoreFloor(data) {
    return data.reduce((acc, storeFloorData) => {
        acc[storeFloorData.store_id] = storeFloorData.floors;
        return acc;
    }, {});
}

export function handleStoreFloorAvailable(state, { payload: { keyState, data, loadingName, storeCode = null, aditionalStates = {} } }) {
    let newStoreFloor = state[keyState];

    if (data?.length === 0) {
        newStoreFloor = {};
    } else if (!newStoreFloor) {
        newStoreFloor = getStoreFloor(data);
    } else if (storeCode) {
        newStoreFloor[storeCode] = data[0].floors;
    } else {
        newStoreFloor = getStoreFloor(data);
    }

    return {
        ...state,
        [keyState]: newStoreFloor,
        [loadingName]: false,
        ...aditionalStates,
    };
}


export function getStoreBrands(store_id) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loading_brands'
        const keyState = 'brandList'
        dispatch({ type: STORES_REQUEST, payload: { loadingName: loadingName } });
        try {
            const brandResponse = await dataSource.getStoreBrands(store_id);
            dispatch({
                type: STORES_AVAILABLE,
                payload: { keyState: keyState, data: brandResponse, loadingName: loadingName },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: STORES_UNAVAILABLE,
                payload: { keyState: keyState, loadingName: loadingName, defaultState: [] },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getStoreCategories(store_id, client_id, can_get_sub_categories = false, supplier_id = null) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loading_categories'
        const keyState = 'categories'
        const keyState2 = 'storeCategories'
        dispatch({ type: STORES_REQUEST, payload: { loadingName: loadingName } });
        try {
            const response = await dataSource.getStoreCategories(store_id, client_id, can_get_sub_categories, supplier_id);
            dispatch({
                type: STORES_AVAILABLE,
                payload: { keyState: keyState, data: response, loadingName: loadingName },
            });
            // TODO: delete on deprecation of old selector
            dispatch({
                type: STORES_AVAILABLE,
                payload: { keyState: keyState2, data: response['categories'], loadingName: loadingName },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: STORES_UNAVAILABLE,
                payload: { keyState: keyState, loadingName: loadingName, defaultState: [] },
            });
            // TODO: delete on deprecation of old selector
            dispatch({
                type: STORES_UNAVAILABLE,
                payload: { keyState: keyState2, loadingName: loadingName, defaultState: [] },
            });
            const snack = {
                open: true,
                message: 'There was an error getting the store categories.',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getNumberFormat(client_id) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loading_number_formatting';
        const keyState = 'numberFormatting';
        dispatch({ type: STORES_REQUEST, payload: { loadingName: loadingName } });
        try {
            const response = await dataSource.getNumberFormats(client_id);
            dispatch({
                type: STORES_AVAILABLE,
                payload: { keyState: keyState, data: response.number_format.locale, loadingName: loadingName },
            });
            dispatch({
                type: STORES_AVAILABLE,
                payload: { keyState: 'numberDecimals', data: response.number_format.decimals, loadingName: loadingName },
            });
            dispatch({
                type: STORES_AVAILABLE,
                payload: { keyState: 'numberCurrencySymbol', data: response.number_format.symbol, loadingName: loadingName },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: STORES_UNAVAILABLE,
                payload: { keyState: keyState, loadingName: loadingName, defaultState: [] },
            });
            const snack = {
                open: true,
                message: 'There was an error getting the currency formats.',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getStoresFloor(storeCode) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'isStoreFloorLoading';
        const keyState = 'storesFloor';
        dispatch({ type: STORES_REQUEST, payload: { loadingName: loadingName } });
        try {
            const response = await dataSource.getStoresFloor(storeCode);
            dispatch({
                type: STORE_FLOOR_AVAILABLE,
                payload: { keyState: keyState, data: response, loadingName: loadingName, storeCode },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: STORES_UNAVAILABLE,
                payload: { keyState: keyState, loadingName: loadingName, defaultState: [] },
            });
            const snack = {
                open: true,
                message: 'There was an error getting the store floor_number.',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}
