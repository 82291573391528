import { connect } from 'react-redux';
import Rescheduling from '../../../../components/features/rescheduling/Rescheduling';

import { getUserStores } from 'core/slices/users';
import { getDSInfo } from 'core/slices/digitalStore';
import { getStoreMapInfo, getRobotSessionsInfo, postRescheduleTicket, getRescheduleTickets, getDetailedRobotSession  } from 'core/slices/rescheduling';
import { getChains, getTasks, getProducts } from 'core/slices/backend';

import { isLoadingUserStores, getUserStoresAllList, getSelectedClient, getMapInfo, isLoadingResMap, isLoadingNewTicket, ticketMessageResponse, getChainsList, getRobotSessions, isLoadingRobotSessions, getTicketsData, isLoadingTickets, getSession } from 'core/selectors';

export default connect(state => ({
    isLoadingUserStores: isLoadingUserStores(state),
    isLoadingResMap: isLoadingResMap(state),
    userStores: getUserStoresAllList(state),
    selectedClient: getSelectedClient(state),
    storeMapInfo: getMapInfo(state),
    isLoadingNewTicket: isLoadingNewTicket(state),
    ticketMessageResponse: ticketMessageResponse(state),
    chainsList: getChainsList(state),
    robotSessions: getRobotSessions(state),
    robotSession: getSession(state),
    isLoadingRobotSessions: isLoadingRobotSessions(state),
    ticketsData: getTicketsData(state),
    isLoadingTickets: isLoadingTickets(state),
}), {
    getUserStores,
    getDSInfo,
    getStoreMapInfo,
    postRescheduleTicket,
    getChains,
    getRobotSessionsInfo,
    getRescheduleTickets,
    getDetailedRobotSession
})(Rescheduling);
