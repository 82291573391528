import { useState, useEffect } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import {
	Backdrop,
	Button,
	Fade,
	Grid,
	IconButton,
	Modal,
	Typography,
	Paper,
} from '@mui/material';


import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarQuickFilter,
} from '@mui/x-data-grid';

// Icons
import SearchIcon from '@mui/icons-material/Search';

// My components
import TransferListComponent from './TransferListComponent';

function CustomToolbar() {
	return (
		<GridToolbarContainer>
			<GridToolbarQuickFilter sx={{ width: '50%', p: 2 }} />
		</GridToolbarContainer>
	);
}

const formatColumns = (t, handleEdit, countTrue) => {
	const columns = [
		{
			field: 'item',
			headerName: 'Item',
			width: 150,
			align: 'left',
		},
		{
			field: 'ean',
			headerName: 'Ean',
			width: 150,
			align: 'left',
		},
		{
			field: 'name',
			headerName: t('cws_app.tracking.Description'),
			width: 450,
			align: 'left',
		},
		{
			field: 'active',
			headerName: t('cws_app.tracking.Active_stores'),
			width: 200,
			align: 'left',
			sortable: false,
			disableColumnMenu: true,
			disableColumnFilter: true,
			renderCell: params => {
				return (
					<Typography>
						{countTrue(params.row.active)}/{Object.keys(params.row.active).length}
					</Typography>
				)
			}
		},
		{
			field: 'edit',
			headerName: t('cws_app.tracking.Action'),
			headerAlign: 'center',
			align: 'center',
			sortable: false,
			disableColumnMenu: true,
			disableColumnFilter: true,
			renderCell: params => {
				return (
					<IconButton
						onClick={e => {
							handleEdit(params.row);
						}}>
						<SearchIcon />
					</IconButton>
				);
			},
		},
	];

	return columns;
};

export default function DataTable(props) {
	const {
		rows,
		trackingListId,
		storesNames,
		postTrackingListStores,
		getTrackingListExcel,
	} = props;

	const [columns, setColumns] = useState([]);

	const [selectedRow, setSelectedRow] = useState(null);

	const { t } = useTranslation();

	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);


	//  TODO: remove and use it on parent
	const handleEdit = (row) => {
		setSelectedRow(row);
		handleOpen();
	};

	const handleDownloadExcel = (event) => {
		if (rows && rows.length > 0) {
			let filename = 'tracking list';
			let columns = ['Item', 'Ean'];
			let stores = Object.keys(rows[0].active);

			let excelRows = rows.map(r => {
				let row = [r.item, r.ean];
				stores.forEach(store => {
					row.push(r.active[store] ? 1 : 0);
				})
				return row
			})
			stores = stores.map(s => s.toUpperCase());
			columns = [...columns, ...stores];
			getTrackingListExcel({
				excelData: [columns, ...excelRows],
				excelFileName: filename,
			});
		}
	};

	// counts true items in a list of objects
	const countTrue = (obj) => {
		let count = 0;
		for (let key in obj) {
			if (obj[key] === true) {
				count++;
			}
		}
		return count;
	};

	const handleEditStores = (right, left) => {
		if (selectedRow && Object.keys(selectedRow).length > 0 && trackingListId) {
			let item = selectedRow.item;
			let ean = selectedRow.ean;

			if (left.length > 0 && right.length > 0) {
				// there are inactive stores and active stores
				// inactive stores
				postTrackingListStores(trackingListId, left, item, ean, false);
				// active stores
				postTrackingListStores(trackingListId, right, item, ean);
			} else if (left.length > 0 && right.length === 0) {
				// only inactive stores
				postTrackingListStores(trackingListId, left, item, ean, false);
			} else {
				// only active stores
				postTrackingListStores(trackingListId, right, item, ean);
			}

		}
	}

	useEffect(() => {
		setColumns(formatColumns(t, handleEdit, countTrue));
	}, [t]);

	return (
		<Grid container alignItems={'center'}>
			<Grid container item xs={12} mb={1} justifyContent={'flex-end'}>
				<Button
					variant="outlined"
					onClick={handleDownloadExcel}
				>
					{t('cws_app.general.download_excel')}
				</Button>
			</Grid>
			<Grid item xs={12}>
				<StyledPaper>
					<DataGrid
						getRowId={row => row.item}
						rows={rows}
						columns={columns}
						slots={{
							toolbar: CustomToolbar, // If you want all the toolbars, use the default GridToolbar instead
						}}
						slotProps={{
							toolbar: {
								showQuickFilter: true,
								quickFilterProps: { debounceMs: 500 },
							},
						}}
						localeText={{
							toolbarFilters: t('cws_app.general.filters', 'Filters'),
						}}
						initialState={{
							pagination: {
								paginationModel: {
									pageSize: 7,
								},
							},
						}}
						autoreset={false}
						pageSizeOptions={[7, 15, 25]}
						disableColumnSelector={true}
						disableRowSelect={true}
					/>

				</StyledPaper>
			</Grid>

			<Modal
				aria-labelledby="Edit stores for product"
				open={open}
				onClose={handleClose}
				closeAfterTransition
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
			>
				<Fade in={open}>
					<div>
						<StyledPaper sx={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							width: '50%',
							minWidth: 900,
							height: '30%',
							minHeight: 600,
							boxShadow: 24,
							borderRadius: 2,
							p: 2,
						}}>
							<Grid container justifyContent="center" alignItems="center">
								<Grid item xs={12}>
									<TransferListComponent
										t={t}
										handleClose={handleClose}
										StyledPaper={StyledPaper}
										row={selectedRow}
										storesNames={storesNames}
										handleEditStores={handleEditStores}
									/>
								</Grid>
							</Grid>
						</StyledPaper>
					</div>
				</Fade>
			</Modal>

		</Grid>
	);
}

const StyledPaper = styled(props => {
	return (
		<Paper
			elevation={1}
			{...props}
		/>
	)
}
)(({ theme }) => {
	return ({
		background: theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
		'& .MuiPaper-root': {
			backgroundColor: theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.paper.main,
			borderRadius: 3,
			color: 'white',
		},
		'& .MuiTableCell-root': {
			background: theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
			color: theme.palette.type === 'light' ? 'black' : 'white',
		},
		'& .MuiInputLabel-root': {
			color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
		},
		'& .MuiOutlinedInput-root': {
			color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
			'& fieldset': {
				borderColor: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
			},
		},
		'& .MuiDataGrid-columnHeaders': {
			backgroundColor:
				theme.palette.type === 'light'
					? theme.palette.primary.main
					: theme.palette.paper.main,
			color: 'white',
		},
		'& .MuiDataGrid-cell': {
			background:
				theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
			color: theme.palette.type === 'light' ? 'black' : 'white',
		},
		'& .MuiDataGrid-menuIconButton': {
			opacity: 1,
			color: 'white',
		},
		'& .MuiDataGrid-sortIcon': {
			color: 'white',
		},
	})
}
);
