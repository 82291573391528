import { useEffect, useState } from "react";
import { Grid, Typography, LinearProgress, Paper, Button } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from "react-router-dom";
import { LocalStorageService } from 'core/services/localStorage';
import { format } from '../../utils/converters';

import { isEmptyOrUndefined, MultiSelector, Selector } from "@zippeditoolsjs/blocks";
import { IconComponent } from "@zippeditoolsjs/zippedi-icons";

import { toSnakeCase } from "core/utils/parsers";
import { JUMBO_CLIENT_ID, ZIPPEDI_CLIENT_ID } from "core/utils/constants";
import { formatDataGridToExcel, formatToExcel, formatNivoToExcel } from "../../utils/parsers";
import DateRange from "../../tools/DateRange";
import AlertBox from '../../tools/AlertBox';
import Modal from '../../tools/Modal';
import Visualization from './Visualization';


export default function Dashboards(props) {
  const {
    isLoadingDashboard,
    dashboardInfo,
    getDashboard,
    isLoadingUserStores, // Is the same loading variable for chains, because they come from the same endpoint
    userStores,
    getUserStores,
    userChains,
    selectedClient,
    daysBeforeRange = 7,
    isLoadingExcelDownload,
    downloadExcel,
    getStoreCategories,
    categories,
    isLoadingCategories,
    getUserSuppliers,
    suppliers,
    isLoadingSuppliers,
    clientFormats,
    isLoadingClientFormats
  } = props;

  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [queryParameters] = useSearchParams();
  const [dashboardForm, setDashboardForm] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [dateState, setDateState] = useState([
    {
      startDate: new Date(new Date().getTime() - (daysBeforeRange * 24 * 60 * 60 * 1000)),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  // Filters states
  const [selectedChain, setSelectedChain] = useState(null);
  const [selectedStores, setSelectedStores] = useState(null);
  const [filteredStores, setFilteredStores] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [selectedStoreType, setSelectedStoreType] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [generalModalOpen, setGeneralModalOpen] = useState(false);
  const [selectedVisualization, setSelectedVisualization] = useState(null);

  const dashboardId = location.state?.dashboardId ? location.state?.dashboardId : queryParameters.get('dashboardId');
  const isOSADashboard = ['586', '581'].includes(dashboardId) // TODO: change this to a more robust way to identify OSA dashboards

  // TODO: remove this when we have a way to know which stores have a darkstore and which ones don't
  const store_types = [
    {
      id: 1,
      name: 'clientstore',
      parsed_name: t('cws_app.general.client_store', 'Client Store')
    },
    {
      id: 2,
      name: 'darkstore',
      parsed_name: t('cws_app.general.dark_store', 'Dark Store')
    }
  ]

  // Lifecycle methods
  useEffect(() => {
    if (!isEmptyOrUndefined(selectedClient, 'object')) {
      getUserStores(selectedClient.client_id);
      setSelectedChain(null);
      setSelectedStores(null);
    }
  }, [selectedClient]);

  useEffect(() => {
    if (!isEmptyOrUndefined(selectedChain, 'object')) {
      getUserSuppliers(selectedClient.client_id, [selectedChain?.chain_name]);
    }
  }, [selectedChain]);

  // Save the selected stores to local storage
  useEffect(() => {
    const localStorageService = new LocalStorageService();
    if (!isEmptyOrUndefined(selectedStores, 'array')) {
      localStorageService.save(localStorageService.paths.home_store, selectedStores);
    }
  }, [selectedStores]);

  // Get selected stores from local storage
  useEffect(() => {
    const localStorageService = new LocalStorageService();
    const localStores = localStorageService.get(localStorageService.paths.home_store);
    if (!isEmptyOrUndefined(localStores, 'array') && !isEmptyOrUndefined(selectedClient, 'object')) {
      const localStoresFiltered = localStores.filter(store => (store.chain_name === selectedClient?.name) || selectedClient?.client_id === ZIPPEDI_CLIENT_ID);
      const chain = localStoresFiltered[0]?.chain_id ? userChains.find(chain => chain.chain_id === localStoresFiltered[0].chain_id) : null
      setSelectedChain(chain);
    } else {
      setSelectedChain([]);
      setSelectedStores([]);
    }
  }, [userChains]);

  // Filter the stores list based on the selected chain. If there's no chain selected, show all the stores
  useEffect(() => {
    const selectedChainID = selectedChain?.chain_id ?? userChains[0]?.chain_id;
    if (!isEmptyOrUndefined(selectedChain, 'object') && isOSADashboard) {
      const filteredStores = userStores.filter(store => store.chain_id === selectedChainID);
      setFilteredStores(filteredStores);
      setSelectedStores(prevState => {
        if (prevState === null) {
          return getFromLocalStorage();
        } else {
          return [];
        }
      });
    } else {
      setFilteredStores(userStores);
    }
  }, [selectedChain, userChains, isOSADashboard])

  // Get categories and subcategories
  useEffect(() => {
    if (!isEmptyOrUndefined(selectedStores, 'array') && selectedStores?.[0]?.store_id !== null) {
      // Gets the categories only from the first store in the list
      getStoreCategories(selectedStores[0].store_id, selectedClient.client_id, true);
    }
  }, [selectedStores])

  // Filter subcategories based on the selected categories
  useEffect(() => {
    if (!isEmptyOrUndefined(categories, 'object') && !isEmptyOrUndefined(categories['sub_categories'], 'array') && !isEmptyOrUndefined(selectedCategories, 'array')) {
      // Keep if the category_value from the subcategories starts with the category_value from the categories
      const filteredSubCat = categories['sub_categories'].filter(category => selectedCategories.some(selectedCategory => category.category_value.startsWith(selectedCategory.category_value)));
      setFilteredSubCategories(filteredSubCat);
      setSelectedSubCategories([]);
    } else {
      setFilteredSubCategories([]);
    }
  }, [categories, selectedCategories])

  // Get dashboard data
  useEffect(() => {
    if (!isEmptyOrUndefined(selectedStores, 'array') && !isEmptyOrUndefined(selectedClient, 'object')) {
      const inputFormat = 'yyyy/MM/dd';
      let selectedStoresParsed = selectedStores?.map(row => row.store_code);
      let startDate, endDate;
      try {
        startDate = format(dateState[0].startDate, null, inputFormat);
        endDate = format(dateState[0].endDate, null, inputFormat);
      } catch (error) {
        console.log('error', error);
        [startDate, endDate] = [null, null];
      }

      const form = {
        dashboard_id: dashboardId,
        client_id: selectedClient.client_id,
        supplier_id: selectedClient?.supplier_id,
        stores: selectedStoresParsed?.length === 0 ? userStores.map(row => row.store_code) : selectedStoresParsed,
        start_date: startDate,
        end_date: endDate
      }

      // Add suppliers to the form
      // TODO: Fix this condition when supplier id filter is standard
      if (isOSADashboard) {
        if (!isEmptyOrUndefined(selectedSuppliers, 'array')) {
          form.suppliers = selectedSuppliers.map(row => row.id);
        } else if (!isEmptyOrUndefined(selectedClient?.supplier_id, 'id')) {
          form.suppliers = [selectedClient?.supplier_id]
        }
      }

      // Add categories to the form
      if (!isEmptyOrUndefined(selectedCategories, 'array')) {
        form.categories = selectedCategories.map(row => row.category_name);
        form.category_level = selectedCategories[0].level;
      }

      // Add subcategories to the form
      if (!isEmptyOrUndefined(selectedSubCategories, 'array')) {
        form.sub_categories = selectedSubCategories.map(row => row.category_name);
        form.sub_category_level = selectedSubCategories[0].level;
      }

      // Add store types to the form
      if (!isEmptyOrUndefined(selectedStoreType, 'array')) {
        form.store_types = selectedStoreType.map(row => row.name);
      }

      setDashboardForm(form);
      if (JSON.stringify(form) !== JSON.stringify(dashboardForm)) {
        getDashboard(form);
      }
    }
  }, [selectedStores, dateState, dashboardId, userStores, selectedSuppliers, selectedCategories, selectedSubCategories, selectedStoreType])

  // Methods

  const getFromLocalStorage = () => {
    const localStorageService = new LocalStorageService();
    const localStores = localStorageService.get(localStorageService.paths.home_store);
    let localStoresFiltered = [];
    if (!isEmptyOrUndefined(localStores, 'array') && !isEmptyOrUndefined(selectedClient, 'object')) {
      localStoresFiltered = localStores.filter(store => (store.chain_name === selectedClient?.name) || selectedClient?.client_id === ZIPPEDI_CLIENT_ID);
    }
    return localStoresFiltered;
  }

  const handleScroll = (event) => {
    setScrollPosition(event.currentTarget.scrollLeft);
  };

  const handleDownload = (raw_data) => {
    const excelDataList = []
    if (dashboardInfo?.visualizations) {
      // Add data for each chart
      dashboardInfo.visualizations.forEach(visualization => {
        if (raw_data) {
          excelDataList.push(downloadOptionsRaw(visualization))
        }
        else {
          excelDataList.push(downloadOptions(visualization))
        }
      })
    }

    const dashboardTitles = dashboardInfo?.visualizations?.map(visualization => toSnakeCase(t(`cws_app.dashboards.${visualization.visualization_name}`, visualization.visualization_name ? visualization.visualization_name : 'Dashboard')));
    const excelDate = format(new Date(), i18n.language, clientFormats.date_format);
    downloadExcel(excelDataList,
      toSnakeCase(t(`cws_app.dashboards.${dashboardInfo?.dashboardTitle}`,
        dashboardInfo?.dashboardTitle ? dashboardInfo?.dashboardTitle : 'Dashboard')),
      false,
      true,
      dashboardTitles,
      excelDate,
    );
    setGeneralModalOpen(false);
  }

  const handleIndividualDownload = (raw_data) => {
    let excelDataList = []
    if (raw_data) {
      excelDataList = downloadOptionsRaw(selectedVisualization);
    }
    else {
      excelDataList = downloadOptions(selectedVisualization);
    }
    const excelDate = format(new Date(), i18n.language, clientFormats.date_format);
    downloadExcel(excelDataList,
      toSnakeCase(t(`cws_app.dashboards.${selectedVisualization.visualization_name}`,
        selectedVisualization.visualization_name ? selectedVisualization.visualization_name : 'Dashboard')),
      false,
      false,
      excelDate);
    setModalOpen(false);
  }

  const checkDownloadDisabled = (visualization) => {
    const excelDataList = downloadOptions(visualization);
    return excelDataList?.length === 0;
  }

  const downloadOptionsRaw = (visualization) => {
    let excelDataList = [];

    // TODO: remove "if" code when Analytics migrates to the new cubes and we can add all the dimensions to one query in the DB (table "metrics")
    if (!isEmptyOrUndefined(visualization, 'object')) {
      if (visualization?.chart_params?.length > 1) {

        // Flat the data to one array
        const flatData = visualization?.chart_params?.flatMap(chart => chart.raw_data);

        const mergedObjects = {};

        for (const obj of flatData) {
          const { creationTimestamp, ...rest } = obj;

          if (!mergedObjects[creationTimestamp]) {
            // If the merged object doesn't exist, create it.
            mergedObjects[creationTimestamp] = { creationTimestamp, ...rest };
          } else {
            // If the merged object already exists, merge the properties.
            Object.assign(mergedObjects[creationTimestamp], rest);
          }
        }

        const result = Object.values(mergedObjects);

        excelDataList = formatToExcel(result, visualization.chart_params[0].chart_target_x)
      } else {
        excelDataList = formatToExcel(visualization?.chart_params?.[0].raw_data, visualization.chart_params[0].chart_target_x)
      }
    }

    // Replace the column name "cat_0" with "main_category"
    if (excelDataList?.[0].includes("cat_0")) {
      excelDataList[0] = excelDataList?.[0].map(x => x === 'cat_0' ? 'main_category' : x);
    }

    // Replace the column name "cat_1" with "sub_category"
    if (excelDataList?.[0].includes("cat_1")) {
      excelDataList[0] = excelDataList?.[0].map(x => x === 'cat_1' ? 'sub_category' : x);
    }

    // Translate the header
    excelDataList[0] = excelDataList[0].map(header => t(`cws_app.dashboards.${header}`, header))

    return excelDataList;
  }

  const downloadOptions = (visualization) => {
    let excelDataList = [];
    if (!isEmptyOrUndefined(visualization, 'object')) {
      if (visualization.visualization_type === 'heatmap') {
        excelDataList = formatNivoToExcel(visualization?.chart_params?.[0]?.data, visualization?.chart_params?.[0].chart_target_x)
      } else if (visualization.visualization_type === 'line_chart' || visualization.visualization_type === 'bar_chart') {
        // TODO: remove "if" code when Analytics migrates to the new cubes and we can add all the dimensions to one query in the DB (table "metrics")
        if (visualization?.chart_params?.length > 1) {
          // Flat the data to one array
          let flatData = [];
          if (visualization.visualization_type === 'line_chart') {
            flatData = visualization?.chart_params?.flatMap(chart => chart.line_data);
          } else {
            flatData = visualization?.chart_params?.flatMap(chart => chart.bar_data);
          }

          const mergedObjects = {};

          for (const obj of flatData) {
            const { creationTimestamp, ...rest } = obj;

            if (!mergedObjects[creationTimestamp]) {
              // If the merged object doesn't exist, create it.
              mergedObjects[creationTimestamp] = { creationTimestamp, ...rest };
            } else {
              // If the merged object already exists, merge the properties.
              Object.assign(mergedObjects[creationTimestamp], rest);
            }
          }

          const result = Object.values(mergedObjects);
          excelDataList = formatToExcel(result, visualization.chart_params[0].chart_target_x)
        } else {
          if (visualization.visualization_type === 'line_chart') {
            excelDataList = formatToExcel(visualization?.chart_params?.[0].line_data, visualization.chart_params[0].chart_target_x)
          } else {
            excelDataList = formatToExcel(visualization?.chart_params?.[0].bar_data, visualization.chart_params[0].chart_target_x)
          }
        }
      } else if (visualization.visualization_type === 'column_grouped_table') {
        const columns = visualization.chart_params[0].columns;
        const rows = visualization.chart_params[0].rows;

        if (!columns.includes('store') && rows.some(row => row.store)) columns.unshift('store');
        excelDataList = formatDataGridToExcel(columns, rows);

        // Format the headers, so that they have the same format as the DataGrid table
        let firstHeader = ['']
        let secondHeader = [t('cws_app.dashboards.Store', 'Store')]
        excelDataList?.[0].forEach((column, index) => {
          const columnParts = column.split('.')
          if (columnParts.length > 1) {
            if (index % 2 === 0) {
              firstHeader.push('')
            } else {
              firstHeader.push(columnParts?.[1])
            }
            secondHeader.push(t(`cws_app.dashboards.${columnParts?.[0]}`, columnParts?.[0]))
          }
        })
        excelDataList = [firstHeader, secondHeader, ...excelDataList.slice(1)]
      } else if (visualization.visualization_type === 'row_grouped_table') {
        const columns = visualization.chart_params[0].columns;
        const rows = visualization.chart_params[0].rows;

        if (!columns.includes('store') && rows.some(row => row.store)) columns.unshift('store');
        excelDataList = formatDataGridToExcel(columns, rows, visualization.chart_params[0].chart_target_x);
      }
    }

    // Translate the header
    excelDataList[0] = excelDataList[0]?.map(header => t(`cws_app.dashboards.${header}`, header))

    return excelDataList;
  }

  return (
    <Grid container pt={4} px={2}>
      <Modal open={modalOpen} setOpen={setModalOpen} title={t('cws_app.dashboards.downloadModalTitle')}
        description={t('cws_app.dashboards.downloadModalDescription')} >
        <Button variant='contained' sx={{ backgroundColor: 'gray' }} onClick={() => handleIndividualDownload(false)}>{t('cws_app.dashboards.dashboardData')}</Button>
        <Button variant='contained' color='secondary' onClick={() => handleIndividualDownload(true)}>{t('cws_app.dashboards.rawData')}</Button>
      </Modal>

      <Modal open={generalModalOpen} setOpen={setGeneralModalOpen} title={t('cws_app.dashboards.downloadModalTitle')}
        description={t('cws_app.dashboards.downloadModalDescription')} >
        <Button variant='contained' sx={{ backgroundColor: 'gray' }} onClick={() => handleDownload(false)}>{t('cws_app.dashboards.dashboardData')}</Button>
        <Button variant='contained' color='secondary' onClick={() => handleDownload(true)}>{t('cws_app.dashboards.rawData')}</Button>

      </Modal>
      <Grid container spacing={2}>
        {/* Title and subtitle */}
        <Grid container item justifyContent='space-between'>
          <Grid item>
            <Typography variant="h4">{dashboardInfo?.dashboardTitle ? t(`cws_app.dashboards.${dashboardInfo?.dashboardTitle}`, dashboardInfo?.dashboardTitle) : ''}</Typography>
            <Typography variant="subtitle1" noWrap sx={{ textAlign: 'start', fontSize: 'small', width: '100%' }}>{dashboardInfo?.dashboardSubtitle ? t(`cws_app.dashboards.${dashboardInfo?.dashboardSubtitle}`, dashboardInfo?.dashboardSubtitle) : ''}</Typography>
          </Grid>


          {/* Download button */}
          {!isEmptyOrUndefined(selectedStores, 'array') && !isEmptyOrUndefined(dashboardInfo, 'object') &&
            <Grid item>
              <LoadingButton variant='contained' onClick={() => setGeneralModalOpen(true)}>
                <Grid container alignItems='center' spacing={1} >
                  <Grid item xs>
                    <IconComponent iconName={'download-outline'} style={{ fontSize: '18px' }} />
                  </Grid>
                  <Grid item xs>
                    <Typography>{t('cws_app.general.download', 'Download')}</Typography>
                  </Grid>
                </Grid>
              </LoadingButton>
            </Grid>
          }
        </Grid>
        {/* Filters */}
        <Grid container item spacing={1}>
          {/* Date filter */}
          <Grid item>
            <DateRange inputDateState={dateState} inputSetDateState={setDateState} days_limitation={90} clientFormats={clientFormats} />
          </Grid>
          {/* Chain filter */}
          {/* Only show the chain filter if there's more than 1 chain */}
          {isOSADashboard && userChains?.length > 1 &&
            <Grid item minWidth='10em' sx={{ flexGrow: 1 }}>
              <Selector
                disabled={userChains?.length > 0 ? false : true}
                options={userChains}
                value={selectedChain}
                setValue={setSelectedChain}
                label={t('cws_app.general.sel_chain', 'Chain')}
                name='chain_name'
                loading={isLoadingUserStores}
              />
            </Grid>}
          {/* Store filter */}
          <Grid item minWidth='10em' sx={{ flexGrow: 1 }}>
            <MultiSelector
              disabled={filteredStores?.length > 0 ? false : true}
              options={filteredStores}
              groupBy={'chain_name'}
              value={selectedStores !== null ? selectedStores : []}
              setValue={setSelectedStores}
              label={t('cws_app.general.Stores', 'Stores')}
              name='parsed_name'
              tagName='store_code'
              loading={isLoadingUserStores}
              limitTags={isOSADashboard ? 1 : 7}
            />
          </Grid>
          {/* Supplier filter */}
          {/* Only show the supplier filter if the client is not a supplier */}
          {isOSADashboard && isEmptyOrUndefined(selectedClient?.supplier_id, 'id') &&
            <Grid item minWidth='10em' sx={{ flexGrow: 1 }}>
              <MultiSelector
                disabled={suppliers?.length > 0 ? false : true}
                options={suppliers}
                value={selectedSuppliers}
                setValue={setSelectedSuppliers}
                label={t('cws_app.general.Supplier', 'Supplier')}
                name='name'
                loading={isLoadingSuppliers}
                limitTags={1}
              />
            </Grid>}
          {/* Categories filter */}
          {isOSADashboard &&
            <Grid item minWidth='10em' sx={{ flexGrow: 1 }}>
              <MultiSelector
                disabled={categories?.categories?.length > 0 ? false : true}
                options={categories?.categories}
                value={selectedCategories}
                setValue={setSelectedCategories}
                label={t('cws_app.general.categories', 'Categories')}
                name='category_name'
                loading={isLoadingCategories}
                limitTags={1}
              />
            </Grid>}
          {/* SubCategories filter */}
          {isOSADashboard &&
            <Grid item minWidth='10em' sx={{ flexGrow: 1 }}>
              <MultiSelector
                disabled={filteredSubCategories?.length > 0 ? false : true}
                options={filteredSubCategories}
                value={selectedSubCategories}
                setValue={setSelectedSubCategories}
                label={t('cws_app.general.Sub-Categories', 'Sub-Categories')}
                name='category_name'
                loading={isLoadingCategories}
                limitTags={1}
              />
            </Grid>}
          {/* Store types filter */}
          {/* Only for Jumbo and Zippedi clients */}
          {isOSADashboard && (selectedClient?.client_id === ZIPPEDI_CLIENT_ID || selectedClient?.client_id === JUMBO_CLIENT_ID) &&
            <Grid item minWidth='10em' sx={{ flexGrow: 1 }}>
              <MultiSelector
                disabled={store_types?.length > 0 ? false : true}
                options={store_types}
                value={selectedStoreType}
                setValue={setSelectedStoreType}
                label={t('cws_app.general.Store type', 'Store type')}
                name='parsed_name'
                limitTags={2}
              />
            </Grid>}
        </Grid>
        {/* Loading bar */}
        {isLoadingDashboard ?
          <LinearProgress sx={{ width: '100%', my: 2 }} color="secondary" />
          :
          <Grid container item>
            {/* Tables and charts */}
            {isEmptyOrUndefined(selectedStores, 'array') ?
              <Paper component={Grid} container item justifyContent='center'>
                <AlertBox
                  content={t('cws_app.general.select_information_to_display', 'Select information to display')}
                />
              </Paper>
              :
              isEmptyOrUndefined(dashboardInfo?.visualizations, 'array') && !isEmptyOrUndefined(selectedStores, 'array') ?
                <Paper component={Grid} container item justifyContent='center'>
                  {/* No data to display */}
                  <AlertBox
                    content={t('cws_app.general.No_data', 'No data to display.')}
                  />
                </Paper>
                :
                <Grid container justifyContent='space-between'>
                  {dashboardInfo?.visualizations?.flatMap((visualization, index) => {
                    const md = visualization?.visualization_params?.charts_per_row ? 5.95 : 12;

                    return (
                      <Visualization
                        key={index}
                        md={md}
                        visualization={visualization}
                        isLoadingExcelDownload={isLoadingExcelDownload}
                        checkDownloadDisabled={checkDownloadDisabled}
                        setSelectedVisualization={setSelectedVisualization}
                        setModalOpen={setModalOpen}
                        isLoadingDashboard={isLoadingDashboard}
                        handleScroll={handleScroll}
                        filteredStores={filteredStores}
                        scrollPosition={scrollPosition}
                      />
                    )

                  })}
                </Grid>
            }
          </Grid >
        }
      </Grid >

    </Grid >
  );
}
