import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Link,
  styled,
  Typography,
} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AlertBox from '../../tools/AlertBox';

import StyledPaper from '../../tools/StyledPaper';
import Metrics from './Metrics';
import Visualizations from './Visualizations';

const styles = {
  divider: {
    marginTop: 8,
    marginBottom: 8,
    width: '-webkit-fill-available',
  },
};

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    overflow: 'hidden',
    backgroundColor: '#ffffffea',
    color: '#1a242d',
    minWidth: '18em',
    maxWidth: 420,
    maxHeight: '20em',
    fontSize: theme.typography.pxToRem(12),
    // border: '1px solid #cacadd',
    padding: '1em',
    boxShadow: '2px 2px 7px 0px #1a242d75',
    '-webkit-box-shadow': '2px 2px 7px 0px #1a242d75',
    '-moz-box-shadow': '2px 2px 7px 0px #1a242d75',
  },
}));

export default function DashboardPreview(props) {
  const {
    dashboard,
    lastResumeDashboardProcessed,
    isLoading,
    inputDashboardsResume,
    deleteCachedDashboard,
    selectedClientName,
    openQuickAccess,
    isLoadingNumberFormat,
    numberFormat,
    downloadExcel,
    isLoadingExcelDownload,
    currencySymbol,
    clientFormats,
  } = props;
  const { t } = useTranslation();
  let navigate = useNavigate();

  useEffect(() => { }, [lastResumeDashboardProcessed, isLoading]);

  const handleNavigate = () => {
    deleteCachedDashboard();
    navigate(`/cpg/dashboards?dashboardId=${dashboard.dashboard_id}`, {
      state: {
        dashboardId: dashboard.dashboard_id,
        dashboardTitle: dashboard?.dashboard_name,
      },
    });
  };

  return (
    <StyledPaper
      elevation={0}
      sx={{ width: '100%', minHeight: 200, px: 2, py: 3 }}>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        rowSpacing={1}
        columnSpacing={3}>
        <Grid item xs={9}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6">
              {t(
                `cws_app.general.${dashboard?.dashboard_name}`,
                dashboard?.dashboard_name,
              )}
            </Typography>
            {inputDashboardsResume &&
              dashboard?.dashboard_name === 'Navigation' &&
              inputDashboardsResume[dashboard.dashboard_id] ? (
              <HtmlTooltip
                // leaveDelay={100000}
                title={
                  <>
                    {inputDashboardsResume[
                      dashboard.dashboard_id
                    ].navigation_status?.total_resumes?.map((resume, index) => (
                      <Fragment key={index}>
                        <Typography color="inherit">
                          {t(`cws_app.home.${resume.status}`)}:{' '}
                          <b>{resume.count}</b>
                        </Typography>
                      </Fragment>
                    ))}
                  </>
                }
                followCursor>
                <Box
                  size="small"
                  sx={{
                    ml: 2,
                    backgroundColor: '#1a242d',
                    color: 'white',
                    padding: '0.2em 0.7em',
                    borderRadius: '1em',
                    fontSize: 'small',
                  }}>
                  {inputDashboardsResume[
                    dashboard.dashboard_id
                  ].navigation_status?.total_resumes?.find(
                    row => row.status === 'incompleted',
                  )
                    ? t('cws_app.home.in_progress', 'In Progress')
                    : t('cws_app.home.stores_completed', 'Completed')}
                </Box>
              </HtmlTooltip>
            ) : null}
          </Box>
          <Typography variant="subtitle1">
            {t(
              `cws_app.general.${dashboard?.dashboard_description}`,
              dashboard?.dashboard_description,
            )}
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          display="flex"
          justifyContent="end"
          sx={{ alignSelf: 'normal' }}>
          <Link
            component="button"
            variant="body2"
            disableElevation
            color="primary"
            onClick={handleNavigate}>
            {t('cws_app.home.view_historical', 'View Historical')}
          </Link>
        </Grid>

        <Divider variant="middle" style={styles.divider} />

        {isLoading === undefined || isLoading ?
          <Grid item xs display="flex" justifyContent="center">
            <CircularProgress color="secondary" />
          </Grid>
          : inputDashboardsResume && inputDashboardsResume?.[dashboard.dashboard_id]?.components?.length ?
            inputDashboardsResume[dashboard.dashboard_id].components.map((component, index) => (
              <Fragment key={index}>
                {
                  component.visualization_type === 'kpi_metric'
                    || component.visualization_type === 'kpi_bar_metric' ?
                    <Metrics
                      {...component}
                      openQuickAccess={openQuickAccess}
                      selectedClientName={selectedClientName}
                      totalComponents={inputDashboardsResume[dashboard.dashboard_id].components.length}
                      isLoadingNumberFormat={isLoadingNumberFormat}
                      numberFormat={numberFormat}
                      vis_type={component.visualization_type}
                      downloadExcel={downloadExcel}
                      isLoadingExcelDownload={isLoadingExcelDownload}
                      metricStyle={component.metric_style}
                      currencySymbol={currencySymbol}
                      clientFormats={clientFormats}
                    />
                    :
                    <Grid item xs={5}>
                      <Visualizations title={component.title} />
                    </Grid>
                }
              </Fragment>
            ))
            : (
              <AlertBox
                content={t('cws_app.general.No_data', 'No data to display.')}
                sx={{ px: 2 }}
              />
            )
        }

      </Grid>
    </StyledPaper >
  );
}
