import { connect } from 'react-redux';
import Users from '../../../../components/userManagement/users/Users';
import { getChains } from 'core/slices/backend';
import { getProductsRoles } from 'core/slices/products';
import { deleteUser, getUsers, getUserClients, getUserStores, postUser, updateUser } from 'core/slices/users';
import {
  getSelectedClient,
  users,
  isLoadingUsers,
  deleteUserResponse,
  isLoadingDeleteUser,
  getUserClientList,
  getChainsList,
  isLoadingChains,
  getUserStoresAllList,
  isLoadingUserStores,
  productsRoles,
  isLoadingProductsRoles,
  userClients,
  isLoadingUserClients,
  userMGMTStores,
  updateUserResponse,
  isLoadingUpdateUser,
  postUserResponse,
  isLoadingPostUser,
} from 'core/selectors';

export default connect(state => ({
  selectedClient: getSelectedClient(state),
  users: users(state),
  isLoadingUsers: isLoadingUsers(state),
  postResponse: postUserResponse(state),
  isLoadingPost: isLoadingPostUser(state),
  updateResponse: updateUserResponse(state),
  isLoadingUpdate: isLoadingUpdateUser(state),
  deleteResponse: deleteUserResponse(state),
  isLoadingDelete: isLoadingDeleteUser(state),
  clientList: getUserClientList(state),
  chainsList: getChainsList(state),
  isLoadingChains: isLoadingChains(state),
  userStores: getUserStoresAllList(state),
  isLoadingUserStores: isLoadingUserStores(state),
  userMGMTStores: userMGMTStores(state),
  productsRoles: productsRoles(state),
  isLoadingProductsRoles: isLoadingProductsRoles(state),
  userClients: userClients(state),
  isLoadingUserClients: isLoadingUserClients(state),
}), {
  getUsers,
  getUserClients,
  postUser,
  updateUser,
  deleteUser,
  getChains,
  getUserStores,
  getProductsRoles,
})(Users);
