import { saveAs } from 'file-saver';
import { Workbook } from 'exceljs';
import { formatDate } from '../utils/parsers';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

export class ExcelExporter {
  constructor() { }

  exportAsExcelFile({ excelData, excelFileName, canFormatData = true, multipleSheets = false, multipleSheetsNames = [], excelDate = null }) {
    const workbook = new Workbook();
    if (multipleSheets) {
      excelData.forEach((sheetData, index) => {
        const workSheet = multipleSheetsNames?.length ? workbook.addWorksheet(`${multipleSheetsNames[index]}_${index}`) : workbook.addWorksheet(`${excelFileName}_${index}`);
        Object.keys(excelData[0]).map((item, index) => {
          const dobCol = workSheet.getColumn(index + 1);
          dobCol.width = 14;
        })
        workSheet.addRows(sheetData);
        workSheet.getRow(1).font = { bold: true };
      })
    } else {
      const sheet = workbook.addWorksheet(excelFileName);
      const columns = [];

      if (canFormatData) {
        Object.keys(excelData[0]).map(item => {
          columns.push({
            header: item,
            key: item
          });
        })
        sheet.columns = columns;

        const firstCol = sheet.getColumn(1);
        const secCol = sheet.getColumn(2);
        firstCol.width = 16;
        secCol.width = 16;
      } else {
        Object.keys(excelData[0]).map((item, index) => {
          const dobCol = sheet.getColumn(index + 1);
          dobCol.width = 14;
        })
      }

      sheet.addRows(excelData);
      sheet.getRow(1).font = { bold: true };
    }

    const currentDate = excelDate ? excelDate : formatDate(new Date(), 'yyyy-MM-dd');
    workbook.xlsx.writeBuffer()
      .then(function (buffer) {
        const data = new Blob([buffer], { type: EXCEL_TYPE });
        saveAs(data, excelFileName + '_export_' + currentDate + EXCEL_EXTENSION);
      });
  }
}
