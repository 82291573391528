import React, { useContext } from 'react';

import {
    Box,
    Grid,
    Typography,
} from '@mui/material';
import { getConsolidatedTranslations } from 'core/utils/parsers';
import { useTranslation } from 'react-i18next';

// My Components
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { DigitalShelfContext } from '../../../contexts/DigitalShelf';
import { checkColor } from '../../utils/colors';
import CustomCardContent from './CustomCardContent';

export default function ProductMetadata(props) {
    const {
        currencySymbol,
        clientFormats,
    } = props;
    const { t } = useTranslation();

    // Context
    const {
        dateState,
        selectedStore,
        wallSignedUrl,
        selectedProduct,
        missingLabelInfo,
        zoomedWallImageStyle,
        misplacedSignedImages,
    } = useContext(DigitalShelfContext);

    // handlers
    const checkIconName = product => {
        let iconName = 'stock';
        if (product.hasOwnProperty('stockout')) {
            // promotions can be isolated with this condition
            if (product.stockout) iconName = 'stock-out';
            else if (product.price_difference) iconName = 'price-difference';
            else if (product.extra_label) iconName = 'price-difference';
            else if (product.misplaced_product) iconName = 'misplaced-product';
            else if (product.landmark_type) iconName = 'location';
            else if (product.spread_to_fill) iconName = 'stock-out';
        } else {
            iconName = 'promotion';
        }
        return iconName;
    };

    return (
        (selectedProduct?.texts || selectedProduct?.landmark ||
            (selectedProduct && checkIconName(selectedProduct) === 'promotion')) ? (
            <Box sx={{ color: 'white' }}>
                <Typography
                    gutterBottom
                    sx={{ fontWeight: 'bold', mt: 0.5, px: 0, color: 'white' }}>
                    {selectedProduct?.texts
                        ? selectedProduct?.texts
                        : selectedProduct?.landmark
                            ? getConsolidatedTranslations(
                                t,
                                selectedStore?.chain_name,
                                'landmark',
                                'Walls',
                                'landmark',
                            ) +
                            ': ' +
                            selectedProduct?.landmark
                            : selectedProduct.description}
                </Typography>
                {!selectedProduct.landmark_type ? (
                    <Grid
                        item
                        container
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{
                            mb: 0.5,
                            height: '1.6em',
                            width: '1.6em',
                            borderRadius: 1,
                        }}
                        bgcolor={checkColor(selectedProduct)}>
                        {checkIconName(selectedProduct) === 'promotion' ? (
                            <Typography
                                sx={{
                                    fontSize: '1.2em',
                                    fontWeight: 'bold',
                                    color: '#000',
                                }}>
                                {' '}
                                %{' '}
                            </Typography>
                        ) : (
                            <IconComponent
                                iconName={checkIconName(selectedProduct)}
                                width={'1.4em'}
                                height={'1.4em'}
                                sx={{ fontSize: '6em' }}
                            />
                        )}
                    </Grid>
                ) : null}
                <Grid
                    item
                    sx={{
                        overflow: 'hidden',
                        maxHeight: '178px',
                        width: '100%',
                        borderRadius: 2,
                    }}>
                    {wallSignedUrl && selectedProduct?.landmark_type !== 'calculated' ? (
                        <Box sx={zoomedWallImageStyle}>
                            <img
                                alt=""
                                id="zoomedWallImage"
                                src={wallSignedUrl}
                            />
                            <canvas
                                id="misplaced_bbox"
                                width="5000"
                                height="5000"
                                style={{ position: 'absolute', top: 0, left: 0 }}
                            />
                        </Box>
                    ) : (
                        <canvas id="misplaced_bbox" width={1} height={1} />
                    )}
                </Grid>
                <CustomCardContent
                    selectedProduct={selectedProduct}
                    selectedStore={selectedStore}
                    dateState={dateState}
                    clientFormats={clientFormats}
                    misplacedSignedImages={misplacedSignedImages}
                    missingLabelInfo={missingLabelInfo}
                    currencySymbol={currencySymbol}
                />
            </Box>
        )
            :
            <Box sx={{ color: 'white' }}>
                <Box sx={{
                    height: '10em', width: '100%', border: '1px solid #16181a', mb: 3, borderRadius: '5px', boxShadow: 1,
                    display: 'flex', justifyContent: 'center', alignItems: 'center'
                }}>
                    <h3>
                        {t('cws_app.general.no_info_hovered', 'No Info Hovered')}
                    </h3>
                </Box>
                <Box sx={{ height: '14em', width: '100%', border: '1px solid #16181a', borderRadius: '5px', boxShadow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <h3>
                        {t('cws_app.general.no_data_to_show', 'No Data to Show')}
                    </h3>
                </Box>
            </Box>
    )
}
