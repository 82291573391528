import React from 'react';
import { Grid, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getConsolidatedTranslations } from 'core/utils/parsers';

export default function TaskProgress(props) {
  const { chain, data } = props;
  const { t } = useTranslation();

  const tooltipTitle = (manual, automatic) => {
    return manual != null && automatic != null ? (
      <>
        <Typography>
          <b>{t('cws_app.digital_store.manual_tasks', 'Manual')}</b>: {manual}
        </Typography>
        <Typography>
          <b>{t('cws_app.digital_store.automatic_tasks', 'Automatic')}</b>:{' '}
          {automatic}
        </Typography>
      </>
    ) : (
      ''
    );
  };
  return (
    <>
      {data[0]?.key === 'total_landmarks' ||
        data[1]?.key === 'total_landmarks' ? (
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
          sx={{ justifyContent: 'center' }}
        >
          {data[0]?.value != null ? (
            <Grid item xs={4} sm={4} md={4} lg={3}>
              <Tooltip
                title={tooltipTitle(
                  data[0]?.total_manual_auto_from?.[0],
                  data[0]?.total_manual_auto_from?.[1],
                )}>
                <Paper>
                  <Stack py={1} px={2} alignItems="center">
                    <Typography>{data[0]?.value}</Typography>
                    <Typography>
                      {t(`cws_app.digital_store.total_aisles`, 'Total Aisles')}
                    </Typography>
                  </Stack>
                </Paper>
              </Tooltip>
            </Grid>
          ) : null}
          {
            data.find(item => item.key === 'task_counter')?.value.map(taskCounter => (
              <Grid item xs={4} sm={4} md={4} lg={3}>
                <Paper>
                  <Stack py={1} px={2} alignItems="center">
                    <Typography color="secondary.main">
                      {taskCounter.value}
                    </Typography>
                    <Typography>
                      {t(`Chains.${chain}.${taskCounter.key}`, `${taskCounter.key}`)}
                    </Typography>
                  </Stack>
                </Paper>
              </Grid>
            ))
          }

        </Grid>
      ) : (
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
          sx={{ justifyContent: 'center' }}
        >
          {data[0]?.value != null ? (
            <Grid item xs={4} sm={4} md={4} lg={3}>
              <Tooltip
                title={tooltipTitle(
                  data[0]?.total_manual_auto_from?.[0],
                  data[0]?.total_manual_auto_from?.[1],
                )}>
                <Paper>
                  <Stack py={1} px={2} alignItems="center">
                    <Typography>{data[0]?.value}</Typography>
                    <Typography>
                      {getConsolidatedTranslations(
                        t,
                        chain,
                        'created_task',
                        'digital_store',
                        'created_task',
                      )}
                    </Typography>
                  </Stack>
                </Paper>
              </Tooltip>
            </Grid>
          ) : null}
          {data[1]?.pending != null ? (
            <Grid item xs={4} sm={4} md={4} lg={3}>
              <Paper>
                <Stack py={1} px={2} alignItems="center">
                  <Typography color="secondary.main">
                    {data[1]?.pending}
                  </Typography>
                  <Typography>
                    {getConsolidatedTranslations(
                      t,
                      chain,
                      'pending_task',
                      'digital_store',
                      'pending_task',
                    )}
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
          ) : null}
          {data[1]?.value != null ? (
            <Grid item xs={4} sm={4} md={4} lg={3}>
              <Tooltip
                title={tooltipTitle(
                  data[1]?.total_manual_auto?.[0],
                  data[1]?.total_manual_auto?.[1],
                )}>
                <Paper>
                  <Stack py={1} px={2} alignItems="center">
                    <Typography color="info.main">{data[1]?.value}</Typography>
                    <Typography>
                      {getConsolidatedTranslations(
                        t,
                        chain,
                        'completed_task',
                        'digital_store',
                        'completed_task',
                      )}
                    </Typography>
                  </Stack>
                </Paper>
              </Tooltip>
            </Grid>
          ) : null}
          {data[0]?.value != null && data[1]?.value != null ? (
            <Grid item xs={4} sm={4} md={4} lg={3}>
              <Paper>
                <Stack py={1} px={2} alignItems="center">
                  <Typography>
                    {Math.round((data[1]?.value * 100) / data[0]?.value)}%
                  </Typography>
                  <Typography>
                    {t(`cws_app.digital_store.progress`, 'Progress')}
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
          ) : null}
        </Grid>
      )}
    </>
  );
}
