import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import {
    Link, List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Chip,
    Tooltip,
} from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

import UserRestricted from '../../tools/CheckUserType';
import { isEmptyOrUndefined } from "core/utils/validators";

const StyledPaper = styled(props => (
    <Paper
        elevation={0}
        sx={{ width: '-webkit-fill-available' }}
        {...props}
    />
))(({ theme }) => {
    return ({
        backgroundColor: theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.paper.main,
        color: 'white',
    })
}
);

const SubsectionNav = styled(List)({
    '& .MuiListItemButton-root': {
        paddingLeft: 18,
        paddingRight: 24,
    },
    '& .MuiListItemIcon-root': {
        minWidth: 0,
        marginRight: 16,
    },
    '& .MuiSvgIcon-root': {
        fontSize: 20,
    },
});

export default function CustomizedList(props) {
    const {
        subSections,
        subSectionName,
        subSectionDescription,
        subSectionIcon,
        secondarySectionDescription,
        isNewView = false,
        isLegacyView = false,
        styles,
        forceClose,
        canShowItemscolapsed = true,
        selectedClient,
    } = props
    const [openSubSection, setOpenSubSection] = React.useState(true);
    const { t } = useTranslation();

    const [userRestricted, setUserRestricted] = React.useState(false);

    React.useEffect(() => {
        if (!isEmptyOrUndefined(selectedClient, 'dict')) {
            setUserRestricted(UserRestricted(selectedClient, ['zippedi', 'supplier']));
        }
    }, [selectedClient]);

    return (
        <Box sx={{ display: 'flex' }}>
            <StyledPaper>
                <SubsectionNav component="nav" disablePadding>
                    <Box
                        sx={{
                            pb: (!forceClose && openSubSection) ? 2 : 0,
                        }}
                    >
                        {(forceClose && canShowItemscolapsed) ? null :
                            <>
                                <ListItemButton
                                    disableTouchRipple
                                    alignItems="flex-start"
                                    onClick={() => setOpenSubSection(!openSubSection)}
                                    sx={{
                                        px: forceClose ? 0 : 3,
                                        pt: 2.5,
                                        pb: (!forceClose && openSubSection) ? 0 : 2.5,
                                        '&:hover': {
                                            backgroundColor: 'rgba(98, 98, 130, 0.1)',
                                            '& svg': { opacity: 1 },
                                        },
                                        '&:focus': {
                                            '& svg': { opacity: 1 },
                                        },
                                    }}
                                >
                                    {subSectionIcon &&
                                        <ListItemIcon sx={[styles.itemIcon, { mr: (!forceClose && openSubSection) ? 3 : 'auto' }]}>
                                            <IconComponent
                                                iconName={subSectionIcon}
                                                style={styles.icon} />
                                        </ListItemIcon>
                                    }
                                    <ListItemText
                                        primary={forceClose ? '' : subSectionName}
                                        primaryTypographyProps={{
                                            fontSize: 15,
                                            fontWeight: 'medium',
                                            lineHeight: '20px',
                                            mb: '2px',
                                        }}
                                        secondary={forceClose ? '' : secondarySectionDescription}
                                        secondaryTypographyProps={{
                                            noWrap: true,
                                            fontSize: 12,
                                            lineHeight: '16px',
                                            color: 'rgba(255,255,255,0.5)',
                                        }}
                                        sx={{ my: 0, }}
                                    />

                                    {
                                        isNewView ?
                                            <Chip label="New" size="small" color="info" />
                                            : isLegacyView ? // TODO: delete on deprecation of cpg
                                                <Chip label="Legacy" size="small" color="warning"
                                                    sx={{
                                                        ml: 2,
                                                        height: '18px',
                                                        width: '50px',
                                                        fontSize: '10px',
                                                        fontWeight: 'bold',
                                                        color: 'white'
                                                    }}
                                                />
                                                : null
                                    }
                                    <KeyboardArrowDown
                                        sx={{
                                            mr: -1,
                                            opacity: 0,
                                            transform: openSubSection ? 'rotate(-180deg)' : 'rotate(0)',
                                            transition: '0.2s',
                                        }}
                                    />
                                </ListItemButton>
                            </>
                        }
                        {((!forceClose && openSubSection) || (canShowItemscolapsed && forceClose)) &&
                            subSections.map((viewInfo, index) => {
                                let chain_name = subSectionDescription === 'dashboards'
                                    && viewInfo.chain_name && selectedClient?.name !== viewInfo.chain_name
                                    ? ` - ${viewInfo.chain_name}` : ''; // TODO: delete chain_name on deprecation of cpg

                                if (viewInfo.description === 'tracking_list_admin' && userRestricted) {
                                    return null;
                                }
                                return (
                                    <ListItemButton
                                        key={viewInfo.description + isLegacyView ? `${viewInfo.report_id}-${index}` : `()-${index}`}
                                        sx={{ display: 'block', py: 0, minHeight: 32 }}
                                    >
                                        <Link href={viewInfo.path}
                                            sx={{ textDecoration: 'none' }}
                                            target={isLegacyView ? "_blank" : ''}
                                            rel={isLegacyView ? "noreferrer" : ''}
                                        >
                                            <ListItemButton sx={[styles.itemButton, { justifyContent: (!forceClose && openSubSection) ? 'flex-end' : 'center' },]}>
                                                {
                                                    ((forceClose || !openSubSection) && subSectionIcon) ?
                                                        <ListItemIcon sx={[styles.itemIcon, { mr: (!forceClose && openSubSection) ? 3 : 'auto' }]}>
                                                            <IconComponent
                                                                iconName={subSectionIcon}
                                                                style={styles.icon} />
                                                        </ListItemIcon> :
                                                        viewInfo.IconTag ?
                                                            <ListItemIcon sx={[styles.itemIcon, { mr: (!forceClose && openSubSection) ? 3 : 'auto' }]}>
                                                                <viewInfo.IconTag style={styles.icon} />
                                                            </ListItemIcon> :
                                                            <></>
                                                }
                                                <Tooltip title={t(`cws_app.general.${viewInfo.description}`, viewInfo.description) + chain_name}>
                                                    <ListItemText
                                                        style={styles.text}
                                                        primaryTypographyProps={{
                                                            style: {
                                                                textOverflow: 'ellipsis', 'whiteSpace': 'nowrap', overflow: 'hidden'
                                                            }
                                                        }}
                                                        primary={t(`cws_app.general.${viewInfo.description}`, viewInfo.description) + chain_name}
                                                        sx={{ opacity: (!forceClose && openSubSection) ? 1 : 0 }} />

                                                </Tooltip>
                                            </ListItemButton>
                                        </Link>
                                    </ListItemButton>
                                )
                            })}
                    </Box>
                </SubsectionNav>
            </StyledPaper>
        </Box>
    );
}