import { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useTranslation } from 'react-i18next';
import { Paper } from '@mui/material';

export default function DatePicker(props) {
	const { date, setDate, labelInput = '', defaultDate = new Date(), inputFormat = 'dd/MM/yyyy', minDate = null, disabled = false } = props;
	const { t } = useTranslation();

	useEffect(() => {
		if (!date) {
			setDate(defaultDate);
		}
	}, [date, setDate, defaultDate])

	const handleChange = (newValue) => {
		setDate(newValue);
	};

	return (
		<Paper elevation={0}>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<DesktopDatePicker
					label={t(labelInput, '')}
					inputFormat={inputFormat}
					value={date}
					onChange={handleChange}
					minDate={minDate}
					disabled={disabled}
					renderInput={(params) => <TextField sx={{ width: '100%' }} {...params} />}
				/>
			</LocalizationProvider>
		</Paper>
	);
}
