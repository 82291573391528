import { useEffect, useState } from "react";
import { Paper} from "@mui/material";
import { styled } from '@mui/material/styles';

import { DataGrid } from "@zippeditoolsjs/table";

const getColumnWidth = () => {
    const windowWidth = window.innerWidth;
    return (0.8 * windowWidth) / 7;
}

const formatColumns = (t) => {
    const dynamicWidth = getColumnWidth();
    const columns = [
      {
        field: 'status_zone',
        headerName: t('cws_app.general.Store_type', 'headers'),
        width: dynamicWidth -30,
        align: 'left',
      },
      {
        field: 'item',
        headerName: t('cws_app.general.Item', 'headers'),
        align: 'left',
        width: dynamicWidth - 30,
      },
      {
        field: 'description',
        headerName: t('cws_app.tracking.Description', 'headers'),
        align: 'left',
        width: dynamicWidth + 90,
      },
      {
        field: 'store',
        headerName: t('cws_app.general.Store', 'headers'),
        align: 'left',
        width: dynamicWidth,
      },
      {
        field: 'status',
        headerName: t('cws_app.general.Last_state', 'headers'),
        align: 'left',
        width: dynamicWidth,
      },
      {
        field: 'stock',
        headerName: t('cws_app.general.Referencial_stock', 'headers'),
        align: 'center',
        width: dynamicWidth - 30,
      },
      {
        field: 'consecutive_days',
        headerName: t('cws_app.general.Consecutives_days', 'headers'),
        align: 'center',
        width: dynamicWidth - 30,
      }
    ];
  
    return columns;
  };

export default function DataTable(props) {
    const{
        t,
        productsStates,
        isLoadingProductsStates
    } = props;
    
    const [columns, setColumns] = useState([]);

    // Reset columns when theme changes (because the icons don't change the color when the theme changes)
    useEffect(() => {
        setColumns(formatColumns(t));
      }, [t]);

  return (
    <>
        <StyledPaper>
            <DataGrid
                getRowId={row => row.index}
                rows={productsStates}
                columns={columns}
                disableExportExcel={false}
                exportTranslations={{
                  'excel': t('cws_app.general.export_xls', 'Export Excel'),
                  'json': t('cws_app.general.export_json', 'Export JSON')
                }}
                localeText={{
                  toolbarFilters: t('cws_app.general.filters', 'Filters'),
                  columnMenuSortAsc: t('cws_app.general.sort_asc','Sort by ASC'),
                  columnMenuSortDesc: t('cws_app.general.sort_desc', 'Sort by DESC'),
                  toolbarExport: t('cws_app.general.export', 'Export'),
                  toolbarQuickFilterPlaceholder: t('cws_app.general.search', 'Search'),
                }}
                initialState={{
                pagination: {
                    paginationModel: {
                    pageSize: 7,
                    },
                },
                }}
                autoreset={false}
                pageSizeOptions={[7]}
                disableColumnSelector={true}
                disableRowSelect={true}
                loading={isLoadingProductsStates}
            />
        </StyledPaper>
    </>
  )
}

const StyledPaper = styled(props => {
    return <Paper elevation={1} {...props} />;
  })(({ theme }) => {
    return {
      width: '100%',
      background:
        theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
      '& .MuiDataGrid-columnHeaders': {
        backgroundColor:
          theme.palette.type === 'light'
            ? theme.palette.primary.main
            : theme.palette.paper.main,
        color: 'white',
      },
      '& .MuiDataGrid-cell': {
        background:
          theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
        color: theme.palette.type === 'light' ? 'black' : 'white',
      },
      '& .MuiDataGrid-columnsPanel': {
        background:
          theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
        color: theme.palette.type === 'light' ? 'black' : 'white',
      },
      '& .MuiDataGrid-menuIconButton': {
        opacity: 1,
        color: 'white',
      },
      '& .MuiDataGrid-sortIcon': {
        color: 'white',
      },
    };
  });
