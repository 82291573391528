import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { styled, } from '@mui/material/styles';
import { useState, useEffect } from 'react';

import ReactMarkdown from 'react-markdown'; // markdown
import remarkGfm from 'remark-gfm' // tables
import rehypeRaw from 'rehype-raw'; // html

//Icons
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { useTranslation } from 'react-i18next';

// Styled components
const ColorButton = styled(Button)(({ theme }) => ({
  color: 'primary',
  height: theme.mixins.toolbar.minHeight,
}));

const StyledMenu = styled(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.primary.main
        : theme.palette.paper.main,
    borderRadius: 3,
    marginTop: theme.spacing(1),
    minWidth: 300,
    maxWidth: 400,
    color: 'white',
  },
}));

const styles = {
  icon: {
    color: 'white',
    fontSize: 30,
  },
  text: {
    fontSize: 16,
    mx: 1,
    mt: 1,
  },
  loadingAvatar: {
    position: 'absolute',
    top: 1,
    left: 4,
    zIndex: -1,
  },
  gridBox: {
    maxHeight: 350,
    maxWidth: 350,
    overflowX: 'hidden', 
    overflowY: 'auto' 
  }
};

export default function Notifications(props) {
  const {
    getUserNotifications,
    postUserNotification,
    notificationList,
    isLoadingNotifications,
  } = props;

  const { t } = useTranslation();

  const [activeNotification, setActiveNotification] = useState({});
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationLength, setNotificationLength] = useState(0);

  const [sortedNotificationList, setSortedNotificationList] = useState([]);

  useEffect(() => {
    if (notificationList?.length > 0) {
      let sortedList = notificationList.sort((a, b) => {
        if(a.notification_id > b.notification_id) return -1;
        return 1;
      });
      setSortedNotificationList(sortedList);
      
      let newLength = 0;
      notificationList.forEach(notification => {
        if (!notification.seen_timestamp) {
          newLength++;
        }
      });
      setNotificationLength(newLength);
    }
  }, [notificationList]);

  const handleOpenMenu = event => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  }

  const handleCloseMenu = () => {
    setOpen(false);
  }

  const handleSelectNotification = async selectedNotification => {
    setActiveNotification(selectedNotification);
    if(selectedNotification?.seen_timestamp) {
      let today = new Date().toISOString().slice(0, 19);
      await postUserNotification(selectedNotification.notification_id, today);
      setNotificationLength(notificationLength - 1);
      getUserNotifications();
    }
  };

  return (
    <div>
      <Box sx={{ m: 1, position: 'relative' }}>
        <ColorButton onClick={handleOpenMenu}>
          <Badge badgeContent={notificationLength > 0 ? notificationLength : 0} color="error">
            <IconComponent
              iconName={'notifications-outline'}
              style={styles.icon}
            />
          </Badge>
        </ColorButton>
        {isLoadingNotifications && (
          <CircularProgress
            size={55}
            color="secondary"
            style={styles.loadingAvatar}
          />
        )}
      </Box>
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        { activeNotification && Object.keys(activeNotification).length > 0 ?
          <div>
            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'} >
              <Grid item container xs={12} mt={2}>
                <Button 
                  onClick={() => setActiveNotification({})}
                  variant="outlined"
                  startIcon={
                    <IconComponent iconName={'arrow-back'} style={{ fontSize: 20, color: 'white' }}
                    />
                  }
                />
                <Typography sx={{ fontSize: 20, }}>
                  {activeNotification.title}
                </Typography>
              </Grid>
              <Grid item xs={12} mx={3} mb={2} sx={styles.gridBox} >
                <Typography sx={styles.text}>
                  <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} >
                    {activeNotification.message_txt}
                  </ReactMarkdown>
                </Typography>
              </Grid>
            </Grid>
          </div>
        : notificationList.length ?
          sortedNotificationList.map((notification, index) => (
          <div key={`${notification.notification_id}-${index}`}>
            <MenuItem onClick={(e) => {
              handleSelectNotification(notification);
            }}>
              <Typography sx={styles.text}>
              {notification.title}
              </Typography>
            </MenuItem>
            {notificationList?.length > 1 ?
              <Divider variant="middle" sx={{ background: '#cacadd4f' }} />
              : null
            }
          </div> ) ) 
        : ( 
          <div>
            <MenuItem>
              <Typography sx={styles.text}>
                {t('cws_app.home.notification_default')}
              </Typography>
            </MenuItem>
          </div>
        ) }
      </StyledMenu>
    </div>
  );
}