import { Box, Button, Popover, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { addDays, isSameDay } from 'date-fns';
import { useState } from 'react';
import { DateRange as ReactDateRange } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import { useTranslation } from 'react-i18next';

// Icons
import DateRangeIcon from '@mui/icons-material/DateRange';
import { format } from '../utils/converters';

const StyledButton = styled(props => {
    return (
        <Button
            {...props}
        />
    )
}
)(({ theme }) => {
    return ({
        backgroundColor: theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
        color: theme.palette.type === 'light' ? '#0000008a' : '#ffffff8a',
        '&.MuiButton-root:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.09)',
        },
        // This values are fixed, so that it matches the height of MUI's Autocomplete
        height: '57px',
        marginBottom: '4px',
    })
});


export default function DateRange({
    days_limitation = 365 * 2,
    inputSetDateState,
    isDateDisabled = false,
    inputDateState = {
        startDate: new Date(), endDate: new Date(),
        key: 'selection'
    },
    clientFormats }) {
    const [dateState, setDateState] = useState(inputDateState);
    const [anchorEl, setAnchorEl] = useState(null);
    const { t, i18n } = useTranslation();

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDisplayDate = () => {
        if (dateState[0].startDate && dateState[0].endDate && !isSameDay(dateState[0].startDate, dateState[0].endDate)) {
            return `${format(dateState[0].startDate, i18n.language, clientFormats.date_format)} - ${format(dateState[0].endDate, i18n.language, clientFormats.date_format)}`;
        } else if (dateState[0].startDate) {
            return `${format(dateState[0].startDate, i18n.language, clientFormats.date_format)}`;
        } else {
            return 'Select Date';
        }
    }

    const handleSelectDateRange = (ranges) => {
        setDateState([ranges[dateState[0].key]]);
    }

    const handleApplyDateRange = () => {
        inputSetDateState(dateState);
        handleClose();
    }

    return (
        <>
            <StyledButton aria-describedby={id} disableElevation variant="contained" onClick={handleClick} disabled={isDateDisabled}>
                <Typography sx={{ display: 'flex', alignContent: 'center', my: 1 }}>
                    {handleDisplayDate()}
                    <DateRangeIcon sx={{ ml: 2 }} />
                </Typography>
            </StyledButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{ px: 1, pt: 1 }}>
                    <ReactDateRange
                        editableDateInputs={true}
                        onChange={item => handleSelectDateRange(item)}
                        moveRangeOnFirstSelection={false}
                        ranges={dateState}
                        minDate={addDays(new Date(), -1 * days_limitation)}
                        maxDate={new Date()}
                        locale={locales[i18n.language]}
                    />

                </Box>
                <Box sx={{ px: 2, pb: 1, display: 'flex', flexDirection: 'row-reverse' }}>
                    <Button variant="outlined" size="small" disableElevation onClick={handleApplyDateRange}>
                        {t('cws_app.general.apply')}
                    </Button>

                </Box>
            </Popover>
        </>
    );
}
