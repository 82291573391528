import { useEffect } from 'react';
import { Buffer } from 'buffer';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';

export default function Link(props) {
  const { setLinkState } = props;
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const view = urlParams.get('view');
    let state = urlParams.get('state');

    if (state && view) {
      const decodedState = Buffer.from(state, 'base64').toString('utf8');
      state = JSON.parse(decodedState);
      setLinkState(state);
      navigate(view)
    }
  }, [setLinkState, navigate]);

  return (
    <Grid container pt={4} justifyContent='center'>
      <Typography>Invalid URL</Typography>
    </Grid>
  );
};
