export default function getReturns(chain_name) {
  const general_status = {
    store_listing: [
      {
        returnTitle: 'general_status_store_listing_returnTitle',
        returnVarious: true,
        returnCode: `
          {
            "store": "STORE_CPDE_1",
            "name": "STORE_NAME_1",
            "chain": "CHAIN_NAME_1",
            "corporation": "CORPORATION_NAME_1",
          },
          {
            "store": "STORE_CPDE_2",
            "name": "STORE_NAME_2",
            "chain": "CHAIN_NAME_2",
            "corporation": "CORPORATION_NAME_2",
          },
        `,
      },
    ],
    session_listing: [
      {
        returnTitle: 'general_status_session_listing_returnTitle_1',
        returnVarious: true,
        returnCode: `
          {
            "session": "652794969-STORE_CODE-DAY",
            "date": "2019-05-23",
            "robot_start_timestamp": "2019-05-22 23:00:00",
            "robot_end_timestamp": "2019-05-23 05:40:24",
            "store_state_updated": True,
            "store_state_timestamp": "2019-05-23 05:45:21",
            "price_tasks_generated": True,
            "price_tasks_timestamp": "2019-05-23 05:46:13",
            "stockout_tasks_generated": True,
            "stockout_tasks_timestamp": "2019-05-23 05:46:15",
            "assortment_tasks_generated": True,
            "assortment_tasks_timestamp": "2019-05-23 05:46:48",
            "product_availability_updated": false,
            "product_availability_timestamp": None,
          },
        `,
      },
      {
        returnTitle: 'general_status_session_listing_returnTitle_2',
        returnVarious: true,
        returnCode: `
          {
            "overhead_location_updated": True,
            "overhead_location_timestamp": "2019-05-23 05:48:42",
          },
        `,
      },
    ],
    status_store: [
      {
        returnTitle: 'general_status_store_listing_returnTitle',
        returnVarious: true,
        returnCode: `
          {
            "store": "STORE_CPDE_1",
            "name": "STORE_NAME_1",
            "chain": "CHAIN_NAME_1",
          },
          {
            "store": "STORE_CPDE_2",
            "name": "STORE_NAME_2",
            "chain": "CHAIN_NAME_2",
          },
        `,
      },
    ],
    status_session: [
      {
        returnTitle: 'general_status_session_listing_returnTitle_1',
        returnVarious: true,
        returnCode: `
          {
            "session": "652794969-STORE_CODE-DAY",
            "date": "2019-05-23",
            "scan_start_timestamp": "2019-05-22 23:00:00",
            "scan_end_timestamp": "2019-05-23 05:40:24",
            "session_complete_timestamp": "2019-05-23 05:45:21",
            "master_data_version": "2019-05-22 12:46:13",
          },
        `,
      },
    ],
  };

  const reports = {
    session_statistics: [
      {
        returnTitle: 'reports_session_statistics_returnTitle',
        returnVarious: true,
        returnCode: `
          {
            "session": "6652794969-STORE_CODE-DAY",
            "date": "2019-05-23",
            "labels": 20166,
            "products": 14862,
            "available_products": 11519,
            "label_price_differences": 128,
            "product_price_differences": 93,
            "label_stockouts": 232,
            "product_stockouts": 201,
            "catalog_assortment": 21530,
            "extra_assortment": 1842,
            "inactive_assortment": 450,
            "missing_assortment": 4653
          },
        `,
      },
    ],
    scanned_labels: [
      {
        returnTitle: 'reports_scanned_labels_returnTitle',
        returnVarious: true,
        returnCode: `
          {
            "detection_id": "15760520189821_000003",
            "item": "248444",
            "ean": "0",
            "description": "PRDUCT_NAME_1",
            "aisle": "1",
            "orient": "W",
            "aisle_part": None,
            "price": 7990,
            "landmark": "S04113",
            "landmark_from_robot": True,
            "location_x": 17.8801842496862,
            "location_z": 1.20104771409249,
            "level": 3,
            "sequence": 7,
            "label_type": "regular"
          },
          {
            "detection_id": "15760520173211_000001",
            "item": "3332527",
            "ean": "0",
            "description": "PRDUCT_NAME_2",
            "aisle": "23",
            "orient": "N",
            "aisle_part": 2,
            "price": 139990
            "landmark": "S04113",
            "landmark_from_robot": False,
            "location_x": 17.8166434203292,
            "location_z": 1.63893990444319,
            "level": None,
            "sequence": None,
            "label_type": "regular"
          },
        `,
      },
    ],
    data_labels: [
      {
        returnTitle: 'reports_scanned_labels_returnTitle',
        returnVarious: true,
        returnCode: `
          {
            "label_id": "15760520189821_000003",
            "item": "248444",
            "ean": "0",
            "price": 7990,
            "description": "PRDUCT_NAME_1",
            "label_type": "regular",
            "aisle_facing": "1-W",
            "bay": "S04113",
            "supplier_id": "10"
          },
          {
            "label_id": "15760520173211_000001",
            "item": "3332527",
            "ean": "0",
            "price": 139990
            "description": "PRDUCT_NAME_2",
            "label_type": "regular",
            "aisle_facing": "23-N",
            "bay": "S04113",
            "supplier_id": "10"
          },
        `,
      },
    ],
    data_overhead_labels: [
      {
        returnTitle: 'reports_overhead_location_returnTitle',
        returnVarious: true,
        returnCode: `
          {
            "label_id": "355965482001_000004",
            "item": "1004865888",
            "ean": "0",
            "box_code": "3464567DDSFFA",
            "label_type": "regular_overhead",
            "aisle_facing": "29-N",
            "bay": "29-003"
          },
          {
            "label_id": "4558567082001_000004",
            "item": "1004870532",
            "ean": "0",
            "box_code": "34647NHFPB",
            "label_type": "regular_overhead",
            "aisle_facing": "7-N",
            "bay": "07-011"
          },
        `,
      },
    ],
    price_difference_tasks: [
      {
        returnTitle: 'reports_price_difference_tasks_returnTitle_1',
        returnVarious: true,
        returnCode: `
          {
            "detection_id": "15585946082001_000004",
            "item": "1696060",
            "ean": "8012901007400",
            "price": 990.0,
            "aisle": "9B",
            "orient": "W",
            "aisle_part": None,
            "location_x": 4.983,
            "location_y": 0.0,
            "location_z": 1.529,
            "store_info": {
              "price_pos": 3999.0,
              "pos_update_timestamp": "2019-05-17 00:36:02"
            },
            "consecutive_alerts": 0
          },
        `,
      },
      {
        returnTitle: 'reports_price_difference_tasks_returnTitle_2',
        returnVarious: true,
        returnCode: `
          {
            "landmark": "S04911",
            "landmark_from_robot": True,
            "level": 3,
            "sequence": "5"
          },
        `,
      },
      {
        returnTitle: 'reports_price_difference_tasks_returnTitle_3',
        returnVarious: true,
        returnCode: `
          {
            "description": "PRDUCT_NAME",
            "brand": "2716",
            "supplier": None,
            "category_0": "3",
            "category_1": "7",
            "category_2": "7",
            "category_3": "1",
            "category_4": None
          },
        `,
      },
    ],
    alerts_price_mismatch: [
      {
        returnTitle: 'reports_price_difference_tasks_returnTitle_1',
        returnVarious: true,
        returnCode: `
          {
            "alert_id": "2611",
            "label_id": "15585946082001_000004",
            "item": "1696060",
            "ean": "8012901007400",
            "description": "LINEAR DRAWER SET",
            "price": 10,
            "aisle_facing": "9B-W",
            "bay": "S04911",
            "store_info": {
              "price_pos": 25,
              "pos_update_timestamp": "2024-04-10 00:36:02"
            },
          },
        `,
      },
    ],
    out_of_stock_tasks: [
      {
        returnTitle: 'reports_out_of_stock_tasks_returnTitle_1',
        returnVarious: true,
        returnCode: `
          {
            "detection_id": "15585906102702_000006",
            "item": "1708158",
            "ean": "381519181191",
            "price": 4590.0,
            "aisle": "14A",
            "orient": "E",
            "aisle_part": None,
            "location_x": 14.874,
            "location_y": 0.0,
            "location_z": 0.703,
            "store_info": {
              "stock_available": 20.0 # Example field, may vary by client
              "update_timestamp": "2019-05-16 12:43:52"
            },
            "consecutive_alerts": 0,
            "spread_to_fill": False
          },
        `,
      },
      {
        returnTitle: 'reports_out_of_stock_tasks_returnTitle_2',
        returnVarious: true,
        returnCode: `
          {
            "landmark": "S04911",
            "landmark_from_robot": True,
            "level": 3,
            "sequence": 5
          },
        `,
      },
      {
        returnTitle: 'reports_out_of_stock_tasks_returnTitle_3',
        returnVarious: true,
        returnCode: `
          {
            "description": "PRODUCT_NAME",
            "brand": "3226",
            "supplier": None,
            "category_0": "14",
            "category_1": "16",
            "category_2": "10",
            "category_3": "1",
            "category_4": None
          },
        `,
      },
    ],
    alerts_shelf_out: [
      {
        returnTitle: 'reports_out_of_stock_tasks_returnTitle_1',
        returnVarious: true,
        returnCode: `
          {
            "alert_id": "2611",
            "label_id": "15585906102702_000006",
            "item": "1708158",
            "ean": "381519181191",
            "description": "PLAIN CREW",
            "price": 4590.0,
            "aisle_facing": "14A-E",
            "bay": "S04911",
            "store_info": {
              "stock_available": 20.0 # Example field, may vary by client
              "update_timestamp": "2014-02-16 12:43:52"
            },
            "alt_stock_location": True,
            "out_of_stock": False
          },
        `,
      },
    ],
    assortment_tasks: [
      {
        returnTitle: 'reports_assortment_tasks_returnTitle_1',
        returnVarious: true,
        returnCode: `
          {
            "detection_id": "15581575126503_000004",
            "item": "992536",
            "ean": "4009300523086",
            "task_type": "missing_assortment",
            "aisle": "23B",
            "orient": "E",
            "aisle_part": None,
            "location_x": 3.292,
            "location_y": 0.0,
            "location_z": 0.794
            "store_info": {
              "enabled_sale": False, # Example field, may vary by client
              "enabled_purchase": False, # Example field, may vary by client
              "update_timestamp": "2019-05-07 16:17:28.440633"
            },
            "consecutive_alerts": 15
          },
        `,
      },
      {
        returnTitle: 'reports_assortment_tasks_returnTitle_2',
        returnVarious: true,
        returnCode: `
          {
            "landmark": "S04911",
            "landmark_from_robot": True,
            "level": 3,
            "sequence": "5"
          },
        `,
      },
      {
        returnTitle: 'reports_assortment_tasks_returnTitle_3',
        returnVarious: true,
        returnCode: `
          {
            "description": "PRODUCT_NAME",
            "brand": "6369",
            "supplier": None,
            "category_0": "7",
            "category_1": "18",
            "category_2": "2",
            "category_3": "1",
            "category_4": None
          },
        `,
      },
    ],
    landmark_tasks: [
      {
        returnTitle: 'reports_landmark_tasks_returnTitle',
        returnVarious: true,
        returnCode: `
          {
            "detection_id": "15581575126503_000004",
            "landmark": "S04911",
            "task_type": "missing_landmark",
            "aisle": "49",
            "orient": None,
            "aisle_part": None,
            "location_x": None,
            "location_y": None,
            "location_z": None,
            "status": "pending",
            "store_info": None,
            "consecutive_alerts": 4
          },
        `,
      },
    ],
    promotion_tasks: [
      {
        returnTitle: 'reports_promotion_tasks_returnTitle',
        returnVarious: true,
        returnCode: `
          {
            "detection_id": "16190679632421_000023",
            "code": "20454",
            "aisle": "13A",
            "orient": "E",
            "aisle_part": null,
            "location_x": 15.155,
            "location_y": 1.113,
            "location_z": 0.613,
            "task_type": "inactive_promotion",
            "store_info": {
              "value": "519",
              "catalog": "CICLO 1",
              "category_0": "18",
              "associated_ean": "7801875069351",
              "associated_item": "255543"
            },
            "consecutive_alerts": 0
          },
        `,
      },
    ],
    adjustment_tasks: [
      {
        returnTitle: 'reports_adjustment_tasks_returnTitle',
        returnVarious: true,
        returnCode: `
          {
            "item": "1477046001",
            "ean": "7801305004464",
            "task_type": "adjustment",
            "creation_timestamp": "2021-04-21 19:22:02.534514"
          },
        `,
      },
    ],
    on_shelf_availability: [
      {
        returnTitle: 'reports_on_shelf_availability_returnTitle_1',
        returnVarious: true,
        returnCode: `
          {
            "item": "265754",
            "ean": "7802500001135",
            "availability": True,
            "labels": 1,
            "stock_alerts": 0,
          },
        `,
      },
      {
        returnTitle: 'reports_on_shelf_availability_returnTitle_2',
        returnVarious: true,
        returnCode: `
          {
            "description": "PRODUCT_NAME",
            "brand": "3179",
            "supplier": None,
            "category_0": "7",
            "category_1": "10",
            "category_2": "2",
            "category_3": "2",
            "category_4": None
          },
        `,
      },
    ],
    planogram_tasks: [
      {
        returnTitle: 'reports_planogram_tasks_returnTitle_1',
        returnVarious: true,
        returnCode: `
          {
            "detection_id": "17005288867821_000004",
            "item": "403113",
            "ean": "0",
            "price": 22690.0,
            "aisle": "2M",
            "orient": "I",
            "aisle_part": null,
            "location_x": 13.325,
            "location_y": 1.0,
            "location_z": 1.123,
            "task_type": "extra_label",
            "store_info": {
                "master_positions": [
                    {
                        "level": null,
                        "landmark": "S03613",
                        "sequence": null
                    },
                    {
                        "level": null,
                        "landmark": "S03613",
                        "sequence": null
                    }
                ]
            },
            "consecutive_alerts": 4
          },
        `,
      },
      {
        returnTitle: 'reports_planogram_tasks_returnTitle_2',
        returnVarious: true,
        returnCode: `
          {
            "detection_id": null,
            "item": "279897",
            "ean": "0",
            "price": null,
            "aisle": null,
            "orient": null,
            "aisle_part": null,
            "location_x": null,
            "location_y": null,
            "location_z": null,
            "task_type": "missing_label",
            "store_info": {
                "last_time_seen": "2023-10-31",
                "master_positions": [
                    {
                        "level": null,
                        "landmark": "S03404",
                        "sequence": null
                    }
                ]
            },
            "consecutive_alerts": 0
          },
        `,
      },
    ],
    product_location: [
      {
        returnTitle: 'reports_product_location_returnTitle',
        returnVarious: true,
        returnCode: `
          {
            "item": "1690093",
            "ean": "7702003004995",
            "description": "PRDUCT_NAME_1",
            "aisle": "13A",
            "orient": "E",
            "aisle_part": 2.0,
            "location_x": 8.121,
            "location_y": 0.0,
            "location_z": 0.163,
            "last_time_seen": "2019-05-30",
            "available": True
          },
          {
            "item": "1230720",
            "ean": "7791728002775",
            "description": "PRDUCT_NAME_2",
            "aisle": "30B",
            "orient": "E",
            "aisle_part": 3.0,
            "location_x": 20.069,
            "location_y": 0.0,
            "location_z": 0.136,
            "last_time_seen": "2019-05-30",
            "available": False
          },
        `,
      },
    ],
    overhead_location: [
      {
        returnTitle: 'reports_overhead_location_returnTitle',
        returnVarious: true,
        returnCode: `
          {
            "item": "1004865888",
            "ean": "0",,
            "location_x": 25.0948505401612,
            "location_y": 1.10980916023254,
            "location_z": 3.01917731761933,
            "last_time_seen": "2021-08-11 09:17:58",
            "aisle": "29",
            "orient": "N",
            "aisle_part": None,
            "landmark": "29-003"
          },
          {
            "item": "1004870532",
            "ean": "0",
            "location_x": 6.03759050369263,
            "location_y": 0.990428835153579,
            "location_z": 2.21905064582825,
            "last_time_seen": "2021-08-11 08:52:19",
            "aisle": "7",
            "orient": "N",
            "aisle_part": None,
            "landmark": "07-011"
          },
        `,
      },
    ],
  };

  const resource_client = {
    latest_master_data_updates: [
      {
        returnTitle: 'resource_client_latest_master_data_updates_returnTitle',
        returnVarious: true,
        returnCode: `
          {
            "store": "STORE_CODE",
            "price_master": "2019-05-30 12:41:51",
            "stock_master": "2019-05-30 12:41:51",
            "assortment_master": "2019-05-30 12:41:51"
          },
          {
            "store": "J512",
            "price_master": "2019-05-30 21:36:17",
            "stock_master": "2019-05-23 17:39:56",
            "assortment_master": "2019-05-30 16:43:04"
          },
        `,
      },
    ],
    category_names: [
      {
        returnTitle: 'resource_client_category_names_returnTitle',
        returnVarious: true,
        returnCode: `
          {
            "level": 0,
            "value": "26",
            "name": "CATEGORY_NAME_1"
          },
          {
            "level": 0,
            "value": "7",
            "name": "CATEGORY_NAME_2"
          },
          {
            "level": 0,
            "value": "38",
            "name": "CATEGORY_NAME_3"
          },
          {
            "level": 1,
            "value": "1501",
            "name": "CATEGORY_NAME_4"
          },
        `,
      },
    ],
    brand_names: [
      {
        returnTitle: 'resource_client_brand_names_returnTitle',
        returnVarious: true,
        returnCode: `
          {
            "value": "2638",
            "name": "BRAND_NAME_1"
          },
          {
            "value": "C373",
            "name": "BRAND_NAME_2"
          },
          {
            "value": "3798",
            "name": "BRAND_NAME_3"
          },
          {
            "value": "4875",
            "name": "BRAND_NAME_4"
          },
        `,
      },
    ],
    product_information: [
      {
        returnTitle: 'resource_client_product_information_returnTitle',
        returnVarious: false,
        returnCode: `
          {
            "item": "996166",
            "ean": "7896015520045",
            "description": "PRODUCT_NAME",
            "price": 5099.0,
            "brand_name": "BRAND_NAME",
            "category_name": "CATEGORY_NAME",
            "supplier_name": "SUPPLIER_NAME",
            "facing_url": "https://example.url"
          },
        `,
      },
    ],
  };

  const resource_images = {
    overhead_capture: [
      {
        returnTitle: 'resource_images_overhead_capture_returnTitle',
        returnVarious: false,
        returnCode: `{
            "url": "https://example.url",
            "metadata":
              {
                "x1": 571.447,
                "x2": 672.409,
                "y1": 2445.55,
                "y2": 2499.06,
                "date": "03/25/2022",
                "aisle": "11",
                "landmark": "11-012"
              }
          }`,
      },
    ],
    images_overhead: [
      {
        returnTitle: 'resource_images_overhead_capture_returnTitle_v2',
        returnVarious: false,
        returnCode: `{
            "url": "https://example.url",
            "item_id": "996166"
          }`,
      },
    ],
    images_aisle_facing: [
      {
        returnTitle: 'resource_images_aisle_facing_returnTitle',
        returnVarious: false,
        returnCode: `{
            "url": "https://example.url",
            "aisle_facing": "B3-W"
          }`,
      },
    ],
    images_label: [
      {
        returnTitle: 'resource_images_label_returnTitle',
        returnVarious: false,
        returnCode: `{
            "url": "https://example.url",
            "label_id": "15581575126503_000004"
          }`,
      },
    ],
    images_position: [
      {
        returnTitle: 'resource_images_position_returnTitle',
        returnVarious: false,
        returnCode: `{
            "url": "https://example.url",
            "label_id": "17126653843021_000000"
          }`,
      },
    ],
  };

  const other = {
    test_connection: [
      {
        returnTitle: 'other_test_connection_returnTitle',
        returnVarious: false,
        returnCode: `{ "message": "The API is up and running" }`,
      },
    ],
    dumping_results: [
      {
        returnTitle: 'other_dumping_results_returnTitle',
        returnVarious: false,
        returnCode: `import csv
# Convert API response to JSON and get header
bulk_json = response.json()
header = bulk_json[0].keys()

with open('./api_response.csv', 'w') as filestream:
    file = csv.writer(filestream)
    file.writerow(header)

    for entry in bulk_json
        # Gather requested data
          row_data = [entry[key] for key in header]
          file.writerow(row_data)`,
      },
    ],
  };

  const returns = Object.assign(
    {},
    general_status,
    reports,
    resource_client,
    resource_images,
    other,
  );

  return returns;
}
