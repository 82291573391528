import { connect } from 'react-redux';
import DigitalStore from '../../../../components/features/digitalStore/DigitalStore';

import { getDSInfo, getDigitalStorePdf, getDigitalStoreInv, getDigitalStoreExcel, getAisleThumbnail, getExcelByDate, getResumeDStore } from 'core/slices/digitalStore';
import { getStoreCategories } from 'core/slices/store';
import { getUserStores, getUserSuppliers } from 'core/slices/users';
import { getTaskTypes } from 'core/slices/tasks';
import { setSnackAlert } from 'core/slices/snackAlert';
import {
    isLoadingMapInfo,
    getStoreMapInfo,
    isLoadingUserStores,
    getUserStoresAllList,
    getSelectedClient,
    isLoadingTaskTypes,
    taskTypes,
    getCategoriesList,
    isLoadingCategories,
    getLinkState,
    isPdfLoading,
    isInvLoading,
    isExcelLoading,
    isLoadingAisleThumbnail,
    aisleThumbnail,
    isLoadingExcelByDate,
    userSuppliers,
    isLoadingUserSuppliers,
    isLoadingResumeDStore,
    getResumeDStoreData,
    getClientFormats,
    isLoadingClientFormats,
} from 'core/selectors';

export default connect(state => ({
    isExcelLoading: isExcelLoading(state),
    isInvLoading: isInvLoading(state),
    isPdfLoading: isPdfLoading(state),
    selectedClient: getSelectedClient(state),
    isLoadingMapInfo: isLoadingMapInfo(state),
    storeMap: getStoreMapInfo(state),
    isLoadingUserStores: isLoadingUserStores(state),
    userStores: getUserStoresAllList(state),
    isLoadingTaskTypes: isLoadingTaskTypes(state),
    taskTypes: taskTypes(state),
    isLoadingCategories: isLoadingCategories(state),
    storeCategories: getCategoriesList(state),
    linkReceived: getLinkState(state),
    isLoadingAisleThumbnail: isLoadingAisleThumbnail(state),
    aisleThumbnail: aisleThumbnail(state),
    isLoadingExcelByDate: isLoadingExcelByDate(state),
    suppliers: userSuppliers(state),
    isLoadingSuppliers: isLoadingUserSuppliers(state),
    isLoadingResumeDStore: isLoadingResumeDStore(state),
    resumeDStore: getResumeDStoreData(state),
    clientFormats: getClientFormats(state),
    isLoadingClientFormats: isLoadingClientFormats(state),
}), {
    setSnackAlert,
    getDigitalStoreExcel,
    getDigitalStoreInv,
    getDigitalStorePdf,
    getDSInfo,
    getUserStores,
    getTaskTypes,
    getStoreCategories,
    getAisleThumbnail,
    getExcelByDate,
    getUserSuppliers,
    getResumeDStore,
})(DigitalStore);
