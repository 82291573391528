export default function getSections() {
  const introduction = [
    {
      id: 1,
      title: 'introduction',
      hasCode: true,
      hasReturns: false,
      codeTitle: 'introduction_1_codeTitle',
      notification: null,
      notificationType: null,
    },
  ];

  const accounts = [
    {
      id: 2,
      title: 'accounts',
      hasCode: false,
      codeTitle: null,
      hasReturns: false,
      returnsTitle: null,
      notification: ['accounts_2_notification_1'],
      notificationType: ['notice'],
      items: [
        {
          id: 1,
          title: 'permissions',
          hasCode: false,
          codeTitle: null,
          hasReturns: false,
          returnsTitle: null,
          notification: ['accounts_2_permissions_1_notification_1'],
          notificationType: ['warning'],
        },
        {
          id: 2,
          title: 'field_mapping',
          hasCode: false,
          codeTitle: null,
          hasReturns: false,
          returnsTitle: null,
          notification: null,
          notificationType: null,
        },
      ],
    },
  ];

  const authentication = [
    {
      id: 3,
      title: 'authentication',
      hasCode: true,
      codeTitle: 'authentication_3_codeTitle',
      hasReturns: false,
      returnsTitle: null,
      notification: [
        'authentication_3_notification_1',
        'authentication_3_notification_2',
      ],
      notificationType: ['notice', 'warning'],
      items: [
        {
          id: 1,
          title: 'requesting_endpoints',
          hasCode: true,
          codeTitle: 'authentication_3_requesting_endpoints_1_codeTitle',
          hasReturns: false,
          returnsTitle: null,
          notification: [
            'authentication_3_requesting_endpoints_1_notification_1',
          ],
          notificationType: ['warning'],
        },
      ],
    },
  ];

  const general_status = [
    {
      id: 4,
      title: 'general_status',
      hasCode: false,
      codeTitle: null,
      hasReturns: false,
      returnsTitle: null,
      notification: null,
      notificationType: null,
      items: [
        {
          id: 1,
          title: 'store_listing',
          hasCode: true,
          codeTitle: 'general_status_4_store_listing_1_codeTitle',
          hasReturns: true,
          returnsTitle: 'general_status_4_store_listing_1_returnsTitle',
          notification: ['general_status_4_store_listing_1_notification_1'],
          notificationType: ['success'],
        },
        /*{
          id: 2,
          title: 'status_store',
          hasCode: true,
          codeTitle: 'general_status_4_store_listing_1_codeTitle_v2',
          hasReturns: true,
          returnsTitle: 'general_status_4_store_listing_1_returnsTitle',
          notification: ['general_status_4_store_listing_1_notification_1_v2'],
          notificationType: ['success'],
        },*/
        {
          id: 2,
          title: 'session_listing',
          hasCode: true,
          codeTitle: 'general_status_4_store_listing_2_codeTitle',
          hasReturns: true,
          returnsTitle: 'general_status_4_store_listing_2_returnsTitle',
          notification: [
            'general_status_4_store_listing_2_notification_1',
            'general_status_4_store_listing_2_notification_2',
          ],
          notificationType: ['notice', 'success'],
        },
        /*{
          id: 4,
          title: 'status_session',
          hasCode: true,
          codeTitle: 'general_status_4_store_listing_2_codeTitle_v2',
          hasReturns: true,
          returnsTitle: 'general_status_4_store_listing_2_returnsTitle',
          notification: [
            'general_status_4_store_listing_2_notification_1_v2',
          ],
          notificationType: ['notice'],
        },*/
      ],
    },
  ];

  const reports = [
    {
      id: 5,
      title: 'reports',
      hasCode: false,
      codeTitle: null,
      hasReturns: false,
      returnsTitle: null,
      notification: ['reports_5_notification_1'],
      notificationType: ['notice'],
      items: [
        {
          id: 1,
          title: 'session_statistics',
          hasCode: true,
          codeTitle: 'reports_5_session_statistics_1_codeTitle',
          hasReturns: true,
          returnsTitle: 'reports_5_session_statistics_1_returnsTitle',
          notification: null,
          notificationType: null,
        },
        {
          id: 2,
          title: 'scanned_labels',
          hasCode: true,
          codeTitle: 'reports_5_scanned_labels_2_codeTitle',
          hasReturns: true,
          returnsTitle: 'reports_5_scanned_labels_2_returnsTitle',
          notification: ['reports_5_scanned_labels_2_notification_1'],
          notificationType: ['notice'],
        },
        /*{
          id: 3,
          title: 'data_labels',
          hasCode: true,
          codeTitle: 'reports_5_scanned_labels_2_codeTitle_v2',
          hasReturns: true,
          returnsTitle: 'reports_5_scanned_labels_2_returnsTitle',
          notification: ['reports_5_scanned_labels_2_notification_1_v2'],
          notificationType: ['notice'],
        },
        {
          id: 4,
          title: 'data_overhead_labels',
          hasCode: true,
          codeTitle: 'reports_5_scanned_overhead_labels_2_codeTitle_v2',
          hasReturns: true,
          returnsTitle: 'reports_5_scanned_overhead_labels_2_returnsTitle',
          notification: ['reports_5_scanned_labels_2_notification_1_v2'],
          notificationType: ['notice'],
        },*/
        {
          id: 3,
          title: 'price_difference_tasks',
          hasCode: true,
          codeTitle: 'reports_5_price_difference_tasks_3_codeTitle',
          hasReturns: true,
          returnsTitle: 'reports_5_price_difference_tasks_3_returnsTitle',
          notification: [
            'reports_5_price_difference_tasks_3_notification_1',
            'reports_5_price_difference_tasks_3_notification_2',
            'reports_5_price_difference_tasks_3_notification_3',
          ],
          notificationType: ['success', 'warning', 'notice'],
        },
        /*{
          id: 6,
          title: 'alerts_price_mismatch',
          hasCode: true,
          codeTitle: 'reports_5_price_difference_tasks_3_codeTitle_v2',
          hasReturns: true,
          returnsTitle: 'reports_5_price_difference_tasks_3_returnsTitle',
          notification: null,
          notificationType: null,
        },*/
        {
          id: 4,
          title: 'out_of_stock_tasks',
          hasCode: true,
          codeTitle: 'reports_5_out_of_stock_tasks_4_codeTitle',
          hasReturns: true,
          returnsTitle: 'reports_5_out_of_stock_tasks_4_returnsTitle',
          notification: [
            'reports_5_out_of_stock_tasks_4_notification_1',
            'reports_5_out_of_stock_tasks_4_notification_2',
            'reports_5_out_of_stock_tasks_4_notification_3',
          ],
          notificationType: ['success', 'warning', 'notice'],
        },
        /*{
          id: 8,
          title: 'alerts_shelf_out',
          hasCode: true,
          codeTitle: 'reports_5_out_of_stock_tasks_4_codeTitle_v2',
          hasReturns: true,
          returnsTitle: 'reports_5_out_of_stock_tasks_4_returnsTitle',
          notification: null,
          notificationType: null,
        },*/
        {
          id: 5,
          title: 'assortment_tasks',
          hasCode: true,
          codeTitle: 'reports_5_assortment_tasks_5_codeTitle',
          hasReturns: true,
          returnsTitle: 'reports_5_assortment_tasks_5_returnsTitle',
          notification: [
            'reports_5_assortment_tasks_5_notification_1',
            'reports_5_assortment_tasks_5_notification_2',
            'reports_5_assortment_tasks_5_notification_3',
            'reports_5_assortment_tasks_5_notification_4',
          ],
          notificationType: ['notice', 'success', 'warning', 'notice'],
        },
        {
          id: 6,
          title: 'landmark_tasks',
          hasCode: true,
          codeTitle: 'reports_5_landmark_tasks_6_codeTitle',
          hasReturns: true,
          returnsTitle: 'reports_5_landmark_tasks_6_returnsTitle',
          notification: null,
          notificationType: null,
        },
        {
          id: 7,
          title: 'promotion_tasks',
          hasCode: true,
          codeTitle: 'reports_5_promotion_tasks_7_codeTitle',
          hasReturns: true,
          returnsTitle: 'reports_5_promotion_tasks_7_returnsTitle',
          notification: null,
          notificationType: null,
        },
        {
          id: 8,
          title: 'adjustment_tasks',
          hasCode: true,
          codeTitle: 'reports_5_adjustment_tasks_8_codeTitle',
          hasReturns: true,
          returnsTitle: 'reports_5_adjustment_tasks_8_returnsTitle',
          notification: null,
          notificationType: null,
        },
        {
          id: 9,
          title: 'on_shelf_availability',
          hasCode: true,
          codeTitle: 'reports_5_on_shelf_availability_9_codeTitle',
          hasReturns: true,
          returnsTitle: 'reports_5_on_shelf_availability_9_returnsTitle',
          notification: [
            'reports_5_on_shelf_availability_9_notification_1',
            'reports_5_on_shelf_availability_9_notification_2',
            'reports_5_on_shelf_availability_9_notification_3',
            'reports_5_on_shelf_availability_9_notification_4',
          ],
          notificationType: ['notice', 'success', 'warning', 'notice'],
        },
        {
          id: 10,
          title: 'product_location',
          hasCode: true,
          codeTitle: 'reports_5_product_location_10_codeTitle',
          hasReturns: true,
          returnsTitle: 'reports_5_product_location_10_returnsTitle',
          notification: ['reports_5_product_location_10_notification_1'],
          notificationType: ['success'],
        },
        {
          id: 11,
          title: 'overhead_location',
          hasCode: true,
          codeTitle: 'reports_5_overhear_location_11_codeTitle',
          hasReturns: true,
          returnsTitle: 'reports_5_overhear_location_11_returnsTitle',
          notification: ['reports_5_overhear_location_11_notification_1'],
          notificationType: ['notice'],
        },
        {
          id: 12,
          title: 'planogram_tasks',
          hasCode: true,
          codeTitle: 'reports_5_planogram_tasks_12_codeTitle',
          hasReturns: true,
          returnsTitle: 'reports_5_planogram_tasks_12_returnsTitle',
          notification: [
            'reports_5_planogram_tasks_12_notification_1',
            'reports_5_planogram_tasks_12_notification_2',
            'reports_5_planogram_tasks_12_notification_3',
          ],
          notificationType: ['success', 'warning', 'notice'],
        },
      ],
    },
  ];

  const resource_client = [
    {
      id: 6,
      title: 'resources_client_data',
      hasCode: false,
      codeTitle: null,
      hasReturns: false,
      returnsTitle: null,
      notification: ['resources_client_data_6_notification_1'],
      notificationType: ['success'],
      items: [
        {
          id: 1,
          title: 'latest_master_data_updates',
          hasCode: true,
          codeTitle:
            'resources_client_data_6_latest_master_data_updates_1_codeTitle',
          hasReturns: true,
          returnsTitle:
            'resources_client_data_6_latest_master_data_updates_1_returnsTitle',
          notification: [
            'resources_client_data_6_latest_master_data_updates_1_notification_1',
          ],
          notificationType: ['warning'],
        },
        {
          id: 2,
          title: 'category_names',
          hasCode: true,
          codeTitle: 'resources_client_data_6_category_names_2_codeTitle',
          hasReturns: true,
          returnsTitle: 'resources_client_data_6_category_names_2_returnsTitle',
          notification: [
            'resources_client_data_6_category_names_2_notification_1',
          ],
          notificationType: ['success'],
        },
        {
          id: 3,
          title: 'brand_names',
          hasCode: true,
          codeTitle: 'resources_client_data_6_brand_names_3_codeTitle',
          hasReturns: true,
          returnsTitle: 'resources_client_data_6_brand_names_3_returnsTitle',
          notification: null,
          notificationType: null,
        },
        {
          id: 4,
          title: 'product_information',
          hasCode: true,
          codeTitle: 'resources_client_data_6_product_information_4_codeTitle',
          hasReturns: true,
          returnsTitle:
            'resources_client_data_6_product_information_4_returnsTitle',
          notification: null,
          notificationType: null,
        },
      ],
    },
  ];

  const resource_images = [
    {
      id: 7,
      title: 'resources_images',
      hasCode: false,
      codeTitle: null,
      hasReturns: false,
      returnsTitle: null,
      notification: null,
      notificationType: null,
      items: [
        {
          id: 1,
          title: 'label_crops',
          hasCode: true,
          codeTitle: 'resources_images_7_label_crops_1_codeTitle',
          hasReturns: false,
          returnsTitle: null,
          notification: null,
          notificationType: null,
          hasImage: true,
        },
        /*{
          id: 2,
          title: 'images_label',
          hasCode: true,
          codeTitle: 'resources_images_7_label_crops_1_codeTitle_v2',
          hasReturns: false,
          returnsTitle: null,
          notification: null,
          notificationType: null,
          hasImage: true,
        },*/
        {
          id: 2,
          title: 'facing_crops',
          hasCode: true,
          codeTitle: 'resources_images_7_facing_crops_2_codeTitle',
          hasReturns: false,
          returnsTitle: null,
          notification: ['resources_images_7_facing_crops_2_notification_1'],
          notificationType: ['warning'],
          hasImage: true,
        },
        /*{
          id: 4,
          title: 'images_position',
          hasCode: true,
          codeTitle: 'resources_images_7_facing_crops_2_codeTitle_v2',
          hasReturns: false,
          returnsTitle: null,
          notification: ['resources_images_7_facing_crops_2_notification_1_v2'],
          notificationType: ['warning'],
          hasImage: true,
        },*/
        {
          id: 3,
          title: 'overhead_capture',
          hasCode: true,
          codeTitle: 'resources_images_7_overhead_capture_3_codeTitle',
          hasReturns: true,
          returnsTitle: 'resources_images_7_overhead_capture_3_returnsTitle',
          notification: null,
          notificationType: null,
          hasImage: true,
        },
        /*{
          id: 6,
          title: 'images_overhead',
          hasCode: true,
          codeTitle: 'resources_images_7_overhead_capture_3_codeTitle_v2',
          hasReturns: true,
          returnsTitle: 'resources_images_7_overhead_capture_3_returnsTitle',
          notification: null,
          notificationType: null,
          hasImage: true,
        },*/
        {
          id: 4,
          title: 'realogram',
          hasCode: true,
          codeTitle: 'resources_images_7_realogram_4_codeTitle',
          hasReturns: false,
          returnsTitle: null,
          notification: null,
          notificationType: null,
          hasImage: true,
        },
        /*{
          id: 8,
          title: 'images_aisle_facing',
          hasCode: true,
          codeTitle: 'resources_images_7_realogram_4_codeTitle_v2',
          hasReturns: false,
          returnsTitle: null,
          notification: null,
          notificationType: null,
          hasImage: true,
        },*/
      ],
    },
  ];

  const other = [
    {
      id: 8,
      title: 'other',
      hasCode: false,
      codeTitle: null,
      hasReturns: false,
      returnsTitle: null,
      notification: null,
      notificationType: null,
      items: [
        {
          id: 1,
          title: 'test_connection',
          hasCode: true,
          codeTitle: 'other_8_test_connection_1_codeTitle',
          hasReturns: true,
          returnsTitle: 'other_8_test_connection_1_returnsTitle',
          notification: null,
          notificationType: null,
        },
        {
          id: 2,
          title: 'dumping_results',
          hasCode: true,
          codeTitle: 'other_8_dumping_results_2_codeTitle',
          hasReturns: true,
          returnsTitle: 'other_8_dumping_results_2_returnsTitle',
          notification: null,
          notificationType: null,
        },
        {
          id: 3,
          title: 'about',
          hasCode: false,
          codeTitle: null,
          hasReturns: false,
          returnsTitle: null,
          notification: null,
          notificationType: null,
        },
      ],
    },
  ];

  const glossary = [
    {
      id: 9,
      title: 'glossary',
      hasCode: false,
      codeTitle: null,
      hasReturns: false,
      returnsTitle: null,
      notification: null,
      notificationType: null,
    },
  ];

  const sections = introduction.concat(
    accounts,
    authentication,
    general_status,
    reports,
    resource_client,
    resource_images,
    other,
    glossary,
  );

  return { list: sections };
}
