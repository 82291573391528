import { createReducer } from './utils';
import { handleRequest, handleAvailable, handleUnavailable } from '../reducerHandlers'
import {
  SNACK_SET,
  BRUNO_REQUEST,
  BRUNO_AVAILABLE,
  BRUNO_UNAVAILABLE,
} from './constants';

const defaultState = {
  brunoUsers: [],
  loadingBrunoUsers: false,
  deleteResponse: {},
  loadingDeleteUser: false,
  userProfile: [],
  loadingUserProfile: false,
  userRoles: [],
  loadingUserRoles: false,
  features: [],
  loadingFeatures: false,
  updateBrunoUser: {},
  loadingUpdateBrunoUser: false,
  postBrunoUserResponse: {},
  loadingPostBrunoUser: false,
};

// Reducer

export const reducer = createReducer(defaultState, {
  [BRUNO_REQUEST]: handleRequest,
  [BRUNO_AVAILABLE]: handleAvailable,
  [BRUNO_UNAVAILABLE]: handleUnavailable,
});

// Actions

export function getBrunoUsers() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'brunoUsers'
    const loadingName = 'loadingBrunoUsers'
    dispatch({ type: BRUNO_REQUEST, payload: { loadingName: loadingName } });
    try {
      const response = await dataSource.getBrunoUsers();
      dispatch({
        type: BRUNO_AVAILABLE,
        payload: { keyState: keyState, data: response?.response, loadingName: loadingName },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: BRUNO_UNAVAILABLE,
        payload: { keyState: keyState, loadingName: loadingName, defaultState: [] },
      });
      const snack = {
        open: true,
        message: "There was an error getting Bruno's users.",
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function deleteBrunoUser(user_id, email = null, to_revoke = false) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'deleteResponse'
    const loadingName = 'loadingDeleteUser'
    dispatch({ type: BRUNO_REQUEST, payload: { loadingName: loadingName } });
    try {
      const response = await dataSource.deleteBrunoUser(user_id, email, to_revoke);
      dispatch({
        type: BRUNO_AVAILABLE,
        payload: { keyState: keyState, data: response, loadingName: loadingName },
      });
      if (to_revoke && response?.message === 'OK') {
        const snack = {
          open: true,
          message: 'The two factor authentication was revoked.',
          severity: 'success',
        };
        dispatch({ type: SNACK_SET, payload: { snack } });
      } else if (response?.message === 'OK') {
        const snack = {
          open: true,
          message: 'The user was deleted.',
          severity: 'success',
        };
        dispatch({ type: SNACK_SET, payload: { snack } });
      }
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: BRUNO_UNAVAILABLE,
        payload: { keyState: keyState, loadingName: loadingName, defaultState: {} },
      });
      const snack = {
        open: true,
        message: "There was an error deleting Bruno's user or removing their MFA.",
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function getUserRoles() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'userRoles'
    const loadingName = 'loadingUserRoles'
    dispatch({ type: BRUNO_REQUEST, payload: { loadingName: loadingName } });
    try {
      const response = await dataSource.getUserRoles();
      dispatch({
        type: BRUNO_AVAILABLE,
        payload: { keyState: keyState, data: response?.response, loadingName: loadingName },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: BRUNO_UNAVAILABLE,
        payload: { keyState: keyState, loadingName: loadingName, defaultState: [] },
      });
      const snack = {
        open: true,
        message: "There was an error getting the user roles.",
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function getBrunoFeatures() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'features'
    const loadingName = 'loadingFeatures'
    dispatch({ type: BRUNO_REQUEST, payload: { loadingName: loadingName } });
    try {
      const response = await dataSource.getBrunoFeatures();
      dispatch({
        type: BRUNO_AVAILABLE,
        payload: { keyState: keyState, data: response?.response, loadingName: loadingName },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: BRUNO_UNAVAILABLE,
        payload: { keyState: keyState, loadingName: loadingName, defaultState: [] },
      });
      const snack = {
        open: true,
        message: "There was an error getting the user roles.",
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function getUserProfile(user_id) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'userProfile'
    const loadingName = 'loadingUserProfile'
    dispatch({ type: BRUNO_REQUEST, payload: { loadingName: loadingName } });
    try {
      const response = await dataSource.getUserProfile(user_id);
      dispatch({
        type: BRUNO_AVAILABLE,
        payload: { keyState: keyState, data: response?.response, loadingName: loadingName },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: BRUNO_UNAVAILABLE,
        payload: { keyState: keyState, loadingName: loadingName, defaultState: [] },
      });
      const snack = {
        open: true,
        message: "There was an error getting the user's profile.",
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function updateBrunoUser(patchBody, deleteBody) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'updateBrunoUser'
    const loadingName = 'loadingUpdateBrunoUser'
    dispatch({ type: BRUNO_REQUEST, payload: { loadingName: loadingName } });
    try {
      const patchResponse = await dataSource.patchUserProfile(patchBody);
      const deleteResponse = await dataSource.deleteUserProfile(deleteBody);
      if (patchResponse?.message === 'OK' && deleteResponse?.message === 'OK') {
        dispatch({
          type: BRUNO_AVAILABLE,
          payload: { keyState: keyState, data: patchResponse, loadingName: loadingName },
        });
        const snack = {
          open: true,
          message: 'The user was updated.',
          severity: 'success',
        };
        dispatch({ type: SNACK_SET, payload: { snack } });
      } else {
        const snack = {
          open: true,
          message: "There was an error updating the user's profile and/or features.",
          severity: 'error',
        };
        dispatch({ type: SNACK_SET, payload: { snack } });
      }
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: BRUNO_UNAVAILABLE,
        payload: { keyState: keyState, loadingName: loadingName, defaultState: [] },
      });
      const snack = {
        open: true,
        message: "There was an error updating the user's profile and/or features.",
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function getUserFeatures(user_id) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'userFeatures'
    const loadingName = 'loadingUserFeatures'
    dispatch({ type: BRUNO_REQUEST, payload: { loadingName: loadingName } });
    try {
      const response = await dataSource.getUserFeatures(user_id);
      dispatch({
        type: BRUNO_AVAILABLE,
        payload: { keyState: keyState, data: response, loadingName: loadingName },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: BRUNO_UNAVAILABLE,
        payload: { keyState: keyState, loadingName: loadingName, defaultState: [] },
      });
      const snack = {
        open: true,
        message: "There was an error getting the user roles.",
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function postBrunoUser(body) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'postBrunoUserResponse'
    const loadingName = 'loadingPostBrunoUser'
    dispatch({ type: BRUNO_REQUEST, payload: { loadingName: loadingName } });
    try {
      const response = await dataSource.postBrunoUser(body);
      dispatch({
        type: BRUNO_AVAILABLE,
        payload: { keyState: keyState, data: response, loadingName: loadingName },
      });
      if (response?.message === 'User created successfully') {
        const snack = {
          open: true,
          message: 'User created successfully.',
          severity: 'success',
        };
        dispatch({ type: SNACK_SET, payload: { snack } });
      }
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: BRUNO_UNAVAILABLE,
        payload: { keyState: keyState, loadingName: loadingName, defaultState: [] },
      });
      const snack = {
        open: true,
        message: "There was an error creating a new user.",
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}
