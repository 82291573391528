import { Suspense, useEffect, useState } from 'react';
import { LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useAnimationCanvas from '../../tools/useAnimationCanvas';

export default function WallDrawer(props) {

	const {
		landmark,
		landmarksVisible,
		png,
		coords = [],
		wallLandmarks,
		canvasHeight,
		canvasWidth,
		originalWidth,
		originalHeight,
		colors,
		selectedStatus,
		selectedCategories,
		product,
		handleCanvasHover,
		handleCanvasLeave = (drw) => { },
		handleCanvasClick = (e, drw) => { },
		aislePromotions,
		aisleLandmarks,
		imgsDict,
	} = props;

	const { t } = useTranslation();
	const [landmarkLabels, setlandmarkLabels] = useState([]);
	const [localCanvasHeight, setLocalCanvasHeight] = useState(canvasHeight);
	const [localCanvasWidth, setLocalCanvasWidth] = useState(canvasWidth);
	const [localCanvasOriginalHeight, setLocalOriginalCanvasHeight] = useState(originalHeight);
	const [localCanvasOriginalWidth, setLocalCanvasOriginalWidth] = useState(originalWidth);
	const [scaleX, setScaleX] = useState(canvasWidth / originalWidth);
	const [scaleY, setScaleY] = useState(canvasHeight / originalHeight);
	const wallBugs = coords && coords.length ? [...coords, ...aislePromotions, ...aisleLandmarks] : [...aislePromotions, ...aisleLandmarks];
	const speed = 0.4

	const [svgs, setSvgs] = useState(null);

	useEffect(() => {
		if (wallLandmarks && wallLandmarks.length && coords && coords.length) {
			let landmarksObj = {};
			wallLandmarks.forEach(l => { landmarksObj[l.landmark] = 0 });

			coords.forEach(coord => {
				// This is for the landmark's black box
				if (coord.landmark) {
					landmarksObj[coord.landmark]++;
				}
			});
			setlandmarkLabels(landmarksObj);
		}
	}, [JSON.stringify(wallLandmarks), JSON.stringify(coords)]);

	useEffect(() => {
		if (imgsDict) {
			setSvgs(imgsDict);
		}
	}, [imgsDict])

	useEffect(() => {
		const bugHeight = 19;
		const scale_y = localCanvasHeight / localCanvasOriginalHeight;
		wallBugs.forEach((bug) => {
			bug.speed = speed
			if (bug.hasOwnProperty('pixel_z')) {
				bug.aux_pos_y = bug.pixel_z * scale_y;
			} else {
				bug.aux_pos_y = (bug.facing_bottom_right_y + bug.facing_top_left_y) / 2 * scale_y - bugHeight / 2;
			}
		})
	}, [coords])

	useEffect(() => {

		setLocalOriginalCanvasHeight(originalHeight);
		setLocalCanvasOriginalWidth(originalWidth);
		setLocalCanvasHeight(canvasHeight);
		setLocalCanvasWidth(canvasWidth);
		setScaleX(canvasWidth / originalWidth);
		setScaleY(canvasHeight / originalHeight);
	}, [JSON.stringify(coords), canvasWidth, originalWidth, canvasHeight, originalHeight])

	const drawDashedLine = (ctx, pattern, x1, y1, x2, y2) => {
		ctx.strokeStyle = "#1e8bea";
		ctx.beginPath();
		ctx.setLineDash(pattern);
		ctx.lineWidth = 3;
		ctx.beginPath();
		ctx.roundRect(x1, y1, x2, y2, 10);
		ctx.stroke();
		ctx.fillStyle = "rgba(90, 70, 40, 0.2)";
		ctx.fill();
	}

	// Landmark black box
	const drawLandmarksFields = (ctx, scale_x, scale_y) => {
		if (wallLandmarks && wallLandmarks.length && landmarkLabels && Object.keys(landmarkLabels).length) {
			wallLandmarks.forEach((l, idx) => {
				if (l.hasOwnProperty('pixel_z')) {
					// curr landmark info
					let text = l.landmark;
					let x = (l.pixel_x > 0 ? l.pixel_x : 4) * scale_x;
					let z = 40;
					// Landmark Highlight
					if (landmark && text === landmark.landmark) {
						let nextX = 0;
						if (idx < wallLandmarks.length - 1) {
							nextX = wallLandmarks[idx + 1].pixel_x * scale_x;
						} else {
							nextX = localCanvasWidth;
						}
						const endX = x < nextX ? nextX - x : l.next_landmark_position ? l.next_landmark_position * scale_x - x : 0;
						drawDashedLine(ctx, [7, 5], x, z - 30, endX, localCanvasHeight - 10);
					}
					// Round square
					ctx.setLineDash([]);
					ctx.strokeStyle = "#000";
					ctx.fillStyle = "#000";
					ctx.beginPath();
					ctx.roundRect(x, z - 30, 200, 70, 10);
					ctx.stroke();
					ctx.fill();
					// Landmark title
					ctx.fillStyle = "#fff";
					ctx.font = 'bold 20px arial';
					ctx.fillText(text, x + 10, z);
					// Landmark totals
					ctx.font = 'bold 14px arial';
					ctx.fillText(t('cws_app.Walls.detected_products', 'Detected products') + ': ' + landmarkLabels[text], x + 10, z + 20);
				}
			})
		}
	}

	const drawFunc = () => {
		if (landmarksVisible) {
			drawerReturn(clearCanvas, [localCanvasWidth, localCanvasHeight])

			if (wallBugs && wallBugs.length) {
				let showingHover = false;

				wallBugs.forEach(element => {
					// Bug color
					let coordColor = 'stock';
					if (element.stockout) coordColor = 'stockout';
					if (element.spread_to_fill) coordColor = 'spread_to_fill';
					else if (element.price_difference) coordColor = 'price_difference';
					else if (element.extra_label) coordColor = 'extra_label';
					else if (element.misplaced_product) coordColor = 'misplaced_product'
					else if (!element.landmark_type && element.hasOwnProperty('pixel_z')) coordColor = 'promotion';
					else if (element.landmark_type) coordColor = 'hua';

					// color selected landmark only (nice to have)
					if (landmark && landmark.landmark && element.landmark
						&& element.landmark !== landmark.landmark
						&& !element.landmark_type
					) coordColor = 'promotion';

					// Skip if not in selected task status or category
					if (selectedStatus && !(selectedStatus.has(coordColor))) return;
					if (selectedCategories && selectedCategories.has(element.name_category)) return;

					// Draw Square Expanded by hover
					if (element.isHoverOn && !showingHover) {
						showingHover = true;
						drawerReturn(drawSqExpanded, [element, scaleX, scaleY, coordColor])
					} else {
						if (element.item && (element.item === product?.item)) {
							// product selected or focused
							drawerReturn(drawSelectedBug, [element, scaleX, scaleY, coordColor])
						} else {
							drawBug(element, scaleX, scaleY, coordColor)
						}
					}
				})
			}
			// Draw Landmarks texts & dotted lines
			drawerReturn(drawLandmarksFields, [scaleX, scaleY])

		}

	}


	const drawSqExpanded = (ctx, element, scale_x, scale_y, coordColor) => {
		let posx = 0
		let posy = 0;
		let max_tl_x = 0
		let max_tl_y = 0;
		let max_br_x = 0;
		let max_br_y = 0;

		const circleDiameter = 19
		let maxWidth = 0
		let maxHeight = 0
		const ancho = 2

		if (element.hasOwnProperty('pixel_z')) {
			maxWidth = 30
			maxHeight = 30
			posx = element.pixel_x * scale_x
			posy = element.pixel_z * scale_y
			max_tl_x = posx + circleDiameter / 2 - maxWidth / 2
			max_tl_y = posy + circleDiameter / 2 - maxHeight / 2
			max_br_x = (posx + circleDiameter / 2 - maxWidth / 2) + maxWidth
			max_br_y = (posy + circleDiameter / 2 - maxHeight / 2) + maxHeight

		} else {
			posx = (element.facing_bottom_right_x + element.facing_top_left_x) / 2 * scale_x - circleDiameter / 2;
			posy = (element.facing_bottom_right_y + element.facing_top_left_y) / 2 * scale_y - circleDiameter / 2;
			maxWidth = (element['facing_bottom_right_x'] - element['facing_top_left_x']) * scale_x;
			maxHeight = (element['facing_bottom_right_y'] - element['facing_top_left_y']) * scale_y;
			max_tl_x = element['facing_top_left_x'] * scale_x
			max_tl_y = element['facing_top_left_y'] * scale_y
			max_br_x = element['facing_bottom_right_x'] * scale_x
			max_br_y = element['facing_bottom_right_y'] * scale_y

		}
		element.extend_x = maxWidth / 4 // delta that adds to the arm, maximum width of the target arm
		element.extend_y = maxHeight / 4

		// Center circle
		ctx.fillStyle = colors[coordColor].focusedColor;
		ctx.beginPath();
		ctx.roundRect(posx, posy, circleDiameter, circleDiameter, 25); // posx, posy, width, height, rounded
		ctx.fill();

		ctx.fillStyle = "#FFFFFF";
		// upper left side corner
		ctx.fillRect(max_tl_x, max_tl_y, ancho + element.extend_x, ancho);
		ctx.fillRect(max_tl_x, max_tl_y, ancho, ancho + element.extend_y);
		// bottom left side corner
		ctx.fillRect(max_tl_x, max_br_y - ancho, ancho + element.extend_x, ancho)
		ctx.fillRect(max_tl_x, max_br_y, ancho, -(ancho + element.extend_y))
		// upper right side corner
		ctx.fillRect(max_br_x, max_tl_y, -(ancho + element.extend_x), ancho);
		ctx.fillRect(max_br_x - ancho, max_tl_y, ancho, ancho + element.extend_y);
		// bottom right side corner
		ctx.fillRect(max_br_x, max_br_y - ancho, - (ancho + element.extend_x), ancho);
		ctx.fillRect(max_br_x - ancho, max_br_y, ancho, -(ancho + element.extend_y));
	}


	const drawBug = (element, scale_x, scale_y, coordColor) => {
		const bugWidth = 19
		const bugHeight = 19

		if (element.hasOwnProperty('pixel_z')) {
			element.posx = element.pixel_x * scale_x;
			element.posy = element.pixel_z * scale_y;
		} else {
			element.posx = (element.facing_bottom_right_x + element.facing_top_left_x) / 2 * scale_x - bugWidth / 2;
			element.posy = (element.facing_bottom_right_y + element.facing_top_left_y) / 2 * scale_y - bugHeight / 2;
		}

		if (coordColor === 'hua') {
			drawerReturn(drawLandmarkBug, [element, bugWidth, bugHeight, coordColor])
		} else if (coordColor === 'promotion' && !landmark?.hasOwnProperty('landmark')) {
			drawerReturn(drawPromoBug, [element, bugWidth, bugHeight, coordColor])
		} else {
			drawerReturn(drawNormalBug, [element, bugWidth, bugHeight, coordColor, false])
		}
	}

	const drawSelectedBug = (ctx, element, scale_x, scale_y, coordColor) => {
		const bugWidth = 19
		const bugHeight = 19

		if (element.hasOwnProperty('pixel_z')) {
			element.posx = (element.pixel_x + bugWidth / 2) * scale_x;
			element.posy = (element.pixel_z + bugHeight / 2) * scale_y;
		} else {
			element.posx = (element.facing_bottom_right_x + element.facing_top_left_x) / 2 * scale_x - bugWidth / 2;
			element.posy = (element.facing_bottom_right_y + element.facing_top_left_y) / 2 * scale_y - bugHeight / 2;
		}

		if (isFinite(element.posx) && isFinite(element.posy)) {
			const gradient = ctx.createLinearGradient(element.posx, element.posy, element.posx + bugWidth, element.posy + bugHeight * 2);
			gradient.addColorStop("0", colors[coordColor].color);
			gradient.addColorStop("0.5", colors[coordColor].focusedColor);
			gradient.addColorStop("1.0", colors[coordColor].focusedColor);
			ctx.fillStyle = gradient;
		}
		ctx.beginPath();
		ctx.roundRect(element.posx, element.posy, bugWidth, bugHeight * 2, 4); // posx, posy, width, height, rounded
		ctx.fill();

		drawNormalBug(ctx, element, bugWidth, bugHeight, coordColor, true)

		element.aux_pos_y += element.speed
		if (element.aux_pos_y + element.speed >= element.posy + 19) {
			element.speed = -element.speed
		} else if (element.aux_pos_y + element.speed < element.posy) {
			element.speed = -element.speed
		}

	}

	const drawNormalBug = (ctx, element, bugWidth, bugHeight, coordColor, selected) => {
		ctx.fillStyle = colors[coordColor].color;
		let pos_y = element.posy
		if (selected) {
			ctx.fillStyle = colors[coordColor].focusedColor;
			pos_y = element.aux_pos_y
		}
		else {
			ctx.fillStyle = colors[coordColor].color;
			pos_y = element.posy
		}
		ctx.beginPath();
		ctx.roundRect(element.posx, pos_y, bugWidth, bugHeight, 4);
		ctx.fill();
		if (svgs?.[coordColor]) {
			ctx.drawImage(svgs[coordColor], element.posx + 1, pos_y + 1, bugWidth - 2, bugHeight - 2)
		}
	}

	const drawLandmarkBug = (ctx, element, bugWidth, bugHeight, coordColor) => {
		ctx.fillStyle = colors[coordColor].color;
		ctx.beginPath();
		ctx.roundRect(element.posx, element.posy, bugWidth, bugHeight, 4);
		ctx.fill();
		ctx.fillStyle = colors.hua.text;
		ctx.font = '18px Material Symbols Outlined';
		ctx.fillText('sell', element.posx + 1, element.posy + bugHeight);
	}

	const drawPromoBug = (ctx, element, bugWidth, bugHeight, coordColor) => {
		ctx.fillStyle = colors[coordColor].color;
		ctx.beginPath();
		ctx.roundRect(element.posx, element.posy, bugWidth, bugHeight, 4);
		ctx.fill();
		ctx.fillStyle = '#000';
		ctx.font = 'bolder 16px Arial';
		ctx.fillText('%', element.posx + 2, element.posy + 15);
	}

	const clearCanvas = (ctx, width, height) => {
		ctx.clearRect(0, 0, width, height);
	}


	const [canvasRef, drawerReturn] = useAnimationCanvas();

	const [animationId, setAnimationId] = useState(null)


	const animate = () => {
		drawFunc()
		const id = requestAnimationFrame(animate)
		setAnimationId(id)
	}

	useEffect(() => {
		if (animationId) {
			cancelAnimationFrame(animationId);
			setAnimationId(null)
		}
		animate()
	}, [
		product,
		JSON.stringify(Array.from(selectedCategories)),
		JSON.stringify(Array.from(selectedStatus)),
		JSON.stringify(landmark),
		JSON.stringify(landmarkLabels),
		localCanvasHeight,
		localCanvasWidth,])

	const scaleMousePosition = (canvas, evt) => {
		const rect = canvas.getBoundingClientRect(); // Abs. size of element
		const scaleX = canvas.width / rect.width;    // Relationship bitmap vs. element for x
		const scaleY = canvas.height / rect.height;  // Relationship bitmap vs. element for y

		return {
			x: (evt.clientX - rect.left) * scaleX,   // Scale mouse coordinates after they have
			y: (evt.clientY - rect.top) * scaleY     // been adjusted to be relative to element
		}
	}

	useEffect(() => {
		if (canvasRef?.current) {
			canvasRef.current.onmousemove = (event) => {
				const canvas = document.getElementById('canvas');
				const { x, y } = scaleMousePosition(canvas, event);
				handleCanvasHover({ clientX: x, clientY: y }, drawerReturn);
			}
			canvasRef.current.onclick = (event) => {
				const canvas = document.getElementById('canvas');
				const { x, y } = scaleMousePosition(canvas, event);
				handleCanvasClick({ clientX: x, clientY: y, canvasWidth, canvasHeight }, drawerReturn);
			}
			canvasRef.current.onmouseleave = () => {
				handleCanvasLeave(drawerReturn);
			}
		}
	}, [wallBugs,]);

	return (
		<>
			<img
				alt=""
				id="wallImage"
				height={canvasHeight}
				width={canvasWidth}
				src={png}
			/>
			<Suspense fallback={<LinearProgress sx={{ width: '98%', mx: 'auto' }} color="secondary" />}>
				<canvas
					id="canvas"
					ref={canvasRef}
					tabIndex="1"
					height={canvasHeight}
					width={canvasWidth}
					style={{ position: 'absolute' }} />
			</Suspense>
		</>
	);
}

