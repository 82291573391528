import { LinearProgress, Tooltip } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { isEmptyOrUndefined } from "core/utils/validators";

const StyledTextField = styled(props => {
	return (<TextField {...props} />)
})(({ theme }) => {
	return ({
		"& .MuiFilledInput-root": {
			background: theme.palette.paper.main,
			color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.87)',
			height: 'auto',
			":hover": {
				backgroundColor: theme.palette.type === 'light' ? '#dfdfe6' : '#2b1e23',
			},
			"&.Mui-disabled": {
				background: theme.palette.type === 'light' ? '#dfdfe6' : '#2b1e23',
			}
		},
		"& .MuiInputLabel-root": {
			color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.87)',
			"&.Mui-disabled": {
				color: theme.palette.grey[500],
			}
		},
		// Focused
		'& .Mui-focused': {
			backgroundColor: theme.palette.type === 'light' ? theme.palette.paper.main : '#261d21',
		},
		// Dropdown icon (needed only for the dark theme)
		"& .MuiSvgIcon-root": {
			color: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.87)' : '',
		},
	})
});

export default function Selector(props) {
	const {
		labelInput = 'General.label_selecter',
		options = [],
		optionLabel = 'name',
		isLoading,
		selectedElement,
		setSelectedElement,
		disabled,
		disableClearable = false,
		defaultValue = null,  // There was a warning because the defualt value (eg.: null, [], '', etc.) didn't match any of the selections
		id = [], // Add a list of one or more values to use as IDs, joined by a hyphen (-). If the array is empty, optionLabel is used as the ID
		required = false,
		tooltipTitle = '',
		helperText = '',
	} = props;
	const { t } = useTranslation();
	const customIDs = isEmptyOrUndefined(id, 'array') ? [optionLabel] : id;

	const filterOptions = createFilterOptions({
		matchFrom: 'any',
		limit: 200,
	});

	return (
		<>
			<Autocomplete
				disableClearable={disableClearable}
				value={selectedElement}
				onChange={(_, newValue) => {
					setSelectedElement(newValue);
				}}
				isOptionEqualToValue={(option, value) => {
					let isEqual = false
					if (value?.[optionLabel] === defaultValue) isEqual = true;
					else if (option?.[optionLabel] === value?.[optionLabel]) isEqual = true;
					else if (option?.optionLabel === value?.optionLabel) isEqual = true;
					return isEqual;
				}}
				id="controllable-selecter"
				options={options ? options : []}
				getOptionLabel={(option) => option ? option?.[optionLabel] : ''}
				renderInput={(params) => <StyledTextField {...params} variant="filled" label={t(labelInput)} required={required} helperText={helperText} />}
				disabled={disabled}
				// Custom renders
				filterOptions={filterOptions}
				renderOption={(props, option) => {
					const keys = customIDs.map(id => {
						return (option[id])
					})
					return (
						<Tooltip key={keys.join('-')} title={tooltipTitle !== '' ? option[tooltipTitle] : ''}>
							<li {...props}>
								{option[optionLabel]}
							</li>
						</Tooltip>
					);
				}}
			/>
			{!isLoading && <Box sx={{ position: 'relative', width: '100%', height: '4px', visibility: 'hidden' }} />}
			{isLoading && <LinearProgress sx={{ width: '100%', mx: 'auto', top: '-5px' }} color="secondary" />}
		</>
	);
}
