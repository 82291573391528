export function getClientDocSection(client) {
    const jumbo = ["scanned_labels"];

    switch (client) {
        case 'Jumbo':
            return jumbo
        default:
            return null
    }
}

export function getClientDocsData(client, t) {
    const jumbo = {
        scanned_labels: `\n| label_type   |  ${t('cws_app.documentation_extra_information.scanned_labels')} |`
    }

    switch (client) {
        case 'Jumbo':
            return jumbo
        default:
            return null
    }
};