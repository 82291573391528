import { createReducer } from './utils';
import * as IconBootstrap from 'react-bootstrap-icons';
import { EncrDecrService } from '../services/crypto';
import { subMinutes } from 'date-fns';
import { handleRequest, handleAvailable, handleUnavailable } from '../reducerHandlers'
import {
    USERS_REQUEST,
    USERS_AVAILABLE,
    USERS_UNAVAILABLE,
    USERS_CACHE,
    USERS_CUSTOM_REQUEST,
    USERS_CUSTOM_AVAILABLE,
    USERS_CUSTOM_UNAVAILABLE,
    USERS_STORES_REQUEST,
    USERS_STORES_AVAILABLE,
    USERS_STORES_UNAVAILABLE,
    USER_CLIENT_SELECT,
    USER_CUSTOM_DASHBOARDS_AVAILABLE,

    USER_REQUEST,
    USER_AVAILABLE,
    USER_UNAVAILABLE,

    SNACK_SET,
} from './constants';

const EncrDecr = new EncrDecrService();

const UserDefaultState = {
    loading_user_info: true,
    loading_user_notifications: false,
    loading_user_stores: false,

    userInfo: { user: { username: '', token: undefined } },
    sectionPaths: { general: {}, admin: {} },
    sectionView: [],
    clients: [],
    selectedClient: null,
    chains: [],
    stores: [],
    userMGMTStores: [],
    notifications: [],

    users: [],
    loadingUsers: false,
    userClients: [],
    isLoadingClients: false,
    loadingUserClients: false,
    postUserResponse: {},
    loadingPostUser: false,
    updateUserResponse: {},
    loadingUpdateUser: false,
    deleteUserResponse: {},
    loadingDeleteUser: false,
    userLegacyDashboards: [], // TODO: delete on deprecation of cpg
    userSuppliers: [],
    loadingUserSuppliers: false,
};

export const reducer = createReducer(UserDefaultState, {
    [USERS_REQUEST]: handleRequest,
    [USERS_AVAILABLE]: handleAvailable,
    [USERS_UNAVAILABLE]: handleUnavailable,

    // Custom reducers
    [USERS_CACHE]: handleUsersCache,
    [USERS_CUSTOM_REQUEST]: handleUsersRequest,
    [USERS_CUSTOM_AVAILABLE]: handleUsersAvailable,
    [USERS_CUSTOM_UNAVAILABLE]: handleUsersUnavailable,
    [USER_CUSTOM_DASHBOARDS_AVAILABLE]: handleUserDashboardsAvailable,

    [USERS_STORES_REQUEST]: handleCustomRequest,
    [USERS_STORES_AVAILABLE]: handleCustomAvailable,
    [USERS_STORES_UNAVAILABLE]: handleCustomUnavailable,

    [USER_REQUEST]: handleCustomRequest,
    [USER_AVAILABLE]: handleCustomAvailable,
    [USER_UNAVAILABLE]: handleCustomUnavailable,

    [USER_CLIENT_SELECT]: handleClientSelect,
});

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

// Storage functions
function getToken() {
    return localStorage.getItem('rekol');
}

function storeToken(token) {
    localStorage.setItem('rekol', token);
}

function storeClients(clientsInfo) {
    const encryptedClient = EncrDecr.set_object(getToken(), clientsInfo);
    localStorage.setItem('PnaoLrs', encryptedClient);
}

function storeSelectedClient(clientInfo) {
    const encryptedClient = EncrDecr.set_object(getToken(), clientInfo);
    localStorage.setItem('PnaoLr', encryptedClient);
}

function storeUser(UserInfo) {
    const encryptedUser = EncrDecr.set_object(getToken(), UserInfo);
    localStorage.setItem('User', encryptedUser);
}

function storePaths(PathsInfo) {
    const encryptedPaths = EncrDecr.set_object(getToken(), PathsInfo);
    localStorage.setItem('Paths', encryptedPaths);
}

// TODO: delete on deprecation of cpg
function storeLegacyReports(reports) {
    const encriptedReports = EncrDecr.set_object(getToken(), reports);
    localStorage.setItem('legacyReports', encriptedReports);
}

function getStoredClients() {
    return EncrDecr.get_object(getToken(), localStorage.getItem('PnaoLrs'));
}

function getStoredSelectedClient(clients) {
    let client;
    try {
        client = EncrDecr.get_object(getToken(), localStorage.getItem('PnaoLr'));
    } catch (error) { }

    client = client ? client : clients.find(row => row.client_id === -1);
    return client ? client : clients[0];
}

function getStoredUser() {
    return EncrDecr.get_object(getToken(), localStorage.getItem('User'));
}

// TODO: delete on deprecation of cpg
function getStoredLegacyDashboards() {
    return EncrDecr.get_object(getToken(), localStorage.getItem('legacyReports'));
}

function setBlobDate() {
    const now = new Date();
    localStorage.setItem('blobDate', now.toString()); // to collect info of last connection but i have this data in bd...
}

function checkLocalInfo() {
    try {
        const cache_time = localStorage.getItem('blobDate');
        if (cache_time) {
            const now = new Date();
            const then = Date.parse(subMinutes(now, 110).toString());
            if (Date.parse(cache_time) > then) {
                const check = getStoredClients() && getStoredUser() && getStoredPaths()
                    // TODO: delete on deprecation of cpg
                    && getStoredLegacyDashboards();
                return check === null ? null : true;
            }
        }
    } catch (error) { console.log('token error'); }
    return null
}

function getStoredPaths() {
    return EncrDecr.get_object(getToken(), localStorage.getItem('Paths'));
}

// handlers functions

function handleUsersRequest(state, _) {
    return {
        ...state,
        loading_user_info: true,
    };
}

function handleUsersAvailable(state, { payload: { userInfo } }) {
    const clients = userInfo.clients ? userInfo.clients : []
    const selectedClient = getStoredSelectedClient(clients);
    let pathList = userInfo.paths ? userInfo.paths : []

    // TODO: Deprecate when bootsrap will be removed
    // TODO: Harcoded Filter! remove this condition when old dashboard are deprecated
    pathList = pathList
        .filter(row => !((row.description === 'dashboards' && (selectedClient.name === 'Bunnings' || selectedClient.name === 'Jumbo')) || (row.description === 'dashboards_new' && (selectedClient.name === 'Santa Isabel'))))
        .map(row => {
            const iconName = row.icon_name.split('-').map(word => capitalizeFirstLetter(word)).join('');
            const iconRow = IconBootstrap[iconName];
            return { ...row, 'IconTag': iconRow ? iconRow : IconBootstrap.Apple }
        })

    const sectionPaths = { general: {}, admin: {} };
    const sectionView = [];
    pathList.forEach(pathInfo => {
        // TODO: Deprecate when all cpg legacy are migrated
        let localPath = pathInfo.path;

        if (localPath) {
            const section = pathInfo.path.includes('admin') ? 'admin' : 'general';
            const main_category = pathInfo.main_category ? pathInfo.main_category : 'basic';
            sectionPaths[section][main_category] = [...(sectionPaths[section][main_category] || []), pathInfo];
        }
        if (pathInfo.is_hot_view) {
            sectionView.push(pathInfo);
        }
    });

    // Store in local storage the clients
    storeToken(userInfo.user.token);
    getUserNotifications()
    storeClients(clients);
    storeUser(userInfo.user);
    storePaths(pathList);
    setBlobDate();
    return {
        ...state,
        loading_user_info: false,
        userInfo: userInfo.user,
        sectionPaths: sectionPaths,
        sectionView: sectionView,
        clients: clients,
        selectedClient: selectedClient,

    };
}

function handleUsersCache(state) {
    // Store in local storage the clients
    let pathList = getStoredPaths();
    const userInfo = getStoredUser();
    const clients = getStoredClients();
    const selectedClient = getStoredSelectedClient(clients);
    const userLegacyDashboards = getStoredLegacyDashboards();

    // TODO: Deprecate when bootsrap will be removed
    pathList = pathList.map(row => {
        const iconName = row.icon_name.split('-').map(word => capitalizeFirstLetter(word)).join('');
        const iconRow = IconBootstrap[iconName];
        return { ...row, 'IconTag': iconRow ? iconRow : IconBootstrap.Apple }
    })
    const sectionPaths = { general: {}, admin: {} };
    const sectionView = [];
    pathList.forEach(pathInfo => {
        if (pathInfo.path) {
            const section = pathInfo.path.includes('admin') ? 'admin' : 'general';
            const main_category = pathInfo.main_category ? pathInfo.main_category : 'basic';
            sectionPaths[section][main_category] = [...(sectionPaths[section][main_category] || []), pathInfo];
        }
        if (pathInfo.is_hot_view) {
            sectionView.push(pathInfo);
        }
    });
    return {
        ...state,
        loading_user_info: false,
        userInfo: userInfo.user,
        sectionPaths: sectionPaths,
        sectionView: sectionView,
        clients: clients,
        selectedClient: selectedClient,
        userLegacyDashboards: userLegacyDashboards,
    };
}

function handleUsersUnavailable(state, _) {
    return UserDefaultState;
}

function handleCustomUnavailable(state, { payload: { keyState, loadingName } }) {
    return {
        ...state,
        [keyState]: [],
        [loadingName]: false,
    };
}

function handleCustomRequest(state, { payload: { loadingName } }) {
    return {
        ...state,
        [loadingName]: true,
    };
}

function handleCustomAvailable(state, { payload: { keyState, loadingName, requestInfo, aditionalStates = {} } }) {
    return {
        ...state,
        [loadingName]: false,
        [keyState]: requestInfo,
        ...aditionalStates,
    };
}


function handleClientSelect(state, { payload: { client } }) {
    storeSelectedClient(client);
    return {
        ...state,
        selectedClient: client,
    };
}

// TODO: delete on deprecation of cpg
function handleUserDashboardsAvailable(state, { payload: { userDashboards } }) {
    storeLegacyReports(userDashboards);
    let sp = { ...state.sectionPaths };
    sp.general.basic?.forEach((section) => {
        if (section.description === 'dashboards') {
            section.subSection = userDashboards.map((report) => (
                {
                    description: report.type,
                    path: report.link,
                    isExternalPath: true,
                    chain_id: report.chain_id,
                    chain_name: report.chain_name,
                    report_id: report.pbi_report_legacy_id,
                }
            ));
            section.icon_name = 'stats-chart-outline'
            section.isExternalPath = false;
            section.path = '';
        }
    });
    return {
        ...state,
        sectionPaths: sp,
        userLegacyDashboards: userDashboards,
    };
}

// Actions

export function getUserInfo() {

    return async (dispatch, getState, { services: { dataSource } }) => {
        dispatch({ type: USERS_CUSTOM_REQUEST });
        try {
            let canRequest = checkLocalInfo();
            if (!canRequest) {
                const userInfo = await dataSource.getUserInfo();
                dispatch({
                    type: USERS_CUSTOM_AVAILABLE,
                    payload: { userInfo },
                });
                const clients = getStoredClients();
                const selectedClient = getStoredSelectedClient(clients);
                // TODO: delete on deprecation of cpg
                const userDashboards = await dataSource.getPBILegacyReports(selectedClient.client_id);
                dispatch({
                    type: USER_CUSTOM_DASHBOARDS_AVAILABLE,
                    payload: { userDashboards },
                });
            } else {
                dispatch({
                    type: USERS_CACHE
                });
            }
        } catch (error) {
            console.log('Error geting user info: ', error);
            dispatch({
                type: USERS_CUSTOM_UNAVAILABLE,
                payload: { error },
            });
            const snack = {
                open: true,
                message: 'Error getting the user info.',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}


export function getUserStores(client_id, user_id = null, isUserMGMT = false) {

    return async (dispatch, _, { services: { dataSource } }) => {
        const keyState = isUserMGMT ? 'userMGMTStores' : 'stores'
        const loadingName = 'loading_user_stores'
        dispatch({ type: USER_REQUEST, payload: { loadingName: loadingName } });
        try {
            const userStores = await dataSource.getUserStores(client_id, user_id);
            dispatch({
                type: USER_AVAILABLE,
                payload: { keyState: keyState, loadingName: loadingName, requestInfo: userStores.stores, aditionalStates: { 'chains': userStores.chains } },
            });
        } catch (error) {
            console.log('Error geting user stores: ', error);
            dispatch({
                type: USER_UNAVAILABLE,
                payload: { error, keyState: keyState, loadingName: loadingName },
            });
            const snack = {
                open: true,
                message: 'Error getting the user stores.',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getUsers(client_id, are_zippedians_included = false, order_by_name = false) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const keyState = 'users'
        const loadingName = 'loadingUsers'
        dispatch({ type: USER_REQUEST, payload: { loadingName: loadingName } });
        try {
            const response = await dataSource.getUsers(client_id, are_zippedians_included, order_by_name);
            dispatch({
                type: USERS_AVAILABLE,
                payload: { keyState: keyState, data: response?.users, loadingName: loadingName },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: USERS_UNAVAILABLE,
                payload: { keyState: keyState, loadingName: loadingName, defaultState: [] },
            });
            const snack = {
                open: true,
                message: "There was an error getting the users.",
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function postUser(body) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const keyState = 'postUserResponse'
        const loadingName = 'loadingPostUser'
        dispatch({ type: USER_REQUEST, payload: { loadingName: loadingName } });
        try {
            const response = await dataSource.postUser(body);
            dispatch({
                type: USERS_AVAILABLE,
                payload: { keyState: keyState, data: response, loadingName: loadingName },
            });
            if (response?.message === 'Created') {
                const snack = {
                    open: true,
                    message: 'The user was created successfully.',
                    severity: 'success',
                };
                dispatch({ type: SNACK_SET, payload: { snack } });
            }
        } catch (error) {
            dispatch({
                type: USERS_UNAVAILABLE,
                payload: { keyState: keyState, loadingName: loadingName, defaultState: {} },
            });
            const snack = {
                open: true,
                message: 'There was an error creating the user: ' + error.message,
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function updateUser(body) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const keyState = 'updateUserResponse'
        const loadingName = 'loadingUpdateUser'
        dispatch({ type: USER_REQUEST, payload: { loadingName: loadingName } });
        try {
            const response = await dataSource.updateUser(body);
            dispatch({
                type: USERS_AVAILABLE,
                payload: { keyState: keyState, data: response, loadingName: loadingName },
            });
            if (response?.message === 'OK') {
                const snack = {
                    open: true,
                    message: 'The user was updated successfully.',
                    severity: 'success',
                };
                dispatch({ type: SNACK_SET, payload: { snack } });
            }
        } catch (error) {
            dispatch({
                type: USERS_UNAVAILABLE,
                payload: { keyState: keyState, loadingName: loadingName, defaultState: {} },
            });
            const snack = {
                open: true,
                message: 'There was an error updating the user: ' + error.message,
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function deleteUser(user_id, email = null, to_revoke = false) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const keyState = 'deleteUserResponse'
        const loadingName = 'loadingDeleteUser'
        dispatch({ type: USER_REQUEST, payload: { loadingName: loadingName } });
        try {
            const response = await dataSource.deleteUser(user_id, email, to_revoke);
            dispatch({
                type: USERS_AVAILABLE,
                payload: { keyState: keyState, data: response, loadingName: loadingName },
            });
            if (to_revoke && response?.message === 'OK') {
                const snack = {
                    open: true,
                    message: 'The two factor authentication was revoked.',
                    severity: 'success',
                };
                dispatch({ type: SNACK_SET, payload: { snack } });

            }
            else if (response?.message === 'OK') {
                const snack = {
                    open: true,
                    message: "User deleted successfully.",
                    severity: 'success',
                };
                dispatch({ type: SNACK_SET, payload: { snack } });
            }
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: USERS_UNAVAILABLE,
                payload: { keyState: keyState, loadingName: loadingName, defaultState: {} },
            });
            if (to_revoke) {
                const snack = {
                    open: true,
                    message: "There was an error revoking the users MFA.",
                    severity: 'error',
                };
                dispatch({ type: SNACK_SET, payload: { snack } });
            } else {
                const snack = {
                    open: true,
                    message: "There was an error deleting the user: " + error.message,
                    severity: 'error',
                };
                dispatch({ type: SNACK_SET, payload: { snack } });
            }
        }
    };
}


export function getUserNotifications() {

    return async (dispatch, _, { services: { dataSource } }) => {
        dispatch({ type: USER_REQUEST, payload: { loadingName: 'loading_user_notifications' } });
        try {
            const notifications = await dataSource.getUserNotifications();
            dispatch({
                type: USER_AVAILABLE,
                payload: { keyState: 'notifications', loadingName: 'loading_user_notifications', requestInfo: notifications.notifications },
            });
        } catch (error) {
            console.log('Error geting user notifications: ', error);
            dispatch({
                type: USER_UNAVAILABLE,
                payload: { error, keyState: 'notifications', loadingName: 'loading_user_notifications' },
            });
            const snack = {
                open: true,
                message: 'Error getting the user notifications.',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function postUserNotification(notification_id, seen_timestamp) {
    return async (dispatch, _, { services: { dataSource } }) => {
        dispatch({ type: USER_REQUEST, payload: { loadingName: 'loading_user_notifications' } });
        try {
            const notifications = await dataSource.postUserNotification(notification_id, seen_timestamp);
            dispatch({
                type: USER_AVAILABLE,
                payload: { loadingName: 'loading_user_notifications' },
            });
        } catch (error) {
            console.log('Error geting user notifications: ', error);
            dispatch({
                type: USER_UNAVAILABLE,
                payload: { error, loadingName: 'loading_user_notifications' },
            });
            const snack = {
                open: true,
                message: 'cws_app.general.default_message_error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getUserClients(user_id, keyState = 'userClients', loadingName = 'loadingUserClients', canLocalPersist = false) {
    return async (dispatch, _, { services: { dataSource } }) => {
        dispatch({ type: USER_REQUEST, payload: { loadingName: loadingName } });
        try {
            const clients = await dataSource.getUserClients(user_id);
            if (canLocalPersist) storeClients(clients ? clients : []);
            dispatch({
                type: USERS_AVAILABLE,
                payload: { keyState: keyState, data: clients, loadingName: loadingName },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: USERS_UNAVAILABLE,
                payload: { keyState: keyState, loadingName: loadingName, defaultState: [] },
            });
            const snack = {
                open: true,
                message: "There was an error getting the user clients.",
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

// state functions
export function selectClient(client) {

    return (dispatch) => {
        try {
            dispatch({
                type: USER_CLIENT_SELECT,
                payload: { client },
            });
        } catch (error) {
            console.log('error: ', error);
            const snack = {
                open: true,
                message: 'cws_app.general.default_message_error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    }
}

// TODO: delete on deprecation of cpg
export function getUserPBILegacyDashboards(client_id) {
    return async (dispatch, _, { services: { dataSource } }) => {
        dispatch({ type: USER_REQUEST, payload: { loadingName: 'loading_user_legacy_dashboards' } });
        try {
            const userDashboards = await dataSource.getPBILegacyReports(client_id);
            dispatch({
                type: USER_CUSTOM_DASHBOARDS_AVAILABLE,
                payload: { userDashboards },
            });
        } catch (error) {
            console.log('Error geting user legacy dashboards: ', error);
            dispatch({
                type: USER_UNAVAILABLE,
                payload: { error, keyState: 'userLegacyDashboards', loadingName: 'loading_user_legacy_dashboards' },
            });
            const snack = {
                open: true,
                message: 'Error getting the user legacy dashboards.',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getUserSuppliers(client_id, chains = [], subscriptions = false, store_code = null, category = null) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const keyState = 'userSuppliers'
        const loadingName = 'loadingUserSuppliers'
        dispatch({ type: USERS_REQUEST, payload: { loadingName: loadingName } });
        try {
            if (chains.length > 0) {
                chains = JSON.stringify(chains)
            }
            const response = await dataSource.getUserSuppliers(client_id, chains, subscriptions, store_code, category);
            dispatch({
                type: USERS_AVAILABLE,
                payload: { keyState: keyState, data: response, loadingName: loadingName },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: USERS_UNAVAILABLE,
                payload: { keyState: keyState, loadingName: loadingName, defaultState: [] },
            });
            const snack = {
                open: true,
                message: "There was an error getting the user suppliers.",
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}
