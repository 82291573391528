import { Avatar, Box, Button, CircularProgress, ClickAwayListener, InputBase, Popper, Stack, Tooltip, Typography } from '@mui/material';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import { styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const ClientSelectorBeta = styled('div')(({ theme }) => ({
    [`& .${autocompleteClasses.paper}`]: {
        boxShadow: 'none',
        margin: 0,
        color: 'inherit',
        fontSize: 13,
    },
    [`& .${autocompleteClasses.listbox}`]: {
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
        padding: 0,
        [`& .${autocompleteClasses.option}`]: {
            minHeight: 'auto',
            alignItems: 'flex-start',
            padding: 8,
            borderBottom: `1px solid  ${theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'
                }`,
            '&[aria-selected="true"]': {
                backgroundColor: 'transparent',
            },
            [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
            {
                backgroundColor: theme.palette.action.hover,
            },
        },
    },
    [`&.${autocompleteClasses.popperDisablePortal}`]: {
        position: 'relative',
    },
}));


function PopperComponent(props) {
    const { disablePortal, anchorEl, open, ...other } = props;
    return <ClientSelectorBeta {...other} />;
}

PopperComponent.propTypes = {
    anchorEl: PropTypes.any,
    disablePortal: PropTypes.bool,
    open: PropTypes.bool.isRequired,
};

const StyledPopper = styled(Popper)(({ theme }) => ({
    border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
    boxShadow: `0 8px 24px ${theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
        }`,
    borderRadius: 6,
    width: 300,
    zIndex: theme.zIndex.modal,
    fontSize: 13,
    color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
    padding: 10,
    width: '100%',
    borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'
        }`,
    '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
        fontSize: 14,
        '&:focus': {
            boxShadow: `0px 0px 0px 3px ${theme.palette.mode === 'light'
                ? 'rgba(3, 102, 214, 0.3)'
                : 'rgb(12, 45, 107)'
                }`,
            borderColor: theme.palette.mode === 'light' ? '#0366d6' : '#388bfd',
        },
    },
}));

const ColorButton = styled(Button)(({ theme }) => ({
    color: 'primary',
    height: theme.mixins.toolbar.minHeight,
}));

const styles = {
    loadingAvatar: {
        position: 'absolute',
        top: 1,
        left: 4,
        zIndex: -1,
    }
}

export default function ClientSelector(props) {
    const { clientsList, isLoadingUserInfo, selectClient, preSelectedClient, getClientFormats } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedClient, setSelectedClient] = React.useState(clientsList ? preSelectedClient : {});
    const [currentClientList, setCurrentClientList] = React.useState(clientsList ? clientsList : []);
    const theme = useTheme();
    const { t } = useTranslation();

    React.useEffect(() => {
        setSelectedClient(preSelectedClient ? preSelectedClient : {});
    }, [preSelectedClient]);

    React.useEffect(() => {
        setCurrentClientList(clientsList);
        if (preSelectedClient) {
            const preSelectedClientIndex = clientsList.findIndex(client => client.client_id === preSelectedClient.client_id);
            if (preSelectedClientIndex !== -1) {
                getClientFormats(preSelectedClient.client_id);
                setSelectedClient(clientsList[preSelectedClientIndex]);
            }
        }
    }, [clientsList, setCurrentClientList]);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        if (anchorEl) {
            anchorEl.focus();
        }
        setAnchorEl(null);
    };

    const handleSelectClient = (event, newValue, reason) => {
        if (
            event.type === 'keydown' &&
            event.key === 'Backspace' &&
            reason === 'removeOption'
        ) {
            return;
        }
        if (newValue) {
            setSelectedClient(newValue);
            selectClient(newValue);
            getClientFormats(newValue.client_id);
            handleClose();
        }
    }

    const open = Boolean(anchorEl);
    const id = open ? 'client-id' : undefined;

    return (
        <>
            {
                currentClientList?.length > 1 ? <>
                    <Box sx={{ m: 1, position: 'relative' }}>
                        <Tooltip title={t('cws_app.general.loading_images', 'Loading images...')} placement="bottom" disableHoverListener={!isLoadingUserInfo}>
                            <ColorButton onClick={handleClick}>
                                {
                                    selectedClient?.signed_logo && selectedClient?.signed_logo !== '/' ?
                                        <img loading="lazy" width="45" src={selectedClient?.signed_logo} alt={`${selectedClient?.name[0]}`} /> :
                                        <Avatar alt={selectedClient?.name} src={selectedClient?.signed_logo} variant="outlined" />
                                }
                            </ColorButton>
                        </Tooltip>
                        {isLoadingUserInfo && (
                            <CircularProgress
                                size={55}
                                color="secondary"
                                style={styles.loadingAvatar}
                            />
                        )}
                    </Box>
                    <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
                        <ClickAwayListener onClickAway={handleClose}>
                            <div>
                                <Box
                                    sx={{
                                        borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'
                                            }`,
                                        padding: '8px 10px',
                                        fontWeight: 600,
                                    }}
                                >
                                    Clients
                                </Box>
                                <Autocomplete
                                    open
                                    onClose={(event, reason) => {
                                        if (reason === 'escape') {
                                            handleClose();
                                        }
                                    }}
                                    value={selectedClient}
                                    onChange={handleSelectClient}
                                    disableCloseOnSelect
                                    PopperComponent={PopperComponent}
                                    renderTags={() => null}
                                    noOptionsText="No clients"
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                                                <Box component="span" sx={{ mr: 1 }}>
                                                    {option.signed_logo === '/' ? <Avatar
                                                        alt={option.name}
                                                        src={option.signed_logo}
                                                        sx={{ bgcolor: '#cacadd44' }}
                                                    /> : <img loading="lazy" width="35" src={option.signed_logo} alt={`${option.name[0]}`} />
                                                    }
                                                </Box>
                                                <Box>
                                                    <Typography>{option.name}</Typography>
                                                </Box>
                                            </Stack>
                                        </li>
                                    )}
                                    options={currentClientList}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <StyledInput
                                            ref={params.InputProps.ref}
                                            inputProps={params.inputProps}
                                            autoFocus
                                            placeholder="Filter Clients"
                                        />
                                    )}
                                />
                            </div>
                        </ClickAwayListener>
                    </StyledPopper>
                </> :
                    <>
                        <Box sx={{ m: 1, position: 'relative' }}>
                            <Avatar alt={selectedClient?.name} src={selectedClient?.signed_logo} variant="outlined" />
                            {isLoadingUserInfo && (
                                <CircularProgress
                                    size={50}
                                    color="secondary"
                                    sx={{
                                        position: 'absolute',
                                        top: -5,
                                        left: -5,
                                        zIndex: 1,
                                    }}
                                />
                            )}
                        </Box>
                    </>

            }
        </ >
    );
}
