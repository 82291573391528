import React, { Component } from 'react';
import { Grid } from '@mui/material';

const styles = {
  container: {
    py: 3,
  },
  text: {
    pb: 1,
    fontWeight: 'bold',
  },
  images: {
    width: 'auto',
    maxWidth: 800,
    height: 'auto',
    maxHeight: 500,
  },
};

class DocsImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image_url: '',
    };
  }

  async componentDidMount() {
    const { title, getDocumentationBlobs } = this.props;

    if (getDocumentationBlobs['blobs_urls']) {
      this.setState({
        image_url: getDocumentationBlobs['blobs_urls'][title],
      });
    }
  }

  async componentDidUpdate(prevProps, _) {
    const { title, getDocumentationBlobs } = this.props;

    if (prevProps.getDocumentationBlobs !== getDocumentationBlobs) {
      if (getDocumentationBlobs['blobs_urls']) {
        this.setState({
          image_url: getDocumentationBlobs['blobs_urls'][title],
        });
      }
    }
  }

  render() {
    const { title } = this.props;
    const { image_url } = this.state;

    return (
      <Grid sx={styles.container}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column">
          <img
            style={styles.images}
            alt={`${title}_image`}
            src={image_url}></img>
        </Grid>
      </Grid>
    );
  }
}

export default DocsImage;
