import { createReducer } from './utils';
import { handleRequest, handleAvailable, handleUnavailable } from '../reducerHandlers'
import { ExcelExporter } from '../services/excelExporter';
import {
  SNACK_SET,
  TRACKING_LIST_REQUEST,
  TRACKING_LIST_AVAILABLE,
  TRACKING_LIST_UNAVAILABLE,
  TRACKING_LIST_AVAILABLE_EXCEL,
} from './constants';

const defaultState = {
  loadingUploadTrackingList: false,
  uploadResponse: {},
  loadingDeleteTrackingList: false,
  deleteResponse: {},
  loadingTrackingList: false,
  loadingTrackingListStores: false,
  loadingTrackingListItems: false,
  loadingTrackingListExcel: false,
  trackingListData: {},
  trackingListDataItems: [],
};

// Reducer

export const reducer = createReducer(defaultState, {
  [TRACKING_LIST_REQUEST]: handleRequest,
  [TRACKING_LIST_AVAILABLE]: handleAvailable,
  [TRACKING_LIST_UNAVAILABLE]: handleUnavailable,
  [TRACKING_LIST_AVAILABLE_EXCEL]: handleAvailableExcel,
});

function handleAvailableExcel(state, { payload: { loadingName, excelData, excelFileName, canFormatData = false } }) {
  const newState = {
    ...state,
    [loadingName]: false,
  };

  const excelExporter = new ExcelExporter();
  excelExporter.exportAsExcelFile({ excelData, excelFileName, canFormatData })

  return newState
}

// Actions

export function uploadTrackingList(form) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'uploadResponse'
    const loadingName = 'loadingUploadTrackingList'
    dispatch({ type: TRACKING_LIST_REQUEST, payload: { loadingName: loadingName } });
    try {
      const response = await dataSource.uploadTrackingList(form);
      dispatch({
        type: TRACKING_LIST_AVAILABLE,
        payload: { keyState: keyState, data: response, loadingName: loadingName },
      });
      if (response?.message === 'success') {
        const snack = {
          open: true,
          message: 'The tracking list was uploaded successfully.',
          severity: 'success',
        };
        dispatch({ type: SNACK_SET, payload: { snack } });
      }
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: TRACKING_LIST_UNAVAILABLE,
        payload: { keyState: keyState, loadingName: loadingName, defaultState: {} },
      });
      const snack = {
        open: true,
        message: 'There was an error uploading the tracking list.',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function deleteTrackingList(tracking_list_id) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'deleteResponse'
    const loadingName = 'loadingDeleteTrackingList'
    dispatch({ type: TRACKING_LIST_REQUEST, payload: { loadingName: loadingName } });
    try {
      const body = {
        tracking_list_id: tracking_list_id,
      }
      const response = await dataSource.deleteTrackingList(body);
      dispatch({
        type: TRACKING_LIST_AVAILABLE,
        payload: { keyState: keyState, data: response, loadingName: loadingName },
      });
      if (response?.message === 'success') {
        const snack = {
          open: true,
          message: 'The tracking list was deleted.',
          severity: 'success',
        };
        dispatch({ type: SNACK_SET, payload: { snack } });
      }
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: TRACKING_LIST_UNAVAILABLE,
        payload: { keyState: keyState, loadingName: loadingName, defaultState: {} },
      });
      const snack = {
        open: true,
        message: 'There was an error deleting the tracking list.',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function getTrackinglist(supplier_id) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'loadingTrackingList';
    const keyState = 'trackingListData';
    dispatch({ type: TRACKING_LIST_REQUEST, payload: { loadingName: loadingName } });
    try {
      const response = await dataSource.getTrackinglist(supplier_id);
      if (response?.message === 'not found') {
        dispatch({
          type: TRACKING_LIST_UNAVAILABLE,
          payload: { keyState: keyState, loadingName: loadingName, defaultState: {} },
        });
        const snack = {
          open: true,
          message: 'No tracking list found.',
          severity: 'warning',
        };
        dispatch({ type: SNACK_SET, payload: { snack } });
      } else {
        dispatch({
          type: TRACKING_LIST_AVAILABLE,
          payload: {
            keyState: keyState,
            data: response,
            loadingName: loadingName
          },
        });
      }
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: TRACKING_LIST_UNAVAILABLE,
        payload: { keyState: keyState, loadingName: loadingName, defaultState: {} },
      });
      const snack = {
        open: true,
        message: 'There was an error getting the tracking list.',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function getTrackinglistItems(track_list_id, active) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'loadingTrackingListItems';
    const keyState = 'trackingListDataItems';
    dispatch({ type: TRACKING_LIST_REQUEST, payload: { loadingName: loadingName } });
    try {
      const response = await dataSource.getTrackinglistItems(track_list_id, active);
      dispatch({
        type: TRACKING_LIST_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response.products,
          loadingName: loadingName
        },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: TRACKING_LIST_UNAVAILABLE,
        payload: { keyState: keyState, loadingName: loadingName, defaultState: [] },
      });
      const snack = {
        open: true,
        message: 'There was an error getting the tracking list items.',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}


export function getTrackingListExcel({ excelData, excelFileName }) {
  return async (dispatch, _, { services: { __ } }) => {
    const loadingName = 'loadingTrackingListExcel'
    const keyState = 'excelFileMessage'

    dispatch({ type: TRACKING_LIST_REQUEST, payload: { loadingName } });
    try {
      dispatch({
        type: TRACKING_LIST_AVAILABLE_EXCEL,
        payload: { loadingName, excelData, excelFileName },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: TRACKING_LIST_UNAVAILABLE,
        payload: { keyState, loadingName, defaultState: { 'message': 'error' } },
      });
      const snack = {
        open: true,
        message: 'There was an error donwlading the excel file.',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function postTrackingListStores(tracking_list_id, stores, item, ean, active) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'loadingTrackingListStores';
    dispatch({ type: TRACKING_LIST_REQUEST, payload: { loadingName: loadingName } });
    console.log(tracking_list_id, stores, item, ean, active)
    try {
      const response = await dataSource.postTrackingListStores(tracking_list_id, stores, item, ean, active);
      const snack = {
        open: true,
        message: 'Tracking list stores updated',
        severity: 'info',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: TRACKING_LIST_UNAVAILABLE,
        payload: { error, loadingName: loadingName },
      });
      const snack = {
        open: true,
        message: 'There was an error updating the tracking list stores.',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}
