import { connect } from 'react-redux';
import DashboardPreview from '../../../../components/features/dashboards/DashboardPreview';

import { downloadExcel } from 'core/slices/general';
import { deleteCachedDashboard } from 'core/slices/dashboards';
import {
    getLastResumeDashboardProcessed,
    isLoadingExcelDownload,
    isLoadingNumberFormats,
    getNumberFormats,
    currencySymbol
} from 'core/selectors';

export default connect(state => ({
    lastResumeDashboardProcessed: getLastResumeDashboardProcessed(state),
    isLoadingExcelDownload: isLoadingExcelDownload(state),
    isLoadingNumberFormat: isLoadingNumberFormats(state),
    numberFormat: getNumberFormats(state),
    currencySymbol: currencySymbol(state),
}), {
    downloadExcel,
    deleteCachedDashboard,
})(DashboardPreview);
