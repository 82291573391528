import { createReducer } from './utils';

import {
    DASHBOARDS_GENERAL_REQUEST,
    DASHBOARDS_GENERAL_AVAILABLE,
    DASHBOARDS_GENERAL_UNAVAILABLE,
    DASHBOARDS_REQUEST,
    DASHBOARDS_AVAILABLE,
    DASHBOARDS_UNAVAILABLE,
    SNACK_SET,
} from './constants';

const defaultState = {
    loading_dashboards: false,
    loading_dashboards_resume: {},
    loading_dashboard: false,
    dashboards: false,
    loading_legacy_reports: false,
    dashboardsList: [],
    dashboardInfo: undefined,
    dashboardsResumeInfo: undefined,
    lastResumeDashboardProcessed: undefined,
    legacyReports: [],

};

export const reducer = createReducer(defaultState, {
    [DASHBOARDS_GENERAL_REQUEST]: handleCustomRequest,
    [DASHBOARDS_GENERAL_AVAILABLE]: handleCustomAvailable,
    [DASHBOARDS_GENERAL_UNAVAILABLE]: handleCustomUnavailable,
    [DASHBOARDS_REQUEST]: handleDashboardsRequest,
    [DASHBOARDS_AVAILABLE]: handleDashboardsAvailable,
    [DASHBOARDS_UNAVAILABLE]: handleDashboardsUnavailable,
});

function handleCustomRequest(state, { payload: { loadingName } }) {
    return {
        ...state,
        [loadingName]: true,
    };
}

function handleCustomAvailable(state, { payload: { keyState, loadingName, requestInfo, aditionalStates = {} } }) {
    return {
        ...state,
        [loadingName]: false,
        [keyState]: requestInfo,
        ...aditionalStates,
    };
}

function handleCustomUnavailable(state, { payload: { keyState, loadingName } }) {
    return {
        ...state,
        [keyState]: [],
        [loadingName]: false,
    };
}

function handleDashboardsRequest(state, { payload: { dashboard_id } }) {
    const loading_dashboards_resume_temp = state.loading_dashboards_resume ? state.loading_dashboards_resume : {};
    loading_dashboards_resume_temp[dashboard_id] = true;
    return {
        ...state,
        loading_dashboards_resume: loading_dashboards_resume_temp,
    };
}

function handleDashboardsAvailable(state, { payload: { dashboardResumeInfo, dashboard_id } }) {
    const dashboardsResumeInfoTemp = state.dashboardsResumeInfo ? state.dashboardsResumeInfo : {};
    const loading_dashboards_resume_temp = state.loading_dashboards_resume ? state.loading_dashboards_resume : {};
    dashboardsResumeInfoTemp[dashboard_id] = dashboardResumeInfo;
    loading_dashboards_resume_temp[dashboard_id] = false;
    return {
        ...state,
        loading_dashboards_resume: loading_dashboards_resume_temp,
        dashboardsResumeInfo: dashboardsResumeInfoTemp,
        lastResumeDashboardProcessed: dashboard_id,
    };
}

function handleDashboardsUnavailable(state, { payload: { dashboard_id } }) {
    const loading_dashboards_resume_temp = state.loading_dashboards_resume ? state.loading_dashboards_resume : {};
    loading_dashboards_resume_temp[dashboard_id] = false;
    return {
        ...state,
        dashboardsResumeInfo: state.dashboardsResumeInfo ? state.dashboardsResumeInfo : {},
        loading_dashboards_resume: loading_dashboards_resume_temp,
    };
}

export function getDashboardResume({ dashboard_id, client_id, stores, supplier_id = null }) {
    return async (dispatch, _, { services: { dataSource } }) => {
        dispatch({ type: DASHBOARDS_REQUEST, payload: { dashboard_id: dashboard_id } });
        try {
            const DashboardInfoResponse = await dataSource.getDashboardResume({ dashboard_id, client_id, stores: JSON.stringify(stores), supplier_id });
            dispatch({
                type: DASHBOARDS_AVAILABLE,
                payload: { dashboardResumeInfo: DashboardInfoResponse.resume_dashboard, dashboard_id: dashboard_id },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: DASHBOARDS_UNAVAILABLE,
                payload: { dashboard_id: dashboard_id, error },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getDashboard({ dashboard_id, client_id, stores, start_date, end_date, categories = [], category_level = null, sub_categories = [], sub_category_level = null, suppliers = [], supplier_id = null, store_types = [] }) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loading_dashboard'
        const keyState = 'dashboardInfo'

        dispatch({ type: DASHBOARDS_GENERAL_REQUEST, payload: { loadingName } });
        try {
            const DashboardInfoResponse = await dataSource.getDashboard({ dashboard_id, client_id, stores: JSON.stringify(stores), start_date, end_date, categories, category_level, sub_categories, sub_category_level, suppliers, supplier_id, store_types });
            const dashboardInfo = DashboardInfoResponse.dashboard_info ? DashboardInfoResponse.dashboard_info : {}
            dispatch({
                type: DASHBOARDS_GENERAL_AVAILABLE,
                payload: { requestInfo: dashboardInfo, keyState, loadingName },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: DASHBOARDS_GENERAL_UNAVAILABLE,
                payload: { error, keyState, loadingName },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function deleteCachedDashboard() {
    return async (dispatch, _,) => {
        dispatch({ type: DASHBOARDS_GENERAL_UNAVAILABLE, payload: { keyState: 'dashboardsList', loadingName: 'loading_dashboards' } })
    }
}


export function getDashboards(client_id, supplier_id = null, stores) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loading_dashboards'
        const keyState = 'dashboardsList'

        dispatch({ type: DASHBOARDS_GENERAL_REQUEST, payload: { keyState, loadingName } });
        try {
            const DashboardInfoResponse = await dataSource.getDashboards(client_id, supplier_id, stores);
            dispatch({
                type: DASHBOARDS_GENERAL_AVAILABLE,
                payload: { requestInfo: DashboardInfoResponse.dashboards_list, keyState, loadingName },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: DASHBOARDS_GENERAL_UNAVAILABLE,
                payload: { error, keyState, loadingName },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

