import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { visuallyHidden } from '@mui/utils';
import PageLink from '../../tools/PageLink';
import { useTranslation } from 'react-i18next';

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}


function modernSort(array, comparator) {
	return array.slice().sort(comparator)
}


function EnhancedTableHead(props) {
	const { renderedColumns, order, orderBy, onRequestSort, lang = 'es' } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};
	const wordBreak = ['ja', 'ko', 'zh'].includes(lang) ? 'keep-all' : 'auto-phrase';

	return (
		<TableHead sx={{ wordBreak }}>
			<TableRow>
				{renderedColumns?.map((column, index) => (
					<TableCell
						key={`${column.id}-${index}`}
						align={'center'}
						sortDirection={orderBy === column.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === column.id}
							direction={orderBy === column.id ? order : 'asc'}
							onClick={createSortHandler(column.id)}
						>
							<Typography className='title_grey' sx={{ color: (theme) => theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)' }}>
								{column.translatedName}
							</Typography>
							{orderBy === column.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	renderedColumns: PropTypes.array.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	lang: PropTypes.string,
};

function EnhancedTableToolbar(props) {
	const { numSelected } = props;

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
				}),
			}}
		>
			<Typography
				sx={{ flex: '1 1 100%' }}
				variant="h6"
				id="tableTitle"
				component="div"
			>
				Nutrition
			</Typography>
		</Toolbar>
	);
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
};

export default function SortPaginatedTable(props) {
	const {
		t,
		page,
		setPage,
		StyledPaper,
		renderedData,
		renderedColumns,
		initialOrderBy = 'aisle',
		checkColumn,
		checkGroupColumnDisplay,
		getGroupColumnCant,
		getGroupColumn,
		getElementValue,
		store,
		date,
		selectedRobotIndex,
	} = props;

	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState(initialOrderBy);
	const dense = useState(false)[0];
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const displayedData = useRef(renderedData.slice(0, 5));
	const { i18n } = useTranslation();

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const getDisplayedData = () => {
		displayedData.current = modernSort(renderedData, getComparator(order, orderBy))
			.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
		return displayedData.current
	}

	const handleKey = (rowTable, index) => {
		return rowTable.detection_id ? `${rowTable.detection_id}-${rowTable.item}-${rowTable.ean}-${index}` : `${rowTable.landmark}-${index}`;
	}
	// Avoid a layout jump when reaching the last page with empty renderedData.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - renderedData.length) : 0;

	return (
		<>
			<StyledPaper>
				{/* <EnhancedTableToolbar numSelected={selected.length} /> */}
				<TableContainer>
					<Table
						sx={{ minWidth: 350 }}
						aria-labelledby="tableTitle"
						size={dense ? 'small' : 'medium'}
					>
						<EnhancedTableHead
							renderedColumns={renderedColumns}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							lang={i18n.language}
						/>
						<TableBody>
							{getDisplayedData()
								?.map((rowTable, rowIndex) =>
									<TableRow key={handleKey(rowTable, rowIndex)}>
										{
											renderedColumns?.map((column, index) =>
												checkColumn(column.id) ?
													(
														checkGroupColumnDisplay(rowIndex, displayedData.current) ?
															<TableCell key={index} rowSpan={getGroupColumnCant(rowTable, displayedData.current)}>
																<PageLink
																	currentState={{
																		date: date,
																		storeName: store,
																		aisleName: rowTable?.client_aisle_name ? rowTable?.client_aisle_name : rowTable?.aisle_name,
																		floorValue: selectedRobotIndex,
																	}}
																	view='/cpg/walls'
																	firstTooltipTitle={t('cws_app.general.see_walls', 'See in Walls')}
																	secondTooltipTitle=''
																	isRedirect={true}
																	text={getGroupColumn(rowTable)}
																	onlyText={true}
																/>
															</TableCell>
															: null
													)
													:
													// TODO: Add  tooltip
													<TableCell key={index} align="center">
														{getElementValue(rowTable[column.id])}
													</TableCell>
											)
										}
									</TableRow>
								)}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: (dense ? 33 : 53) * emptyRows,
									}}
								>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={renderedData.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					showFirstButton={true}
					showLastButton={true}
					labelRowsPerPage={`${t('cws_app.digital_store.rows_per_page', 'Rows per page')}:`}
				/>
			</StyledPaper>
		</>
	);
}