import { connect } from 'react-redux';
import Notifications from '../../../../components/features/notifications/Notifications';

import {
    getNotifications,
    getClientNotification,
    postClientNotification,
    updateClientNotification,
    deleteClientNotification,
} from 'core/slices/notifications';

import {
    getUsers,
} from 'core/slices/users';

import {
    getClientStores,
} from "core/slices/clients";

import {
    getChains,
} from 'core/slices/backend';

import {
    getNotificationsList,
    isLoadingNotificationsList,
    users,
    isLoadingUsers,
    getClientStoresList,
    isLoadingClientStores,
    getChainsList,
    isLoadingChains,
    getUserClientList,
    getNotification,
    isLoadingNotification,
} from 'core/selectors';

export default connect(state => ({
    notificationsList: getNotificationsList(state),
    isLoadingNotifications: isLoadingNotificationsList(state),
    users: users(state),
    isLoadingUsers: isLoadingUsers(state),
    chainsList: getChainsList(state),
    isLoadingChains: isLoadingChains(state),
    storeList: getClientStoresList(state),
    isLoadingStores: isLoadingClientStores(state),
    clientList: getUserClientList(state),
    notification: getNotification(state),
    isLoadingNotification: isLoadingNotification(state),
}), {
    getNotifications,
    getClientNotification,
    getUsers,
    getChains,
    getClientStores,
    postClientNotification,
    updateClientNotification,
    deleteClientNotification,
})(Notifications);