import { connect } from 'react-redux';

import { getUserInfo, getUserPBILegacyDashboards } from 'core/slices/users';

import { 
    isLoadingUserInfo,
    getSectionPaths,
    getSelectedClient,
    getUserLegacyDashboards
} from 'core/selectors';

import Layout from '../../../components/features/navbar/Layout';

export default connect(
    state => ({
        isLoadingUserInfo: isLoadingUserInfo(state),
        sectionPaths: getSectionPaths(state),
        selectedClient: getSelectedClient(state),
        pbiReportsList: getUserLegacyDashboards(state),
    }),
    {
        getUserInfo,
        getSelectedClient,
        getUserPBILegacyDashboards,
    },
)(Layout);
