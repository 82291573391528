import { useState, useEffect } from 'react';

import {
	Grid,
	Typography,
	Paper,
} from '@mui/material';

import { useTheme, styled } from '@mui/material/styles';

import { useTranslation } from 'react-i18next';

// My Components
import NotificationList from './NotificationList';
import NotificationForm from './NotificationForm';

export default function Notifications(props) {
	const {
		notificationsList,
		isLoadingNotifications,
		users,
		isLoadingUsers,
		chainsList,
		isLoadingChains,
		storeList,
		isLoadingStores,
		clientList,
		notification,
		isLoadingNotification,

		getClientStores,
		getNotifications,
		getClientNotification,
		getUsers,
		getChains,
		postClientNotification,
		updateClientNotification,
		deleteClientNotification,

	} = props;

	const { t } = useTranslation();
	const theme = useTheme();
	const textColor = theme.palette.type === 'light' ? 'black' : 'white';

	const [parsedStores, setParsedStores] = useState(null);

	// form data
	const [clientSelector, setClientSelector] = useState([]);
	const [chainSelector, setChainelector] = useState([]);
	const [storeSelector, setStoreSelector] = useState([]);
	const [usersSelector, setUsersSelector] = useState([]);
	const [title, setTitle] = useState('');
	const [body, setBody] = useState('');
	const [activeNotification, setActiveNotification] = useState(null);

	// initial load
	useEffect(() => {
		getNotifications();
		getClientStores(-1);
		getUsers(-1, true);
		getChains();
	}, []);

	// form mode
	const [mode, setMode] = useState('create');
	const handleSetMode = (mode, element) => {
		setMode(mode);
		setActiveNotification(element);
	}

	useEffect(() => {
		if (activeNotification) {
			setTitle(activeNotification.title);
			setBody(activeNotification.message_txt);
			getClientNotification(activeNotification.notification_id);
		}
	}, [activeNotification]);

	// filter elements from [items] that match [data] on selector
	const filterData = (data, items, selector) => {
		let newData = [];
		[...items[selector]].forEach(item => {
			if (item) {
				newData = [...data]
					.filter(element => element[selector] === item)
					.map(element => ({ ...element, selected: true }));
			}
		});
		return newData;
	}

	// set active notification selectors
	useEffect(() => {
		if (!isLoadingNotification && notification && Object.keys(notification).length > 0) {
			let newClientSelector = filterData(clientList, notification[0], 'client_id');
			let newChainSelector = filterData(chainsList, notification[0], 'chain_country_id');
			let newStoreSelector = filterData(storeList, notification[0], 'store_id');
			let newUsersSelector = filterData(users, notification[0], 'user_id');

			setClientSelector(newClientSelector);
			setChainelector(newChainSelector);
			setStoreSelector(newStoreSelector);
			setUsersSelector(newUsersSelector);
		}
	}, [isLoadingNotification, notification]);

	// parse store names
	useEffect(() => {
		if (storeList?.length > 0) {
			let newStores = [];
			storeList.forEach(store => {
				newStores.push({
					...store,
					"parsedName": `${store.store_code} - ${store.chain_name} ${store.store_name}`,
				})
			});
			setParsedStores(newStores);
		}
	}, [storeList]);

	const handleCreateNotification = async (
		title, body, clientSelector, chainSelector, storeSelector, usersSelector
	) => {
		await postClientNotification(title, body, clientSelector, chainSelector, storeSelector, usersSelector);
		getNotifications();
	}

	const handleUpdateNotification = async (
		notification_id, title, body, clientSelector, chainSelector, storeSelector, usersSelector
	) => {
		await updateClientNotification(notification_id, title, body, clientSelector, chainSelector, storeSelector, usersSelector);
		getNotifications();
	}

	const handleDeleteNotification = async (contact_id) => {
		await deleteClientNotification(contact_id);
		getNotifications();
	}

	const handleChangeNotification = (mode) => {
		if (mode === 'delete') {
			if (activeNotification && activeNotification?.notification_id) {
				handleDeleteNotification(activeNotification?.notification_id);
			}
		} else if (mode === 'create' && title?.length > 0 && body?.length > 0) {
			handleCreateNotification(
				title, body, clientSelector, chainSelector, storeSelector, usersSelector);
		} else if (mode === 'edit' && activeNotification?.notification_id) {
			handleUpdateNotification(
				activeNotification.notification_id, title, body, clientSelector, chainSelector, storeSelector, usersSelector);
		}
		setMode('create');
		setTitle('');
		setBody('');
		setActiveNotification(null);
		setClientSelector([]);
		setChainelector([]);
		setStoreSelector([]);
		setUsersSelector([]);
	}

	useEffect(() => {
		if (mode === 'create') {
			setTitle('');
			setBody('');
			setActiveNotification(null);
			setClientSelector([]);
			setChainelector([]);
			setStoreSelector([]);
			setUsersSelector([]);
		}
	}, [mode]);

	return (
		<Grid container direction={'row'} pt={4} px={5} spacing={2}>
			<Grid item xs={12}>
				<Typography sx={{ fontSize: '34px', color: textColor }}>
					{t('cws_app.general.notifications_admin', 'Notifications')}
				</Typography>
			</Grid>
			<Grid item container xs={12}
				direction={{ sm: 'column', md: 'row' }}
				spacing={2}
				alignItems={'flex-start'}
				justifyContent={'space-between'}
			>
				<Grid item xs>
					<NotificationList
						notificationsList={notificationsList}
						isLoadingNotifications={isLoadingNotifications}
						handleSetMode={handleSetMode}
						activeNotification={activeNotification}
						setActiveNotification={setActiveNotification}
					/>
				</Grid>
				<Grid item xs={7}>
					<NotificationForm
						users={users}
						isLoadingUsers={isLoadingUsers}
						chainsList={chainsList}
						isLoadingChains={isLoadingChains}
						storeList={parsedStores}
						isLoadingStores={isLoadingStores}
						clientList={clientList}
						clientSelector={clientSelector}
						setClientSelector={setClientSelector}
						chainSelector={chainSelector}
						setChainelector={setChainelector}
						storeSelector={storeSelector}
						setStoreSelector={setStoreSelector}
						usersSelector={usersSelector}
						setUsersSelector={setUsersSelector}
						title={title}
						setTitle={setTitle}
						body={body}
						setBody={setBody}
						mode={mode}
						setMode={setMode}
						activeNotification={activeNotification}
						isLoadingNotification={isLoadingNotification}
						notification={notification}
						handleChangeNotification={handleChangeNotification}
					/>
				</Grid>
			</Grid>
		</Grid>
	)
}