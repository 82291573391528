import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import { reducer as backendReducer } from './slices/backend';
import { reducer as brunoReducer } from './slices/bruno';
import { reducer as clientsReducer } from './slices/clients';
import { reducer as dashboardsReducer } from './slices/dashboards';
import { reducer as digitalStoreReducer } from './slices/digitalStore';
import { reducer as documentationReducer } from './slices/documentation';
import { reducer as generalReduer } from './slices/general';
import { reducer as linkReducer } from './slices/link';
import { reducer as productsReducer } from './slices/products';
import { reducer as sessionReducer } from './slices/session';
import { reducer as storesReducer } from './slices/store';
import { reducer as suppliersReducer } from './slices/suppliers';
import { reducer as tasksReducer } from './slices/tasks';
import { reducer as trackingListReducer } from './slices/trackingList';
import { reducer as userReducer } from './slices/users';
import { reducer as wallsReducer } from './slices/wall';
import { reducer as reschedulingReducer } from './slices/rescheduling';
import { reducer as mailerReducer } from './slices/mailer';
import { reducer as notificationsReducer } from './slices/notifications';
import { reducer as translationsReducer } from './slices/translations';
import { reducer as producsStatesReducer } from './slices/productsStates';

// Zippedi tools reducer
import { snackAlertReducer } from '@zippeditoolsjs/blocks';

export default function _createStore(services) {
  return createStore(
    combineReducers({
      snackAlert: snackAlertReducer,
      backend: backendReducer,
      bruno: brunoReducer,
      clients: clientsReducer,
      dashboards: dashboardsReducer,
      digitalStore: digitalStoreReducer,
      documentation: documentationReducer,
      general: generalReduer,
      link: linkReducer,
      products: productsReducer,
      session: sessionReducer,
      store: storesReducer,
      suppliers: suppliersReducer,
      tasks: tasksReducer,
      trackingList: trackingListReducer,
      user: userReducer,
      wall: wallsReducer,
      rescheduling: reschedulingReducer,
      mailer: mailerReducer,
      notifications: notificationsReducer,
      translations: translationsReducer,
      productsStates: producsStatesReducer,
    }),
    applyMiddleware(thunk.withExtraArgument({ services })),
  );
}
