import { createReducer } from './utils';

import {
  DOCUMENTATION_SUCCESS,
  DOCUMENTATION_FAILURE,
  DOCUMENTATION_LOADING,
  CREDENTIAL_SUCCESS,
  CREDENTIAL_FAILURE,
  CREDENTIAL_LOADING,
} from './constants';

const defaultState = {
  blobs: {},
  loading: false,
  errorMessage: null,
  credentialRequested: false,
  credentialLoading: false,
};

export const reducer = createReducer(defaultState, {
  [DOCUMENTATION_SUCCESS]: handleDocumentationSuccess,
  [DOCUMENTATION_FAILURE]: handleDocumentationFailure,
  [DOCUMENTATION_LOADING]: handleDocumentationLoading,
  [CREDENTIAL_SUCCESS]: handleCredentialSuccess,
  [CREDENTIAL_FAILURE]: handleCredentialFailure,
  [CREDENTIAL_LOADING]: handleCredentialLoading,
});

// Documentation
function handleDocumentationLoading(state, _) {
  return {
    ...state,
    loading: true,
  };
}

function handleDocumentationSuccess(state, { payload: { response } }) {
  return {
    ...state,
    loading: false,
    blobs: response,
  };
}

function handleDocumentationFailure(state, { payload: { error } }) {
  return {
    ...state,
    loading: false,
    blobs: {},
    errorMessage: error,
  };
}

// Credentials
function handleCredentialLoading(state, _) {
  return {
    ...state,
    credentialLoading: true,
  };
}

function handleCredentialSuccess(state, { payload: { response } }) {
  return {
    ...state,
    credentialLoading: false,
    credentialRequested: response['status'],
  };
}

function handleCredentialFailure(state, { payload: { error } }) {
  return {
    ...state,
    credentialLoading: false,
    credentialRequested: false,
    errorMessage: error,
  };
}

export function documentationImages() {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: DOCUMENTATION_LOADING });

    const {
      user: { selectedClient },
    } = getState();

    try {
      const response = await dataSource.getDocumentationImages(
        selectedClient.name,
        selectedClient.client_id,
      );
      dispatch({ type: DOCUMENTATION_SUCCESS, payload: { response } });
    } catch (error) {
      console.log('documentationImages error: ', error);
      dispatch({ type: DOCUMENTATION_FAILURE, payload: { error } });
    }
  };
}

export function requestDocumentationCredentials(description) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: CREDENTIAL_LOADING });

    const {
      session: { userInfo },
      user: { selectedClient },
    } = getState();

    try {
      const response = await dataSource.postDocumentationCredentials(
        userInfo['email'],
        description,
        selectedClient.client_id,
      );

      dispatch({ type: CREDENTIAL_SUCCESS, payload: { response } });
    } catch (error) {
      console.log('requestDocumentationCredentials error: ', error);
      dispatch({ type: CREDENTIAL_FAILURE, payload: { error } });
    }
  };
}
