import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import Selector from '../../tools/Selector';
import RolesTable from './RolesTable';
import FeaturesTransferList from './FeaturesTransferList';
import { isEmptyOrUndefined } from "core/utils/validators";
import CircularLoader from '../../tools/CircularLoader';

const steps = (t) => {
  const steps = [t('cws_app.general.user', 'User'), t('cws_app.general.profile', 'Profile'), t('cws_app.bruno.Features', 'Features')];
  return steps;
}

export default function UserStepper(props) {
  const {
    theme,
    selectedClient,
    user,
    getUserProfile,
    userProfile,
    isLoadingUserProfile,
    suppliers,
    isLoadingSuppliers,
    userRoles,
    isLoadingUserRoles,
    chains,
    isLoadingChains,
    getStoreCategories,
    categories,
    isLoadingCategories,
    brunoFeatures,
    isLoadingBrunoFeatures,
    getUserFeatures,
    userFeatures,
    isLoadingUserFeatures,
    userStores,
    isLoadingUserStores,
    updateBrunoUser,
    updateUserResponse,
    isLoadingUpdateUser,
    postBrunoUser,
    postUserResponse,
    isLoadingPostUser,
  } = props;

  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [canContinue, setCanContinue] = useState(false);
  // Step 1 states: user info
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  // Step 2 states: table roles
  const [profile, setProfile] = useState([]);
  const [newRole, setNewRole] = useState('');
  const [newChain, setNewChain] = useState('');
  const [profileIDs, setProfileIDs] = useState([]);
  // Step 3 states: features
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [feature, setFeature] = useState(brunoFeatures ? brunoFeatures[0] : []);
  const [featuresToSave, setFeaturesToSave] = useState([]);

  // Lifecycle methods

  useEffect(() => {
    // Conditions to pass to the next step
    if (activeStep === 0 && username && firstName && lastName) {
      setCanContinue(true);
    } else if (activeStep === 1 && profile.length > 0) {
      setCanContinue(true);
    } else if (activeStep === 2) {
      setCanContinue(true);
    } else {
      setCanContinue(false);
    }
  }, [activeStep, username, firstName, lastName, newRole, newChain, profile])

  // Get user profile and features if it's an existing user
  useEffect(() => {
    if (!isEmptyOrUndefined(user, 'dict')) {
      getUserProfile(user.id);
      getUserFeatures(user.id);
    }
  }, [user]);

  // Set user info if it's an existing user
  useEffect(() => {
    if (!isEmptyOrUndefined(user, 'dict')) {
      setUsername(user.username);
      setFirstName(user.name);
      setLastName(user.lastName);
    }
  }, [user]);

  // Set user profile if it's an existing user
  useEffect(() => {
    if (!isEmptyOrUndefined(userProfile, 'array')) {
      setProfile(userProfile);
    }
  }, [userProfile]);

  // FeaturesTrasferList lifecycle methods

  // Save the original user features and rename the key 'store' to 'store_code'
  useEffect(() => {
    if (!isEmptyOrUndefined(userFeatures, 'array')) {
      const parsedUserFeatures = userFeatures.map((userFeature) => {
        return {
          ...userFeature,
          store_code: userFeature.store,
          chain_name: userFeature.chain,
        };
      });
      setFeaturesToSave(parsedUserFeatures);
    }
  }, [userFeatures]);

  // Parse the data (userFeatures) so that it's a list of chains with their respective stores
  useEffect(() => {
    const filteredFeatures = featuresToSave?.filter((userFeature) => userFeature.feature_id === feature.feature_id);
    // List of all chains with their respective stores
    const parsedStores = chains?.map((chain) => {
      const stores = userStores?.filter((store) => store.chain_name === chain.name);
      return {
        chain_country_id: chain.chain_country_id,
        chain_name: chain.name,
        chain: chain.name,
        stores,
      };
    });

    if (!isEmptyOrUndefined(filteredFeatures, 'array')) {
      const currentFeaturesList = [];
      filteredFeatures.forEach((filteredFeature) => {
        const chain = chains?.find((chain) => chain.name === filteredFeature.chain_name);
        let store = userStores?.find((store) => store.store_code === filteredFeature.store_code);
        store = {
          ...store,
          chain: filteredFeature.chain_name,
          store: filteredFeature.store_code,
          access_id: filteredFeature.access_id,
          feature_id: filteredFeature.feature_id,
          user_id: filteredFeature.user_id,
        };

        // Remove the store from the parsedStores list
        const leftChainIndex = parsedStores.findIndex((chainAndStores) => chainAndStores.chain_name === chain.name);
        const leftStoreIndex = parsedStores[leftChainIndex].stores.findIndex((store) => store.store_code === filteredFeature.store_code);
        parsedStores[leftChainIndex].stores.splice(leftStoreIndex, 1);

        // If the chain is not in the currentFeaturesList, add it with the store, otherwise, add only the store to the chain
        const chainIndex = currentFeaturesList.findIndex((chainAndStores) => chainAndStores.chain_name === chain.name);
        if (chainIndex === -1) {
          currentFeaturesList.push({
            chain_country_id: chain.chain_country_id,
            chain: chain.name,
            chain_name: chain.name,
            store: filteredFeature.store_code,
            stores: [store],
          });
        } else {
          currentFeaturesList[chainIndex].stores.push(store);
        }
      });
      setRight(currentFeaturesList);
      setLeft(parsedStores);
    } else {
      setRight([]);
      setLeft(parsedStores);
    }
  }, [featuresToSave, feature?.feature_id, chains, userStores]);

  // Refresh the page when updating a user is successful
  useEffect(() => {
    if (updateUserResponse?.message === 'OK') {
      window.location.reload();
    }
  }, [updateUserResponse])

  // Refresh the page when posting a new user is successful
  useEffect(() => {
    if (postUserResponse?.message === 'User created successfully') {
      window.location.reload();
    }
  }, [postUserResponse])

  // Methods

  const handleNext = () => {
    // Send data
    if (activeStep === steps(t).length - 1) {
      // If it's an existing user, update, otherwise, create
      if (!isEmptyOrUndefined(user, 'dict')) {
        const patchBody = {
          user_id: user.id,
          new_username: username,
          first_name: firstName,
          last_name: lastName,
          profiles: JSON.stringify(profile),
          features: JSON.stringify(featuresToSave),
        };
        const deleteBody = {
          user_id: user.id,
          profile_ids: JSON.stringify(profileIDs)
        }
        updateBrunoUser(patchBody, deleteBody);
      } else {
        const postBody = {
          username,
          first_name: firstName,
          last_name: lastName,
          profiles: JSON.stringify(profile),
          features: JSON.stringify(featuresToSave),
        };
        postBrunoUser(postBody);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <Stepper activeStep={activeStep}>
        {steps(t).map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {/* Step 1 */}
      {activeStep === 0 &&
        <Grid container spacing={2} direction='column'>
          <Grid item>
            <Typography variant='h6' sx={{ mt: 2 }}>{t('cws_app.general.user', 'User')}</Typography>
          </Grid>
          {/* Username */}
          <Grid item>
            <TextField
              required
              fullWidth
              label={t('cws_app.general.username', 'Username')}
              id="username"
              variant="filled"
              disabled={!isEmptyOrUndefined(user, 'dict')}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Grid>
          {/* First name */}
          <Grid item>
            <TextField
              required
              fullWidth
              label={t('cws_app.general.name', 'Name')}
              id="firstName"
              variant="filled"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Grid>
          {/* Last name */}
          <Grid item>
            <TextField
              required
              fullWidth
              label={t('cws_app.general.last_name', 'Last Name')}
              id="lastName"
              variant="filled"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Grid>
        </Grid>
      }
      {/* Step 2 */}
      {activeStep === 1 &&
        <Grid container direction='column'>
          <Grid item>
            <Typography variant='h6' sx={{ mt: 2 }}>{t('cws_app.general.profile', 'Profile')}</Typography>
          </Grid>
          {/* User roles table */}
          {isLoadingUserProfile ?
            <CircularLoader />
            :
            <RolesTable
              theme={theme}
              selectedClient={selectedClient}
              profile={profile}
              setProfile={setProfile}
              newRole={newRole}
              setNewRole={setNewRole}
              newChain={newChain}
              setNewChain={setNewChain}
              suppliers={suppliers}
              isLoadingSuppliers={isLoadingSuppliers}
              userRoles={userRoles}
              isLoadingUserRoles={isLoadingUserRoles}
              chains={chains}
              isLoadingChains={isLoadingChains}
              getStoreCategories={getStoreCategories}
              categories={categories}
              isLoadingCategories={isLoadingCategories}
              userStores={userStores}
              isLoadingUserStores={isLoadingUserStores}
              profileIDs={profileIDs}
              setProfileIDs={setProfileIDs}
            />
          }
        </Grid>
      }
      {/* Step 3 */}
      {activeStep === 2 &&
        <Grid container direction='column' spacing={2}>
          <Grid item>
            <Typography variant='h6' sx={{ mt: 2 }}>{t('cws_app.bruno.Features', 'Features')}</Typography>
          </Grid>
          {/* Feature selector */}
          <Grid item width='50%'>
            <Selector
              disableClearable
              options={brunoFeatures}
              isLoading={isLoadingBrunoFeatures}
              optionLabel='feature'
              labelInput={'cws_app.bruno.Features'}
              selectedElement={feature}
              setSelectedElement={setFeature}
            />
          </Grid>
          {/* Features transfer list */}
          {isLoadingUserFeatures ?
            <CircularLoader />
            :
            <FeaturesTransferList
              theme={theme}
              feature={feature}
              left={left}
              setLeft={setLeft}
              right={right}
              setRight={setRight}
              featuresToSave={featuresToSave}
              setFeaturesToSave={setFeaturesToSave}
            />
          }
        </Grid>
      }
      {/* Back, Next and Send buttons */}
      <Grid container item justifyContent='space-between' mt={2}>
        <Button variant='contained' onClick={handleBack} disabled={activeStep === 0}>
          {t('cws_app.general.back', 'Back')}
        </Button>
        <LoadingButton
          onClick={handleNext}
          disabled={!canContinue}
          loading={isEmptyOrUndefined(user, 'dict') ? isLoadingPostUser : isLoadingUpdateUser}
        >
          {activeStep === steps(t).length - 1 ? t('cws_app.general.send', 'Send') : t('cws_app.general.next', 'Next')}
        </LoadingButton>
      </Grid>
    </>
  );
}
