import { Box, Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import StoreMap from '../../tools/StoreMap';

const RADIUSPLUS = 4,
    INTERVALAISLE = 3;
export default function ResMap(props) {
  const {
    storeMap,
    storeCategories,
    visualizationMode,
    taskType,
  } = props;
  const { t } = useTranslation();

  const [isHoverOn, setIsHoverOn] = useState(false);
  const [categoriesOnMap, setCategoriesOnMap] = useState({});
  const [selectedAlertIndex, setSelectedAlertIndex] = useState(null);
  const [selectedElement, setSelectedElement] = useState({});

  return (
    <div>
    { storeMap ?
      <StoreMap
        storeMap={storeMap}
        visualizationMode={'rs_basic'}
      />
      :
      null
    }
    </div>
  )
}