import React, { useState, useEffect } from 'react';
import { Box, CssBaseline } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

// Redux wrapper
import Navbar from '../../../redux/containers/navbar/Navbar';

// My components
import SideMenu from './SideMenu';
import { isEmptyOrUndefined } from 'core/utils/validators';

// Styled components
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

function Layout(props) {
  const {
    children,
    isDarkTheme,
    toggleTheme,
    isLoadingUserInfo,
    sectionPaths,
    toogleTranslation,
    getUserInfo,
    pbiReportsList,
    selectedClient,
    getUserPBILegacyDashboards,
  } = props;
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    if (!isEmptyOrUndefined(selectedClient, 'dict')) {
      getUserPBILegacyDashboards(selectedClient.client_id);
    }
  }, [selectedClient]);

  const handleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* Top navigation bar */}
      <Navbar
        open={open}
        handleDrawer={handleDrawer}
        toggleTheme={toggleTheme}
        isDarkTheme={isDarkTheme}
        isLoadingUserInfo={isLoadingUserInfo}
        toogleTranslation={toogleTranslation}
      />

      {/* Left navigation bar */}
      <SideMenu
        open={open}
        handleDrawer={handleDrawer}
        DrawerHeader={DrawerHeader}
        isDarkTheme={isDarkTheme}
        sections={sectionPaths}
        navigate={navigate}
        pbiReportsList={pbiReportsList}
      />

      {isLoadingUserInfo ? (
        <></>
      ) : (
        <Box component="main" sx={{ flexGrow: 1, overflow: 'auto' }}>
          <DrawerHeader />
          {children}
        </Box>
      )}
    </Box>
  );
}

export default Layout;
