import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';

import { isEmptyOrUndefined } from "@zippeditoolsjs/blocks";
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

import Selector from '../../tools/Selector';

export default function TopComponents(props) {
  const {
    theme,
    selectedClient,
    getChains,
    chains,
    isLoadingChains,
    selectedChain,
    setSelectedChain,
    selectedSupplier,
    setSelectedSupplier,
    uploadTrackingList,
    isLoadingUpload,
    uploadResponse,
    deleteTrackingList,
    isLoadingDelete,
    deleteResponse,
    trackingListID,
    storesLength,
    loadingTrackingListData,
    loadingTrackingListDataItems,
    getUserSuppliers,
    suppliers,
    isLoadingSuppliers,
  } = props;
  const { t } = useTranslation();
  const delete_text_options = ['DELETE', 'ELIMINAR', 'LÖSCHEN']
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteText, setDeleteText] = useState('');
  const [openUpload, setOpenUpload] = useState(false);
  const [file, setFile] = useState(null);
  const [filteredChain, setFilteredChain] = useState([]);

  // Lifecycle methods

  useEffect(() => {
    getChains();
  }, [])

  useEffect(() => {
    // Filter chains by selected client
    if (selectedClient?.client_id !== -1) {
      const filterChain = chains.filter(chain => chain?.name === selectedClient?.name);
      setFilteredChain(filterChain[0]);
    }
  }, [selectedClient, chains])

  // Get suppliers info when a chain is selected and reset the selected supplier
  useEffect(() => {
    if (!isEmptyOrUndefined(selectedChain, 'object')) {
      getUserSuppliers(selectedClient.client_id, [selectedChain?.name]);
    }
    setSelectedSupplier(null);
  }, [selectedChain])

  // Refresh page when upload is successful
  useEffect(() => {
    if (uploadResponse?.status === 200) {
      window.location.reload();
    }
  }, [uploadResponse])

  // Refresh page when delete is successful
  useEffect(() => {
    if (deleteResponse?.status === 200) {
      window.location.reload();
    }
  }, [deleteResponse])

  // Methods

  // Upload tracking list methods
  const onUpload = () => {
    const form = new FormData();
    form.append('tracking_list_id', trackingListID);
    form.append('file', file);
    uploadTrackingList(form);
  }

  const handleClickOpenUpload = () => {
    setOpenUpload(true);
  };

  const handleCloseUpload = () => {
    setOpenUpload(false);
    setFile(null);
  };

  // Delete tracking list methods
  const onDelete = () => {
    deleteTrackingList(trackingListID);
  }

  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setDeleteText('');
  };

  return (
    <Grid container columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}>
      <Paper component={Grid} container item columns={{ xs: 4, sm: 8, md: 12 }} alignItems='center' p={2} sx={{ backgroundColor: theme.palette.type === 'dark' ? '#2e2728' : '' }}>
        <Grid container item spacing={1} xs={12} sm={12} md={7}>
          {/* Chain selector */}
          <Grid item xs={12} sm={12} md={6}>
            <Selector
              labelInput="cws_app.general.sel_chain"
              options={selectedClient?.client_id !== -1 ? [filteredChain] : chains} // If a client is selected, only show the client as an option
              optionLabel={'name'}
              isLoading={isLoadingChains}
              selectedElement={selectedChain}
              setSelectedElement={setSelectedChain}
              disabled={loadingTrackingListData || loadingTrackingListDataItems}
            />
          </Grid>
          {/* Supplier selector */}
          <Grid item xs={12} sm={12} md={6}>
            <Selector
              labelInput="cws_app.management.select_supplier"
              options={suppliers}
              optionLabel={'name'}
              isLoading={isLoadingSuppliers}
              selectedElement={selectedSupplier}
              setSelectedElement={setSelectedSupplier}
              disabled={isEmptyOrUndefined(selectedChain, 'object') || loadingTrackingListData || loadingTrackingListDataItems}
              id={['id']}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={12} md={5} justifyContent={{ xs: 'space-between', md: 'flex-end' }} sx={{ mt: { xs: 1, md: 0 } }}>
          {/* Upload button and dialog */}
          <Button
            variant="outlined"
            sx={{ mb: { xs: 1, sm: 0 }, mr: 1, height: '4em' }}
            disabled={isEmptyOrUndefined(selectedSupplier, 'object') || !trackingListID}
            onClick={handleClickOpenUpload}
          >
            {t('cws_app.general.upload_file', 'Upload file')}
          </Button>
          <Dialog
            open={openUpload}
            onClose={handleCloseUpload}
            aria-labelledby="upload-dialog-title"
            aria-describedby="upload-dialog-description"
          >
            <DialogTitle id="upload-dialog-title">
              {t('cws_app.tracking.upload_confirmation', 'Upload this tracking list?')}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="upload-dialog-description">
                {t('cws_app.tracking.upload_confirmation_text', 'Please select the .xlsx (excel) file you want to upload.')}
              </DialogContentText>
              {file ?
                <Box textAlign='left' mt={2} >
                  <DialogContentText id="upload-dialog-description">
                    {t('cws_app.general.selected_file', 'Selected file')}: <Typography sx={{ fontWeight: 'bold' }}> {file?.name} </Typography>
                  </DialogContentText>
                </Box>
                :
                <Box textAlign='center' mt={2} >
                  <Button variant="contained" component="label">
                    {t('cws_app.general.select_file', 'Select file')}
                    <input hidden accept=".xlsx" type="file" onChange={(e) => setFile(e?.target?.files[0])} />
                  </Button>
                </Box>
              }
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseUpload}>{t('cws_app.general.cancel', 'Cancel')}</Button>
              <LoadingButton
                loading={isLoadingUpload}
                loadingPosition="start"
                startIcon={
                  <IconComponent
                    iconName={'cloud-upload'}
                    style={{ fontSize: '18px' }}
                  />
                }
                onClick={onUpload}
                disabled={!file}
              >
                {t('cws_app.general.upload', 'Upload')}
              </LoadingButton>
            </DialogActions>
          </Dialog>
          {/* Delete button and dialog */}
          <Button
            variant="outlined"
            color="error"
            sx={{ height: '4em' }}
            disabled={isEmptyOrUndefined(selectedSupplier, 'object') || !trackingListID}
            onClick={handleClickOpenDelete}
          >
            {t('cws_app.general.delete', 'Delete')}
          </Button>
          <Dialog
            open={openDelete}
            onClose={handleCloseDelete}
            aria-labelledby="delete-dialog-title"
            aria-describedby="delete-dialog-description"
          >
            <DialogTitle id="delete-dialog-title">
              {t('cws_app.tracking.delete_confirmation', 'Are you sure you want to delete this tracking list?')}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="delete-dialog-description">
                {t('cws_app.tracking.delete_confirmation_text', 'To PERMANENTLY delete this tracking list, please type DELETE in the box below.')}
              </DialogContentText>
              <TextField
                required
                hiddenLabel
                id="delete-confirmation-text"
                variant="filled"
                sx={{ mt: 1 }}
                value={deleteText}
                onChange={(e) => setDeleteText(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDelete}>{t('cws_app.general.cancel', 'Cancel')}</Button>
              <LoadingButton
                loading={isLoadingDelete}
                loadingPosition="start"
                startIcon={
                  <IconComponent
                    iconName={'trash'}
                    style={{ fontSize: '18px' }}
                  />
                }
                onClick={onDelete}
                disabled={delete_text_options.indexOf(deleteText) === -1}
              >
                {t('cws_app.general.delete', 'Delete')}
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </Grid>
      </Paper>
      {/* Information cards */}
      {/* // TODO: add metrics */}
      {false && !isEmptyOrUndefined(selectedSupplier, 'object') && storesLength && chains?.length > 0 &&
        <Grid item xs={4} sm={8} md={12} lg={5}>
          <Grid container height='100%' justifyContent='space-between'>
            <Paper component={Grid} container item xs={12} sm={2.8} direction='column' mb={{ xs: 1, sm: 0 }} p={1} justifyContent='center' alignItems='center'>
              <Typography variant='h5'>5/{chains.length}</Typography>
              <Typography>{t('cws_app.general.chains', 'Chains')}</Typography>
            </Paper>
            <Paper component={Grid} container item xs={12} sm={2.8} direction='column' mb={{ xs: 1, sm: 0 }} p={1} justifyContent='center' alignItems='center'>
              <Typography variant='h5'>5/{storesLength}</Typography>
              <Typography>{t('cws_app.general.stores', 'Stores')}</Typography>
            </Paper>
            <Paper component={Grid} container item xs={12} sm={2.8} direction='column' mb={{ xs: 1, sm: 0 }} p={1} justifyContent='center' alignItems='center'>
              <Typography variant='h5'>5/425</Typography>
              <Typography>{t('cws_app.general.products', 'Products')}</Typography>
            </Paper>
            <Paper component={Grid} container item xs={12} sm={2.8} direction='column' p={1} justifyContent='center' alignItems='center'>
              <Typography variant='h5'>5/425</Typography>
              <Typography>{t('cws_app.tracking.Activated', 'Activated')}</Typography>
            </Paper>
          </Grid>
        </Grid>
      }
    </Grid>
  )
}
