import { IconButton, useTheme } from '@mui/material'
import { IconComponent } from '@zippeditoolsjs/zippedi-icons'
import React from 'react'
import { colorBasedOnTheme } from '../utils/colors';

export default function CloseButton(props) {
  const { setIsOpen, disabled, sx={}, isColorbgAlwaysDark=false } = props;
  const theme = useTheme();
  let color = 'color';
  let bgcolorHover = 'bgcolor-hover'

  const handleClick = () => {
    setIsOpen(false);
  };

  if (isColorbgAlwaysDark) {
    color = 'color-darkTheme';
    bgcolorHover = 'bgcolor-hover-darkTheme';
  }

  const styles = Object.assign({}, sx, { color: colorBasedOnTheme(theme, color), bgcolor: 'transparent', '&:hover': {'bgcolor': colorBasedOnTheme(theme, bgcolorHover)} });

  return (
    <IconButton
      onClick={handleClick}
      disabled={disabled}
      sx={styles}
    >
      <IconComponent
        iconName={'close-outline'}
        style={{ fontSize: '20px' }}
      />
    </IconButton>
  )
}
