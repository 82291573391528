import { connect } from 'react-redux';
import ShelfSearch from '../../../../components/features/digitalShelf/ShelfSearch';

import {
    getSearchResults,
} from 'core/slices/wall';

import {
    getProductSearchResults,
    isLoadingProductSearchResults
} from 'core/selectors';

export default connect(state => ({
    searchResults: getProductSearchResults(state),
    isLoadingSearchResults: isLoadingProductSearchResults(state)
}), {
    getSearchResults,
})(ShelfSearch);
