import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Grid, Typography, LinearProgress, Paper, Tooltip, IconButton } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

import { BarChart, HeatMap, LineChart } from "@zippeditoolsjs/dashboards";
import { DataGrid } from "@zippeditoolsjs/table";
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

import RowGroupedTable from "./RowGroupedTable";


export default function Visualization(props) {
  const {
    md,
    visualization,
    isLoadingExcelDownload,
    checkDownloadDisabled,
    setSelectedVisualization,
    setModalOpen,
    isLoadingDashboard,
    handleScroll,
    filteredStores,
    scrollPosition
  } = props
  const [isLineChart, setIsLineChart] = useState(visualization.visualization_type === 'line_chart' ? true : false)
  const { t } = useTranslation();

  const chart = (visualization) => {
    if (visualization.visualization_type === 'line_chart' || visualization.visualization_type === 'bar_chart') {
      if (isLineChart) {
        // Change the keys with the translated values
        const data = visualization?.chart_params?.[0]?.line_data.map(row => {
          const parsedRow = {};
          for (const [key, value] of Object.entries(row)) {
            let parsedKey = '';
            if (t(`cws_app.dashboards.${key}_dict.line`, `false`) !== `false`) {
              parsedKey = t(`cws_app.dashboards.${key}_dict.line`, `cws_app.dashboards.${key}`);
            } else {
              parsedKey = t(`cws_app.dashboards.${key}`, key);
            }
            parsedRow[parsedKey] = value;
          }
          return parsedRow;
        })
        const index = t(`cws_app.dashboards.${visualization?.chart_params?.[0]?.line_chart_target_x}`, visualization?.chart_params?.[0]?.line_chart_target_x);
        let categories = []
        categories = visualization?.chart_params?.[0]?.y_axis_list.map(category => {
          if (t(`cws_app.dashboards.${category}_dict.line`, `false`) !== `false`) {
            return t(`cws_app.dashboards.${category}_dict.line`, category)
          } else {
            return t(`cws_app.dashboards.${category}`, category)
          }
        });
        const colors = visualization?.chart_params?.[0]?.colors;
        return (
          <LineChart
            showGridLines
            connectNulls
            data={data}
            index={index}
            categories={categories}
            metricType={visualization?.metric_type}
            onValueChange={() => { }}
            colors={colors}
          />
        )
      } else {
        // Change the keys with the translated values
        const data = visualization?.chart_params?.[0]?.bar_data.map(row => {
          const parsedRow = {};
          for (const [key, value] of Object.entries(row)) {
            let parsedKey = '';
            if (t(`cws_app.dashboards.${key}_dict.bar`, `false`) !== `false`) {
              parsedKey = t(`cws_app.dashboards.${key}_dict.bar`, `cws_app.dashboards.${key}`);
            } else {
              parsedKey = t(`cws_app.dashboards.${key}`, key);
            }
            parsedRow[parsedKey] = value;
          }
          return parsedRow;
        })

        const bar_chart_target_x = visualization?.chart_params?.[0]?.bar_chart_target_x || 'store';
        const index = t(`cws_app.dashboards.${bar_chart_target_x}`, bar_chart_target_x);
        let categories = []
        categories = visualization?.chart_params?.[0]?.y_axis_list.map(category => {
          if (t(`cws_app.dashboards.${category}_dict.bar`, `false`) !== `false`) {
            return t(`cws_app.dashboards.${category}_dict.bar`, category)
          } else {
            return t(`cws_app.dashboards.${category}`, category)
          }
        });
        const colors = visualization?.chart_params?.[0]?.colors;
        return (
          <BarChart
            className="mt-6"
            index={index}
            categories={categories}
            data={data}
            colors={colors}
            layout={'horizontal'}
            stack={visualization.visualization_params?.bar_type === 'stacked'}
          />
        )
      }
    }

    // Heatmap
    if (visualization.visualization_type === 'heatmap') {
      // If there are more than 35 stores selected, make the width proportional to the number of stores and scrollable
      const chartWidth = visualization.number_of_stores > 35 ? { width: visualization.number_of_stores * 20 } : {};
      return (
        <HeatMap
          data={visualization?.chart_params?.[0]?.data}
          axisRight={null}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: t(`cws_app.dashboards.${visualization.visualization_params?.y_axis_name}`, visualization.visualization_params?.y_axis_name) ?? '',
            legendPosition: 'middle',
            legendOffset: -80
          }}
          axisTop={{
            tickRotation: 40,
          }}
          colors={{
            type: 'sequential',
            scheme: 'red_purple',
            divergeAt: 0.5,
            minValue: visualization.visualization_params?.min_value ?? 0,
            maxValue: visualization.visualization_params?.max_value ?? 100
          }}
          legends={[
            {
              anchor: 'bottom-left',
              translateX: scrollPosition,
              translateY: 30,
              length: 400,
              thickness: 8,
              direction: 'row',
              tickPosition: 'after',
              tickSize: 3,
              tickSpacing: 4,
              tickOverlap: false,
              tickFormat: '>-.2s',
              title: t(`cws_app.dashboards.${visualization.visualization_params?.legend_title}`, visualization.visualization_params?.legend_title) ?? '',
              titleAlign: 'start',
              titleOffset: 4
            }
          ]}
          {...chartWidth}
        />
      )
    }

    // Column grouped table
    if (visualization.visualization_type === 'column_grouped_table') {
      // Translate the second row of the header
      const columns = visualization?.chart_params?.[0]?.columns?.map(column => {
        const parsedColumnName = column.split('.') ? column.split('.')[0] : column;
        return {
          field: column,
          headerName: t(`cws_app.dashboards.${parsedColumnName}`, parsedColumnName) ?? '',
          width: 150,
        }
      })
      return (
        <DataGrid
          rows={visualization?.chart_params?.[0]?.rows}
          columns={columns}
          experimentalFeatures={{ columnGrouping: true }}
          columnGroupingModel={visualization?.chart_params?.[0]?.columnGroupingModel}
          sx={{ border: 'none' }}
          slotProps={{
            toolbar: {
              showQuickFilter: false,
            },
          }}
        />
      )
    }

    // Row grouped table for OSA dashboard
    if (visualization.visualization_type === 'row_grouped_table') {
      return (
        <RowGroupedTable data={visualization?.chart_params?.[0]} stores={filteredStores} />
      )
    }
  }

  return (
    <Paper component={Grid} container xs={12} md={md} alignItems='center' mb={1} p={2}>
      <Grid xs>
        {/* Visualization title */}
        <Typography variant="h6">{t(`cws_app.dashboards.${visualization.visualization_name}`, visualization.visualization_name ? visualization.visualization_name : '')}</Typography>
      </Grid>
      {/* Change View button */}
      {visualization.visualization_type === 'line_chart' || visualization.visualization_type === 'bar_chart' ?
        <Grid>
          <Tooltip title={isLineChart ? t('cws_app.home.change_bar_chart', 'Change to Bar Chart') : t('cws_app.home.change_line_chart', 'Change to Line Chart')} placement="bottom">
            <IconButton onClick={() => setIsLineChart(!isLineChart)} sx={{ p: 0 }}>
              {isLineChart ?
                <IconComponent
                  iconName={'bar-chart'}
                /> :
                <IconComponent
                  iconName={'analytics-outline'}
                />
              }
            </IconButton>
          </Tooltip>
        </Grid> : null
      }

      {/* Download button */}
      <Grid>
        <LoadingButton loading={isLoadingExcelDownload} disabled={checkDownloadDisabled(visualization)} onClick={() => {
          setSelectedVisualization(visualization);
          setModalOpen(true)
        }}>
          <IconComponent
            iconName={'download-outline'}
            style={{ fontSize: '25px' }}
          />
        </LoadingButton>
      </Grid>
      {/* Visualization description */}
      {visualization?.visualization_description &&
        <Tooltip title={visualization?.visualization_description ? t(`cws_app.dashboards.${visualization.visualization_description}`, visualization.visualization_description) : ''}>
          <IconButton>
            <IconComponent
              iconName={'information-circle'}
              style={{ fontSize: '25px' }}
            />
          </IconButton>
        </Tooltip>
      }
      {/* Chart */}
      <Grid container>
        <Grid xs={12} onScroll={handleScroll} sx={{ height: '25rem', overflowX: 'scroll' }}>
          {isLoadingDashboard ?
            <LinearProgress sx={{ mb: 1, mx: 'auto' }} color="secondary" />
            :
            chart(visualization)
          }
        </Grid>
      </Grid>
    </Paper>
  )
}
