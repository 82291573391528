import { useRef, useEffect } from 'react'

function resizeCanvasToDisplaySize(canvas) {

    const { width, height } = [10, 20]

    if (canvas.width !== width || canvas.height !== height) {
        canvas.width = width
        canvas.height = height
        return [width, height]
    }
    return false
}

export default function useAnimationCanvas(drawerFunction = null) {
    const canvasRef = useRef(null)

    const predraw = (context, canvas) => {
        context.save()
        resizeCanvasToDisplaySize(context, canvas)
    }

    const postdraw = (context) => {
        context.restore()
    }

    const canvasDraw = (drawerFunction, args = []) => {
        const canvas = canvasRef.current
        let drawerReturn = {};
        if (canvas) {
            const context = canvas.getContext('2d')
            if (context) {
                predraw(context, canvas);
                drawerReturn = { ...drawerFunction(context, ...args), updated: true }
                postdraw(context);
            }
        }
        return drawerReturn
    }

    useEffect(() => {
        if (drawerFunction) {
            const canvas = canvasRef.current
            if (canvas) {
                const context = canvas.getContext('2d')
                if (context) {
                    predraw(context, canvas);
                    const { width, height } = context.canvas
                    context.clearRect(0, 0, width, height)
                    canvasDraw(drawerFunction);
                    postdraw(context);
                }
            }
        }
    }, [canvasRef])

    return [canvasRef, canvasDraw]
}
