import { createReducer } from './utils';
import { ExcelExporter } from '../services/excelExporter';
import { handleRequest, handleAvailable, handleUnavailable } from '../reducerHandlers'
import {
  SNACK_SET,
  GENERAL_REQUEST,
  GENERAL_AVAILABLE,
  GENERAL_UNAVAILABLE,
} from './constants';

const defaultState = {
  loadingExcelDownload: false,
};

// Reducer

export const reducer = createReducer(defaultState, {
  [GENERAL_REQUEST]: handleRequest,
  [GENERAL_AVAILABLE]: handleAvailable,
  [GENERAL_UNAVAILABLE]: handleUnavailable,
});


export function downloadExcel(excelData, excelFileName, canFormatData, multipleSheets, multipleSheetsNames = [], excelDate = null) {
  return async (dispatch, _, { services: { __ } }) => {
    const keyState = 'excelDownloadMessage'
    const loadingName = 'loadingExcelDonwload'

    dispatch({ type: GENERAL_REQUEST, payload: { loadingName } });

    const excelExporter = new ExcelExporter();
    excelExporter.exportAsExcelFile({ excelData, excelFileName, canFormatData, multipleSheets, multipleSheetsNames, excelDate })

    try {
      dispatch({
        type: GENERAL_AVAILABLE,
        payload: { keyState: keyState, data: excelFileName, loadingName: loadingName },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: GENERAL_UNAVAILABLE,
        payload: { keyState: keyState, loadingName: loadingName },
      });
      const snack = {
        open: true,
        message: 'There was an error downloading the excel file',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}