import { useRef, useEffect } from 'react'

function resizeCanvasToDisplaySize(canvas) {

  const { width, height } = [10, 20]

  if (canvas.width !== width || canvas.height !== height) {
    canvas.width = width
    canvas.height = height
    return [width, height]
  }
  return false
}

export default function useCanvas(draw) {
  const canvasRef = useRef(null)
  const drawerReturn = useRef(null)

  const predraw = (context, canvas) => {
    context.save()
    resizeCanvasToDisplaySize(context, canvas)
    const { width, height } = context.canvas
    context.clearRect(0, 0, width, height)
  }

  const postdraw = (context) => {
    context.restore()
  }

  useEffect(() => {
    const canvas = canvasRef.current
    if (canvas) {
      const context = canvas.getContext('2d')
      if (context) {
        predraw(context, canvas);
        drawerReturn.current = draw(context)
        postdraw(context);
      }
    }
  }, [draw])

  return [canvasRef, drawerReturn.current ? { ...drawerReturn.current, 'updated': true } : {}]
}
