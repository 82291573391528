import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import { Paper, Grid, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';

import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarQuickFilter,
} from '@mui/x-data-grid';

const StyledPaper = styled(props => {
	return (
		<Paper
			elevation={1}
			{...props}
		/>
	)
}
)(({ theme }) => {
	return ({
		width: '100%',
		background: theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
		'& .MuiPaper-root': {
			backgroundColor: theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.paper.main,
			borderRadius: 3,
			color: 'white',
		},
		'& .MuiTableCell-root': {
			background: theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
			color: theme.palette.type === 'light' ? 'black' : 'white',
		},
		'& .MuiInputLabel-root': {
			color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
		},
		'& .MuiOutlinedInput-root': {
			color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
			'& fieldset': {
				borderColor: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
			},
		},
		'& .MuiDataGrid-columnHeaders': {
			backgroundColor:
				theme.palette.type === 'light'
					? theme.palette.primary.main
					: theme.palette.paper.main,
			color: 'white',
		},
		'& .MuiDataGrid-cell': {
			background:
				theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
			color: theme.palette.type === 'light' ? 'black' : 'white',
		},
		'& .MuiDataGrid-menuIconButton': {
			opacity: 1,
			color: 'white',
		},
		'& .MuiDataGrid-sortIcon': {
			color: 'white',
		},
	})
}
);

function CustomToolbar() {
	return (
		<GridToolbarContainer>
			<GridToolbarQuickFilter sx={{ width: '50%', p: 2 }} />
		</GridToolbarContainer>
	);
}

const formatColumns = (t, handleViewMode, handleEditClient) => {
	const columns = [
		{ field: 'name', headerName: 'Client Name', width: 220 },
		{ field: 'supplier_id', headerName: 'Supplier', width: 200, align: 'center', headerAlign: 'center', },
		{ field: 'client_type', headerName: 'Client Type', width: 220, align: 'right' },
		// { id: 'parsed_scope', label: 'Scope', minWidth: 170 },
		{
			field: 'is_active', headerName: 'Status', width: 200, align: 'center', headerAlign: 'center',
			renderCell: (params) => {
				return (
					<Chip
						label={params.row.is_active ? t('General.active') : t('General.inactive')}
						color={params.row.is_active ? 'success' : 'primary'}
						variant={params.row.is_active ? '' : 'outlined'}
					/>
				)
			}
		},
		// componentType: 'chip', getOption: (is_active) => { return is_active ? t('General.active') : t('General.inactive') } },
		{
			field: 'edit', headerName: 'Edit', width: 200, align: 'center', headerAlign: 'center',
			renderCell: (params) => {
				return (
					<IconButton aria-label="back-arrow"
						onClick={() => { handleViewMode({ editMode: true }); handleEditClient(params.row) }}>
						<EditIcon />
					</IconButton>
				)
			}

		},
	]
	return columns;
}

export default function ClientMTable(props) {
	const { t } = useTranslation();
	const {
		handleViewMode,
		handleEditClient,
		columnsToFilter = ['name', 'is_active', 'client_type'],
		rows = [
			{
				client_id: 1,
				name: 'John',
				supplier_id: '',
				client_type: 'Retail',
				scope: [],
				is_active: true,
			},
		]
	} = props;

	const [columns, setColumns] = React.useState([]);

	React.useEffect(() => {
		setColumns(formatColumns(t, handleViewMode, handleEditClient));
	}, [t,]);

	return (
		<StyledPaper elevation={1}
			style={{
				'border-top-left-radius': 0, 'border-top-right-radius': 8,
				'border-bottom-left-radius': 0, 'border-bottom-right-radius': 0,
			}}
		>

			<DataGrid
				getRowId={(row) => row.client_id}
				rows={rows}
				columns={columns}
				slots={{
					toolbar: CustomToolbar, // If you want all the toolbars, use the default GridToolbar instead
				}}
				slotProps={{
					toolbar: {
						showQuickFilter: true,
						quickFilterProps: { debounceMs: 500 },
					},
				}}
				localeText={{
					toolbarFilters: t('cws_app.general.filters', 'Filters'),
				}}
				initialState={{
					pagination: {
						paginationModel: {
							pageSize: 7,
						},
					},
				}}
				autoreset={false}
				pageSizeOptions={[7, 15, 25]}
				disableColumnSelector={true}
				disableRowSelect={true}
			/>


		</StyledPaper>
	);
}
