import React, { Component } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark as highlighterStyle } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import getReturns from './GetReturns';

//Icons
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

const styles = {
  container: {
    bgcolor: 'primary.main',
  },
  layout: {
    p: 2,
  },
  button: {
    height: 20,
    width: 20,
    color: 'white',
  },
  title: {
    color: 'white',
  },
  subTitle: {
    color: 'white',
    pt: 2,
  },
  position: {
    top: 5,
    right: 5,
  },
  syntaxStyle: {
    borderRadius: 2,
  },
};

// Styled components
const StyledButton = styled(IconButton)(() => ({
  '&:hover': {
    backgroundColor: '#808080',
    borderColor: '#737373',
    boxShadow: 'none',
  },
}));

class DocsCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codeText: '',
    };
  }
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    const { title } = this.props;
    var readmePath;

    try {
      readmePath = require(`../../../../codes/${title}.py`);
    } catch (error) {
      readmePath = require('../../../../codes/default.py');
    }

    fetch(readmePath)
      .then(response => {
        return response.text();
      })
      .then(text => {
        if (this._isMounted) {
          this.setState({
            codeText: text,
          });
        }
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  formatData = (data, returnVarious) => {
    const { title } = this.props;
    if (title === 'dumping_results') {
      return data.trim();
    }

    const start = returnVarious ? ' [' : ' [\n\t';
    const end = returnVarious ? '  ... \n ]' : '\n ]';

    return start + data + end;
  };

  render() {
    const { title, codeTitle, hasReturns, t } = this.props;
    const { codeText } = this.state;
    const returnData = getReturns();

    const hasFooters = ['authentication', 'dumping_results'];

    return (
      <Accordion sx={[styles.container, styles.layout]}>
        <AccordionSummary
          expandIcon={
            <IconComponent
              iconName={'chevron-down-outline'}
              style={{ color: 'white' }}
            />
          }>
          <Typography sx={styles.title}>
            {t('cws_app.documentation_information.' + codeTitle)}
          </Typography>
        </AccordionSummary>

        <AccordionDetails sx={styles.layout}>
          {/* Main Code */}
          <Grid position="relative">
            <Grid position="absolute" sx={styles.position}>
              <CopyToClipboard text={codeText}>
                <StyledButton color="primary" size="small">
                  <IconComponent
                    iconName={'copy-outline'}
                    style={styles.button}
                  />
                </StyledButton>
              </CopyToClipboard>
            </Grid>

            <SyntaxHighlighter
              language="python"
              wrapLines={true}
              style={highlighterStyle}
              customStyle={styles.syntaxStyle}>
              {codeText}
            </SyntaxHighlighter>
          </Grid>

          {/* Return Code */}
          {hasReturns && (
            <>
              {returnData[title].map((data, index) => {
                return (
                  <Grid key={`code_${title}_${index}`}>
                    <Typography sx={styles.subTitle}>
                      {t(
                        'cws_app.documentation_returns.' +
                          `${data['returnTitle']}`,
                      )}
                    </Typography>
                    <Grid position="relative">
                      <Grid position="absolute" sx={styles.position}>
                        <CopyToClipboard
                          text={this.formatData(
                            data['returnCode'],
                            data['returnVarious'],
                          )}>
                          <StyledButton color="primary" size="small">
                            <IconComponent
                              iconName={'copy-outline'}
                              style={styles.button}
                            />
                          </StyledButton>
                        </CopyToClipboard>
                      </Grid>

                      <SyntaxHighlighter
                        language="python"
                        wrapLines={true}
                        style={highlighterStyle}>
                        {this.formatData(
                          data['returnCode'],
                          data['returnVarious'],
                        )}
                      </SyntaxHighlighter>
                    </Grid>
                  </Grid>
                );
              })}
            </>
          )}

          {/* Footer */}
          {hasFooters.includes(title) && (
            <Typography sx={styles.subTitle}>
              {t('cws_app.documentation_information.' + `${title}_footer`)}
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default DocsCode;
