import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, LinearProgress, Skeleton, Typography } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

import { isEmptyOrUndefined, MultiSelector, Selector } from "@zippeditoolsjs/blocks";
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { format } from '../../utils/converters';

// My components

import AlertBox from '../../tools/AlertBox';
import DashboardPreview from "../../../redux/containers/features/dashboards/DashboardPreview";
import QuickAccess from "./QuickAccess";
import { toSnakeCase } from "core/utils/parsers";
import { formatToExcel } from '../../utils/parsers';

const drawerWidth = 350;

export default function Home(props) {
  const {
    isLoadingDashboards,
    dashboardsList,
    getDashboards,
    loadingDashboardResume,
    getDashboardResume,
    dashboardsResume,
    sectionsView,
    getUserStores,
    userStores,
    isLoadingUserStores,
    selectedClient,
    isLoadingBrands,
    brandList,
    getShareOfShelfExcel,
    isLoadingExcelDownload,
    downloadExcel,
    getNumberFormat,
    getUserSuppliers,
    suppliers,
    isLoadingSuppliers,
    clientFormats,
    isLoadingClientFormats,
  } = props;
  const { t, i18n } = useTranslation();
  const [openQuickAccess, setOpenQuickAccess] = useState(null);
  const [selectedStores, setStoresState] = useState([]);
  const [selectedBrand, setBrandState] = useState(brandList[0]);
  const [supplier, setSupplierState] = useState(null);

  const today = format(new Date(), i18n.language, clientFormats.date_format);

  // functions


  function storeOpenQuickAccess(isQuickAccessOpen) {
    localStorage.setItem('isQuickAccessOpen', isQuickAccessOpen);
  }

  const getStoredOpenQuickAccess = () => {
    return localStorage.getItem('isQuickAccessOpen');
  };

  const handleDownload = () => {
    if (dashboardsResume) {
      let filteredDashboardList = Object.values(dashboardsResume);
      const availableDashboardIds = dashboardsList.map(item => item.dashboard_id);
      filteredDashboardList = filteredDashboardList.filter(item => availableDashboardIds.includes(item.dashboard_id));
      const excelDataList = [];
      const multipleSheetsNames = [];
      filteredDashboardList.forEach(dashboard => {
        dashboard.components.forEach(visualization => {
          if (visualization?.visualization_type === 'kpi_metric') {
            excelDataList.push(formatToExcel(JSON.parse(visualization?.chart_data_by_date ?? '[{}]')));
            multipleSheetsNames.push(toSnakeCase(visualization?.title.slice(0, 28)));
            excelDataList.push(formatToExcel(JSON.parse(visualization?.chart_data ?? '[{}]'), 'store'));
            multipleSheetsNames.push(toSnakeCase(visualization?.title.slice(0, 28)));
          } else if (visualization?.visualization_type === 'kpi_bar_metric') {
            const chartData = JSON.parse(visualization?.chart_data ?? '[{}]');
            chartData?.map(row => {
              delete row.x
              return row;
            });
            excelDataList.push(formatToExcel(chartData));
            multipleSheetsNames.push(toSnakeCase(visualization?.title.slice(0, 28)));
          }
        })
      });
      const excelDate = format(new Date(), i18n.language, clientFormats.date_format);
      downloadExcel(excelDataList, toSnakeCase(t('cws_app.home.home_excel_title', 'Dashboard Resume')), false, true, multipleSheetsNames, excelDate);
    }
  }
  const handleIsLoadingDashboardResume = () => {
    return loadingDashboardResume && Object.values(loadingDashboardResume).some(value => value === true);
  }

  // Lifecycle methods

  useEffect(() => {
    if (!isEmptyOrUndefined(selectedStores, 'array')) {
      const chains = [...new Set(selectedStores.map((store) => store.chain_name))]
      getUserSuppliers(selectedClient.client_id, chains);
    }
    setSupplierState(selectedClient?.supplier_id ? { name: selectedClient.name, id: selectedClient.supplier_id } : null);
  }, [selectedStores])

  useEffect(() => {
    if (openQuickAccess === null) {
      const openStored = getStoredOpenQuickAccess();
      if (openStored === null) {
        setOpenQuickAccess(true);
      } else {
        setOpenQuickAccess(openStored === 'true');
      }
    } else {
      storeOpenQuickAccess(openQuickAccess);
    }
  }, [openQuickAccess]);

  useEffect(() => {
    if (selectedClient) {
      (async function () {
        getNumberFormat(selectedClient.client_id);
        await getUserStores(selectedClient.client_id);
        setStoresState([]);
      })();
    }
  }, [getUserStores, selectedClient]);

  useEffect(() => { }, [sectionsView]);

  useEffect(() => {
  }, [loadingDashboardResume]);

  useEffect(() => {
    if (!isLoadingDashboards && userStores && userStores.length) {
      const client_id = selectedClient.client_id;
      let selectedStoresParsed = selectedStores ? selectedStores.map(row => ({ store_code: row.store_code, chain_country_id: row.chain_country_id, store_id: row.store_id })) : [];
      selectedStoresParsed = selectedStoresParsed.length === 0 ? userStores.map(row => ({ store_code: row.store_code, chain_country_id: row.chain_country_id, store_id: row.store_id })) : selectedStoresParsed;
      dashboardsList?.forEach(dashboard => {
        if (
          !loadingDashboardResume[dashboard.dashboard_id] &&
          selectedStores &&
          client_id !== undefined
        ) {
          getDashboardResume({
            dashboard_id: dashboard.dashboard_id,
            client_id: client_id,
            stores: selectedStoresParsed,
            supplier_id: supplier?.id,
          });
        }
      });
    }
  }, [dashboardsList]);


  useEffect(() => {
    if (!isLoadingDashboards && selectedClient && selectedClient.is_active) {

      let selectedStoresParsed = selectedStores ? selectedStores.map(row => ({ store_code: row.store_code, chain_country_id: row.chain_country_id, store_id: row.store_id })) : [];
      selectedStoresParsed = selectedStoresParsed.length === 0 ? userStores.map(row => ({ store_code: row.store_code, chain_country_id: row.chain_country_id, store_id: row.store_id })) : selectedStoresParsed;
      if ((new Set(selectedStores.map((store) => store.chain_name))).size !== 1) {
        getDashboards(selectedClient.client_id, null, selectedStoresParsed);
      } else {
        getDashboards(selectedClient.client_id, supplier?.id, selectedStoresParsed);
      }
    }
  }, [selectedClient, selectedStores, supplier]);

  return (
    <Grid
      container
      justifyContent="middle"
      alignItems="flex-start"
      pt={4}
      px={2}
      columnSpacing={4}>
      {/* Dashboards */}
      <Grid
        item
        xs={12}
        md={openQuickAccess ? 9 : 12}
        sx={{ transition: 'width 0.25s' }}>
        {/* Filters */}
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="left"
            alignItems="flex-start"
            columnSpacing={1}>
            <Grid container item xs={12}>
              <Grid item xs={4}>
                <Typography variant="subtitle2" sx={{ py: 1, color: '#74748A' }}>
                  {t('cws_app.home.today_info')} {today}
                </Typography>
              </Grid>
              <Grid item xs sx={{ alignSelf: 'top', textAlign: 'end', justifyContent: 'center', pb: 1 }}>
                <LoadingButton variant='contained' loading={isLoadingExcelDownload || handleIsLoadingDashboardResume()} onClick={handleDownload} disabled={isEmptyOrUndefined(dashboardsResume, 'dict')}>
                  <Grid container alignItems='center' spacing={1} >
                    <Grid item xs>
                      <IconComponent iconName={'download-outline'} style={{ fontSize: '18px' }} />
                    </Grid>
                    <Grid item xs>
                      <Typography>{t('cws_app.general.download', 'Download')}</Typography>
                    </Grid>
                  </Grid>
                </LoadingButton>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={12} mb={2}>
              {selectedStores === null ?
                <LinearProgress sx={{ width: '100%', my: 2 }} color="secondary" />
                :
                <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
                  {/* Stores multiselector */}
                  <Box sx={{ width: '100%' }}>
                    <MultiSelector
                      options={userStores}
                      value={selectedStores}
                      setValue={setStoresState}
                      label={t('cws_app.general.sel_store', 'Store')}
                      name={'parsed_name'}
                      tagName={'store_code'}
                      loading={isLoadingUserStores}
                      groupBy={'chain_name'}
                    />
                  </Box>
                  {/* Suppliers selector */}
                  {!selectedClient?.supplier_id ?
                    <Box sx={{ width: '100%' }}>
                      <Selector
                        label={t("cws_app.general.select_supplier", "Supplier")}
                        options={suppliers}
                        name={'name'}
                        loading={isLoadingSuppliers}
                        value={supplier}
                        setValue={setSupplierState}
                        disabled={(new Set(selectedStores.map((store) => store.chain_name))).size !== 1 || suppliers.length === 0 || isLoadingSuppliers}
                      />
                    </Box>
                    : null
                  }
                </Box>
              }
            </Grid>
            {brandList.length > 0 &&
              <Grid item xs={4}>
                <Selector
                  label={t("cws_app.general.sel_brand", "Brand")}
                  options={brandList}
                  name={'parsed_name'}
                  loading={isLoadingBrands}
                  value={selectedBrand}
                  setValue={setBrandState}
                />
              </Grid>
            }
          </Grid>
        </Grid>
        {/* Metrics */}
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}>
          {isLoadingDashboards ? (
            <Grid item xs={6} sx={{ width: '100%' }}>
              <Skeleton variant="rectangular" width="100%" height={200} />
              <Skeleton variant="rectangular" width="100%" height={200} sx={{ mt: 2 }} />
            </Grid>
          ) : dashboardsList.length ? (
            dashboardsList.map((dashboard, index) => {
              return (
                <Grid item xs={6} key={index} sx={{ width: '100%' }}>
                  <DashboardPreview
                    key={index}
                    openQuickAccess={openQuickAccess}
                    dashboard={dashboard}
                    inputDashboardsResume={dashboardsResume}
                    selectedClientName={selectedClient.name}
                    selectedClientId={selectedClient.client_id}
                    isLoading={loadingDashboardResume[dashboard.dashboard_id]}
                    clientFormats={clientFormats}
                  />
                </Grid>
              );
            })
          ) :
            <Grid item xs={6} sx={{ width: '100%' }}>
              <AlertBox content={t('cws_app.home.no_dashboards_content', 'There are no dashboards for this client')} sx={{ mt: 1 }} />
            </Grid>
          }
        </Grid>
      </Grid>

      {/* Quick Access */}
      <Grid item xs={12} md={openQuickAccess ? 3 : 0} sx={{ display: 'flex', position: 'absolute', right: 0 }} >
        <QuickAccess sectionsView={sectionsView} getShareOfShelfExcel={getShareOfShelfExcel} supplier_id={supplier?.id} drawerWidth={drawerWidth} open={openQuickAccess} setOpen={setOpenQuickAccess} />
      </Grid>
    </Grid>
  );
}
