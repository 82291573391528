import { Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import {
	getChainMeaning,
	getConsolidatedTranslations,
} from 'core/utils/parsers';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteMultiSelector from '../../tools/InfiniteMultiSelector';
import SearchBar from '../../tools/SearchBar';
import { isEmptyOrUndefined } from 'core/utils/validators';
import DownloadSection from './DownloadSection';
import SortPaginatedTable from './SortPaginatedTable';
import { getRobotIndex } from '../../utils/converters';

const StyledPaper = styled(props => {
	return <Paper elevation={1} {...props} />;
})(({ theme }) => {
	return {
		background:
			theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
		'& .MuiPaper-root': {
			backgroundColor:
				theme.palette.type === 'light'
					? theme.palette.primary.main
					: theme.palette.paper.main,
			borderRadius: 3,
			color: 'white',
		},
		'& .MuiTableCell-root': {
			background:
				theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
			color: theme.palette.type === 'light' ? 'black' : 'white',
		},
		'& .MuiInputLabel-root': {
			color:
				theme.palette.type === 'light'
					? 'rgba(0, 0, 0, 0.6)'
					: 'rgba(255, 255, 255, 0.6)',
		},
		'& .MuiOutlinedInput-root': {
			color:
				theme.palette.type === 'light'
					? 'rgba(0, 0, 0, 0.6)'
					: 'rgba(255, 255, 255, 0.6)',
			'& fieldset': {
				borderColor:
					theme.palette.type === 'light'
						? 'rgba(0, 0, 0, 0.6)'
						: 'rgba(255, 255, 255, 0.6)',
			},
		},
	};
});

export default function TaskTable(props) {
	const {
		aislesByTask,
		subCategoriesByTask,

		inputTaskData = [],
		filteredTaskData,
		setFilteredTaskData,

		chain,
		groupParameter,
		groupColumnInfo,
		aislesClientNames,
		displayedColumns = [],

		storeMap,
		selectedClient,
		date,
		selectedStore,
		selectedStoreTaskType,
		selectedCategory,
		storeCategories,

		getDigitalStoreExcel,
		getDigitalStorePdf,
		getDigitalStoreInv,
		isExcelLoading,
		isPdfLoading,
		isInvLoading,
		getExcelByDate,
		isLoadingExcelByDate,

		linkReceived,

		robotSession,

		supplier_id,
		resumeBiData,
		clientFormats,
	} = props;
	const { t, i18n } = useTranslation();
	const [renderedColumns, setRenderedColumns] = useState(displayedColumns);
	const [selectorAisles, setSelectorAisles] = useState([]);
	const [selectorSubCategories, setSelectorSubCategories] = useState([]);
	const [value, setValue] = useState(null);
	const [searchValue, setSearchValue] = useState('');
	const [predicateFilter, setPredicateFilter] = useState('◡◡');
	const [inputPage, setInputPage] = useState(0);
	const [taskData, setTaskData] = useState(inputTaskData);

	// Lifecycle methods
	useEffect(() => {
		i18n.on('languageChanged', function (lng) { setInitData() });
		return () => i18n.off('languageChanged');
	}, []);

	useEffect(() => {
		applyFilter(taskData, getTableFilter(selectorAisles, 'aisleMode'));
	}, [selectorAisles]);

	useEffect(() => {
		applyFilter(taskData, getTableFilter(selectorSubCategories, 'subCategoryMode'));
	}, [selectorSubCategories]);

	useEffect(() => {
		if (typeof searchValue === 'string') {
			applyFilter(taskData, getTableFilter(searchValue, 'normal'));
		}
	}, [searchValue]);

	useEffect(() => {
		if (inputTaskData && inputTaskData.length > 0) {
			setInitData()
		}
	}, [inputTaskData]);

	// Reset values if the client or any map selector changes (date, store, task, category)
	useEffect(() => {
		setSelectorAisles([]);
		setSelectorSubCategories([]);
		setValue(null);
		setSearchValue('');
	}, [
		selectedClient,
		date,
		selectedStore,
		selectedStoreTaskType,
		selectedCategory,
	]);

	// Methods
	const setInitData = () => {
		let taskDataAux = inputTaskData.slice();
		const columnsRendered = displayedColumns?.map(column => {
			if (column === 'task_type') {
				taskDataAux = inputTaskData?.map(row => {
					return {
						...row,
						[column]: getConsolidatedTranslations(
							t,
							chain,
							row[column],
							'general',
							row[column],
						),
					};
				});
			}
			return {
				id: column,
				translatedName: getConsolidatedTranslations(
					t,
					chain,
					column,
					'general',
					column,
				),
			};
		});
		setRenderedColumns(columnsRendered);
		setTaskData(taskDataAux);
		applyFilter(taskDataAux);
	};

	const getGroupColumnCant = (rowTable, displayedData = filteredTaskData) => {
		return displayedData.filter(
			row =>
				row[groupParameter?.rowField] === rowTable[groupParameter?.rowField],
		).length;
	};

	const checkGroupColumnDisplay = (index, displayedData = filteredTaskData) => {
		return displayedData[index - 1] === undefined ||
			displayedData[index][groupParameter?.rowField] !==
			displayedData[index - 1]?.[groupParameter?.rowField]
			? true
			: false;
	};

	const getGroupColumn = rowTable => {
		if (!isEmptyOrUndefined(groupColumnInfo, 'dict')) {
			const rowParameter = groupColumnInfo[rowTable[groupParameter?.rowField]];
			if (rowParameter) {
				return aislesClientNames?.[rowParameter.name]
					? aislesClientNames[rowParameter.name]
					: rowParameter.name;
			} else {
				return aislesClientNames?.[rowTable[groupParameter?.rowField]]
					? aislesClientNames[rowTable[groupParameter?.rowField]]
					: rowTable[groupParameter?.rowField];
			}
		}
	};

	const checkColumn = column => {
		return column === groupParameter?.rowName; //aisle
	};

	const getElementValue = rowTableValue => {
		// TODO: Uncomment when translations are ready
		// const elementValueTranslated = getChainMeaning(t, chain, rowTableValue);
		const elementValueTranslated = rowTableValue;
		return rowTableValue === true
			? '✅'
			: rowTableValue === false
				? '-'
				: elementValueTranslated != null || elementValueTranslated !== undefined
					? elementValueTranslated
					: rowTableValue != null || rowTableValue !== undefined
						? rowTableValue
						: '-';
	};

	const customFilterPredicate = (rowTable, predicateFilter) => {
		// "filtradoenbuscador ◡ filtrado◬pasillos ◡ filtrado◬s◬categoría"
		const [normalFilter, asileFilter, subCategoryFilter] =
			predicateFilter.split('◡');
		const isMatchWithNormal =
			Object.keys(rowTable)
				.reduce((currentTerm, key) => {
					return currentTerm + rowTable[key] + '◬';
				}, '')
				.toLowerCase()
				.indexOf(normalFilter.trim().toLowerCase()) !== -1;
		const isMatchWithAisle =
			!asileFilter ||
			asileFilter.split('◬').includes(rowTable.aisle_name.trim());
		const isMatchWithsubCategory =
			!subCategoryFilter ||
			subCategoryFilter
				.split('◬')
				.includes(rowTable[groupParameter?.rowField].trim());

		return isMatchWithNormal && isMatchWithAisle && isMatchWithsubCategory;
	};

	const getTableFilter = (filterValues, filterMode) => {
		let filterParsed,
			predicateFilterAux = '';
		const filterAux = predicateFilter.split('◡');

		switch (filterMode) {
			case 'normal':
				if (predicateFilter) {
					filterAux[0] = filterValues.trim().toLowerCase();
					predicateFilterAux = filterAux.join('◡');
				} else {
					predicateFilterAux = `${filterParsed}◡◡`;
				}
				break;

			case 'aisleMode':
				filterParsed = filterValues
					?.map(row => row.name)
					.join('◬')
					.trim();
				if (predicateFilter) {
					filterAux[1] = filterParsed;
					predicateFilterAux = filterAux.join('◡');
				} else {
					predicateFilterAux = `◡${filterParsed}◡`;
				}
				break;

			case 'subCategoryMode':
				filterParsed = filterValues
					?.map(row => row[groupParameter?.rowField])
					.join('◬')
					.trim();
				if (predicateFilter) {
					filterAux[2] = filterParsed;
					predicateFilterAux = filterAux.join('◡');
				} else {
					predicateFilterAux = `◡◡${filterParsed}`;
				}
				break;

			default:
				break;
		}

		setPredicateFilter(predicateFilterAux);
		return predicateFilterAux;
	};

	const applyFilter = (taskData, tableFilter = predicateFilter) => {
		setInputPage(0);
		setFilteredTaskData(
			taskData.filter(row => customFilterPredicate(row, tableFilter)),
		);
	};

	return (
		<Grid container spacing={2}>
			<Grid
				item
				container
				spacing={{ xs: 2, md: 3 }}
				columns={{ xs: 4, sm: 8, md: 12 }}>
				<Grid item xs={4} sm={8} md={6}>
					<Grid
						container
						spacing={{ xs: 2, md: 3 }}
						columns={{ xs: 4, sm: 8, md: 12 }}>
						{/* Aisle selector */}
						{aislesByTask.length > 0 ? (
							<Grid item xs={12} sm={4} md={6}>
								<InfiniteMultiSelector
									inputLabel={t('cws_app.general.sel_aisle', 'Aisle')}
									options={aislesByTask}
									inputVariant={'filled'}
									inputSelected={selectorAisles}
									setInputSelectedOptions={setSelectorAisles}
									objectName="client_aisle_name"
									objectId="client_aisle_name"
								/>
							</Grid>
						) : null}
						{/* Sub-categories selector (only for task == missing labels) */}
						{subCategoriesByTask.length > 0 ? (
							<Grid item xs={12} sm={4} md={6}>
								<InfiniteMultiSelector
									inputLabel={t(
										'cws_app.general.sel_sub_category',
										'Sub-category',
									)}
									options={subCategoriesByTask}
									inputVariant={'filled'}
									inputSelected={selectorSubCategories}
									setInputSelectedOptions={setSelectorSubCategories}
									objectId="name"
								/>
							</Grid>
						) : null}
					</Grid>
				</Grid>
				<Grid item xs={4} sm={8} md={6}>
					<Grid
						container
						justifyContent={{ xs: null, md: 'flex-end' }}
						spacing={0}
						columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}>
						{/* Search bar */}
						<Grid item xs={4} sm={6} md={7} lg={8}>
							<SearchBar
								value={value}
								setValue={setValue}
								inputValue={searchValue}
								setInputValue={setSearchValue}
								options={taskData}
								optionLabel="description"
								id={['item', 'ean', 'detection_id']}
							/>
						</Grid>
						{/* Downloadables */}
						<Grid
							item
							xs={5}
							sm={3}
							md={4}
							lg={3}
							mt={{ xs: 2, md: 0 }}
							ml={{ xs: 0, md: 1 }}
							display="flex"
							justifyContent={{ xs: null, sm: 'flex-end' }}
							alignItems="flex-end">
							<DownloadSection
								displayedColumns={storeMap?.columns}
								storeCategories={storeCategories}
								chain={chain}
								filteredTaskData={filteredTaskData}
								isDataFiltered={false}  // TODO: When we have the table we must change this and compare filtered info with storeMap.task_table_info

								client_id={selectedClient?.client_id}
								signed_logo={selectedClient?.signed_logo}
								supplier_id={supplier_id}

								inputDate={format(date, 'yyyy-MM-dd')}
								inputTaskType={selectedStoreTaskType?.task_name}
								inputStoreId={selectedStore?.store_id}
								inputStoreName={selectedStore?.parsed_name}

								getDigitalStoreExcel={getDigitalStoreExcel}
								getDigitalStorePdf={getDigitalStorePdf}
								getDigitalStoreInv={getDigitalStoreInv}
								getExcelByDate={getExcelByDate}

								isExcelLoading={isExcelLoading}
								isPdfLoading={isPdfLoading}
								isInvLoading={isInvLoading}
								isLoadingExcelByDate={isLoadingExcelByDate}

								linkReceived={linkReceived}

								resumeBiData={resumeBiData ?? {}}
								subCategoriesByTask={storeMap?.sub_categories_list}
								linearAislesClientNames={
									storeMap?.map_info?.client_linear_names
								}
								aislesClientNames={storeMap?.map_info?.client_aisles_names}
								robotSessions={storeMap?.sessions}
								// TODO: get the robotIndex in a smarter way (not from the session name)
								selectedRobotIndex={getRobotIndex({ session: robotSession, floorIdentifier: 'Piso', defaultIndex: storeMap.sessions[0].robotIndex })}
								selectedCategory={selectedCategory}
								clientFormats={clientFormats}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<SortPaginatedTable
					t={t}
					page={inputPage}
					setPage={setInputPage}
					StyledPaper={StyledPaper}
					renderedData={filteredTaskData}
					renderedColumns={renderedColumns}
					checkColumn={checkColumn}
					checkGroupColumnDisplay={checkGroupColumnDisplay}
					getGroupColumnCant={getGroupColumnCant}
					getGroupColumn={getGroupColumn}
					getElementValue={getElementValue}
					store={selectedStore?.parsed_name}
					date={date}
					selectedRobotIndex={getRobotIndex({ session: robotSession, floorIdentifier: 'Piso', defaultIndex: storeMap.sessions[0].robotIndex })}
				/>
			</Grid>
		</Grid>
	);
}
