import { useEffect, useState } from "react";
import { format } from '../../utils/converters';
import { useTranslation } from "react-i18next";

import { AreaChart } from "@zippeditoolsjs/dashboards";

import { getConsolidatedTranslations } from 'core/utils/parsers';

const dataFormatter = (inputValue, metric_type, numberFormat, lang = 'en') => {
  let numberFormatted = numberFormat && metric_type !== 'date' ? inputValue.toLocaleString(numberFormat) : inputValue;
  return metric_type === 'percent' ? `${numberFormatted} %`
    : metric_type === 'number' ? numberFormatted
      : metric_type === 'date' ? format(numberFormatted, lang, 'MMM d')
        : metric_type === 'money' ? `$ ${numberFormatted}`
          : inputValue;
};


export default function AreaGraph(props) {
  const {
    selectedClientName = '',
    colorsInput = ["indigo"],
    indexInput = "date",
    metric_type = 'number',
    keysInput = ["value"],
    inputFormat,
    data = [],
    numberFormat = 'en-US',
  } = props;

  const { t, i18n } = useTranslation();
  const [chartData, setChartData] = useState(data);
  const [categories, setCategories] = useState(keysInput);
  const [index, setIndex] = useState(indexInput);

  useEffect(() => {
    if (data && keysInput && keysInput.length > 0 && data.length > 0 && indexInput) {
      const dataAux = data.map((rowInfo) => {
        const itemAux = {};
        Object.keys(rowInfo).forEach((keyName) => {
          itemAux[getConsolidatedTranslations(t, selectedClientName, keyName, 'general', keyName)] = rowInfo[keyName];
        });
        return itemAux;
      });
      const keysAux = keysInput.map((key) => {
        return getConsolidatedTranslations(t, selectedClientName, key, 'general', key);
      });
      const indexAux = getConsolidatedTranslations(t, selectedClientName, indexInput, 'general', indexInput);

      if (inputFormat) {
        dataAux.forEach((element) => {
          element[indexAux] = format(new Date(element.date), i18n.language, inputFormat);
        });
      }

      setChartData(dataAux);
      setCategories(keysAux);
      setIndex(indexAux);
    }
  }, [data, keysInput, indexInput, selectedClientName]);

  return (
    <AreaChart
      className="w-full h-[10em]"
      index={index}
      data={chartData}
      categories={categories}
      colors={colorsInput}
      valueFormatter={(inputValue) => dataFormatter(inputValue, metric_type, numberFormat, i18n.language)}
    />
  );
}