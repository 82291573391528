import { connect } from 'react-redux';
import DocsLayout from '../../../../components/features/documentation/DocsLayout';

import { documentationImages } from 'core/slices/documentation';
import { isLoadingDocumentation } from 'core/selectors';

export default connect(
  state => ({
    isLoadingDocumentation: isLoadingDocumentation(state),
  }),
  {
    documentationImages,
  },
)(DocsLayout);
