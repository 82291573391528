import React, { useEffect, useState } from 'react';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { useTranslation } from 'react-i18next';

// Components
import Selector from '../../tools/Selector';
import TranslationTable from './TranslationTable';
import TranslationForm from './TranslationForm';
import CircularLoader from '../../tools/CircularLoader';

//Icons
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

const styles = {
  layout: {
    borderRadius: 2,
    m: 1,
    px: 2,
    pb: 2,
  },
};

export default function Translations(props) {
  const {
    getTranslationsDictionaries,
    getTranslationsAppname,
    getTranslationsLanguages,
    getTranslationsDictionary,
    updateTranslations,
    addNewTranslations,
    dictionariesList,
    appNameList,
    languageList,
    translationList,
    isLoadingDictionaries,
    isLoadingAppNames,
    isLoadingLanguages,
    isLoadingTranslations,
    updateTranslationDict,
    newTranslationDict,
    deleteNewTranslations,
    patchNewTranslations,
    chainsList,
    isLoadingChains,
    getChains
  } = props;

  const { t } = useTranslation();
  const theme = useTheme();
  const textColor = theme.palette.type === 'light' ? 'black' : 'white';

  const [selectedDictionaries, setSelectedDictionaries] = useState(null);
  const [selectedAppNames, setSelectedAppNames] = useState(null);
  const [selectedLanguages, setSelectedLanguages] = useState(null);
  const [counter, setCounter] = useState(0);

  const handleSendData = async () => {
    if (
      (newTranslationDict && Object.keys(newTranslationDict).length > 0) ||
      (updateTranslationDict && Object.keys(updateTranslationDict).length > 0)
    ) {
      await patchNewTranslations(
        selectedDictionaries.name,
        selectedAppNames.app_name ? selectedAppNames.app_name : selectedAppNames.name,
        selectedLanguages.language_name,
      );
      alert(t('cws_app.translations.updated'));
      getTranslationsDictionary(
        selectedDictionaries.name,
        selectedAppNames.app_name ? selectedAppNames.app_name : selectedAppNames.name,
        selectedLanguages.language_name,
      );
    }
  };

  useEffect(() => {
    getTranslationsDictionaries();
    getTranslationsAppname();
    getTranslationsLanguages();
    getChains();
  }, []);

  useEffect(() => {
    if (selectedAppNames) {
      getTranslationsLanguages(selectedAppNames);
    }
  }, [selectedAppNames]);

  useEffect(() => {
    if (selectedDictionaries && selectedLanguages) {
      getTranslationsDictionary(
        selectedDictionaries.name,
        selectedDictionaries?.name === "General" ?
          null
          : selectedAppNames?.name ?
            selectedAppNames.name
            : selectedAppNames?.app_name,
        selectedLanguages.language_name,
      );
    }
  }, [selectedAppNames, selectedLanguages]);

  useEffect(() => {
    if (selectedDictionaries) {
      setSelectedAppNames(null);
    }
  }, [selectedDictionaries]);

  return (
    <Grid container pt={3} px={5}>
      {/* Selector section */}
      <Typography sx={{ fontSize: '34px', color: textColor }}>
        {t('cws_app.translations.management')}
      </Typography>

      <StyledPaper sx={styles.layout}>
        <Grid container spacing={2} sx={{ my: 1, px: 4 }} alignItems={'center'} justifyContent={'space-between'}>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '20px', fontWeight: 'bold', color: textColor }}>
              {t('cws_app.translations.selection')}
            </Typography>
          </Grid>
          <Grid item container xs={10} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
            <Grid item xs>
              <Selector
                labelInput={t('cws_app.translations.sel_dict')}
                options={dictionariesList}
                optionLabel="name"
                isLoading={isLoadingDictionaries}
                selectedElement={selectedDictionaries}
                setSelectedElement={setSelectedDictionaries}
                disabled={isLoadingDictionaries}
              />
            </Grid>
            <Grid item xs>
              <Selector
                labelInput={t('cws_app.translations.sel_lang')}
                options={languageList}
                optionLabel="language_name"
                isLoading={isLoadingLanguages}
                selectedElement={selectedLanguages}
                setSelectedElement={setSelectedLanguages}
                disabled={isLoadingLanguages}
              />
            </Grid>
            {selectedLanguages &&
              <Grid item xs>
                {selectedDictionaries?.name === "Apps" ?
                  <Selector
                    labelInput={t('cws_app.translations.sel_app')}
                    options={appNameList}
                    optionLabel="app_name"
                    isLoading={isLoadingAppNames}
                    selectedElement={selectedAppNames}
                    setSelectedElement={setSelectedAppNames}
                    disabled={isLoadingAppNames}
                  />
                  : selectedDictionaries?.name === "Chains" ?
                    <Selector
                      labelInput={t('cws_app.translations.sel_chain')}
                      options={chainsList}
                      optionLabel="name"
                      isLoading={isLoadingChains}
                      selectedElement={selectedAppNames}
                      setSelectedElement={setSelectedAppNames}
                      disabled={isLoadingChains}
                    />
                    : null
                }
              </Grid>
            }

            {/* Add button */}
          </Grid>
          {selectedAppNames ? (
            <Grid item container xs justifyContent={'flex-end'}>
              <Button
                disabled={isLoadingTranslations}
                variant="contained"
                onClick={() => {
                  addNewTranslations('', '', counter);
                  setCounter(counter + 1);
                }}
              >
                <IconComponent iconName={'add'} style={{ fontSize: 25 }} />
              </Button>
            </Grid>
          ) : null}

        </Grid>

        {selectedLanguages && (
          <Grid container spacing={2} sx={{ px: 4, pt: 1 }} alignItems={'center'} justifyContent={'space-between'}>
            {/* Add translations section */}
            {Object.keys(newTranslationDict).length > 0 && (
              <TranslationForm
                t={t}
                textColor={textColor}
                addNewTranslations={addNewTranslations}
                newTranslationDict={newTranslationDict}
                deleteNewTranslations={deleteNewTranslations}
              />
            )}

            {/* Translations table section */}
            {selectedAppNames ? (
              <>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: '20px', fontWeight: 'bold', color: textColor }}>
                    {t('cws_app.translations.translations')}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  {isLoadingTranslations ? (
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center">
                      <CircularLoader size="2em" />
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        {t('cws_app.translations.loading')}
                      </Typography>
                    </Grid>
                  ) : (
                    <TranslationTable
                      t={t}
                      translationList={translationList}
                      isLoadingTranslations={isLoadingTranslations}
                      updateTranslations={updateTranslations}
                    />
                  )}
                </Grid>
              </>
            ) : null}

            {/* Send button */}
            <Grid item xs={12}>
              {selectedAppNames ? (
                <Grid container>
                  <Grid container item justifyContent="flex-end">
                    <Button variant="contained"
                      disabled={
                        isLoadingTranslations ||
                        !(
                          Object.keys(updateTranslationDict).length > 0 ||
                          Object.keys(newTranslationDict).length > 0
                        )
                      }
                      onClick={() => handleSendData()}
                      startIcon={
                        <IconComponent iconName={'send'} style={{ fontSize: 20 }} />
                      }>
                      {t('cws_app.general.send')}
                    </Button>
                  </Grid>
                  <Grid container item justifyContent="flex-end">
                    <Typography variant='caption'>{t('cws_app.translations.new_translations_msg_1', 'New translations require app redeployment. Please ')}<Typography variant='caption' sx={{ color: theme.palette.error.main }}>{t('cws_app.translations.new_translations_msg_2', 'inform the Software Team.')}</Typography></Typography>
                  </Grid>
                </Grid>)
                : null}
            </Grid>
          </Grid>
        )}
      </StyledPaper>
    </Grid>
  );
}

const StyledPaper = styled(props => {
  return <Paper elevation={1} {...props} />;
})(({ theme }) => {
  return {
    width: '100%',
    background:
      theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.primary.main
          : theme.palette.paper.main,
      color: 'white',
    },
    '& .MuiDataGrid-cell': {
      background:
        theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
      color: theme.palette.type === 'light' ? 'black' : 'white',
    },
    '& .MuiDataGrid-columnsPanel': {
      background:
        theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
      color: theme.palette.type === 'light' ? 'black' : 'white',
    },
    '& .MuiDataGrid-menuIconButton': {
      opacity: 1,
      color: 'white',
    },
    '& .MuiDataGrid-sortIcon': {
      color: 'white',
    },
  };
});
