import { useEffect, useState } from 'react';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogContent, Grid, Paper, Typography, useTheme } from '@mui/material';
import { isEmptyOrUndefined } from "core/utils/validators";
import { colorBasedOnTheme } from '../../utils/colors';
import UsersTable from './UsersTable';
import UserForm from './UserForm';
import { FAMILY_ID_INTERN } from 'core/utils/constants';

export default function Users(props) {
  const {
    selectedClient,
    getUsers,
    users,
    isLoadingUsers,
    getUserClients,
    userClients,
    isLoadingUserClients,
    postUser,
    postResponse,
    isLoadingPost,
    updateUser,
    updateResponse,
    isLoadingUpdate,
    deleteUser,
    deleteResponse,
    isLoadingDelete,
    clientList,
    getChains,
    chainsList,
    isLoadingChains,
    getUserStores,
    userStores,
    isLoadingUserStores,
    userMGMTStores,
    getProductsRoles,
    productsRoles,
    isLoadingProductsRoles,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  // Add user states
  const [open, setOpen] = useState(false);
  // Table states
  const [tableUsers, setTableUsers] = useState([]);
  // Metrics states
  const [lastWeekConnections, setLastWeekConnections] = useState(0);
  const [offlineForMonths, setOfflineForMonths] = useState(0);
  const [activeUsers, setActiveUsers] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);

  // Lifecycle methods

  useEffect(() => {
    getChains();
    getProductsRoles();
  }, []);

  useEffect(() => {
    if (!isEmptyOrUndefined(selectedClient, 'dict')) {
      getUsers(selectedClient.client_id, true);
      getUserStores(selectedClient.client_id);
    }
  }, [selectedClient]);

  // Filter the users based on the selected client
  useEffect(() => {
    if (!isEmptyOrUndefined(users, 'array') && !isEmptyOrUndefined(selectedClient, 'dict')) {
      let currentUsers = [];
      if (selectedClient.client_id === -1) {
        currentUsers = users;
      } else {
        currentUsers = users.flatMap((user) => {
          if (user?.family_id !== FAMILY_ID_INTERN) {
            return {
              id: user.user_id,
              ...user,
            }
          }
          return [];
        });
      }
      setTableUsers(currentUsers);
    }
  }, [users, selectedClient]);

  // Metric calculations
  useEffect(() => {
    if (!isEmptyOrUndefined(tableUsers, 'array')) {
      let last_week_connections = 0;
      let offline_for_months = 0;
      let active_users = 0;

      tableUsers.forEach(user => {
        if (user?.last_connection) {
          const last_connection = new Date(user.last_connection);
          const today = new Date();
          const diffTime = Math.abs(today - last_connection); // Milliseconds
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          // Last week connections
          if (diffDays <= 7) {
            last_week_connections++;
          }
          // Offline for 3 months or more
          if (diffDays >= 90) {
            offline_for_months++;
          }
        } else {
          offline_for_months++; // If last_connection is null, add 1 to offline_for_months
        }
        if (user.is_user_active) {
          active_users++;
        }
      });

      setLastWeekConnections(last_week_connections);
      setOfflineForMonths(offline_for_months);
      setActiveUsers(active_users);
      setTotalUsers(tableUsers.length);
    }
  }, [tableUsers]);

  // Methods

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} pt={4} px={2}>
      {/* Page title */}
      <Grid container item xs={12} sm={12} md={12} justifyContent='space-between'>
        <Typography variant='h4' sx={{ color: colorBasedOnTheme(theme, 'color') }}>{t('cws_app.general.users_admin', 'Users')}</Typography>
        {/* Add user button */}
        <Button
          variant='contained'
          startIcon={
            <IconComponent
              iconName={'add'}
              style={{ fontSize: '20px' }}
            />
          }
          onClick={() => setOpen(true)}
        >
          {t('cws_app.management.add_user', 'Add User')}
        </Button>
        {/* Add user dialog */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="edit-dialog-title"
          aria-describedby="edit-dialog-description"
          maxWidth='md'
          fullWidth
        >
          <DialogContent>
            <UserForm
              selectedClient={selectedClient}
              clientList={clientList}
              chainsList={chainsList}
              isLoadingChains={isLoadingChains}
              getUserStores={getUserStores}
              userStores={userStores}
              isLoadingUserStores={isLoadingUserStores}
              productsRoles={productsRoles}
              isLoadingProductsRoles={isLoadingProductsRoles}
              postUser={postUser}
              postResponse={postResponse}
              isLoadingPost={isLoadingPost}
            />
          </DialogContent>
        </Dialog>
      </Grid>
      {/* Metrics */}
      {!isLoadingUsers && !isEmptyOrUndefined(users, 'array') && totalUsers !== null &&
        <Grid item xs={4} sm={8} md={12}>
          <Grid container item justifyContent='space-between' height={{ md: '8em' }}>
            {activeUsers !== null &&
              <Paper component={Grid} container item direction='column' xs={12} sm={3.8} mb={{ xs: 2, sm: 0 }} p={2} justifyContent='center' alignItems='center' sx={{ color: colorBasedOnTheme(theme, 'color'), backgroundColor: theme.palette.paper.main }}>
                <Typography variant='h5'>{parseFloat(((activeUsers * 100) / totalUsers).toFixed(1))}%</Typography>
                <Typography variant='caption' align='center'>{t('cws_app.management.active_users', 'Active users')}</Typography>
              </Paper>
            }
            {lastWeekConnections !== null &&
              <Paper component={Grid} container item direction='column' xs={12} sm={3.8} mb={{ xs: 2, sm: 0 }} p={2} justifyContent='center' alignItems='center' sx={{ color: colorBasedOnTheme(theme, 'color'), backgroundColor: theme.palette.paper.main }}>
                <Typography variant='h5'>{lastWeekConnections}/{totalUsers}</Typography>
                <Typography variant='caption' align='center'>{t('cws_app.management.connected_last_week', 'Connected last week')}</Typography>
              </Paper>
            }
            {offlineForMonths !== null &&
              <Paper component={Grid} container item direction='column' xs={12} sm={3.8} p={2} justifyContent='center' alignItems='center' sx={{ color: colorBasedOnTheme(theme, 'color'), backgroundColor: theme.palette.paper.main }}>
                <Typography variant='h5'>{offlineForMonths}/{totalUsers}</Typography>
                <Typography variant='caption' align='center'>{t('cws_app.management.offline_three_months', 'Offline for the last 3 months')}</Typography>
              </Paper>
            }
          </Grid>
        </Grid>
      }
      {/* Users table */}
      <Grid item xs={4} sm={8} md={12}>
        <UsersTable
          theme={theme}
          selectedClient={selectedClient}
          users={tableUsers}
          isLoadingUsers={isLoadingUsers}
          getUserClients={getUserClients}
          userClients={userClients}
          isLoadingUserClients={isLoadingUserClients}
          updateUser={updateUser}
          updateResponse={updateResponse}
          isLoadingUpdate={isLoadingUpdate}
          deleteUser={deleteUser}
          deleteResponse={deleteResponse}
          isLoadingDelete={isLoadingDelete}
          clientList={clientList}
          chainsList={chainsList}
          isLoadingChains={isLoadingChains}
          getUserStores={getUserStores}
          userStores={userStores}
          isLoadingUserStores={isLoadingUserStores}
          userMGMTStores={userMGMTStores}
          productsRoles={productsRoles}
          isLoadingProductsRoles={isLoadingProductsRoles}
        />
      </Grid>
    </Grid>
  )
}
