import React, { Component } from 'react';
import { Grid } from '@mui/material';

// Redux
import CustomPage from '../../../redux/containers/documentation/CustomPage';

// Functions
import getSections from './GetSections';

class Documentation extends Component {
  componentDidMount() {
    const sections = getSections();
    const res = {};
    sections.list.forEach(section => {
      if (section.items != null) {
        section.items.forEach(item => (res[item.title] = item.title));
      }
      res[section.title] = section.title;
    });
  }

  render() {
    const sections = getSections();

    return (
      <Grid item>
        {sections.list.map((section, index) => {
          return (
            <Grid key={index}>
              {section.items != null ? (
                <Grid>
                  <CustomPage
                    key={`documentation_${section.title}`}
                    title={section.title}
                    hasSubsection={true}
                    hasCode={section.hasCode}
                    codeTitle={section.codeTitle}
                    hasReturns={section.hasReturns}
                    returnsTitle={section.returnsTitle}
                    notification={section.notification}
                    notificationType={section.notificationType}
                  />
                  {section.items.map(item => (
                    <CustomPage
                      key={`documentation_${item.title}`}
                      title={item.title}
                      hasSubsection={false}
                      hasCode={item.hasCode}
                      codeTitle={item.codeTitle}
                      hasReturns={item.hasReturns}
                      returnsTitle={item.returnsTitle}
                      notification={item.notification}
                      notificationType={item.notificationType}
                      hasImage={item.hasImage}
                    />
                  ))}
                </Grid>
              ) : (
                <CustomPage
                  key={`documentation_${section.title}`}
                  title={section.title}
                  hasSubsection={true}
                  hasCode={section.hasCode}
                  codeTitle={section.codeTitle}
                  hasReturns={section.hasReturns}
                  returnsTitle={section.returnsTitle}
                  notification={section.notification}
                  notificationType={section.notificationType}
                />
              )}
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

export default Documentation;
