import React, { Component } from 'react';
import {
  Collapse,
  Grid,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { withTranslation } from 'react-i18next';

// Redux
import DocsModal from '../../../redux/containers/features/documentation/DocsModal';

// Functions
import getSections from './GetSections';

// Icons
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

const styles = {
  fixLayout: {
    height: '100%',
    bgcolor: 'primary.main',
  },
  fixListLayout: {
    height: '100%',
    bgcolor: 'primary.main',
    position: 'fixed',
    overflowY: 'scroll'
  },
  listGrid: {
    bgcolor: 'primary.main',
  },
  grid: {
    ml: 4,
    bgcolor: 'primary.main',
  },
  text: {
    color: 'white',
    marginLeft: 3,
  },
  icon: {
    color: 'white',
    marginRight: 24,
  },
};

class DocsNavbar extends Component {
  // Nested list handler
  state = {};
  handleNavbarClick = e => {
    Object.keys(this.state).forEach(key => {
      // TODO: fix this warning: This condition will always return 'true' since JavaScript compares objects by reference, not value.
      if (key !== [e]) {
        this.setState({ ...this.state, [key]: false });
      }
    });
    this.setState({ [e]: !this.state[e] });

    // Scroll
    const element = document.getElementById(e);
    if (element) {
      // Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  handleClickScroll = e => {
    const element = document.getElementById(e);
    if (element) {
      // Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  render() {
    const { t } = this.props;
    const sections = getSections();

    return (
      <Grid
        container
        flexDirection={'column'}
        sx={[styles.fixLayout, styles.grid]}>
        <Grid sx={styles.fixListLayout}>
          <List
            key={'navbar'}
            sx={styles.listGrid}
            component="nav"
            overflow="auto">
            {/* Mapping sections */}
            {sections.list.map(list => {
              return (
                <Grid key={`navbar_${list.title}`}>
                  {list.items != null ? (
                    <Grid sx={{ py: 1 }} key={`navbar_section_${list.title}`}>
                      <ListItemButton
                        onClick={this.handleNavbarClick.bind(this, list.title)}
                        dense={true}>
                        <ListItemText
                          primary={t('cws_app.documentation.' + list.title)}
                          primaryTypographyProps={styles.text}
                        />
                        {this.state[list.title] ? (
                          <IconComponent
                            iconName={'chevron-up-outline'}
                            style={styles.icon}
                          />
                        ) : (
                          <IconComponent
                            iconName={'chevron-down-outline'}
                            style={styles.icon}
                          />
                        )}
                      </ListItemButton>

                      <Collapse
                        component="li"
                        in={this.state[list.title]}
                        timeout="auto"
                        unmountOnExit>
                        <List disablePadding>
                          {/* Mapping items */}
                          {list.items.map(item => (
                            <Grid key={`navbar_item_${item.title}`}>
                              <ListItemButton
                                onClick={this.handleClickScroll.bind(
                                  this,
                                  item.title,
                                )}
                                sx={{ pl: 4, bgcolor: '#31424e' }}
                                dense={true}>
                                <ListItemText
                                  primary={t(
                                    'cws_app.documentation.' + item.title,
                                  )}
                                  primaryTypographyProps={styles.text}
                                />
                              </ListItemButton>
                            </Grid>
                          ))}
                        </List>
                      </Collapse>
                    </Grid>
                  ) : (
                    <Grid sx={{ py: 1 }} key={`navbar_section_${list.title}`}>
                      <ListItemButton
                        onClick={this.handleNavbarClick.bind(this, list.title)}
                        dense={true}>
                        <ListItemText
                          primary={t('cws_app.documentation.' + list.title)}
                          primaryTypographyProps={styles.text}
                        />
                      </ListItemButton>
                    </Grid>
                  )}
                </Grid>
              );
            })}
          </List>

          {/* Credential Request Modal */}
          <Grid sx={{ pt: 4 }}>
            <DocsModal />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation()(DocsNavbar);
