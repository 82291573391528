// @ts-nocheck
export default function DataSourceService({ baseUrl, http }) {
  const PATH_GENERIC_INFO = '/info';

  // Clients
  const PATH_CLIENTS_TYPES = '/clients/types';
  const PATH_CLIENTS_INFO = '/clients/info';
  const PATH_CLIENTS_STORES = '/clients/stores';
  const PATH_CLIENTS_MANAGEMENT = '/clients/management';
  const PATH_DOCUMENTATION_BLOBS = '/client/documentation/blobs';
  const PATH_CLIENTS_FORMATS = '/clients/formats';

  // Users
  const PATH_USERS = '/users';
  const PATH_USER_INFO = '/user/info';
  const PATH_USER_STORES_INFO = '/user/stores';
  const PATH_USER_NOTIFICATION = '/user/notification';
  const PATH_USER_CLIENTS = '/user/clients';
  const PATH_USER_SUPPLIERS = '/user/suppliers';

  // Dashboards
  const PATH_DASHBOARDS_USER_INFO = '/dashboards/user';
  const PATH_DASHBOARDS_RESUME_USER_INFO = '/dashboards/resume/user';
  const PATH_DASHBOARD_INFO = '/dashboards/info';
  const PATH_LEGACY_REPORTS = '/dashboards/legacy-reports'; // TODO: delete on deprecation of cpg

  // Stores
  const PATH_STORE_CATEGORIES = '/stores/categories';
  const PATH_STORE_BRANDS = '/stores/brands';
  const PATH_STORE_NUMBER_FORMAT = '/stores/number-formatting';

  // Tasks
  const PATH_TASK_TYPES = '/task/types';

  // Suppliers
  const PATH_SUPPLIERS_STORES = '/suppliers/stores';
  const PATH_SUPPLIERS_ACTIVE_SUBS = '/suppliers/active-sub';

  // Backend
  const PATH_BACKEND_CHAINS = '/v1/backend/chains';
  const PATH_BACKEND_TASKS = '/v1/backend/tasks';
  const PATH_BACKEND_PRODUCTS = '/v1/backend/products';
  const PATH_BACKEND_EXCEL_SOS = '/v1/backend/excel/share_of_shelf';

  // Documentation
  const PATH_TICKETS_DOCUMENTATION = '/client/tickets/documentation';

  // Digital Store
  const PATH_DIGITAL_STORE_INFO = '/digital_store/info';
  const PATH_DIGITAL_STORE_PDF = '/digital_store/pdf';
  const PATH_DIGITAL_STORE_INV = '/digital_store/inv';
  const PATH_DIGITAL_STORE_AISLE_THUMBNAIL = '/digital-store/aisle-thumbnail';
  const PATH_DIGITAL_STORE_EXCEL_BY_DATE = '/digital-store/excel-by-date';
  const PATH_DIGITAL_STORE_MAP = '/digital_store/map';
  const PATH_DIGITAL_STORE_RESUME = '/digital_store/resume';

  // Walls
  const PATH_WALLS_AISLES = '/v1/walls/aisles';
  const PATH_WALLS_IMAGES = '/v1/walls/signed-url';
  const PATH_WALLS_LANDMARKS = '/v1/walls/landmarks';
  const PATH_ALL_LANDMARKS = '/v1/walls/landmark';
  const PATH_WALL_COORDS_INFO = '/v1/walls/coords';
  const PATH_WALL_COORDS_EXCEL = '/v1/walls/excel';
  const PATH_WALL_SEARCH_PRODUCT = '/v1/walls/search';
  const PATH_WALL_EXCEL_LANDMARKS = '/v1/walls/excel/landmarks';
  const PATH_WALL_PROMOTIONS = '/v1/walls/promotions';
  const PATH_WALL_MISPLACED_PRODUCT_SIGNED_IMAGES = '/v1/walls/signed-images'

  const PATH_STORE_FLOOR = '/v1/backend/store/floors';
  const PATH_STORE_MAP = '/v1/backend/store/map';
  const PATH_ROBOT_SESSIONS = '/v1/robot_sessions';
  const PATH_ROBOT_SESSION = '/v1/detailed_session';
  const PATH_TICKETS = '/v1/tickets';

  // Tracking List
  const PATH_TRACKING_LIST = '/tracking-list';
  const PATH_TRACKING_LIST_ITEMS = '/tracking-list/items';

  //Products Status
  const PATH_PRODUCTS_STATUS = '/products_status';

  // Bruno Users
  const PATH_BRUNO_USERS = '/bruno/users';
  const PATH_BRUNO_ROLES = '/bruno/roles';
  const PATH_BRUNO_FEATURES = '/bruno/features';
  const PATH_BRUNO_USER_PROFILE = '/bruno/user/profile';
  const PATH_BRUNO_USER_FEATURES = '/bruno/user/features';

  // Notifications
  const PATH_NOTIFICATIONS = '/notifications/management';

  // Mailer
  const PATH_MAILER_CONTACT_LIST = '/clients/mailer/contacts-list';
  const PATH_MAILER_TASK_LIST = '/clients/mailer/tasks';
  const PATH_MAILER_CONTACT = '/clients/mailer/reports';

  //Products
  const PATH_PRODUCTS_ROLES = '/products/roles';

  // Translations
  const PATH_TRANSLATIONS_DICTIONARIES = '/translations/dictionaries';
  const PATH_TRANSLATIONS_APP_NAMES = '/translations/app_names';
  const PATH_TRANSLATIONS_LANGUAGES = '/translations/languages';
  const PATH_TRANSLATIONS_DICTIONARY = '/translations/dictionary';

  return {
    getGenericInfo,
    getClientsTypes,
    getClientInfo,
    getClientStores,
    getClientFormats,
    postNewClient,
    patchClient,
    getUserInfo,
    getUserStores,
    getDashboard,
    getDashboards,
    getDashboardResume,
    getPBILegacyReports, // TODO: delete on deprecation of cpg
    getUserNotifications,
    postUserNotification,
    getUsers,
    getUserClients,
    postUser,
    updateUser,
    deleteUser,
    getUserSuppliers,
    getStoreCategories,
    getStoreBrands,
    getSupplierStores,
    updateActiveSubSupplier,
    getProducts,
    getChains,
    getTasks,
    getShareOfShelfExcel,
    getDocumentationImages,
    postDocumentationCredentials,
    getTaskTypes,
    getDSInfo,
    getStoreMaps,
    getResumeDStore,
    getDigitalStorePdf,
    getDigitalStoreInv,
    getWallsAisles,
    getWallImage,
    getLandmarks,
    getLandmark,
    getCoords,
    getCoordsForExcel,
    getAisleThumbnail,
    getSearchResults,
    getExcelByDate,
    getLandmarksExcel,
    getAislePromotions,
    getMapInfo,
    getStoresFloor,
    getRobotSessions,
    getDetailedRobotSession,
    getTickets,
    postTicket,
    getTrackinglist,
    getTrackinglistItems,
    postTrackingListStores,
    uploadTrackingList,
    deleteTrackingList,
    getBrunoUsers,
    deleteBrunoUser,
    getUserRoles,
    getBrunoFeatures,
    getUserProfile,
    patchUserProfile,
    deleteUserProfile,
    getUserFeatures,
    postBrunoUser,
    getMailerContacts,
    getMailerTasks,
    getMailerContactInfo,
    createMailerContact,
    updateMailerContact,
    deleteMailerContact,
    getClientNotifications,
    postClientNotification,
    updateClientNotification,
    deleteClientNotification,
    getProductsRoles,
    getTranslationsDictionaries,
    getTranslationsAppName,
    getTranslationsLanguages,
    getTranslationsDictionary,
    patchTranslationsDictionary,
    getNumberFormats,
    getMisplacedProductSignedImages,
    getProductsStatus
  };

  function getGenericInfo({ info }) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_GENERIC_INFO}`,
      body: {
        info,
      },
    });
  }

  // Clients
  function getClientsTypes() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_CLIENTS_TYPES}`,
    });
  }

  function getClientInfo(client_id) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_CLIENTS_INFO}`,
      query: { client_id },
    });
  }

  function postNewClient(fileForm) {
    return http.postAuthenticated({
      content: 'raw',
      url: `${baseUrl}${PATH_CLIENTS_MANAGEMENT}`,
      body: fileForm,
    });
  }

  function patchClient(fileForm) {
    return http.patchAuthenticated({
      content: 'raw',
      url: `${baseUrl}${PATH_CLIENTS_MANAGEMENT}`,
      body: fileForm,
    });
  }

  function getClientStores(client_id) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_CLIENTS_STORES}`,
      query: { client_id },
    });
  }

  function getClientFormats(client_id) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_CLIENTS_FORMATS}`,
      query: { client_id },
    });
  }

  // Users
  function getUserInfo() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_USER_INFO}`,
    });
  }

  function getUserStores(client_id, user_id) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_USER_STORES_INFO}`,
      query: { client_id, user_id },
    });
  }

  function getUsers(client_id, are_zippedians_included, order_by_name) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_USERS}`,
      query: { client_id, are_zippedians_included, order_by_name },
    });
  }

  function getUserClients(user_id) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_USER_CLIENTS}`,
      query: { user_id },
    });
  }

  function postUser(body) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_USERS}`,
      body: body,
    });
  }

  function updateUser(body) {
    return http.patchAuthenticated({
      url: `${baseUrl}${PATH_USERS}`,
      body: body,
    });
  }

  function deleteUser(user_id, email, to_revoke) {
    return http.deleteAuthenticated({
      url: `${baseUrl}${PATH_USERS}`,
      body: { user_id, email, to_revoke },
    });
  }

  function getUserNotifications() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_USER_NOTIFICATION}`,
    });
  }

  function postUserNotification(notification_id, seen_timestamp) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_USER_NOTIFICATION}`,
      body: { notification_id, seen_timestamp },
    });
  }

  function getUserSuppliers(client_id, chains, subscriptions, store_code = null, category = null) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_USER_SUPPLIERS}`,
      query: { client_id, chains, subscriptions, store_code, category },
    });
  }

  // Dashboards
  function getDashboard({
    dashboard_id,
    client_id,
    stores,
    start_date,
    end_date,
    categories,
    category_level,
    sub_categories,
    sub_category_level,
    suppliers,
    supplier_id,
    store_types,
  }) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_DASHBOARD_INFO}`,
      body: {
        dashboard_id,
        client_id,
        stores,
        start_date,
        end_date,
        categories,
        category_level,
        sub_categories,
        sub_category_level,
        suppliers,
        supplier_id,
        store_types,
      },
    });
  }

  function getDashboardResume({
    dashboard_id,
    client_id,
    stores,
    supplier_id,
  }) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_DASHBOARDS_RESUME_USER_INFO}`,
      body: { dashboard_id, client_id, stores, supplier_id },
    });
  }

  function getDashboards(client_id, supplier_id, stores) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_DASHBOARDS_USER_INFO}`,
      body: { client_id, supplier_id, stores },
    });
  }
  // TODO: delete on deprecation of cpg
  function getPBILegacyReports(client_id) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_LEGACY_REPORTS}`,
      query: { client_id },
    });
  }

  // Stores
  function getStoreCategories(store_id, client_id, can_get_sub_categories, supplier_id = null) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_STORE_CATEGORIES}`,
      query: { store_id, client_id, can_get_sub_categories, supplier_id },
    });
  }

  function getStoreBrands(store_id) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_STORE_BRANDS}`,
      query: { store_id },
    });
  }

  function getNumberFormats(client_id) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_STORE_NUMBER_FORMAT}`,
      query: { client_id },
    })
  }

  // Tasks
  function getTaskTypes(client_id) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_TASK_TYPES}`,
      query: { client_id },
    });
  }

  // Documentation
  function getDocumentationImages(client_name, client_id) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_DOCUMENTATION_BLOBS}`,
      query: { client_name, client_id },
    });
  }

  function postDocumentationCredentials(email, description, client_id) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_TICKETS_DOCUMENTATION}`,
      query: { email, description, client_id },
    });
  }

  // Suppliers
  function getSupplierStores(supplier_id = null) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_SUPPLIERS_STORES}`,
      query: { supplier_id },
    });
  }

  function updateActiveSubSupplier(supplier_id = null, active_sub = null) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_SUPPLIERS_ACTIVE_SUBS}`,
      body: { supplier_id, active_sub },
    });
  }

  // Backend
  function getChains(chain_id = null) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_BACKEND_CHAINS}`,
      query: { chain_id },
    });
  }

  function getTasks(task_id = null) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_BACKEND_TASKS}`,
      query: { task_id },
    });
  }

  function getProducts(product_id = null) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_BACKEND_PRODUCTS}`,
      query: { product_id },
    });
  }

  function getShareOfShelfExcel({ supplier_id, rubro_name }) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_BACKEND_EXCEL_SOS}`,
      query: { supplier_id, rubro_name },
    });
  }

  // Digital Store
  /**
   * All digital store info as a map and tasks information
   * @param  	{Date} 				  date 			     target date
   * @param  	{Number} 				sub_category_level number of subcategory level
   * @param  	{Number} 				store_id       store's id
   * @param  	{String} 				task_type      type of task (e.g. 'price diference')
   * @param  	{Number} 				client_id      client's id
   * @param  	{Number} 				category_i 	   number of category
   * @param  	{Number} 				supplier_id 	 Supplier's id
   * @param  	{Boolean} 			include_map 	 Boolean to include map in the response
   * @param  	{String} 				session        (optional) store's session
   * @param  	{Number} 				fixed_width    (optional) Set the width of the map
   * @param  	{Object} 				mapInfoRequest (optional) info of the before request to get the map (use when the map is not in the response)
   */
  function getDSInfo(form) {
    let parsedForm = {};
    Object.entries(form).forEach(([keyForm, valuesForm]) => {
      parsedForm = Object.assign(
        parsedForm,
        valuesForm === null ? null : { [keyForm]: valuesForm },
      );
    });
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_DIGITAL_STORE_INFO}`,
      query: parsedForm,
    });
  }

  function getStoreMaps(form) {
    let parsedForm = {};
    Object.entries(form).forEach(([keyForm, valuesForm]) => {
      parsedForm = Object.assign(
        parsedForm,
        valuesForm === null ? null : { [keyForm]: valuesForm },
      );
    });
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_DIGITAL_STORE_MAP}`,
      body: parsedForm,
    });
  }

  function getResumeDStore(taskData, taskType, taskGrouping) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_DIGITAL_STORE_RESUME}`,
      body: { task_data: taskData, task_type: taskType, task_grouping: taskGrouping },
    });
  }

  /**
   * Get the thumbail for an aisle
   * @param  	{String} 	store_id 	Store's ID
   * @param  	{String} 	aisle 		Aisle name
   * @param  	{String} 	session 	Session of the requested aisle
   * @return  {Object}						Dictionary with the response message, url, scale, width and height
   */
  function getAisleThumbnail(client_id, store_id, aisle, session) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_DIGITAL_STORE_AISLE_THUMBNAIL}`,
      query: { client_id, store_id, aisle, session },
    });
  }

  /**
   * Get the pdf related to the task selected in digital store view
   * @param  	{Number} 				store_id              store's id
   * @param  	{Number} 				client_id             client's id
   * @param  	{String} 				r_type                Type of the task
   * @param  	{String} 				signed_logo           Logo of the client
   * @param  	{String} 				report_title          Title of the report
   * @param  	{String} 				robot_header          Number of robot's header
   * @param  	{Array} 				report_columns        Columns of the report
   * @param  	{Array} 				report_columns_names  Columns names of the report
   * @param  	{Date} 				  selected_date         target date
   * @param  	{JSON} 				  stats                 BI stats
   * @param  	{JSON} 				  table_info            All the info of the table
   * @param  	{JSON} 				  aisles_client_names   Translated aisles names
   * @param  	{String} 				language              (optional) Language of the report
   * @param  	{JSON} 				  categories            (optional) Categories of the store
   * @param  	{JSON} 				  sub_categories        (optional)  Subcategories of the store
   */
  function getDigitalStorePdf(form) {
    let parsedForm = {};
    Object.entries(form).forEach(([keyForm, valuesForm]) => {
      parsedForm = Object.assign(
        parsedForm,
        valuesForm === null ? null : { [keyForm]: valuesForm },
      );
    });
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_DIGITAL_STORE_PDF}`,
      body: parsedForm,
    });
  }

  /**
   * Get the INV related to the store selected in digital store view
   * @param  	{Number} 				store_id              store's id
   * @param  	{Number} 				client_id             client's id
   * @param  	{String} 				r_type                Type of the task
   * @param  	{String} 				signed_logo           Logo of the client
   * @param  	{String} 				report_title          Title of the report
   * @param  	{String} 				robot_header          Number of robot's header
   * @param  	{Array} 				report_columns        Columns of the report
   * @param  	{Array} 				report_columns_names  Columns names of the report
   * @param  	{Date} 				  selected_date         target date
   * @param  	{JSON} 				  stats                 BI stats
   * @param  	{JSON} 				  table_info            All the info of the table
   * @param  	{String} 				language              (optional) Language of the report
   */
  function getDigitalStoreInv(form) {
    let parsedForm = {};
    Object.entries(form).forEach(([keyForm, valuesForm]) => {
      parsedForm = Object.assign(
        parsedForm,
        valuesForm === null ? null : { [keyForm]: valuesForm },
      );
    });
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_DIGITAL_STORE_INV}`,
      body: parsedForm,
    });
  }

  /**
   * Get the data for the excel download restricted by date and task type
   * @param  	{String} 	client_id 	  Client's ID
   * @param  	{String} 	supplier_id 	Supplier's ID
   * @param  	{String} 	store_id 		  Store's ID
   * @param  	{String} 	task_type 		Type of task (e.g. 'price_diference')
   * @param  	{String} 	category 		  [Optional] Category
   * @param  	{String} 	start_time 		Start time of the requested data
   * @param  	{String} 	end_time 		  End time of the requested data
   * @return  {Object}						    Dictionary with the tasks
   */
  function getExcelByDate(form) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_DIGITAL_STORE_EXCEL_BY_DATE}`,
      query: { ...form },
    });
  }

  // Walls
  function getWallsAisles(store, supplier_id, alerts_info, date, floor_number) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_WALLS_AISLES}`,
      query: { store, supplier_id, alerts_info, date, floor_number },
    });
  }

  function getWallImage(store, supplier_id, session, aisle, lang) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_WALLS_IMAGES}`,
      query: { store, supplier_id, session, aisle, lang },
    });
  }

  function getLandmarks(store, aisle_code, session) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_WALLS_LANDMARKS}`,
      query: { store, aisle_code, session },
    });
  }

  function getLandmark(store, supplier_id, session) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_ALL_LANDMARKS}`,
      query: { store, supplier_id, session },
    });
  }

  function getCoords(
    store_id,
    supplier_id,
    session,
    aisle,
    lang,
    client_id,
    supplier_info,
  ) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_WALL_COORDS_INFO}`,
      query: { store_id, supplier_id, session, aisle, lang, client_id, supplier_info },
    });
  }

  function getCoordsForExcel(
    store_id,
    supplier_id,
    session,
    date,
    lang,
    supplier_info,
  ) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_WALL_COORDS_EXCEL}`,
      query: { store_id, supplier_id, session, date, lang, supplier_info },
    });
  }

  function getSearchResults(
    store,
    supplier_id,
    product,
    days,
    limit,
  ) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_WALL_SEARCH_PRODUCT}`,
      query: { store, supplier_id, product, days, limit },
    });
  }

  function getLandmarksExcel(store, supplier_id) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_WALL_EXCEL_LANDMARKS}`,
      query: { store, supplier_id },
    });
  }

  function getAislePromotions(store, supplier_id, aisle_code, session) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_WALL_PROMOTIONS}`,
      query: { store, supplier_id, aisle_code, session },
    });
  }

  function getMisplacedProductSignedImages(store, blobs) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_WALL_MISPLACED_PRODUCT_SIGNED_IMAGES}`,
      body: { store, blobs },
    });
  }

  function getMapInfo(store_code) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_STORE_MAP}`,
      query: { store_code },
    });
  }

  function getStoresFloor(store_code) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_STORE_FLOOR}`,
      query: { store_code },
    });
  }

  function getRobotSessions(
    codes,
    initial_date,
    final_date,
    simplified = true,
  ) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_ROBOT_SESSIONS}`,
      query: { codes, initial_date, final_date, simplified },
    });
  }

  function getDetailedRobotSession(session_id) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_ROBOT_SESSION}`,
      query: { session_id },
    });
  }

  function getTickets(
    ticket_type,
    store_codes,
    client_id,
    chain_country_id,
    supplier_id,
    ticket_date,
  ) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_TICKETS}`,
      query: {
        ticket_type,
        store_codes,
        client_id,
        chain_country_id,
        supplier_id,
        ticket_date,
      },
    });
  }

  function postTicket(
    ticket_type,
    client_id,
    chain_country_id,
    store_codes,
    ticket_form,
    selected_type,
  ) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_TICKETS}`,
      body: {
        ticket_type,
        client_id,
        chain_country_id,
        store_codes,
        ticket_form,
        selected_type,
      },
    });
  }

  // Tracking List
  function getTrackinglist(supplier_id) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_TRACKING_LIST}`,
      query: { supplier_id },
    });
  }

  // Products Status
  /**
   * Get the last state of products in a stores with a supplier and a category
   * @param  	{String} 				  store          store to find last state products
   * @param  	{Array} 				supplier_ids     supplier ids to find last state products
   * @param  	{Array} 				categories       categories to find last state products
   * @param  	{Array} 				products_status  status of the products you want to search
   *                                           (e.g. ["Disponible en góndola","Stock desajustado","Stock insuficiente","Producto en bodega","No implementado"])
   */
  function getProductsStatus(store, supplier_ids, categories, products_status) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_PRODUCTS_STATUS}`,
      body: { store, supplier_ids, categories, products_status },
    });
  }

  function getTrackinglistItems(track_list_id, active) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_TRACKING_LIST_ITEMS}`,
      query: { track_list_id, active },
    });
  }

  function postTrackingListStores(tracking_list_id, stores, item, ean, active) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_SUPPLIERS_STORES}`,
      body: { tracking_list_id, stores, item, ean, active },
    });
  }

  /**
   * Upload a tracking list through Bifrost
   * @param  	{Object} 	form    Form data with tracking_list_id and file (uploaded .xlsx file)
   * @return  {Object}					Dictionary with the response message and status (code)
   */
  function uploadTrackingList(form) {
    return http.putAuthenticated({
      content: 'raw',
      url: `${baseUrl}${PATH_TRACKING_LIST}`,
      body: form,
    });
  }

  /**
   * Delete a tracking list through Bifrost
   * @param  	{Object} 	body 	Dictionary with tracking_list_id
   * @return  {Object}			  Dictionary with the response message and status (code)
   */
  function deleteTrackingList(body) {
    return http.deleteAuthenticated({
      url: `${baseUrl}${PATH_TRACKING_LIST}`,
      body: body,
    });
  }

  // Bruno Users
  function getBrunoUsers() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_BRUNO_USERS}`,
    });
  }

  function deleteBrunoUser(user_id, email, to_revoke) {
    return http.deleteAuthenticated({
      url: `${baseUrl}${PATH_BRUNO_USERS}`,
      body: { user_id, email, to_revoke },
    });
  }

  function getUserRoles() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_BRUNO_ROLES}`,
    });
  }

  function getBrunoFeatures() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_BRUNO_FEATURES}`,
    });
  }

  function getUserProfile(user_id) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_BRUNO_USER_PROFILE}`,
      query: { user_id },
    });
  }

  function patchUserProfile(body) {
    return http.patchAuthenticated({
      url: `${baseUrl}${PATH_BRUNO_USER_PROFILE}`,
      body: body,
    });
  }

  function deleteUserProfile(body) {
    return http.deleteAuthenticated({
      url: `${baseUrl}${PATH_BRUNO_USER_PROFILE}`,
      body: body,
    });
  }

  function getUserFeatures(user_id) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_BRUNO_USER_FEATURES}`,
      query: { user_id },
    });
  }

  function postBrunoUser(body) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_BRUNO_USERS}`,
      body: body,
    });
  }

  // Notifications
  function getClientNotifications(notification_id) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_NOTIFICATIONS}`,
      query: { notification_id },
    });
  }

  function postClientNotification(
    title,
    message_txt,
    clients_info,
    chains_country_info,
    stores_info,
    to_users_info,
    notification_type,
  ) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_NOTIFICATIONS}`,
      body: {
        title,
        message_txt,
        clients_info,
        chains_country_info,
        stores_info,
        to_users_info,
        notification_type,
      },
    });
  }

  function updateClientNotification(
    notification_id,
    title,
    message_txt,
    clients_info,
    chains_country_info,
    stores_info,
    to_users_info,
    notification_type,
  ) {
    return http.putAuthenticated({
      url: `${baseUrl}${PATH_NOTIFICATIONS}`,
      body: {
        notification_id,
        title,
        message_txt,
        clients_info,
        chains_country_info,
        stores_info,
        to_users_info,
        notification_type,
      },
    });
  }

  function deleteClientNotification(notification_id) {
    return http.deleteAuthenticated({
      url: `${baseUrl}${PATH_NOTIFICATIONS}`,
      body: { notification_id },
    });
  }

  // Mailer
  function getMailerContacts() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_MAILER_CONTACT_LIST}`,
    });
  }

  function getMailerTasks() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_MAILER_TASK_LIST}`,
    });
  }

  function getMailerContactInfo(contact_id) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_MAILER_CONTACT}`,
      query: { contact_id },
    });
  }

  function createMailerContact(name, email, tasks, stores) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_MAILER_CONTACT}`,
      body: { name, email, tasks, stores },
    });
  }

  function updateMailerContact(contact_id, name, tasks, stores) {
    return http.putAuthenticated({
      url: `${baseUrl}${PATH_MAILER_CONTACT}`,
      body: { contact_id, name, tasks, stores },
    });
  }

  function deleteMailerContact(contact_id) {
    return http.deleteAuthenticated({
      url: `${baseUrl}${PATH_MAILER_CONTACT}`,
      body: { contact_id },
    });
  }

  // Products
  function getProductsRoles() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_PRODUCTS_ROLES}`,
    });
  }
  // Translations
  function getTranslationsDictionaries() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_TRANSLATIONS_DICTIONARIES}`,
    });
  }

  function getTranslationsAppName() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_TRANSLATIONS_APP_NAMES}`,
    });
  }

  function getTranslationsLanguages(kind_name) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_TRANSLATIONS_LANGUAGES}`,
      query: { kind_name },
    });
  }

  function getTranslationsDictionary(kind_base, kind_name, language_code) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_TRANSLATIONS_DICTIONARY}`,
      query: { kind_base, kind_name, language_code },
    });
  }

  function patchTranslationsDictionary(
    kind_base,
    kind_name,
    language_code,
    new_translations,
  ) {
    return http.patchAuthenticated({
      url: `${baseUrl}${PATH_TRANSLATIONS_DICTIONARY}`,
      body: { kind_base, kind_name, language_code, new_translations },
    });
  }
}
