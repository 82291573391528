import { createReducer } from './utils';
import {
  handleRequest,
  handleAvailable,
  handleUnavailable,
} from '../reducerHandlers';
import {
  TRANSLATIONS_REQUEST,
  TRANSLATIONS_AVAILABLE,
  TRANSLATIONS_UNAVAILABLE,
  SNACK_SET,
} from './constants';

// Reducer

const defaultState = {
  loading_dictionaries: false,
  loading_app_names: false,
  loading_languages: false,
  loading_translations: false,

  dictionaries: {},
  appNames: {},
  languages: {},
  translations: [],

  updateTranslations: {},
  newTranslation: {},
};

export const reducer = createReducer(defaultState, {
  [TRANSLATIONS_REQUEST]: handleRequest,
  [TRANSLATIONS_AVAILABLE]: handleAvailable,
  [TRANSLATIONS_UNAVAILABLE]: handleUnavailable,
});

// Actions

export function getTranslationsDictionaries() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'dictionaries';
    const loadingName = 'loading_dictionaries';
    dispatch({
      type: TRANSLATIONS_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      const response = await dataSource.getTranslationsDictionaries();
      dispatch({
        type: TRANSLATIONS_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response.dictionaries,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: TRANSLATIONS_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: [],
        },
      });
      const snack = {
        open: true,
        message: 'There was an error getting the translation dictionaries.',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function getTranslationsAppname() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'appNames';
    const loadingName = 'loading_app_names';
    dispatch({
      type: TRANSLATIONS_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      const response = await dataSource.getTranslationsAppName();
      dispatch({
        type: TRANSLATIONS_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response.app_names,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: TRANSLATIONS_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: [],
        },
      });
      const snack = {
        open: true,
        message: 'There was an error getting the translation appnames.',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function getTranslationsLanguages(kind_name) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'languages';
    const loadingName = 'loading_languages';
    dispatch({
      type: TRANSLATIONS_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      const response = await dataSource.getTranslationsLanguages(kind_name);
      dispatch({
        type: TRANSLATIONS_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response.languages,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: TRANSLATIONS_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: [],
        },
      });
      const snack = {
        open: true,
        message: 'There was an error getting the translation languages.',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function getTranslationsDictionary(kind_base, kind_name, language_code) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'translations';
    const loadingName = 'loading_translations';
    dispatch({
      type: TRANSLATIONS_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      const response = await dataSource.getTranslationsDictionary(
        kind_base,
        kind_name,
        language_code,
      );
      response.translations.forEach((element, index) => {
        element['id'] = index + 1;
      });

      dispatch({
        type: TRANSLATIONS_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response.translations,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: TRANSLATIONS_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: [],
        },
      });
      const snack = {
        open: true,
        message: 'There was an error getting the translation list.',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function updateTranslations(base_name, new_translation) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    const {
      translations: { updateTranslations },
    } = getState();
    const keyState = 'updateTranslations';

    try {
      const newDictionary = Object.assign({}, updateTranslations);
      newDictionary[base_name] = new_translation;

      dispatch({
        type: TRANSLATIONS_AVAILABLE,
        payload: { keyState: keyState, data: newDictionary },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: TRANSLATIONS_UNAVAILABLE,
        payload: { keyState: keyState, defaultState: [] },
      });
      const snack = {
        open: true,
        message: 'There was an error adding the new translation.',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function addNewTranslations(base_name, new_translation, id) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    const {
      translations: { newTranslation },
    } = getState();
    const keyState = 'newTranslation';

    try {
      const newDictionary = Object.assign({}, newTranslation);
      newDictionary[id] = {
        key: base_name,
        value: new_translation,
      };

      dispatch({
        type: TRANSLATIONS_AVAILABLE,
        payload: { keyState: keyState, data: newDictionary },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: TRANSLATIONS_UNAVAILABLE,
        payload: { keyState: keyState, defaultState: [] },
      });
      const snack = {
        open: true,
        message: 'There was an error adding the new translation.',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function deleteNewTranslations(id) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    const {
      translations: { newTranslation },
    } = getState();
    const keyState = 'newTranslation';

    try {
      const newDictionary = Object.assign({}, newTranslation);
      delete newDictionary[id];

      dispatch({
        type: TRANSLATIONS_AVAILABLE,
        payload: { keyState: keyState, data: newDictionary },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: TRANSLATIONS_UNAVAILABLE,
        payload: { keyState: keyState, defaultState: [] },
      });
      const snack = {
        open: true,
        message: 'There was an error adding the new translation.',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function patchNewTranslations(dictName, appName, language) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    const {
      translations: { newTranslation, updateTranslations },
    } = getState();

    try {
      var formattedData = {};

      Object.keys(newTranslation).forEach(id => {
        formattedData[newTranslation[id]['key']] = newTranslation[id]['value'];
      });

      Object.keys(updateTranslations).forEach(key => {
        formattedData[key] = updateTranslations[key];
      });

      await dataSource.patchTranslationsDictionary(
        dictName,
        appName,
        language,
        JSON.stringify(formattedData),
      );
    } catch (error) {
      console.log('error: ', error);
      const snack = {
        open: true,
        message: 'There was an error adding the new translation.',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}
