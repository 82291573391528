import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-roboto';
import './index.css';
import "@zippeditoolsjs/dashboards/dist/styles.css";
import App from './app/App';
import reportWebVitals from './reportWebVitals';

// Language services
import { i18n, initI18n } from '@zippeditoolsjs/translations';
import { I18nextProvider } from 'react-i18next';

import en from './i18n/en.json';
import es from './i18n/es.json';
import de from './i18n/de.json';
import ja from './i18n/ja.json';

initI18n(en, es, null, { de, ja });

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
