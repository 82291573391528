import { useEffect, useState, } from 'react';
import {
	Button,
	Card,
	CardHeader,
	Checkbox,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Typography,
} from '@mui/material';

function not(a, b) {
	return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
	return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
	return [...a, ...not(b, a)];
}

export default function TransferListComponent(props) {
	const {
		t,
		row,
		handleClose,
		storesNames,
		handleEditStores,
	} = props;
	const [checked, setChecked] = useState([]);
	const [left, setLeft] = useState([]);
	const [right, setRight] = useState([]);

	const leftChecked = intersection(checked, left);
	const rightChecked = intersection(checked, right);

	const handleToggle = (value) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	const numberOfChecked = (items) => intersection(checked, items).length;

	const handleToggleAll = (items) => () => {
		if (numberOfChecked(items) === items.length) {
			setChecked(not(checked, items));
		} else {
			setChecked(union(checked, items));
		}
	};

	const handleCheckedRight = () => {
		setRight(right.concat(leftChecked));
		setLeft(not(left, leftChecked));
		setChecked(not(checked, leftChecked));
	};

	const handleCheckedLeft = () => {
		setLeft(left.concat(rightChecked));
		setRight(not(right, rightChecked));
		setChecked(not(checked, rightChecked));
	};

	const customList = (title, items) => (
		<Card sx={{ width: 300, }}>
			<CardHeader
				avatar={
					<Checkbox
						color={'secondary'}
						onClick={handleToggleAll(items)}
						checked={numberOfChecked(items) === items.length && items.length !== 0}
						indeterminate={
							numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
						}
						disabled={items.length === 0}
						inputProps={{ 'aria-label': 'all items selected', }}
					/>
				}
				title={<Typography sx={{ fontSize: 18, }}> {title} </Typography>}
				subheader={
					<Typography sx={{ fontSize: 14, }}>
						{numberOfChecked(items)}/{items.length} selected
					</Typography>
				}
			/>
			<Divider />
			<List dense component="div" role="list"
				sx={{
					p: 1,
					width: '20%',
					minWidth: 300,
					height: 300,
					overflow: 'auto',
					bgcolor: 'background.paper',
				}}
			>
				{items.map((value) => {
					const labelId = `transfer-list-all-item-${value}-label`;
					return (
						<ListItem
							key={value}
							role="listitem"
							ListItemButton
							onClick={handleToggle(value)}
						>
							<ListItemIcon>
								<Checkbox
									color={'secondary'}
									checked={checked.indexOf(value) !== -1}
									tabIndex={-1}
									disableRipple
									inputProps={{ 'aria-labelledby': labelId, }}
								/>
							</ListItemIcon>
							<ListItemText id={labelId}
								primary={
									<Typography sx={{ fontSize: 14, color: '#000' }}>
										{value} {storesNames[value]}
									</Typography>
								}
							/>
						</ListItem>
					);
				})}
			</List>
		</Card>
	);

	useEffect(() => {
		if (row && Object.keys(row).length > 0) {
			let newLeft = [];
			let newRight = [];
			Object.keys(row.active).forEach((key) => {
				if (row.active[key]) {
					newRight.push(key);
				} else {
					newLeft.push(key);
				}
			});
			setLeft(newLeft);
			setRight(newRight);
		}
	}, [row]);

	const handleSaveLists = (right, left) => {
		handleEditStores(right, left);
	}

	return (
		<Grid container direction="column" spacing={2} sx={{ px: 2, py: 1 }}>
			<Grid item>
				<Typography
					sx={{
						mt: 1,
						fontSize: 30,
						fontWeight: "40px"
					}}
				>
					{t('cws_app.tracking.Modify_stores')}
				</Typography>
				<Typography
					sx={{
						my: 1,
						fontSize: 18,
						fontWeight: "40px"
					}}
				>
					{t('cws_app.tracking.update_stores') + " " + row.name}
				</Typography>
			</Grid>
			<Divider variant="middle" sx={{ mx: 2, }} component="div" />
			<Grid item xs={12} container spacing={2} justifyContent="center" alignItems="center">
				<Grid item>{customList(t('cws_app.tracking.No_active_stores'), left)}</Grid>
				<Grid item>
					<Grid container direction="column" alignItems="center">
						<Button
							sx={{ my: 0.5 }}
							variant="outlined"
							onClick={handleCheckedRight}
							disabled={leftChecked.length === 0}
							aria-label="move selected right"
						>
							&gt;
						</Button>
						<Button
							sx={{ my: 0.5 }}
							variant="outlined"
							onClick={handleCheckedLeft}
							disabled={rightChecked.length === 0}
							aria-label="move selected left"
						>
							&lt;
						</Button>
					</Grid>
				</Grid>
				<Grid item>{customList(t('cws_app.tracking.Active_stores'), right)}</Grid>
			</Grid>
			<Grid item xs>
				<Grid container direction="row" justifyContent="space-between">
					<Button
						sx={{ mt: 1, textTransform: 'none' }}
						variant="contained"
						onClick={handleClose}
						aria-label="cancel"
						color="error"
					>
						{t('cws_app.general.cancel')}
					</Button>
					<Button
						sx={{ mt: 1, textTransform: 'none' }}
						variant="contained"
						onClick={() => { handleSaveLists(right, left) }}
						aria-label="save"
						color='primary'
					>
						{t('cws_app.tracking.save_changes')}
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
}