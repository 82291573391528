import {
  Button,
  Box,
  Grid,
  Modal,
  Typography,
  TextField,
  CircularProgress,
} from '@mui/material';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

// Icons
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

const styles = {
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
  },
  description: {
    textAlign: 'justify',
    mt: 1,
  },
  space: {
    mt: 3,
  },
  button: {
    color: 'primary.main',
    borderColor: 'white',
    bgcolor: 'white',
  },
};

function debounce(func, timeout = 500) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

class DocsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opne: false,
      description: '',
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleInputChange = e => {
    this.setState({ description: e.target.value });
  };

  handleDebounce = debounce(e => this.handleInputChange(e));

  render() {
    const {
      requestDocumentationCredentials,
      getUserInfo,
      isCredentialLoading,
      t,
    } = this.props;
    const { open, description } = this.state;

    return (
      <Grid container sx={{ px: 5 }}>
        <Button
          variant="outlined"
          sx={styles.button}
          onClick={() => this.handleOpen()}>
          {t('cws_app.documentation.request_credentials', 'Request Credentials')}
        </Button>

        <Modal open={open} onClose={() => this.handleClose()}>
          <Box sx={styles.modalContainer}>
            <Typography sx={{ fontWeight: 'bold' }} variant="h5">
              {t('cws_app.documentation.request_credentials', 'Request Credentials')}
            </Typography>
            <Grid sx={styles.space}>
              <Typography sx={styles.description}>
                We reserve the right to grant permissions and decide which
                accounts are created. However, if you want to request
                credentials please add some information:
              </Typography>

              <Typography sx={[styles.description, { fontWeight: 'bold' }]}>
                Motivation, Expected usage (objectives and approximated
                workload), Contact.
              </Typography>
            </Grid>

            <Grid sx={styles.space}>
              <Typography sx={{ mb: 2 }}>
                <strong>From: </strong>
                {getUserInfo['email']}
              </Typography>

              <TextField
                id="outlined-multiline-static"
                fullWidth
                multiline
                placeholder="Add any information"
                onChange={e => this.handleDebounce(e)}
              />
            </Grid>

            <Grid sx={{ mt: 2 }} container justifyContent="flex-end">
              <Button
                variant="contained"
                onClick={() => requestDocumentationCredentials(description)}
                disabled={isCredentialLoading}
                endIcon={
                  isCredentialLoading ? (
                    <CircularProgress
                      sx={{ color: 'white' }}
                      size={20}
                      thickness={5}
                    />
                  ) : (
                    <IconComponent
                      iconName="send-outline"
                      style={styles.icon}
                    />
                  )
                }>
                Send
              </Button>
            </Grid>
          </Box>
        </Modal>
      </Grid>
    );
  }
}

export default withTranslation()(DocsModal);
