import React, { useEffect, useState } from 'react'
import { Badge, Card, CardActionArea, CardActions, CardContent, Grid, Pagination, Paper, Tooltip, Typography, useTheme } from '@mui/material'
import { UpcScan } from 'react-bootstrap-icons';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { lowerCaseAllWordsExceptFirstLetters } from 'core/utils/parsers';
import Canvas from '../../tools/Canvas';
import CloseButton from '../../tools/CloseButton'
import SearchBar from '../../tools/SearchBar';
import { colorBasedOnAlert, colorBasedOnTheme, highlightedProductColor } from '../../utils/colors';
import { convertMeterToPixel, getRobotIndex } from '../../utils/converters';
import { isEmptyOrUndefined } from "core/utils/validators";
import PageLink from '../../tools/PageLink';
import { getConsolidatedTranslations } from 'core/utils/parsers';

export default function Sidenav(props) {
  const {
    t,
    setIsSidenavOpen,
    selectedAlert,
    setSelectedAlert,
    getAisleThumbnail,
    selectedClient,
    isLoadingAisleThumbnail,
    aisleThumbnail,
    selectedStore,
    date,
    aisleLandmarkMap,
    aislesList,
    height,
    sidenavRef,
    robotSession,
  } = props;
  const theme = useTheme();
  const [products, setProducts] = useState([]);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [highlightedProduct, setHighlightedProduct] = useState({});
  const [aisleSections, setAisleSections] = useState({}); // Total number of sections (tramos) in the aisle and an array with all, sorted by page
  const [page, setPage] = useState(1);
  const [sectionRange, setSectionRange] = useState({});
  const [currentAlert, setCurrentAlert] = useState({});

  // Lifecycle methods

  // Convert date to ISO 8601 format (considering the time zone) and get the thumbnail
  useEffect(() => {
    // The second comparison is to not fetch the thumbnail again if it's the same aisle
    if (selectedStore && currentAlert?.aisle_name !== selectedAlert?.aisle_name) {
      const session = selectedAlert?.alertList[0]?.session;
      getAisleThumbnail(selectedClient?.client_id, selectedStore?.store_id, selectedAlert?.aisle_name, session)
      setCurrentAlert(selectedAlert)
    }
  }, [selectedAlert])

  // Reset product list and clicked product
  useEffect(() => {
    setProducts(selectedAlert?.alertList)
    setHighlightedProduct({})
    // Set the range of meters in which the section (tramo) belongs to
    setSectionRange({ from: 3 * (selectedAlert?.tramo - 1), to: 3 * selectedAlert?.tramo }) // Formula: [3 * (n - 1), 3 * n]
  }, [selectedAlert])

  // Filter the product's list based on the searchBar
  useEffect(() => {
    if (typeof value === 'string' || typeof inputValue === 'string') {
      const filteredProducts = selectedAlert?.alertList.filter(product => product?.description?.toLowerCase().includes(inputValue?.toLowerCase()))
      setProducts(filteredProducts)
    }
  }, [value, inputValue])

  // Total sections in the aisle and dict with the sections for the pagination and metadata at the top of the thumbnail
  useEffect(() => {
    let sections = { totalSections: 0, sections: {} };
    if (aisleLandmarkMap && selectedAlert) {
      sections['totalSections'] = aisleLandmarkMap[selectedAlert['aisle_name']].length
      for (let index = 0; index < sections['totalSections']; index++) {
        const section = aislesList[aisleLandmarkMap[selectedAlert['aisle_name']][index]]
        sections['sections'][index + 1] = section // + 1 so it matches the pagination number
        // Jump to the corresponding page, that matches the selected section (tramo)
        if (section?.tramo === selectedAlert['tramo']) {
          setPage(index + 1)
        }
      }
      setAisleSections(sections)
    }
  }, [selectedAlert])

  // Methods

  // Draw every a dot on the thumbnail for every product with an alert
  const drawDots = (ctx) => {
    let minPositionX = 1000000; // Number that's bigger than any thumbnail size for the automatic scroll
    selectedAlert?.alertList.forEach(alert => {
      const [x, y] = convertMeterToPixel(alert?.location_x, alert?.location_z, aisleThumbnail?.scale)
      drawCircle(ctx, x, y, 3, colorBasedOnAlert(alert?.task_type))
      minPositionX = x < minPositionX ? x : minPositionX;
    })

    // Scroll to the position of the product closest to the origin in the x-axis
    const thumbnailScroll = document.getElementById("thumbnailScroll");
    if (minPositionX && thumbnailScroll) {
      thumbnailScroll.scrollTo(minPositionX, 0);
    }

    // Change the color of the product if it's clicked
    if (!isEmptyOrUndefined(highlightedProduct, 'dict')) {
      const [x, y] = convertMeterToPixel(highlightedProduct?.location_x, highlightedProduct?.location_z, aisleThumbnail?.scale)
      drawCircle(ctx, x, y, 3, highlightedProductColor(highlightedProduct?.task_type))
    }
  }

  function drawCircle(ctx, x, y, radius, fill, stroke, strokeWidth) {
    ctx.beginPath()
    ctx.arc(x, y, radius, 0, 2 * Math.PI, false)
    if (fill) {
      ctx.fillStyle = fill
      ctx.fill()
    }
    if (stroke) {
      ctx.lineWidth = strokeWidth
      ctx.strokeStyle = stroke
      ctx.stroke()
    }
  }

  // Scroll to the position of the clicked product
  const handleClick = (alert) => {
    setHighlightedProduct(alert)
    const thumbnailScroll = document.getElementById("thumbnailScroll");
    const coordinates = convertMeterToPixel(alert?.location_x, null, aisleThumbnail?.scale)
    if (thumbnailScroll && coordinates) {
      thumbnailScroll.scrollTo(coordinates[0], 0);
      scrollToTop();
    }
  }

  // Scroll the whole sidenav component to the top
  const scrollToTop = () => {
    sidenavRef.current.scroll({
      top: 0,
      behavior: "smooth"
    });
  };

  const handlePaginagionChange = (_, value) => {
    setPage(value);
    setSelectedAlert(aisleSections?.sections[value])
  }

  return (
    <Paper ref={sidenavRef} sx={{ position: 'relative', width: { xs: '100vh', md: '30%' }, height: height, ml: { xs: 0, md: 1 }, mt: { xs: 1, md: 0 }, px: 2, color: colorBasedOnTheme(theme, 'color-darkTheme'), bgcolor: colorBasedOnTheme(theme, 'bgcolor-darkTheme'), overflow: 'auto' }}>
      {/* Header */}
      <Grid container item justifyContent='flex-end' alignItems='center' sx={{ position: 'absolute', right: '1%' }}>
        <CloseButton setIsOpen={setIsSidenavOpen} isColorbgAlwaysDark={true} />
      </Grid>
      {/* Aisle title */}
      <Grid container item justifyContent='center' mt={4}>
        <Typography variant='h4'>{getConsolidatedTranslations(t, selectedClient?.name, 'aisle', 'general', 'Aisle')} {selectedAlert['client_aisle_name'] ? selectedAlert['client_aisle_name'] : selectedAlert['aisle_name']}</Typography>
      </Grid>
      {/* Thumbnail and alerts metadata */}
      <Grid container item direction='column' justifyContent='center' alignItems='center' my={1}>
        <Grid container item justifyContent='space-between' alignItems='flex-end'>
          <Typography>{t('cws_app.digital_store.alerts', 'Alerts')}: {selectedAlert?.totalAlerts}</Typography>
          <Typography variant='caption' color={theme.palette.grey[500]}>{t('cws_app.digital_store.alert_linear_info', 'alerts from meter {{from}} to {{to}}', { from: sectionRange?.from, to: sectionRange?.to })}</Typography>
        </Grid>
        <Canvas id='sidenavCanvas' containerID='thumbnailScroll' draw={drawDots} loading={isLoadingAisleThumbnail} src={aisleThumbnail?.url} alt='Aisle thumbnail' width={aisleThumbnail?.width} height={aisleThumbnail?.height} />
        <Pagination count={aisleSections?.totalSections} page={page} onChange={handlePaginagionChange} size='small' color='secondary' sx={{ mt: theme.spacing(1), button: { color: colorBasedOnTheme(theme, 'color-darkTheme') } }} />
        {/* Search bar for the list of products */}
        <SearchBar
          fullWidth
          searchbartheme='dark'
          sx={{ my: theme.spacing(2) }}
          options={selectedAlert?.alertList}
          value={value}
          setValue={setValue}
          inputValue={inputValue}
          setInputValue={setInputValue}
          optionLabel='description'
          id={['item', 'ean', 'detection_id']}
        />
      </Grid>
      {/* Product cards */}
      <Grid container spacing={1}>
        {products?.map(alert => {
          return (
            <Grid item key={`${alert.item}-${alert.ean}-${alert.detection_id}`} width={{ xs: '100%', sm: '33%', md: '50%' }}>
              {/* Consecutive alerts badge */}
              <Badge
                badgeContent={
                  <Tooltip title={t(`Chains.${selectedStore?.chain_name}.consecutive_alerts`, 'Consecutive alerts')}>
                    <span>{alert?.consecutive_alerts}</span>
                  </Tooltip>
                }
                color='secondary'
                invisible={alert?.consecutive_alerts === 0 || alert?.completed}
                max={999}
                sx={{ height: '100%', width: '100%', cursor: 'default' }}
              >
                {/* Completed alert badge */}
                <Badge
                  badgeContent={
                    <Tooltip title={t(`Chains.${selectedStore?.chain_name}.completed`, 'Completed')}>
                      <span>
                        <IconComponent
                          iconName={'checkmark-outline'}
                          style={{ fontSize: '10px' }}
                        />
                      </span>
                    </Tooltip>
                  }
                  color='success'
                  invisible={!alert?.completed}
                  sx={{ height: '100%', width: '100%' }}
                >
                  {/* Product card */}
                  <Card sx={{ display: 'flex', height: '100%', width: '100%', flexDirection: 'column', justifyContent: 'space-between', color: colorBasedOnTheme(theme, 'color-darkTheme'), bgcolor: colorBasedOnTheme(theme, 'bgcolor-darkTheme') }}>
                    <CardActionArea sx={{ height: '100%' }} onClick={() => handleClick(alert)}>
                      <CardContent sx={{ display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                        {/* TODO: use CardMedia to display the products image (instead of the cart icon) */}
                        <IconComponent
                          iconName={'cart-outline'}
                          style={{ fontSize: '5em' }}
                        />
                        <Typography my={2} align='center'>{lowerCaseAllWordsExceptFirstLetters(alert?.description)}</Typography>
                        <Typography align='center'>{t(`Chains.${selectedStore?.chain_name}.item`, 'Item')}: {alert?.item}</Typography>
                      </CardContent>
                    </CardActionArea>
                    {/* Link button to Walls */}
                    <CardActions sx={{ justifyContent: 'flex-end' }}>
                      {/* TODO: change the bootstrap icon with the new one (Ara's icon) when the icons on the sideMenu are also migrated */}
                      <PageLink
                        currentState={{
                          date: date,
                          selectedStore: selectedStore,
                          aisleName: selectedAlert?.aisle_name,
                          product: alert,
                          floorValue: getRobotIndex({ session: robotSession, floorIdentifier: 'Piso', defaultIndex: 1 })
                        }}
                        view='/cpg/walls'
                        iconButton={<UpcScan size={20} />}
                        sx={{ color: colorBasedOnTheme(theme, 'color-darkTheme') }}
                        firstTooltipTitle={t('cws_app.general.see_walls', 'See in Walls')}
                        secondTooltipTitle=''
                        isRedirect={true}
                      />
                      {/* <IconComponent
                        iconName={'barcode-outline'}
                        style={{ fontSize: '30px' }}
                      /> */}
                    </CardActions>
                  </Card>
                </Badge>
              </Badge>
            </Grid>
          )
        })
        }
      </Grid>
    </Paper>
  )
}
