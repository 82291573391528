import { connect } from 'react-redux';

import { checkSession, logOut } from 'core/slices/session';
import { getUserNotifications, postUserNotification, getUserClients } from 'core/slices/users';
import { getIsLoadingUserClientList, getUserClientList, getUserInfo, getUserNotificationsList, isLoadingUserNotifications } from 'core/selectors';


import Navbar from '../../../components/features/navbar/Navbar';

export default connect(
    state => ({
        isLoadingClients: getIsLoadingUserClientList(state),
        getUserClientList: getUserClientList(state),
        getUserInfo: getUserInfo(state),
        notificationsList: getUserNotificationsList(state),
        isLoadingUserNotifications: isLoadingUserNotifications(state),
    }),
    {
        getUserClients,
        getUserNotifications,
        postUserNotification,
        logOut,
        checkSession,
    },
)(Navbar);
