import { connect } from 'react-redux';
import Bruno from '../../../../components/userManagement/bruno/Bruno';
import { deleteBrunoUser, getBrunoFeatures, getBrunoUsers, getUserFeatures, getUserProfile, getUserRoles, postBrunoUser, updateBrunoUser } from 'core/slices/bruno';
import { getChains } from 'core/slices/backend';
import { getStoreCategories } from 'core/slices/store';
import { getUserStores, getUserSuppliers } from 'core/slices/users';
import {
  brunoUsers,
  isLoadingBrunoUsers,
  deleteBrunoUserResponse,
  isLoadingDeleteBrunoUser,
  brunoUserProfile,
  isLoadingBrunoUserProfile,
  userRoles,
  isLoadingUserRoles,
  getChainsList,
  isLoadingChains,
  getSelectedClient,
  getCategoriesList,
  isLoadingCategories,
  brunoFeatures,
  isLoadingBrunoFeatures,
  brunoUserFeatures,
  isLoadingBrunoUserFeatures,
  getUserStoresAllList,
  isLoadingUserStores,
  updateBrunoUserResponse,
  isLoadingUpdateBrunoUser,
  postBrunoUserResponse,
  isLoadingPostBrunoUser,
  userSuppliers,
  isLoadingUserSuppliers,
} from 'core/selectors';

export default connect(state => ({
  selectedClient: getSelectedClient(state),
  brunoUsers: brunoUsers(state),
  isLoadingBrunoUsers: isLoadingBrunoUsers(state),
  deleteResponse: deleteBrunoUserResponse(state),
  isLoadingDelete: isLoadingDeleteBrunoUser(state),
  userRoles: userRoles(state),
  isLoadingUserRoles: isLoadingUserRoles(state),
  chains: getChainsList(state),
  isLoadingChains: isLoadingChains(state),
  categories: getCategoriesList(state),
  isLoadingCategories: isLoadingCategories(state),
  brunoFeatures: brunoFeatures(state),
  isLoadingBrunoFeatures: isLoadingBrunoFeatures(state),
  userProfile: brunoUserProfile(state),
  isLoadingUserProfile: isLoadingBrunoUserProfile(state),
  userFeatures: brunoUserFeatures(state),
  isLoadingUserFeatures: isLoadingBrunoUserFeatures(state),
  userStores: getUserStoresAllList(state),
  isLoadingUserStores: isLoadingUserStores(state),
  updateUserResponse: updateBrunoUserResponse(state),
  isLoadingUpdateUser: isLoadingUpdateBrunoUser(state),
  postUserResponse: postBrunoUserResponse(state),
  isLoadingPostUser: isLoadingPostBrunoUser(state),
  suppliers: userSuppliers(state),
  isLoadingSuppliers: isLoadingUserSuppliers(state),
}), {
  getBrunoUsers,
  deleteBrunoUser,
  getUserRoles,
  getChains,
  getStoreCategories,
  getBrunoFeatures,
  getUserProfile,
  getUserFeatures,
  getUserStores,
  updateBrunoUser,
  postBrunoUser,
  getUserSuppliers,
})(Bruno);
