import React, { Component } from 'react';
import {
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';

//Icons
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

// Styled components
const ColorButton = styled(Button)(({ theme }) => ({
  color: 'primary',
  height: theme.mixins.toolbar.minHeight,
}));

const StyledMenu = styled(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => {
  return {
    '& .MuiPaper-root': {
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.primary.main
          : theme.palette.paper.main,
      borderRadius: 3,
      marginTop: theme.spacing(1),
      minWidth: 300,
      color: 'white',
    },
  };
});

const styles = {
  icon: {
    color: 'white',
    fontSize: 30,
  },
  text: {
    fontSize: 14,
    marginLeft: 12,
  },
};

class MyMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isLanguageMenuOpen: false,
      anchorEl: null,
      username: props.username,
      tenant: props.tenant,
      REACT_APP_AUTH_BASE_URL: process.env.REACT_APP_AUTH_BASE_URL,
    };
  }

  handleOpenMenu = event => {
    this.setState({ open: true });
    this.setAnchorEl(event.currentTarget);
  };

  handleCloseMenu = () => {
    this.setState({ open: false });
  };

  handleLogout = () => {
    // TODO: persist Dashboard metadata
    // this.dashboardsService.persistDashboardsMetadata();
    this.props.logOut();
  }

  handleToggleLanguageMenu = () => {
    this.setState({ isLanguageMenuOpen: !this.state.isLanguageMenuOpen });
  };

  setAnchorEl = event => {
    this.setState({ anchorEl: event });
  };

  handleProfileNavigation = () => {
    window.location.href = `${this.state.REACT_APP_AUTH_BASE_URL}/profile?tenant=${this.state.tenant}&redirect=${window.location.href}`;
  };

  render() {
    const { open, anchorEl } = this.state;
    const { toggleTheme, toogleTranslation, t } = this.props;

    const lngs = {
      en: { nativeName: 'English' },
      de: { nativeName: 'Deutsch' },
      es: { nativeName: 'Spanish' },
      ja: { nativeName: '日本語' },
    };

    return (
      <Grid>
        <ColorButton onClick={this.handleOpenMenu}>
          <IconComponent
            iconName={'person-circle-outline'}
            style={styles.icon}
          />
        </ColorButton>

        {this.state.isLanguageMenuOpen ? (
          <StyledMenu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={this.handleCloseMenu}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}>
            <MenuItem onClick={this.handleToggleLanguageMenu}>
              <IconComponent
                iconName={'chevron-back-outline'}
                style={styles.icon}
              />
            </MenuItem>
            {Object.keys(lngs).map(lng => (
              <div key={lng}>
                <MenuItem
                  onClick={() => {
                    toogleTranslation(lng);
                  }}>
                  {lngs[lng].nativeName}
                </MenuItem>
                <Divider variant="middle" style={{ background: '#cacadc' }} />
              </div>
            ))}
          </StyledMenu>
        ) : (
          <StyledMenu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={this.handleCloseMenu}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}>
            <MenuItem onClick={this.handleCloseMenu}>
              <IconComponent iconName={'person-outline'} style={styles.icon} />
              <Typography style={styles.text}>{this.state.username}</Typography>
            </MenuItem>

            {this.state.tenant && (<MenuItem onClick={this.handleProfileNavigation}>
              <IconComponent iconName={'pencil-outline'} style={styles.icon} />
              <Typography style={styles.text}>{t('General.edit_profile', 'Edit Profile')}</Typography>
            </MenuItem>)}

            <Divider variant="middle" style={{ background: 'white' }} />

            <MenuItem onClick={this.handleToggleLanguageMenu}>
              <IconComponent iconName={'globe-outline'} style={styles.icon} />
              <Typography style={styles.text}>{t("cws_app.home.change_lan")}</Typography>
            </MenuItem>

            <MenuItem onClick={toggleTheme}>
              <IconComponent iconName={'moon-outline'} style={styles.icon} />
              <Typography style={styles.text}>{t("cws_app.home.change_thme")}</Typography>
            </MenuItem>

            <MenuItem onClick={this.handleLogout}>
              <IconComponent iconName={'log-out-outline'} style={styles.icon} />
              <Typography style={styles.text}>{t("cws_app.home.logout")}</Typography>
            </MenuItem>
          </StyledMenu>
        )}
      </Grid>
    );
  }
}

export default withTranslation()(MyMenu);
