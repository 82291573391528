import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';


const OPTIONS = [
    { id: "1", name: "select 1" },
    { id: "2", name: "select 2" },
    { id: "3", name: "select 3" }
];

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

export default function MultiSelector(props) {
    const {
        inputVariant,
        inputLabel = 'Select',
        options = OPTIONS,
        canSelectAll = true,
        objectName = 'name',
        objectId = 'id',
        inputSelected,
        setInputSelectedOptions
    } = props;
    const [selectedOption, setSelectedOption] = React.useState([]);
    const [isSelectedAll, setIsSelectedAll] = React.useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (inputSelected && inputSelected.length) {
            setSelectedOption(inputSelected);
        }
    }, [inputSelected])

    useEffect(() => {
        setInputSelectedOptions(selectedOption)
    }, [selectedOption])

    const handleChange = (event) => {
        const {
            target: { value }
        } = event;
        if (value.slice(-1)[0] !== '') {
            setSelectedOption(
                // On autofill we get a the stringified value.
                typeof value === "string" ? value.split(",") : value
            );
        } else {
            handleSelectAll();
        }
    };

    const handleRenderValue = (selected, MAX_LENGTH = 3) => {
        const previewString = selected
            .slice(0, MAX_LENGTH)
            // eslint-disable-next-line eqeqeq
            .flatMap(obj => obj != undefined ? [options.find(option => option[objectId] === obj)[objectName]] : [])
            .join(", ")
        return selected.length > MAX_LENGTH ? `${previewString}, ...` : previewString
    }

    const handleSelectAll = () => {
        setSelectedOption(isSelectedAll ? [] : options.map(option => option[objectId]));
        setIsSelectedAll(!isSelectedAll);
    }

    return (
        <div>
            <FormControl variant={inputVariant} sx={{ width: '100%' }}>
                <InputLabel id="mutiple-checkbox-label">{inputLabel}</InputLabel>
                <Select
                    labelId="mutiple-checkbox-label"
                    style={{ backgroundColor: "rgba(255, 255, 255, 0.87)" }}
                    id="mutiple-checkbox"
                    multiple
                    value={selectedOption}
                    onChange={handleChange}
                    label={inputLabel}
                    renderValue={handleRenderValue}
                    MenuProps={MenuProps}>
                    {canSelectAll &&
                        <MenuItem key="all-multi-select" value="">
                            <Checkbox checked={isSelectedAll} />
                            <ListItemText primary={t('cws_app.management.select_all')} />
                        </MenuItem>
                    }
                    {options.map((option) => (
                        <MenuItem key={option[objectId]} value={option[objectId]}>
                            <Checkbox checked={selectedOption.indexOf(option[objectId]) > -1} />
                            <ListItemText primary={option[objectName]} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}