import { createReducer } from './utils';

import {
  SNACK_SET,
  RESCHEDULE_REQUEST,
  RESCHEDULE_AVAILABLE,
  RESCHEDULE_UNAVAILABLE
} from './constants';

const defaultState = {
  loading_dashboards: false,
  loading_dashboards_resume: {},
};

export const reducer = createReducer(defaultState, {
  [RESCHEDULE_REQUEST]: handleCustomRequest,
  [RESCHEDULE_AVAILABLE]: handleCustomAvailable,
  [RESCHEDULE_UNAVAILABLE]: handleCustomUnavailable
});

function handleCustomRequest(state, { payload: { loadingName } }) {
  return {
    ...state,
    [loadingName]: true,
  };
}

function handleCustomAvailable(state, { payload: { keyState, loadingName, requestInfo, aditionalStates = {} } }) {
  return {
    ...state,
    [loadingName]: false,
    [keyState]: requestInfo,
    ...aditionalStates,
  };
}

function handleCustomUnavailable(state, { payload: { keyState, loadingName } }) {
  return {
    ...state,
    [keyState]: [],
    [loadingName]: false,
  };
}

export function getStoreMapInfo(store) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isLoadingResMap'
    const keyState = 'storeMap'

    dispatch({ type: RESCHEDULE_REQUEST, payload: { loadingName } });
    try {
      const storeMapResponse = await dataSource.getMapInfo(store);
      const storeMap = storeMapResponse ? storeMapResponse : {}
      dispatch({
        type: RESCHEDULE_AVAILABLE,
        payload: { requestInfo: storeMap, keyState, loadingName },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: RESCHEDULE_UNAVAILABLE,
        payload: { error, keyState, loadingName, defaultState: {} },
      });
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function getRobotSessionsInfo(store_list, initial_date, final_date) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isLoadingRobotSessions';
    const keyState = 'robotSessions';

    dispatch({ type: RESCHEDULE_REQUEST, payload: { loadingName } });
    try {
      const sessionsResponse = await dataSource.getRobotSessions(JSON.stringify(store_list), initial_date, final_date);
      const robotSessions = sessionsResponse ? sessionsResponse : {};
      dispatch({
        type: RESCHEDULE_AVAILABLE,
        payload: { requestInfo: robotSessions, keyState, loadingName },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: RESCHEDULE_UNAVAILABLE,
        payload: { error, keyState, loadingName, defaultState: {} },
      });
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function getDetailedRobotSession(session_id) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isLoadingSession';
    const keyState = 'robotSession';

    dispatch({ type: RESCHEDULE_REQUEST, payload: { loadingName } });
    try {
      const sessionResponse = await dataSource.getDetailedRobotSession(session_id);
      const session = sessionResponse ? sessionResponse : {};
      dispatch({
        type: RESCHEDULE_AVAILABLE,
        payload: { requestInfo: session, keyState, loadingName },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: RESCHEDULE_UNAVAILABLE,
        payload: { error, keyState, loadingName, defaultState: {} },
      });
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function getRescheduleTickets(ticket_type, store_codes, client_id, chain_country_id, supplier_id, ticket_date) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isLoadingTickets';
    const keyState = 'ticketsData';

    dispatch({ type: RESCHEDULE_REQUEST, payload: { loadingName } });
    try {
      const ticketsResponse = await dataSource.getTickets(ticket_type, JSON.stringify(store_codes), client_id, chain_country_id, supplier_id, ticket_date);
      const ticketsData = ticketsResponse ? ticketsResponse : {};
      dispatch({
        type: RESCHEDULE_AVAILABLE,
        payload: { requestInfo: ticketsData, keyState, loadingName },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: RESCHEDULE_UNAVAILABLE,
        payload: { error, keyState, loadingName, defaultState: {} },
      });
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  }
}

export function postRescheduleTicket(ticket_type, client_id, chain_country_id, store_codes, ticket_form, selected_type) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isLoadingNewTicket'
    const keyState = 'ticketMessageResponse'

    dispatch({ type: RESCHEDULE_REQUEST, payload: { loadingName } });
    try {
      const ticketResponse = await dataSource.postTicket(ticket_type, client_id, chain_country_id, JSON.stringify(store_codes), JSON.stringify(ticket_form), selected_type);
      const ticket = ticketResponse ? ticketResponse : {}
      dispatch({
        type: RESCHEDULE_AVAILABLE,
        payload: { requestInfo: ticket, keyState, loadingName },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: RESCHEDULE_UNAVAILABLE,
        payload: { error, keyState, loadingName, defaultState: {} },
      });
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

