import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, Switch, TextField, Typography } from "@mui/material"
import InfiniteMultiSelector from "../../tools/InfiniteMultiSelector";
import { MultiSelector } from "@zippeditoolsjs/blocks";
import Selector from "../../tools/Selector";
import { isEmptyOrUndefined } from "core/utils/validators";
import { IconComponent } from "@zippeditoolsjs/zippedi-icons";
import { Validators as ZippediValidators, generatePassword } from '@zippeditoolsjs/security';
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { ZIPPEDI_CLIENT_ID } from "core/utils/constants";

export default function UserForm(props) {
  const {
    selectedClient,
    user,
    clientList,
    chainsList,
    isLoadingChains,
    getUserStores,
    userStores,
    isLoadingUserStores,
    userMGMTStores,
    productsRoles,
    isLoadingProductsRoles,
    getUserClients,
    userClients,
    isLoadingUserClients,
    updateUser,
    updateResponse,
    isLoadingUpdate,
    postUser,
    postResponse,
    isLoadingPost,
  } = props
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [clients, setClients] = useState([]);
  const [role, setRole] = useState('');
  const [filteredChains, setFilteredChains] = useState([]);
  const [chains, setChains] = useState([]);
  const [storesList, setStoresList] = useState([]);
  const [stores, setStores] = useState([]);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isZippedian, setIsZippedian] = useState(false);
  const [isNewUser, setIsNewUser] = useState(true);
  const zippediValidators = new ZippediValidators();

  // Lifecycle methods

  // Set if it's a new user
  useEffect(() => {
    setIsNewUser(isEmptyOrUndefined(user, 'dict'));
  }, [user]);

  // Set user info if it's an existing user and get extra
  useEffect(() => {
    if (!isNewUser) {
      getUserClients(user.user_id)
      getUserStores(user.client_id, user.user_id, true)

      setFirstName(user.first_name);
      setLastName(user.last_name);
      setEmail(user.email);
      setIsActive(user.is_user_active);
      setRole(!isEmptyOrUndefined(productsRoles, 'array') ? productsRoles.find(role => role.role_id === user.role_id) : '');
    }
  }, [isNewUser]);

  // Set the clients list
  useEffect(() => {
    if (!isEmptyOrUndefined(userClients, 'array')) {
      setClients(userClients);
    } else {
      setClients([]);
    }
  }, [userClients]);

  // Filter the chains list based on the selected client (i.e. the chain_country_id's that are in userStores)
  useEffect(() => {
    if (isZippedian) {
      setFilteredChains(chainsList);
    } else {
      const chainsIds = userStores.map(store => store.chain_country_id);
      const currentChains = structuredClone(chainsList).filter(chain => chainsIds.includes(chain.chain_country_id));
      setFilteredChains(currentChains);
    }
  }, [chainsList, userStores, isZippedian]);

  // Set the checked stores and chains based on userMGMTStores
  useEffect(() => {
    if (!isEmptyOrUndefined(userMGMTStores, 'array')) {
      const chainsIds = userMGMTStores.map(store => store.chain_country_id);
      const currentChains = structuredClone(chainsList).filter(chain => chainsIds.includes(chain.chain_country_id));
      setChains(currentChains);
      setStores(userMGMTStores);
    } else {
      setChains([]);
      setStores([]);
    }
  }, [userMGMTStores, chainsList]);

  // Filter the list with all stores based on the selected chains
  useEffect(() => {
    if (!isEmptyOrUndefined(chains, 'array') && !isEmptyOrUndefined(userStores, 'array')) {
      const filteredStores = structuredClone(userStores).filter(store => {
        return chains.some(chain => chain.chain_country_id === store.chain_country_id)
      });
      // Remove from the stores list the ones that are not selected in the chains list
      const chainsIds = chains.map(chain => chain.chain_country_id);
      const currentStores = stores.filter(store => chainsIds.includes(store.chain_country_id));
      setStores(currentStores);
      setStoresList(filteredStores);
    } else {
      setStores([]);
      setStoresList([]);
    }
  }, [chains]);

  // Check if the user is zippedian
  useEffect(() => {
    if (!isEmptyOrUndefined(selectedClient, 'dict')) {
      const isZippedi = selectedClient?.client_id === ZIPPEDI_CLIENT_ID;
      setIsZippedian(isZippedi);
    } else {
      setIsZippedian(false);
    }
  }, [selectedClient]);

  // If it's a new user and zippedian, add the zippedi client to the list, otherwise, add the selected client
  useEffect(() => {
    if (isNewUser && isZippedian) {
      const zippediClient = clientList.find(client => client.client_id === ZIPPEDI_CLIENT_ID);
      setClients([zippediClient]);
    } else if (isNewUser && selectedClient?.client_id !== ZIPPEDI_CLIENT_ID) {
      setClients([selectedClient]);
    }
  }, [isNewUser, isZippedian, selectedClient, clientList]);

  // Refresh the page when the user update is successful
  useEffect(() => {
    if (updateResponse?.message === 'OK') {
      window.location.reload();
    }
  }, [updateResponse])

  // Refresh the page when the user creation is successful
  useEffect(() => {
    if (postResponse?.message === 'Created') {
      window.location.reload();
    }
  }, [postResponse])

  // Methods

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleGeneratePassword = () => {
    const newPassword = generatePassword();
    setPassword(newPassword);
  }

  const handleSwtichChange = (event) => {
    setIsActive(event.target.checked);
  }

  const handleUser = () => {
    let add_client = [];
    let deleted_client = [];
    let add_chains = [];
    let deleted_chains = [];
    let add_stores = [];
    let deleted_stores = [];

    if (isNewUser) {
      // For new user, add all the clients, chains and stores
      add_client = clients.map(client => client.client_id);
      add_chains = chains;
      add_stores = stores;
    } else {
      // (Existing user) For clients, chains and stores, filter the ones that were added and the ones that were deleted from the original lists
      add_client = clients.filter(client => !userClients.some(c => c.client_id === client.client_id)).map(client => client.client_id);
      deleted_client = userClients.filter(client => !clients.some(c => c.client_id === client.client_id)).map(client => client.client_id);

      const chainsIds = userMGMTStores.map(store => store.chain_country_id);
      const initialChains = chainsList.filter(chain => chainsIds.includes(chain.chain_country_id));
      add_chains = chains.filter(chain => !initialChains.some(c => c.chain_country_id === chain.chain_country_id));
      deleted_chains = initialChains.filter(chain => !chains.some(c => c.chain_country_id === chain.chain_country_id));

      add_stores = stores.filter(store => !userMGMTStores.some(s => s.store_code === store.store_code));
      deleted_stores = userMGMTStores.filter(store => !stores.some(s => s.store_code === store.store_code));
    }

    let body = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      is_active: isNewUser ? true : isActive,
      add_client: isNewUser ? [selectedClient?.client_id] : add_client,
      deleted_client: deleted_client,
      role_id: user?.role_id === 0 ? user?.role_id : role?.role_id,
      add_chains: add_chains,
      deleted_chains: deleted_chains,
      add_stores: add_stores,
      deleted_stores: deleted_stores,
    };

    if (isNewUser) {
      body.password = password;
      body.client_id = selectedClient?.client_id;
      body.supplier_id = selectedClient?.supplier_id;
      postUser(body);
    } else {
      body.user_id = user.user_id;
      body.family_id = user.family_id;
      updateUser(body);
    }
  }

  return (
    <Grid container spacing={2} direction='column'>
      <Grid item>
        <Typography variant='h6'>{t('cws_app.general.user', 'User')}</Typography>
      </Grid>
      <Grid container item spacing={2}>
        {/* First name */}
        <Grid item xs={12} sm={isNewUser ? 6 : 4.5}>
          <TextField
            required
            fullWidth
            label={t('cws_app.general.name', 'Name')}
            id="firstName"
            variant="filled"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Grid>
        {/* Last name */}
        <Grid item xs={12} sm={isNewUser ? 6 : 4.5}>
          <TextField
            required
            fullWidth
            label={t('cws_app.general.last_name', 'Last Name')}
            id="lastName"
            variant="filled"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Grid>
        {/* Is active switch */}
        {!isNewUser &&
          <Grid container item xs={12} sm={3} justifyContent={{ xs: 'flex-start', sm: 'flex-end' }} alignItems='center'>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={isActive}
                    onChange={handleSwtichChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={isActive ? t('cws_app.management.active_user', 'Active user') : t('cws_app.management.inactive_user', 'Inactive user')}
              />
            </FormGroup>
          </Grid>
        }
      </Grid>
      {/* Email */}
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          label={t('cws_app.general.email', 'Email')}
          id="email"
          variant="filled"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={!isNewUser}
        />
      </Grid>
      {/* Password */}
      {isNewUser &&
        <Grid container item spacing={2} wrap='nowrap'>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label={t('cws_app.general.pass', 'Password')}
              id="password"
              variant="filled"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? 'text' : 'password'}
              error={password?.length < zippediValidators.MIN_PASSWORD_LENGTH}
              helperText={password?.length < zippediValidators.MIN_PASSWORD_LENGTH && t('cws_app.general.password_info', 'The passwords must be at least 8 characters long, must not be sequential (e.g. 1234, aaaa) and not contain contextual words such as "zippedi", "cadena", or any user information.')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ?
                        <IconComponent
                          iconName={'eye'}
                          style={{ fontSize: '20px' }}
                        />
                        :
                        <IconComponent
                          iconName={'eye-off'}
                          style={{ fontSize: '20px' }}
                        />
                      }
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Button variant='contained' onClick={handleGeneratePassword} sx={{ height: '4em' }}>
              {t('cws_app.general.generate', 'Generate')}
            </Button>
          </Grid>
        </Grid>
      }
      {/* Clients */}
      {selectedClient.client_id === ZIPPEDI_CLIENT_ID &&
        <Grid item>
          <InfiniteMultiSelector
            required
            inputLabel={t('cws_app.general.client_admin', 'Clients')}
            options={clientList}
            isLoading={isLoadingUserClients}
            disabled={isLoadingUserClients}
            inputSelected={clients}
            setInputSelectedOptions={setClients}
            objectId='client_id'
            canSelectAll={false}
          />
        </Grid>
      }
      {/* Role */}
      {(isZippedian || (isNewUser && selectedClient?.client_id === ZIPPEDI_CLIENT_ID)) &&
        <Grid item>
          <Selector
            required
            options={productsRoles}
            isLoading={isLoadingProductsRoles}
            disabled={isLoadingProductsRoles}
            optionLabel='description'
            labelInput={'cws_app.staff.Rol'}
            tooltipTitle={'views_hint'}
            selectedElement={role}
            setSelectedElement={setRole}
            helperText={user?.role_id === 0 ? "Your're admin 🤫" : ''}
          />
        </Grid>
      }
      <Grid container item spacing={2}>
        {/* Chains */}
        <Grid item xs={12} md={6}>
          <InfiniteMultiSelector
            required
            inputLabel={t('cws_app.general.chains', 'chains')}
            options={filteredChains}
            isLoading={isLoadingChains || isLoadingUserStores}
            disabled={isLoadingChains || isLoadingUserStores}
            inputSelected={chains}
            setInputSelectedOptions={setChains}
            objectId='chain_country_id'
            canSelectAll={false}
            returnEmptyList={false}
          />
        </Grid>
        {/* Stores */}
        <Grid item xs={12} md={6}>
          <MultiSelector
            options={storesList}
            value={stores}
            setValue={setStores}
            label={t('cws_app.general.Stores', 'Stores')}
            name={'parsed_name'}
            tagName={'store_code'}
            loading={isLoadingUserStores}
            disabled={isLoadingUserStores || chains?.length === 0}
            groupBy={'chain_name'}
            textFieldVariant={'outlined'}
          />
        </Grid>
      </Grid>
      {/* Send button */}
      <Grid container item justifyContent='flex-end'>
        <LoadingButton
          variant='contained'
          onClick={handleUser}
          loading={isLoadingUpdate || isLoadingPost}
          disabled={
            isLoadingUpdate
            || isLoadingPost
            || firstName?.length === 0
            || lastName?.length === 0
            || email?.length === 0
            || (isNewUser && password?.length < zippediValidators.MIN_PASSWORD_LENGTH)
            || isEmptyOrUndefined(clients, 'array')
            || (isZippedian && isEmptyOrUndefined(role, 'dict') && user?.role_id !== 0)
            || isEmptyOrUndefined(chains, 'array')
            || isEmptyOrUndefined(stores, 'array')
          }
        >
          {t('cws_app.general.send', 'Send')}
        </LoadingButton>
      </Grid>
    </Grid>
  )
}
