import { createReducer } from './utils';
import { handleRequest, handleAvailable, handleUnavailable } from '../reducerHandlers'
import { 
	SNACK_SET,
	LINK_REQUEST,
	LINK_AVAILABLE,
	LINK_UNAVAILABLE 
} from './constants';

const defaultState = {
  linkReceived: {},
	loadingLinkReceived: false,
};

// Reducer

export const reducer = createReducer(defaultState, {
	[LINK_REQUEST]: handleRequest,
	[LINK_AVAILABLE]: handleAvailable,
	[LINK_UNAVAILABLE]: handleUnavailable,
});

// Actions

export function setLinkState(linkReceived) {
	return async (dispatch) => {
		const keyState = 'linkReceived'
		const loadingName = 'loadingLinkReceived'
		dispatch({ type: LINK_REQUEST, payload: { loadingName: loadingName } });
		try {
			dispatch({
				type: LINK_AVAILABLE,
				payload: { keyState: keyState, data: linkReceived, loadingName: loadingName },
			});
		} catch (error) {
			console.log('error: ', error);
			dispatch({
				type: LINK_UNAVAILABLE,
				payload: { keyState: keyState, loadingName: loadingName, defaultState: {} },
			});
			const snack = {
				open: true,
				message: 'There was an error with the link.',
				severity: 'error',
			};
			dispatch({ type: SNACK_SET, payload: { snack } });
		}
	};
}
