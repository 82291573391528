import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { DataGrid, GridRowsProp, GridColDef, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import LoadingButton from '@mui/lab/LoadingButton';
import { colorBasedOnTheme } from '../../utils/colors';
import { isEmptyOrUndefined } from "core/utils/validators";
import CircularLoader from '../../tools/CircularLoader';
import {
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import UserForm from './UserForm';
import { isValidDate } from 'core/utils/validators';
import { formatDate } from 'core/utils/parsers';

// Column styles
const smallMinWidth = 80;
const mediumMinWidth = 150;
const largeMinWidth = 200;
const smallFlex = 0.3;
const mediumFlex = 0.5;
const largeFlex = 1;

const formatColumns = (t, theme, handleEditOpen, handleMFAOpen, handleDeleteOpen) => {
  const columns: GridColDef[] = [
    { field: 'email', headerName: t('cws_app.general.email', 'Email'), minWidth: largeMinWidth, flex: largeFlex },
    { field: 'is_user_active', headerName: t('cws_app.management.active', 'Active'), minWidth: smallMinWidth, flex: smallFlex },
    { field: 'last_connection_formated', headerName: t('cws_app.general.last_connection', 'Last connection'), minWidth: mediumMinWidth, flex: mediumFlex },
    {
      field: 'edit', headerName: t('cws_app.general.edit', 'Edit'), minWidth: smallMinWidth, flex: smallFlex,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => handleEditOpen(params.row)}
            sx={{ color: theme.palette.type === 'light' ? '' : 'lightgray' }}
          >
            <IconComponent
              iconName={'pencil'}
              style={{ fontSize: '20px' }}
            />
          </IconButton>
        );
      }
    },
    {
      field: 'revokeMFA', headerName: t('cws_app.general.revoke_mfa', 'Revoke MFA'), minWidth: smallMinWidth, flex: smallFlex,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => handleMFAOpen(params.row)}
            sx={{ color: theme.palette.type === 'light' ? '' : 'lightgray' }}
          >
            <IconComponent
              iconName={'lock-closed'}
              style={{ fontSize: '20px' }}
            />
          </IconButton>
        );
      }
    },
    {
      field: 'delete', headerName: t('cws_app.general.delete', 'Delete'), minWidth: smallMinWidth, flex: smallFlex,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => handleDeleteOpen(params.row)}
            sx={{ color: theme.palette.error.main }}
          >
            <IconComponent
              iconName={'trash'}
              style={{ fontSize: '20px' }}
            />
          </IconButton>
        );
      }
    },
  ];

  return columns;
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
}

export default function UsersTable(props) {
  const {
    theme,
    selectedClient,
    users,
    isLoadingUsers,
    getUserClients,
    userClients,
    isLoadingUserClients,
    updateUser,
    updateResponse,
    isLoadingUpdate,
    deleteUser,
    deleteResponse,
    isLoadingDelete,
    clientList,
    chainsList,
    isLoadingChains,
    getUserStores,
    userStores,
    isLoadingUserStores,
    userMGMTStores,
    productsRoles,
    isLoadingProductsRoles,
  } = props;
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [openMFA, setOpenMFA] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [user, setUser] = useState(null);

  // Lifecycle methods

  // Format users for the DataGrid
  useEffect(() => {
    if (!isEmptyOrUndefined(users, 'array')) {
      const formatRows: GridRowsProp = users.map((user) => {
        const date = new Date(user.last_connection);
        user['last_connection_formated'] = isValidDate(date) ? formatDate(date) : '-'
        return {
          id: user.user_id,
          ...user,
        }
      });
      setRows(formatRows);
      setColumns(formatColumns(t, theme, handleEditOpen, handleMFAOpen, handleDeleteOpen));
    }
  }, [users]);

  // Reset columns when theme changes (because the icons don't change the color when the theme changes)
  useEffect(() => {
    setColumns(formatColumns(t, theme, handleEditOpen, handleMFAOpen, handleDeleteOpen));
  }, [t, theme]);

  // Refresh the page when the user is deleted or their MFA is revoked successfully
  useEffect(() => {
    if (deleteResponse?.message === 'OK') {
      window.location.reload();
    }
  }, [deleteResponse])

  // Methods

  // Edit button handler
  const handleEditOpen = (row) => {
    setOpenEdit(true);
    setUser(row);
  }

  const handleEditClose = () => {
    setOpenEdit(false);
    setUser(null);
  }

  // Revoke MFA button handler
  const handleMFAOpen = (row) => {
    setOpenMFA(true);
    setUser(row);
  }

  const handleMFAClose = () => {
    setOpenMFA(false);
    setUser(null);
  }

  const onRevokeMFA = () => {
    deleteUser(user.id, user.email, true);
  }

  // Delete button handler
  const handleDeleteOpen = (row) => {
    setOpenDelete(true);
    setUser(row);
  }

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setUser(null);
  }

  const onDelete = () => {
    deleteUser(user.id, user.email);
  }

  return (
    <>
      {isLoadingUsers ?
        <Grid container justifyContent='center' alignItems='center' sx={{ height: '100%' }}>
          <CircularLoader />
        </Grid>
        :
        !isEmptyOrUndefined(users, 'array') ?
          <Paper sx={{ p: 1, backgroundColor: theme.palette.paper.main }}>
            <DataGrid
              rows={rows}
              columns={columns}
              slots={{
                toolbar: CustomToolbar // If you want all the toolbars, use the default GridToolbar instead
              }}
              // To translate the texts of the table
              localeText={{
                toolbarColumns: t('cws_app.general.columns', 'Columns'),
                toolbarFilters: t('cws_app.general.filters', 'Filters'),
                toolbarDensity: t('cws_app.general.density', 'Density'),
              }}
              initialState={{
                pagination: { paginationModel: { pageSize: 5 } },
              }}
              pageSizeOptions={[5, 10, 15]}
              // Style
              sx={{ color: colorBasedOnTheme(theme, 'color') }}
              slotProps={{
                pagination: {
                  sx: {
                    color: colorBasedOnTheme(theme, 'color'),
                  },
                  SelectProps: {
                    sx: {
                      '& .MuiSvgIcon-root': {
                        color: colorBasedOnTheme(theme, 'color')
                      }
                    }
                  },
                }
              }}
            />
            {/* Edit user dialog */}
            <Dialog
              open={openEdit}
              onClose={handleEditClose}
              aria-labelledby="edit-dialog-title"
              aria-describedby="edit-dialog-description"
              maxWidth='md'
              fullWidth
            >
              <DialogContent>
                <UserForm
                  selectedClient={selectedClient}
                  user={user}
                  clientList={clientList}
                  chainsList={chainsList}
                  isLoadingChains={isLoadingChains}
                  getUserStores={getUserStores}
                  userStores={userStores}
                  isLoadingUserStores={isLoadingUserStores}
                  userMGMTStores={userMGMTStores}
                  productsRoles={productsRoles}
                  isLoadingProductsRoles={isLoadingProductsRoles}
                  getUserClients={getUserClients}
                  userClients={userClients}
                  isLoadingUserClients={isLoadingUserClients}
                  updateUser={updateUser}
                  updateResponse={updateResponse}
                  isLoadingUpdate={isLoadingUpdate}
                />
              </DialogContent>
            </Dialog>
            {/* Revoke MFA dialog */}
            <Dialog
              open={openMFA}
              onClose={handleMFAClose}
              aria-labelledby="mfa-dialog-title"
              aria-describedby="mfa-dialog-description"
              maxWidth='xs'
              fullWidth
            >
              <DialogTitle id="mfa-dialog-title">
                {t('cws_app.general.revoke_mfa', 'Revoke MFA')}?
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="mfa-dialog-description">
                  <Grid container>
                    <Grid item>
                      <Typography>{t('cws_app.general.username', 'Username')}:</Typography>
                    </Grid>
                    &nbsp; {/* To add space between the words */}
                    <Grid item>
                      <Typography sx={{ color: theme.palette.error.main }}> {user?.username}?</Typography>
                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleMFAClose}>{t('cws_app.general.cancel', 'Cancel')}</Button>
                <LoadingButton
                  loading={isLoadingDelete}
                  loadingPosition="start"
                  startIcon={
                    <IconComponent
                      iconName={'lock-open'}
                      style={{ fontSize: '18px' }}
                    />
                  }
                  onClick={onRevokeMFA}
                >
                  {t('cws_app.general.send', 'Send')}
                </LoadingButton>
              </DialogActions>
            </Dialog>
            {/* Delete user dialog */}
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="delete-dialog-title"
              aria-describedby="delete-dialog-description"
            >
              <DialogTitle id="delete-dialog-title">
                {t('cws_app.staff.delete_confirmation', 'Delete this user?')}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="delete-dialog-description">
                  {t('cws_app.staff.delete_confirmation_text', 'You are going to PERMANENTLY delete this user and their data that has been created to date. To confirm, click the DELETE button below.')}
                </DialogContentText>
                <DialogContentText id="mfa-dialog-description">
                  <Grid container sx={{ pt: 2 }}>
                    <Grid item>
                      <Typography>{t('cws_app.general.username', 'Username')}:</Typography>
                    </Grid>
                    &nbsp; {/* To add space between the words */}
                    <Grid item>
                      <Typography sx={{ color: theme.palette.error.main }}> {user?.username}?</Typography>
                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDeleteClose}>{t('cws_app.general.cancel', 'Cancel')}</Button>
                <LoadingButton
                  loading={isLoadingDelete}
                  loadingPosition="start"
                  startIcon={
                    <IconComponent
                      iconName={'trash'}
                      style={{ fontSize: '18px' }}
                    />
                  }
                  onClick={onDelete}
                >
                  {t('cws_app.general.delete', 'Delete')}
                </LoadingButton>
              </DialogActions>
            </Dialog>
          </Paper>
          :
          <Grid item xs={12} sm={12} md={12}>
            <Paper sx={{ p: 2 }}>
              <Typography textAlign={'center'} > {t('cws_app.general.default_message_error', 'An error has occurred, please try again later.')}</Typography>
            </Paper>
          </Grid>
      }
    </>
  )
}
