import { createReducer } from './utils';

import { SNACK_SET } from './constants';

const defaultState = {
  snack: { open: false, severity: null, message: null },
};

export const reducer = createReducer(defaultState, {
  [SNACK_SET]: handleSnackSet,
});

function handleSnackSet(state, { payload: { snack } }) {
  return {
    ...state,
    snack,
  };
}

export function setSnackAlert(snack) {
  return async dispatch => {
    dispatch({
      type: SNACK_SET,
      payload: { snack },
    });
  };
}
