import { useState, useEffect } from 'react';
import { IconButton, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { DataGrid } from '@zippeditoolsjs/table'

const formatColumns = (t, setOpen, handleEditContact, handleDeleteContact) => {
  const columns = [
    {
      field: 'name',
      headerName: t('cws_app.general.contact'),
      width: 300,
      align: 'left',
    },
    {
      field: 'mail',
      headerName: t('cws_app.general.mail'),
      align: 'left',
      width: 370,
    },
    {
      field: 'edit',
      headerName: t('cws_app.general.edit'),
      headerAlign: 'center',
      align: 'center',
      width: 150,
      sortable: false,
      sortingOrder: [null],
      disableColumnMenu: true,
      disableColumnFilter: true,
      renderCell: params => {
        return (
          <IconButton
            onClick={e => {
              setOpen(true);
              handleEditContact(params.row);
            }}>
            <EditIcon />
          </IconButton>
        );
      },
    },
    {
      field: 'delete',
      headerName: t('cws_app.general.delete'),
      headerAlign: 'center',
      align: 'center',
      width: 150,
      sortable: false,
      sortingOrder: [null],
      disableColumnMenu: true,
      disableColumnFilter: true,
      renderCell: params => {
        return (
          <IconButton
            onClick={e => {
              setOpen(true);
              handleDeleteContact(params.row);
            }}>
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];

  return columns;
};

export default function DataTable(props) {
  const {
    t,
    theme,
    mailerContactList,
    isloadingMailerContacts,
    setMode,
    setSelectedContact,
    setOpen
  } = props;

  const [columns, setColumns] = useState([]);

  const handleEditContact = row => {
    setMode('edit');
    setSelectedContact(row);
  };

  const handleDeleteContact = row => {
    setMode('delete');
    setSelectedContact(row);
  };

  // Reset columns when theme changes (because the icons don't change the color when the theme changes)
  useEffect(() => {
    setColumns(formatColumns(t, setOpen, handleEditContact, handleDeleteContact));
  }, [t, theme]);

  return (
    <StyledPaper>
      <DataGrid
        getRowId={row => row.contact_id}
        rows={mailerContactList}
        columns={columns}
        disableExportCsv={false}
        localeText={{
          toolbarFilters: t('cws_app.general.filters', 'Filters'),
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 7,
            },
          },
        }}
        autoreset={false}
        pageSizeOptions={[7, 15, 25]}
        disableColumnSelector={true}
        disableRowSelect={true}
        loading={isloadingMailerContacts}
      />
    </StyledPaper>
  );
}

const StyledPaper = styled(props => {
  return <Paper elevation={1} {...props} />;
})(({ theme }) => {
  return {
    width: '100%',
    background:
      theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.primary.main
          : theme.palette.paper.main,
      color: 'white',
    },
    '& .MuiDataGrid-cell': {
      background:
        theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
      color: theme.palette.type === 'light' ? 'black' : 'white',
    },
    '& .MuiDataGrid-columnsPanel': {
      background:
        theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
      color: theme.palette.type === 'light' ? 'black' : 'white',
    },
    '& .MuiDataGrid-menuIconButton': {
      opacity: 1,
      color: 'white',
    },
    '& .MuiDataGrid-sortIcon': {
      color: 'white',
    },
  };
});
