import { createReducer } from './utils';

import {
    CLIENTS_REQUEST,
    CLIENTS_AVAILABLE,
    CLIENTS_UNAVAILABLE,

    CLIENT_REQUEST,
    CLIENT_AVAILABLE,
    CLIENT_UNAVAILABLE,
    SNACK_SET,
} from './constants';

const defaultState = {
    loading_client_info: false,
    loading_client_types: false,
    loading_clients: false,
    loading_client_stores: false,
    clients: false,
    clientList: [],
    clientTypesList: [],
    clientInfo: {},
    clientStores: [],
    clientFormats: {},
    loadingClientFormats: false,
};

export const reducer = createReducer(defaultState, {
    [CLIENTS_REQUEST]: handleClientsRequest,
    [CLIENTS_AVAILABLE]: handleClientsAvailable,
    [CLIENTS_UNAVAILABLE]: handleClientsUnavailable,

    [CLIENT_REQUEST]: handleCustomRequest,
    [CLIENT_AVAILABLE]: handleCustomAvailable,
    [CLIENT_UNAVAILABLE]: handleCustomUnavailable,
});

function handleClientsRequest(state, _) {
    return {
        ...state,
        loading_clients: true,
    };
}

function handleClientsAvailable(state, { payload: { clients } }) {
    return {
        ...state,
        loading_clients: false,
        clients: true,
        clientList: clients.clients_list,
    };
}

function handleClientsUnavailable(state, _) {
    return defaultState;
}

function handleCustomRequest(state, { payload: { loadingName } }) {
    return {
        ...state,
        [loadingName]: true,
    };
}

function handleCustomAvailable(state, { payload: { keyState, loadingName, requestInfo, aditionalStates = {} } }) {
    return {
        ...state,
        [loadingName]: false,
        [keyState]: requestInfo,
        ...aditionalStates,
    };
}

function handleCustomUnavailable(state, { payload: { keyState, loadingName } }) {
    return {
        ...state,
        [keyState]: [],
        [loadingName]: false,
    };
}

export function getClientsTypes() {
    return async (dispatch, _, { services: { dataSource } }) => {
        dispatch({ type: CLIENT_REQUEST, payload: { loadingName: 'loading_client_types' } });
        try {
            const clientTypesResponse = await dataSource.getClientsTypes();
            dispatch({
                type: CLIENT_AVAILABLE,
                payload: { keyState: 'clientTypesList', loadingName: 'loading_client_types', requestInfo: clientTypesResponse.client_types },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: CLIENT_UNAVAILABLE,
                payload: { error, keyState: 'clientTypesList', loadingName: 'loading_client_types' },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getClientInfo(client_id) {
    return async (dispatch, _, { services: { dataSource } }) => {
        dispatch({ type: CLIENT_REQUEST, payload: { loadingName: 'loading_client_info' } });
        try {
            const clientInfoResponse = await dataSource.getClientInfo(client_id);
            dispatch({
                type: CLIENT_AVAILABLE,
                payload: { keyState: 'clientInfo', loadingName: 'loading_client_info', requestInfo: clientInfoResponse.client_info },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: CLIENT_UNAVAILABLE,
                payload: { error, keyState: 'clientInfo', loadingName: 'loading_client_info' },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function postNewClient(fileForm) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loading_new_client'

        dispatch({ type: CLIENT_REQUEST, payload: { loadingName } });
        try {
            const response = await dataSource.postNewClient(fileForm);
            alert("cliente creado exitosamente")
            dispatch({
                type: CLIENT_AVAILABLE,
                payload: { loadingName },
            });
        } catch (error) {
            console.log('error: ', error);
            alert("error al crear cliente")
            dispatch({
                type: CLIENT_UNAVAILABLE,
                payload: { error, loadingName },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function patchClient(fileForm) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loading_patch_client'

        dispatch({ type: CLIENT_REQUEST, payload: { loadingName } });
        try {
            const response = await dataSource.patchClient(fileForm);
            // TODO: translate
            alert("cliente editado exitosamente")
            dispatch({
                type: CLIENT_AVAILABLE,
                payload: { loadingName },
            });
        } catch (error) {
            console.log('error: ', error);
            alert("error al editar cliente")
            dispatch({
                type: CLIENT_UNAVAILABLE,
                payload: { error, loadingName },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}


export function getClientStores(client_id) {
    return async (dispatch, _, { services: { dataSource } }) => {
        dispatch({ type: CLIENT_REQUEST, payload: { loadingName: 'loading_client_stores' } });
        try {
            const response = await dataSource.getClientStores(client_id);
            dispatch({
                type: CLIENT_AVAILABLE,
                payload: { keyState: 'clientStores', loadingName: 'loading_client_stores', requestInfo: response },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: CLIENT_UNAVAILABLE,
                payload: { error, keyState: 'clientStores', loadingName: 'loading_client_stores' },
            });
            const snack = {
                open: true,
                message: 'Error in getting client stores',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getClientFormats(client_id) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const keyState = 'clientFormats'
        const loadingName = 'loadingClientFormats'
        dispatch({ type: CLIENT_REQUEST, payload: { loadingName: loadingName } });
        try {
            const response = await dataSource.getClientFormats(client_id);
            dispatch({
                type: CLIENT_AVAILABLE,
                payload: { requestInfo: response, keyState, loadingName },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: CLIENT_UNAVAILABLE,
                payload: { error, keyState, loadingName },
            });
            const snack = {
                open: true,
                message: "There was an error getting the client formats.",
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}
