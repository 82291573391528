import { useState, useEffect } from 'react';
import { useTheme, styled } from '@mui/material/styles';

import {
  Grid,
  Typography,
  Paper,
  FormControlLabel,
  Switch
} from '@mui/material';

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';

import { useTranslation } from 'react-i18next';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter sx={{ width: '50%', p: 2 }} />
    </GridToolbarContainer>
  );
}

const formatColumns = (t, handleChangeActive) => {
  const columns = [
    { field: 'id', headerAlign: 'center', headerName: 'Id', align: 'center', width: 80 },
    { field: 'name', headerName: t('cws_app.general.supplier_admin'), align: 'left', width: 400 },
    { field: 'chain_list_str', headerName: t('cws_app.general.chains'), width: 200, align: 'left' },
    {
      field: 'active_stores', headerAlign: 'center', headerName: t('cws_app.tracking.Active_stores'), width: 150, align: 'center',
      renderCell: params => {
        let active_stores = params.row.active_stores?.length > 0 ? params.row.active_stores?.length : '0';
        return (
          <Typography>
            {active_stores}
          </Typography>
        )
      }
    },
    {
      field: 'active_sub', headerAlign: 'center', headerName: t('General.status'), align: 'center', width: 150,
      renderCell: params => {
        return (<FormControlLabel
          control={
            <Switch
              color="success"
              checked={params.row.active_sub}
              onChange={() => { handleChangeActive(params.row) }}
            />
          }
          label={params.row?.active_sub ?
            <Typography sx={{ fontSize: 14, mx: 2 }}>
              {t('General.active')}
            </Typography>
            :
            <Typography sx={{ fontSize: 14, mx: 1 }}>
              {t('General.inactive')}
            </Typography>
          }
        />)
      }
    },
  ];

  return columns;
};

export default function DataTable(props) {
  const { t } = useTranslation();

  const {
    rows,
    changeRow,
  } = props;

  const theme = useTheme();

  const [columns, setColumns] = useState([]);


  const [rowsState, setRowsState] = useState(rows);
  const [selectedRow, setSelectedRow] = useState(null);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //  TODO: remove and use it on parent
  const handleEdit = (row) => {
    setSelectedRow(row);
    handleOpen();
  };

  const handleChangeActive = async (row) => {
    let newRows = [...rowsState].map((r) => {
      if (r.id === row.id) {
        r.active_sub = !r.active_sub;
      }
      return r;
    });
    await changeRow(row);
    setRowsState(newRows);
  };

  useEffect(() => {
    setColumns(formatColumns(t, handleChangeActive));
  }, [t, theme]);


  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <StyledPaper elevation={1}
        style={{
          'border-top-left-radius': 0, 'border-top-right-radius': 8,
          'border-bottom-left-radius': 0, 'border-bottom-right-radius': 0,
        }}
      >
        <DataGrid
          rows={rowsState}
          columns={columns}
          slots={{
            toolbar: CustomToolbar, // If you want all the toolbars, use the default GridToolbar instead
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          localeText={{
            toolbarFilters: t('cws_app.general.filters', 'Filters'),
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 7,
              },
            },
          }}
          autoreset={false}
          pageSizeOptions={[7, 15, 25]}
          disableColumnSelector={true}
          disableRowSelect={true}
        />

      </StyledPaper>
    </Grid>
  );
}


const StyledPaper = styled(props => {
  return (
    <Paper
      elevation={1}
      {...props}
    />
  )
}
)(({ theme }) => {
  return ({
    width: '100%',
    background: theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.paper.main,
      color: 'white',
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.primary.main
          : theme.palette.paper.main,
      color: 'white',
    },
    '& .MuiDataGrid-cell': {
      background:
        theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
      color: theme.palette.type === 'light' ? 'black' : 'white',
    },
    '& .MuiDataGrid-menuIconButton': {
      opacity: 1,
      color: 'white',
    },
    '& .MuiDataGrid-sortIcon': {
      color: 'white',
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
    },
    '& .MuiOutlinedInput-root': {
      color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
      '& fieldset': {
        borderColor: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
      },
    },
  })
}
);
