import { connect } from 'react-redux';
import ClientManagement from '../../../../components/userManagement/clients/ClientManagement';

import { getUserStores, getUserSuppliers } from 'core/slices/users';
import { getClientsTypes, getClientInfo, postNewClient, patchClient } from 'core/slices/clients';
import { getChains, getTasks, getProducts } from 'core/slices/backend';

import {
  getSelectedClient, getUserClientList,
  getUserStoresAllList, isLoadingUserStores,
  isLoadingChains, getChainsList, isLoadingClientTypes, getClientTypes,
  isLoadingTasks, getTasksList, isLoadingProducts, getProductsList,
  isLoadingClientInfo, getClientInfoObject,
  userSuppliers,
  isLoadingUserSuppliers,
} from 'core/selectors';

export default connect(state => ({
  chainsList: getChainsList(state),
  clientList: getUserClientList(state),
  clientTypesList: getClientTypes(state),
  userStores: getUserStoresAllList(state),
  selectedClient: getSelectedClient(state),
  productsList: getProductsList(state),
  clientInfo: getClientInfoObject(state),
  isLoadingClientInfo: isLoadingClientInfo(state),
  isLoadingProducts: isLoadingProducts(state),
  isLoadingClientTypes: isLoadingClientTypes(state),
  isLoadingUserStores: isLoadingUserStores(state),
  isLoadingChains: isLoadingChains(state),
  isLoadingTasks: isLoadingTasks(state),
  tasksList: getTasksList(state),
  suppliers: userSuppliers(state),
  isLoadingSuppliers: isLoadingUserSuppliers(state),
}), {
  getTasks,
  getChains,
  getProducts,
  patchClient,
  postNewClient,
  getClientInfo,
  getUserStores,
  getClientsTypes,
  getUserSuppliers
})(ClientManagement);
