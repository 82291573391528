import * as React from 'react';
import Button from '@mui/material/Button';
import ImageUploading from "react-images-uploading";
import { Box } from '@mui/material';

export default function UploadBlob(props) {
  const {
    buttonTxt = "open",
    inputColor = "primary",
    inputVariant = "outlined",
    multipleImages = false,
    images, setImages,
  } = props;

  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {
    setImages(multipleImages ? imageList : imageList.slice(-1));
  };

  return (
    <div className="App">
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        acceptType={["jpg", "png"]}
      >
        {({
          imageList,
          onImageUpload,
          onImageUpdate,
          isDragging,
          dragProps
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <Button variant={inputVariant} color={inputColor}
              style={isDragging ? { color: "red" } : null}
              onClick={onImageUpload}
              {...dragProps}
            >
              {buttonTxt}
            </Button>
            {imageList.map((image, index) => (
              <Box key={index} className="image-item" sx={{ mt: 1, ml: 3 }}>
                <img src={image.data_url} alt="" width="70" />
                {multipleImages &&
                  <div className="image-item__btn-wrapper">
                    <Button onClick={() => onImageUpdate(index)}>Update</Button>
                  </div>
                }
              </Box>
            ))}
          </div>
        )}
      </ImageUploading>
    </div>
  );
}
