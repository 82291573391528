import React, { Component } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import DocsNavbar from './DocsNavbar';
import Documentation from './Documentation';

class DocsLayout extends Component {
  async componentDidMount() {
    const { documentationImages } = this.props;
    documentationImages();
  }

  render() {
    const { isLoadingDocumentation } = this.props;

    return (
      <Grid container item>
        {isLoadingDocumentation ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ pt: 40 }}>
            <CircularProgress size={55} color="secondary" />
          </Grid>
        ) : (
          <Grid container item direction="row">
            <Grid item xs={9} pl={2}>
              <Documentation />
            </Grid>
            <Grid item xs={3}>
              <DocsNavbar />
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default DocsLayout;
