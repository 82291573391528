import { connect } from "react-redux";
import Mailer from "../../../../components/features/mailer/Mailer";

import {
    getMailerContacts,
    getMailerTasks,
    getMailerContactInfo,
    createMailerContact,
    updateMailerContact,
    deleteMailerContact,

} from "core/slices/mailer";

import {
    getClientStores,
} from "core/slices/clients";

import {
    getSelectedClient,
    getClientMailerContactList,
    isLoadingMailerContacts,
    getClientStoresList,
    isLoadingClientStores,
    getClientMailerTaskList,
    isLoadingMailerTaskList,
    getContactInfo,
    isLoadingContactInfo,
} from "core/selectors";

export default connect(state => ({
    selectedClient: getSelectedClient(state),
    mailerContactList: getClientMailerContactList(state),
    isloadingMailerContacts: isLoadingMailerContacts(state),
    clientStores: getClientStoresList(state),
    loadingClientStores: isLoadingClientStores(state),
    mailerTaskList: getClientMailerTaskList(state),
    isloadingMailerTasks: isLoadingMailerTaskList(state),
    mailerContactInfo: getContactInfo(state),
    isloadingContactInfo: isLoadingContactInfo(state),
}), {
    getMailerContacts,
    getClientStores,
    getMailerTasks,
    getMailerContactInfo,
    createMailerContact,
    updateMailerContact,
    deleteMailerContact,
})(Mailer);