import { format } from 'date-fns';
import { DATE_HOUR_FORMAT } from './constants';

// Translation sequence: by chain -> by default value -> by keyword
export function getChainMeaning(t, chainName, keyword, defaultValue) {
	const keyChainTranslation = `Chains.${chainName}.${keyword}`;
	const translation = t(keyChainTranslation);
	return translation != keyChainTranslation ? translation : defaultValue ? defaultValue : keyword;
}


// Translation sequence: by chain -> by default value -> by keyword
export function getConsolidatedTranslations(t, chainName, keyword, targetView, defaultValue, appDict = 'cws_app') {
	if (keyword) {
		const targetDict = `${appDict}.${targetView}.`;
		let translation = getChainMeaning(t, chainName, keyword, 'word_not_found');
		if (translation === 'word_not_found') translation = t(targetDict.concat(keyword));
		if (!translation.includes(targetDict)) return translation
	}
	return defaultValue ?? keyword;
}


/**
 * Translate the value of a specific keyword in a list of objects
 * @param  	{Array} 				initialList 	List of objects
 * @param  	{Translation}  	        t				translation object
 * @param  	{String} 				chainName 		Name of the chain
 * @param  	{String} 				keyword			Key of the object to translate
 * @param  	{String} 				targetView 		Name of the app, feature or chain to search the translation
 * @param  	{String} 				defaultValue 	Used if the keyword is not found
 * @param  	{String} 				appDict			Name of the dictionary for the app
 * @return  {Array}									List with a new object with the translated word
 */
export function translateValueInList(initialList, t, chainName, keyword, targetView, appDict = 'cws_app') {
	let finalList = initialList.map(obj => {
		const parsedName = getConsolidatedTranslations(t, chainName, obj[keyword], targetView, obj[keyword], appDict)
		obj['parsed_name'] = parsedName
		return obj
	})
	return finalList
}


export function lowerCaseAllWordsExceptFirstLetters(string) {
	let words = ''
	if (string) {
		words = string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();
	} else {
		words = string
	}
	return words
}


/**
 * Sort an array of objects by a specific key using natural sort
 * @param  	{Array} 				targetArray 	Array of objects to be sorted
 * @param  	{String} 				parameter 	    Key of the object to sort
 */
export function naturalSort(targetArray, parameter = 'code') {
	return targetArray.sort((a, b) => a[parameter].localeCompare(b[parameter], undefined, { numeric: true, sensitivity: 'base' }));
}


/**
 * Sort an array of objects by a specific key using numeric sort
 * @param  	{Array} 				targetArray 	Array of objects to be sorted
 * @param  	{String} 				parameter 	    Key of the object to sort
 */
export function numericSort(targetArray, parameter = 'alerts') {
	return targetArray.sort((a, b) => b[parameter] - a[parameter]);
}


/**
 * Sort an array of objects by a specific key usign alphabetic sort
 * @param  	{Array} 				ObjectToSort 	Array of objects to be sorted
 * @param  	{String} 				parameter 	    Key of the object to sort
 */
export function alphabeticSortByParam(ObjectToSort, param = 'name') {
	ObjectToSort.sort((a, b) => {
		const paramA = a[param].toLowerCase(), paramB = b[param].toLowerCase();
		return paramA < paramB ? -1 : paramA > paramB ? 1 : 0;
	});
}


/**
 * Format a number to a decimal format with a specific number of decimals
 * @param  	{Number} 				num 				Number to be formatted
 * @param  	{Number} 				toFixed 			Number of decimals
 */
export function decimalFormat(num, toFixed = 2) {
	return `${num.toFixed(toFixed)}`;
}


/**
 * Transform a hexadecimal color to rgb
 * @param {String} hex  Hexadecimal color
 * @returns {Array} [r, g, b]
 */
export function hexToRgb(hex) {
	const parsedHex = hex.replace('#', '');
	var r = parseInt(parsedHex.substring(0, 2), 16);
	var g = parseInt(parsedHex.substring(2, 4), 16);
	var b = parseInt(parsedHex.substring(4, 6), 16);

	return [r, g, b];
}


/**
 * Check if a value is a number
 * @param {Number} a Number to be checked
 * @returns {Boolean} Boolean value
 */
export function isNumber(a) {
	return typeof a === 'number' && !isNaN(a);
}


function componentToHex(c) {
	var hex = c.toString(16);
	return hex.length == 1 ? "0" + hex : hex;
}


/**
 * Transform a rgb color to hexadecimal
 * @param {Number} r  Red value
 * @param {Number} g  Green value
 * @param {Number} b  Blue value
 * @returns {String} Hexadecimal color
 */
export function rgbToHex(r, g, b) {
	return isNumber(r ?? g ?? b) ? "#" + componentToHex(r) + componentToHex(g) + componentToHex(b) : "#000000";
}

export function formatDate(date, dateFormat = DATE_HOUR_FORMAT) {
	return date ? format(date, dateFormat) : '';
}

/**
 * Parse a string in lowercase and with underscores to a string with spaces and first letters in uppercase (sentence case)
 * @param 	{String} string  	String to be parsed
 * @returns {String} 					Parsed string
 * @example
 * FormatToSentenceCase('hello_world') // Hello World
 */
export function FormatToSentenceCase(string) {
	let newString = string.replace(/_/g, ' ')
	return lowerCaseAllWordsExceptFirstLetters(newString);
}

/**
 * Convert a string to snake case
 * @param 	{String} string  	String to be parsed
 * @returns {String} 					Parsed string
 * @example
 * toSnakeCase('HellowWorld') // hellow_world
 */
export function toSnakeCase(str) {
	// Replace all non-alphanumeric characters with spaces, except letters with umlaut and tildes.
	const acceptedCharacters = ['ä', 'ö', 'ü', 'ß', 'ñ', 'á', 'é', 'í', 'ó', 'ú', 'à', 'è', 'ì', 'ò', 'ù'];
	const nonAlphanumericRegex = new RegExp(
		"[^\\w" + acceptedCharacters.join("") + "]",
		"g"
	);

	str = str.replace(nonAlphanumericRegex, " ");

	// Split the string into words.
	const words = str.split(" ");

	// Remove any empty words.
	const nonEmptyWords = words.filter(word => word.length > 0);

	// Lowercase each word.
	const newWords = nonEmptyWords.map(word => word.toLowerCase());

	// Replace any words that start with an uppercase letter with an underscore and the lowercased word.
	const result = newWords.map(word => {
		if (word[0] === word[0].toUpperCase()) {
			return "_" + word.toLowerCase();
		} else {
			return word;
		}
	});

	// Join the words with underscores.
	return result.join("_");
}