import { useState, useEffect } from 'react'

import {
	Button,
	Divider,
	Grid,
	Paper,
	TextField,
	Typography,
} from '@mui/material';

import { useTheme, styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

// markdown components
import MDEditor from '@uiw/react-md-editor';
import ReactMarkdown from 'react-markdown'; // markdown
import remarkGfm from 'remark-gfm' // tables
import rehypeRaw from 'rehype-raw'; // html

//My Components
import InfiniteMultiSelector from '../../tools/InfiniteMultiSelector';
import { isEmptyOrUndefined } from "core/utils/validators";


export default function NotificationForm(props) {

	const {
		users,
		isLoadingUsers,
		chainsList,
		isLoadingChains,
		storeList,
		isLoadingStores,
		clientList,
		clientSelector,
		setClientSelector,
		chainSelector,
		setChainelector,
		storeSelector,
		setStoreSelector,
		usersSelector,
		setUsersSelector,
		title,
		setTitle,
		body,
		setBody,
		mode,
		isLoadingNotification,
		notification,
		activeNotification,
		handleChangeNotification,
	} = props;

	const { t } = useTranslation();
	const theme = useTheme();
	const textColor = theme.palette.type === 'light' ? 'black' : 'white';

	const [date, setDate] = '';

	useEffect(() => {
		if (mode === 'delete' && !isEmptyOrUndefined(activeNotification)) {
			setDate(new Date(notification.creation_timestamp).toDateString());
		}
	}, [activeNotification]);

	return (
		<StyledPaper sx={{ p: 2, }}>
			<Grid container justifyContent={'space-between'} alignItems={'center'} mb={1}>
				<Grid item xs={12}>
					<Typography sx={{ fontSize: '24px', color: textColor }}>
						{mode === 'create' ?
							t('cws_app.notifications.new_notif', 'New notification')
							: mode === 'edit' ?
								t('cws_app.notifications.edit_notif', 'Edit notification')
								:
								t('cws_app.notifications.delete_notif', 'Delete notification')
						}
					</Typography>
				</Grid>
			</Grid>
			<Divider p={1} />
			<Grid container px={2} pb={2} spacing={2} direction={'column'}>
				{mode !== 'delete' ?
					<>
						<Grid item xs={12}>
							<InfiniteMultiSelector
								inputVariant='standard'
								inputLabel={t('cws_app.notifications.client', 'Notify to client')}
								options={clientList}
								inputSelected={clientSelector}
								setInputSelectedOptions={setClientSelector}
								objectName='name' objectId='client_id'
								isLoading={mode === 'edit' ? isLoadingNotification : false}
							/>
						</Grid>
						<Grid item xs={12} container spacing={3} >
							<Grid item xs={12} md={6}>
								<InfiniteMultiSelector
									inputVariant='standard'
									inputLabel={t('cws_app.notifications.chain', 'Notify to chain')}
									options={chainsList}
									inputSelected={chainSelector}
									setInputSelectedOptions={setChainelector}
									objectName='name' objectId='chain_country_id'
									isLoading={mode === 'edit' ? isLoadingNotification : isLoadingChains}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<InfiniteMultiSelector
									inputVariant='standard'
									inputLabel={t('cws_app.notifications.store', 'Notify to store')}
									options={storeList}
									inputSelected={storeSelector}
									setInputSelectedOptions={setStoreSelector}
									objectName='parsedName' objectId='store_id'
									isLoading={mode === 'edit' ? isLoadingNotification : isLoadingStores}
									groupBy={'chain_name'}
									canSelectAll={false}
								/>
							</Grid>
						</Grid>

						<Grid item xs={12} >
							<InfiniteMultiSelector
								inputVariant='standard'
								inputLabel={t('cws_app.notifications.user', 'Notify to user')}
								options={users}
								inputSelected={usersSelector}
								setInputSelectedOptions={setUsersSelector}
								objectName='username' objectId='user_id'
								isLoading={mode === 'edit' ? isLoadingNotification : isLoadingUsers}
							/>
						</Grid>
						<Grid item xs={12} >
							<TextField
								id="outlined-disabled"
								variant="standard"
								required={mode === 'delete' ? false : true}
								value={title}
								label={t('cws_app.notifications.title', 'Title')}
								onChange={(e) => { setTitle(e.target.value) }}
								fullWidth
							/>
						</Grid>
					</>
					: null
				}


				<Grid item xs={12} >
					{
						mode === 'delete' ?
							<>
								<Typography sx={{ fontSize: '20px', color: textColor }}>
									<ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} >
										{title}
									</ReactMarkdown>
								</Typography>
								<Typography sx={{ fontSize: '16px', color: textColor }}>
									<ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} >
										{body}
									</ReactMarkdown>
								</Typography>
								<Divider sx={{ p: 1 }} />
								<Typography sx={{ fontSize: '16px', color: textColor }}>
									{activeNotification?.redacted_by}
								</Typography>
								<Typography sx={{ fontSize: '16px', color: textColor }}>
									{date}
								</Typography>
							</>
							:
							<div data-color-mode={theme.palette.type}>
								<MDEditor
									value={body}
									onChange={setBody}
								/>
							</div>
					}
				</Grid>
				<Grid item container xs={12} justifyContent={'flex-end'}>
					<Button variant="contained" color="primary" sx={{ my: 1 }}
						onClick={() => { handleChangeNotification(mode) }}
					>
						{mode === 'delete' ? t('cws_app.general.delete') : t('cws_app.general.save')}
					</Button>
				</Grid>
			</Grid>
		</StyledPaper>

	)
}


const StyledPaper = styled(
	props => {
		return <Paper elevation={1} {...props} />
	}
)(({ theme }) => {
	return ({
		width: '100%',
		background: theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
		color: theme.palette.type === 'light' ? 'black' : 'white',
		'& .MuiPaper-root': {
			backgroundColor: theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
			borderRadius: 3,
		},
	})
}
);
