import { createReducer } from './utils';
import { handleRequest, handleAvailable, handleUnavailable } from '../reducerHandlers'
import {
  SNACK_SET,
  PRODUCTS_REQUEST,
  PRODUCTS_AVAILABLE,
  PRODUCTS_UNAVAILABLE,
} from './constants';

const defaultState = {
  productsRoles: [],
  loadingProductsRoles: false,
};

// Reducer

export const reducer = createReducer(defaultState, {
  [PRODUCTS_REQUEST]: handleRequest,
  [PRODUCTS_AVAILABLE]: handleAvailable,
  [PRODUCTS_UNAVAILABLE]: handleUnavailable,
});

// Actions

export function getProductsRoles() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'productsRoles'
    const loadingName = 'loadingProductsRoles'
    dispatch({ type: PRODUCTS_REQUEST, payload: { loadingName: loadingName } });
    try {
      const response = await dataSource.getProductsRoles();
      dispatch({
        type: PRODUCTS_AVAILABLE,
        payload: { keyState: keyState, data: response, loadingName: loadingName },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: PRODUCTS_UNAVAILABLE,
        payload: { keyState: keyState, loadingName: loadingName, defaultState: [] },
      });
      const snack = {
        open: true,
        message: "There was an error getting the product's roles.",
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}
