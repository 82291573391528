import {
	Grid,
	Chip,
	Badge,
	Typography,
	Paper,
	LinearProgress,
	IconButton,
	Tooltip,
	useTheme,
	Box,
} from "@mui/material";

import SortIcon from '@mui/icons-material/Sort';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import { useTranslation } from 'react-i18next';
import { getConsolidatedTranslations } from 'core/utils/parsers';

export function HorizontalSelectorAisles(props) {
	const {
		aislesList,
		aislesData,
		isLoadingAislesData,
		selectedAisle,
		setAisleState,
		sortAislesByName,
		sortAislesByAlerts,
		selectedChain,
		linearNames
	} = props;
	const { t } = useTranslation();
	const theme = useTheme();

	const linearNamesCond = linearNames && Object.keys(linearNames).length > 0;

	return (
		<Grid item container>
			{!isLoadingAislesData && aislesList && aislesList.length ?
				<Grid item container xs={12} sx={{ mb: 1 }}>
					<Grid item container justifyContent={'space-between'}>
						<Typography sx={{ p: 1, }}>
							{t('cws_app.Walls.total_aisle')}: {aislesList.length}
							{selectedAisle && Object.keys(selectedAisle).length > 0
								&& selectedAisle['code'] !== null
								? `, ${t('cws_app.Walls.A_selected')}: ` + (linearNamesCond && selectedAisle['code'] in linearNames ? linearNames[selectedAisle['code']] : selectedAisle['code'])
								: ''}
						</Typography>
						<Grid item>
							<Tooltip placement='top' title={t('cws_app.Walls.sort_by_alert', 'Sort by Alerts')}>
								<IconButton onClick={sortAislesByAlerts} justif={'flex-end'}>
									<SortIcon />
								</IconButton>
							</Tooltip>
							<Tooltip placement='top' title={t('cws_app.Walls.sort_by_aisle', 'Sort by Name')}>
								<IconButton onClick={sortAislesByName} justif={'flex-end'}>
									<SortByAlphaIcon />
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Paper component="ul" elevation={0}
							sx={{
								display: "flex",
								justifyContent: "left",
								flexWrap: "nowrap",
								listStyle: "none",
								padding: theme.spacing(0.5),
								margin: 0,
								overflowX: "auto",
								overflowY: "hidden",
								maxWidth: "100%",
								p: 2,
								backgroundColor: '#1A242D'
							}}
						>
							{aislesList.map(aisle => {
								let alerts = aisle['alerts'][0];
								let orient = aisle['orient'];
								let aisleCode = aisle['code'];
								return (
									<li key={aisleCode}>
										<Tooltip
											placement='bottom'
											title={
												<Box sx={{ display: 'flex', flexDirection: 'column' }}>
													<Typography variant='small' sx={{ color: 'white' }}>
														{getConsolidatedTranslations(t, selectedChain, 'aisle', 'Walls', 'Aisle')}: {aisle['aisle']} {getConsolidatedTranslations(t, selectedChain, 'orient', 'Walls', 'Orient')}: {orient}
													</Typography>
													<Typography variant='small' sx={{ color: 'white' }}>{getConsolidatedTranslations(t, selectedChain, 'stockout', 'Walls', 'Stockout')}: {aisle?.alerts_stockout ?? 0}</Typography>
													<Typography variant='small' sx={{ color: 'white' }}>{getConsolidatedTranslations(t, selectedChain, 'price_difference', 'Walls', 'Price Difference')}: {aisle?.alerts_price_difference ?? 0}</Typography>
												</Box>}>
											<Badge
												badgeContent={alerts !== null ? alerts : 0}
												color="secondary"
											>
												<Chip
													label={linearNamesCond && aisleCode in linearNames ? linearNames[aisleCode] : aisleCode}
													variant={'contained'}
													onClick={() => setAisleState(aisle)}
													sx={{
														mx: 1,
														px: 0.7,
														borderRadius: 1,
														color: 'white'
													}}
													style={{
														backgroundColor: selectedAisle && Object.keys(selectedAisle).length > 0
															&& aisleCode === selectedAisle['code'] ? '#F40D42' : '#17181A'
													}}
												/>
											</Badge>
										</Tooltip>
									</li>
								)
							})}
						</Paper>
					</Grid>
				</Grid>
				: aislesData && !isLoadingAislesData ?
					<Grid item container xs={12}></Grid>
					:
					<Grid item container xs={12} alignContent='center' justifyContent='center'>
						<Typography sx={{ m: 1 }}>{t('cws_app.Walls.loading_aisles_data', 'Loading Aisles Data')}</Typography>
						<LinearProgress sx={{ width: '100%', m: 1 }} color="secondary" />
					</Grid>
			}
		</Grid>
	);
}