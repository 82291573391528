import { connect } from 'react-redux';
import WallSelector from '../../../../components/features/digitalShelf/WallSelector';
import { getUserStores, getUserSuppliers } from 'core/slices/users';
import {
    getWallsAisles,
    getWallImage,
    getLandmarks,
    getAllLandmark,
    getCoords,
    setWallLandmarks,
    setProductState,
    getAislePromotions,
    setAisleLandmarkState,
} from 'core/slices/wall';

import {
    getStoreMaps,
} from 'core/slices/digitalStore';

import {
    getStoresFloor,
} from 'core/slices/store';

import { setLinkState } from 'core/slices/link';

import {
    getSelectedClient,
    getUserStoresAllList,
    getWallsAislesData,
    getWallLandmarks,
    getAllLandmarks,
    getCoordsInfo,
    getLinkState,

    isLoadingAislesData,
    isLoadingUserStores,
    isLoadingWallLandmarks,
    isLoadingLandmark,
    isLoadingCoordsInfo,

    getSelectedWallProduct,
    getAisleLandmarkState,

    userSuppliers,
    isLoadingUserSuppliers,
    getStoreMapsObject,
    isLoadingMaps,
    getClientFormats,
    isLoadingClientFormats,

    getStoreFloor,
    isStoreFloorLoading,
} from 'core/selectors';

export default connect(state => ({
    userStores: getUserStoresAllList(state),
    selectedClient: getSelectedClient(state),
    aislesData: getWallsAislesData(state),
    wallLandmarks: getWallLandmarks(state),
    coordsInfo: getCoordsInfo(state),
    selectedProduct: getSelectedWallProduct(state),
    linkReceived: getLinkState(state),
    aisleLandmarks: getAisleLandmarkState(state),

    isLoadingUserStores: isLoadingUserStores(state),
    isLoadingAislesData: isLoadingAislesData(state),
    isLoadingLandmarks: isLoadingWallLandmarks(state),
    landmarkData: getAllLandmarks(state),
    isLoadingLandmark: isLoadingLandmark(state),
    isLoadingCoordsInfo: isLoadingCoordsInfo(state),

    suppliers: userSuppliers(state),
    isLoadingSuppliers: isLoadingUserSuppliers(state),
    isLoadingMaps: isLoadingMaps(state),
    storeMaps: getStoreMapsObject(state),
    clientFormats: getClientFormats(state),
    isLoadingClientFormats: isLoadingClientFormats(state),

    storesFloor: getStoreFloor(state),
    isStoreFloorLoading: isStoreFloorLoading(state),
}), {
    getUserStores,
    getWallsAisles,
    getWallImage,
    getLandmarks,
    getAllLandmark,
    getCoords,
    setWallLandmarks,
    setLinkState,
    setProductState,
    getAislePromotions,
    setAisleLandmarkState,
    getUserSuppliers,
    getStoreMaps,
    getStoresFloor,
})(WallSelector);
