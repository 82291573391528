import { createReducer } from "./utils";

import {
    handleRequest,
    handleAvailable,
    handleUnavailable
} from '../reducerHandlers';

import {
    PRODUCTS_STATES_REQUEST,
    PRODUCTS_STATES_AVAILABLE,
    PRODUCTS_STATES_UNAVAILABLE,
    SNACK_SET
} from './constants';


const defaultState = {
    productsStates: [],
    loading_productsStates: false,
}

export const reducer = createReducer(defaultState, {
    [PRODUCTS_STATES_REQUEST]: handleRequest,
    [PRODUCTS_STATES_AVAILABLE]: handleAvailable,
    [PRODUCTS_STATES_UNAVAILABLE]: handleUnavailable,
});


export function getProductsStates(store, supplier_ids, categories, products_status) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loading_productsStates'
        const keyState = 'productsStates'

        dispatch({ type: PRODUCTS_STATES_REQUEST, payload: { loadingName } });
        try {
            const productsStatesResponse = await dataSource.getProductsStatus(store, supplier_ids, categories, products_status);
            const productsState = productsStatesResponse?.data ?? [];
            dispatch({
                type: PRODUCTS_STATES_AVAILABLE,
                payload: { data: productsState, keyState, loadingName },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: PRODUCTS_STATES_UNAVAILABLE,
                payload: { error, keyState, loadingName },
            });
            const snack = {
                open: true,
                message: 'There was an error getting the products states',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}
