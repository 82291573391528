import { createReducer } from './utils';

import {
    NOTIFICATIONS_REQUEST,
    NOTIFICATIONS_AVAILABLE,
    NOTIFICATIONS_UNAVAILABLE,
    SNACK_SET,
} from './constants';

const defaultState = {
    loading_client_notifications_info: false,
    loading_client_notification: false,
    loading_notification: false,
    notificationsList: [],
    notification: {},
};

export const reducer = createReducer(defaultState, {
    [NOTIFICATIONS_REQUEST]: handleCustomRequest,
    [NOTIFICATIONS_AVAILABLE]: handleCustomAvailable,
    [NOTIFICATIONS_UNAVAILABLE]: handleCustomUnavailable,
});

function handleCustomRequest(state, { payload: { loadingName } }) {
    return {
        ...state,
        [loadingName]: true,
    };
}

function handleCustomAvailable(state, { payload: { keyState, loadingName, requestInfo, aditionalStates = {} } }) {
    return {
        ...state,
        [loadingName]: false,
        [keyState]: requestInfo,
        ...aditionalStates,
    };
}

function handleCustomUnavailable(state, { payload: { keyState, loadingName } }) {
    return {
        ...state,
        [keyState]: [],
        [loadingName]: false,
    };
}

export function getNotifications() {
    return async (dispatch, _, { services: { dataSource } }) => {
        dispatch({ type: NOTIFICATIONS_REQUEST, payload: { loadingName: 'loading_client_notifications_info' } });
        try {
            const response = await dataSource.getClientNotifications();
            dispatch({
                type: NOTIFICATIONS_AVAILABLE,
                payload: { keyState: 'notificationsList', loadingName: 'loading_client_notifications_info', requestInfo: response.notifications_response },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: NOTIFICATIONS_UNAVAILABLE,
                payload: { error, keyState: 'notificationsList', loadingName: 'loading_client_notifications_info' },
            });
            const snack = {
                open: true,
                message: 'Error in getting notifications list',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    }
}

export function getClientNotification(notification_id) {
    return async (dispatch, _, { services: { dataSource } }) => {
        dispatch({ type: NOTIFICATIONS_REQUEST, payload: { loadingName: 'loading_client_notification' } });
        try {
            const response = await dataSource.getClientNotifications(notification_id);
            dispatch({
                type: NOTIFICATIONS_AVAILABLE,
                payload: { keyState: 'notification', loadingName: 'loading_client_notification', requestInfo: response.notifications_response },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: NOTIFICATIONS_UNAVAILABLE,
                payload: { error, keyState: 'notification', loadingName: 'loading_client_notification' },
            });
            const snack = {
                open: true,
                message: 'Error in getting notifications list',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    }
}


export function postClientNotification(
    title, message_txt, clients_info, chains_country_info, stores_info, to_users_info, notification_type
) {
    return async (dispatch, _, { services: { dataSource } }) => {
        dispatch({ type: NOTIFICATIONS_REQUEST, payload: { loadingName: 'loading_notification' } });
        try {
            const response = await dataSource.postClientNotification(title, message_txt, clients_info,
                chains_country_info, stores_info, to_users_info, notification_type);
            dispatch({
                type: NOTIFICATIONS_AVAILABLE,
                payload: { loadingName: 'loading_notification' },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: NOTIFICATIONS_UNAVAILABLE,
                payload: { error, loadingName: 'loading_notification' },
            });
            const snack = {
                open: true,
                message: 'Error in posting notification',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    }
}


export function updateClientNotification(notification_id, title, message_txt, clients_info,
    chains_country_info, stores_info, to_users_info, notification_type
) {
    return async (dispatch, _, { services: { dataSource } }) => {
        dispatch({ type: NOTIFICATIONS_REQUEST, payload: { loadingName: 'loading_notification' } });
        try {
            const response = await dataSource.updateClientNotification(notification_id, title, message_txt, clients_info,
                chains_country_info, stores_info, to_users_info, notification_type);
            dispatch({
                type: NOTIFICATIONS_AVAILABLE,
                payload: { loadingName: 'loading_notification' },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: NOTIFICATIONS_UNAVAILABLE,
                payload: { error, loadingName: 'loading_notification' },
            });
            const snack = {
                open: true,
                message: 'Error in updating notification',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    }
}


export function deleteClientNotification(notification_id) {
    return async (dispatch, _, { services: { dataSource } }) => {
        dispatch({ type: NOTIFICATIONS_REQUEST, payload: { loadingName: 'loading_notification' } });
        try {
            const response = await dataSource.deleteClientNotification(notification_id);
            dispatch({
                type: NOTIFICATIONS_AVAILABLE,
                payload: { loadingName: 'loading_notification' },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: NOTIFICATIONS_UNAVAILABLE,
                payload: { error, loadingName: 'loading_notification' },
            });
            const snack = {
                open: true,
                message: 'Error in updating notification',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    }
}