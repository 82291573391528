import { Grid, Typography } from '@mui/material';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

// Components
import Aside from './Aside';

// Redux
import DocsImage from '../../../redux/containers/features/documentation/DocsImage';

const styles = {
  container: {
    bgcolor: 'white',
    borderRadius: 2,
    py: 3,
    px: 4,
  },
  text: {
    textAlign: 'justify',
  },
  aside: {
    py: 2,
  },
};

class DocsText extends Component {
  constructor(props) {
    super(props);
    const defaultLang = this.props.i18n.language ? this.props.i18n.language.split('-')[0] : 'en';
    this.state = {
      markdownText: [],
      // we only have english and spanish for now
      defaultLang: ['en', 'es'].includes(defaultLang) ? defaultLang : 'en',
    };
  }
  _isMounted = false;

  loadReadme = async () => {
    const { title } = this.props;
    var readmePath;
    try {
      readmePath = require(`../../../../docs/${this.state.defaultLang}/${title}.md`);
    } catch (error) {
      readmePath = require(`../../../../docs/${this.state.defaultLang}/default.md`);
    }
    await fetch(readmePath)
      .then(response => {
        return response.text();
      })
      .then(text => {
        const split_text = text.split('SPLIT_STRING');
        if (this._isMounted) {
          this.setState({
            markdownText: split_text,
          });
        }
      });
  };

  async componentDidMount() {
    this._isMounted = true;
    await this.loadReadme();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.defaultLang !== this.state.defaultLang) {
      this.setState({
        ...this.state,
        // we only have english and spanish for now
        defaultLang: ['en', 'es'].includes(this.state.defaultLang) ? this.state.defaultLang : 'en',
      });
      await this.loadReadme();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { markdownText } = this.state;
    const { title, notification, notificationType, hasImage, t } = this.props;
    return (
      <Grid container item sx={styles.container}>
        {markdownText.map((markdown_text, markdown_index) => {
          return (
            <Grid container item key={`text_${title}_${markdown_index}`}>
              {markdown_text.trim() !== 'HERE_IS_A_ASIDE' ? (
                <Typography
                  variant="body1"
                  fontFamily="Roboto"
                  component={'div'}
                  sx={styles.text}>
                  <>
                    <ReactMarkdown
                      className="markdown"
                      children={markdown_text.trim()}
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[rehypeRaw]}
                    />
                  </>
                </Typography>
              ) : (
                <Grid container item sx={styles.aside}>
                  <Aside
                    t={t}
                    notification={notification[Math.floor(markdown_index / 2)]}
                    notificationType={
                      notificationType[Math.floor(markdown_index / 2)]
                    }
                  />
                </Grid>
              )}
            </Grid>
          );
        })}

        {/* Image */}
        {hasImage && <DocsImage title={title} />}
      </Grid>
    );
  }
}

export default withTranslation()(DocsText);
