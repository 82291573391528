import { Box, FormControl, FormControlLabel, Grid, Paper, Radio, RadioGroup, SpeedDial, SpeedDialAction, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';

import StoreMap from '../../tools/StoreMap';
import { IconComponent } from "@zippeditoolsjs/zippedi-icons";
import { highlightedProductColor, mapBackgroundColor } from "../../utils/colors";
import { colorBasedOnTheme } from "../../utils/colors";
import CloseButton from "../../tools/CloseButton";
import { getChainMeaning } from 'core/utils/parsers';
import { isEmptyOrUndefined } from "core/utils/validators";

const RADIUSPLUS = 4,
    INTERVALAISLE = 3;
export default function InteractiveMap(props) {
    const {
        chain,
        hasRobot,
        storeMap,
        storeCategories,
        taskType,
        setIsSidenavOpen,
        selectedAlert,
        setSelectedAlert,
        setAisleLandmarkMap,
        setAislesList,
        robotSession,
        setRobotSession,
        setRefresh,
        heightRef,
        isHoverActive = true,
        isCLickActive = true,
        canShowActions = true,
        selectedAisle = {},
        selectedDots = [],
        highlightedBoxes = [],
        isZoomActive,
        visualizationMode: inputVisualizationMode = 'ds_aisle',
        taskData,
    } = props;
    const { t } = useTranslation();
    const theme = useTheme();

    const [isHoverOn, setIsHoverOn] = useState(false);
    const [categoriesOnMap, setCategoriesOnMap] = useState({});
    const [selectedAlertIndex, setSelectedAlertIndex] = useState(null);
    const [selectedElement, setSelectedElement] = useState({});
    const [isTooltipFixed, setIsTooltipFixed] = useState(false);
    const [landmarkMap, setLandmarkMap] = useState({})
    const [taskDataGrouped, setTaskDataGrouped] = useState([])
    // Speed dial states
    const [visualizationMode, setVisualizationMode] = useState(inputVisualizationMode);
    const [openSpeedDial, setOpenSpeedDial] = useState(false);
    const [openLabel, setOpenLabel] = useState(false);
    const [openRobot, setOpenRobot] = useState(false);
    const speedDialRef = useRef(null);
    const labelRef = useRef(null);
    const robotRef = useRef(null);
    // Highlighted element
    const [highlightStyle, setHighlightStyle] = useState({
        height: '20px',
        width: '20px',
        top: 0,
        left: 0,
        backgroundColor: '#ffa6004b',
        position: 'absolute',
        borderRadius: '50%',
        border: `2px ${highlightedProductColor()} solid`,
        opacity: 0,
        zIndex: -1,
        transition: 'opacity 0.2s',
    });
    const [isTooltipPined, setIsTooltipPined] = useState(false);

    const selectedAlerts = useState({})[0];

    // Lyfecycle methods

    useEffect(() => {
        if (taskData?.length) {
            const categoryAux = mapCategoriesLayout(storeMap.map_info, taskData, taskType === 'promotions');
            setCategoriesOnMap(categoryAux);
        }
    }, [storeMap])

    // Hook that closes the speed dial or the filters depending on clicks outside of the passed ref
    useEffect(() => {
        // Close speed dial if clicked on outside of element
        function handleClickOutside(event) {
            if (labelRef.current && !labelRef.current.contains(event.target)) {
                setOpenLabel(false);
            } else if (robotRef.current && !robotRef.current.contains(event.target)) {
                setOpenRobot(false);
            } else if (!labelRef.current && !robotRef.current && speedDialRef.current && !speedDialRef.current.contains(event.target)) {
                setOpenSpeedDial(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [speedDialRef, labelRef, robotRef]);

    useEffect(() => {
        if (selectedAlert) {
            setSelectedElement(selectedAlert);
            setIsTooltipPined(true);
            setIsSidenavOpen(true);
            setSelectedAlert(selectedAlert)
            setTimeout(() => {
                highlightElement(selectedAlert);
                const [xElement, yElement] = selectedAlert.map_point_scaled;
                moveTooltipCanvas({ mode: 'moveOn', coord: [xElement, yElement] });
            }, 150);
        }
    }, [selectedAlert]);

    // Methods
    const mapCategoriesLayout = (mapInfo, taskTableInfo, parseCategories = false) => {
        const categoryAux = {};
        if (storeCategories?.length > 1) {
            const scale_valueX = mapInfo.scale_value[0],
                scale_valueY = mapInfo.scale_value[1],
                category_i = parseCategories ? `store_category_${storeCategories[1].level}` : `category_${storeCategories[1].level}`;

            storeCategories.forEach(categoryInfo => {
                categoryAux[categoryInfo.value] = { name: categoryInfo.category_name };
            })
            taskTableInfo.forEach(detectionInfo => {
                if (detectionInfo.map_point) {
                    // separating darkStore of the rest of store to have two categories on the map
                    let categoryValue, categoryInfo;
                    if (detectionInfo.aisle?.includes('DS')) {
                        categoryValue = `${detectionInfo[category_i]}-DS`;
                        categoryInfo = categoryAux[categoryValue];
                        // TODO: check why something not come the name of the category like: J508 27-07-2022
                        if (!categoryInfo) {
                            if (categoryAux[detectionInfo[category_i]]?.name) {
                                categoryAux[categoryValue] = { name: categoryAux[detectionInfo[category_i]].name }
                                // categoryAux[categoryValue] = { name: 'No detectado' }
                            }
                        };
                    } else {
                        categoryValue = detectionInfo[category_i];
                        categoryInfo = categoryAux[categoryValue];
                    }

                    const map_pointX = detectionInfo.map_point[0],
                        map_pointY = detectionInfo.map_point[1];
                    if (categoryInfo && categoryInfo.map_point) {
                        const totalAlerts = categoryInfo.totalAlerts;
                        categoryInfo.totalAlerts++;
                        categoryInfo.map_point[0] = (categoryInfo.map_point[0] * (totalAlerts - 1) + map_pointX * scale_valueX) / totalAlerts;
                        categoryInfo.map_point[1] = (categoryInfo.map_point[1] * (totalAlerts - 1) + map_pointY * scale_valueY) / totalAlerts;
                    } else if (categoryInfo) {
                        categoryInfo.map_point = [map_pointX * scale_valueX, map_pointY * scale_valueY];
                        categoryInfo.totalAlerts = 1;
                    }
                }
            });
        }
        return categoryAux;
    }

    const moveTooltipCanvas = ({ mode, coord, isTooltipFixed = false }) => {
        const tooltipCanvas = document.getElementById('tooltipCanvas');
        const responsiveCanvas = document.getElementById('responsive-canvas');
        if (mode === 'moveOn') {
            setIsTooltipFixed(isTooltipFixed);
            const [xElement, yElement] = coord;
            // Scale the alert coordinates for the tooltip
            const width = responsiveCanvas.offsetWidth
            const height = responsiveCanvas.offsetHeight
            const original_width = storeMap?.map_info?.width
            const original_height = storeMap?.map_info?.height
            const scaled_x = xElement * width / original_width
            const scaled_y = yElement * height / original_height
            const [newLeft, newTop] = [`${scaled_x + 30}px`, `${scaled_y + 50}px`]; // + 30/50 so the tooltip is in the correct position
            tooltipCanvas.style.left = newLeft;
            tooltipCanvas.style.top = newTop;
            tooltipCanvas.style.opacity = '1';
            tooltipCanvas.style.zIndex = '1';
        } else if (mode === 'close') {
            setIsTooltipFixed(false);
            tooltipCanvas.style.opacity = '0';
            tooltipCanvas.style.zIndex = '-1';
        } else {
            tooltipCanvas.style.opacity = '0';
            tooltipCanvas.style.zIndex = '-1';
        }
    }

    const getMousePos = (event, parsedTaskData) => {
        const aislesClientNames = storeMap.map_info.client_linear_names;
        const x = event.clientX,
            y = event.clientY;
        let notFound = true;
        for (let index = 0; index < parsedTaskData.taskDataGrouped.length; index++) {
            const element = parsedTaskData.taskDataGrouped[index];
            const [xElement, yElement] = element.map_point_scaled;
            const radius = element.radius;
            const mouseIn = xElement - (radius + RADIUSPLUS) <= x && x <= xElement + (radius + RADIUSPLUS) && yElement - (radius + RADIUSPLUS) <= y && y <= yElement + (radius + RADIUSPLUS);
            if (mouseIn && (!isHoverOn || index !== selectedAlertIndex)) {
                notFound = false;
                setIsHoverOn(true);
                setSelectedAlertIndex(index);

                // add client aisle name
                const aisleName = element.aisle_name
                const clientAisleName = aislesClientNames.hasOwnProperty(aisleName) ? aislesClientNames[aisleName] : aisleName;
                element["client_aisle_name"] = clientAisleName
                element["hovered"] = true
                setSelectedElement(element);
                setLandmarkMap(parsedTaskData.aisleLandmarkMap)
                setTaskDataGrouped(parsedTaskData.taskDataGrouped)
                moveTooltipCanvas({ mode: 'moveOn', coord: [xElement, yElement] });
                break;
            } else if (mouseIn && isHoverOn) {
                notFound = false;
                break;
            }
        }
        if (notFound) {
            setSelectedElement({ ...selectedElement, hovered: false })
            if (!isTooltipPined) {
                setSelectedElement({ index: undefined, groupName: undefined, aisle_name: undefined, client_aisle_name: undefined, landmark: undefined, map_point: undefined, tramo: undefined, radius: undefined, completed: undefined, totalAlerts: undefined });
                if (!isTooltipFixed) { moveTooltipCanvas({}) };
                setIsHoverOn(false);
            }
        }
    }

    const highlightElement = (element, isActive = true) => {
        if (isActive) {
            const responsiveCanvas = document.getElementById('responsive-canvas');
            const [xElement, yElement] = element.map_point_scaled;
            // Scale the alert coordinates for the tooltip
            const width = responsiveCanvas.offsetWidth
            const height = responsiveCanvas.offsetHeight
            const original_width = storeMap?.map_info?.width
            const original_height = storeMap?.map_info?.height
            const scaled_x = xElement * width / original_width
            const scaled_y = yElement * height / original_height
            const [newLeft, newTop] = [scaled_x + 16.5, scaled_y + 56]; // + 30/50 so the tooltip is in the correct position

            const highlighterRadius = element.radius + RADIUSPLUS;
            const newStyle = {
                ...highlightStyle
                , left: `${newLeft - highlighterRadius}px`, top: `${newTop - highlighterRadius}px`
                , opacity: '1', zIndex: '1'
                , height: `${2 * highlighterRadius}px`, width: `${2 * highlighterRadius}px`
            }
            setHighlightStyle(newStyle);
        } else {
            setHighlightStyle({ ...highlightStyle, opacity: '0', zIndex: '-1' });
        }
    }

    const handleCanvasClick = (event, forceCloseTooltip = false) => {
        if (selectedElement?.hovered && !forceCloseTooltip) {
            setIsTooltipPined(true);
            setIsSidenavOpen(true);
            setSelectedAlert(selectedElement)
            setAisleLandmarkMap(landmarkMap)
            setAislesList(taskDataGrouped)
            setTimeout(() => {
                highlightElement(selectedElement);
                const [xElement, yElement] = selectedElement.map_point_scaled;
                moveTooltipCanvas({ mode: 'moveOn', coord: [xElement, yElement] });
            }, 150);
        } else {
            setIsTooltipPined(false);
            setSelectedElement({ hovered: false, index: undefined, groupName: undefined, aisle_name: undefined, client_aisle_name: undefined, landmark: undefined, map_point: undefined, tramo: undefined, radius: undefined, completed: undefined, totalAlerts: undefined });
            setIsHoverOn(false);
            moveTooltipCanvas({});
            highlightElement(selectedElement, false);
            if (!forceCloseTooltip) setIsSidenavOpen(false);
        }
    }

    const handleCanvasHover = (event, drawerReturn) => {
        getMousePos(event, drawerReturn);
    }

    // Speed dial actions and handlers
    const actions = [
        { icon: <IconComponent iconName={'filter'} style={{ fontSize: '20px' }} />, name: t('cws_app.digital_store.label_filter', 'Label filter'), operation: 'label' },
        { icon: <IconComponent iconName={'map'} style={{ fontSize: '20px' }} />, name: t('cws_app.digital_store.change_robot_map', 'Change robot map'), operation: 'robot' },
        { icon: <IconComponent iconName={'refresh'} style={{ fontSize: '20px' }} />, name: t('cws_app.digital_store.refresh_map', 'Refresh map'), operation: 'refresh' },
    ];

    const handleSpeedDialOpen = () => setOpenSpeedDial(true);

    const handleSpeedDialActionClick = (e, operation) => {
        e.preventDefault();
        setOpenSpeedDial(true);
        switch (operation) {
            case 'label':
                setOpenLabel(true);
                setOpenRobot(false);
                break;
            case 'robot':
                setOpenLabel(false);
                setOpenRobot(true);
                break;
            case 'refresh':
                setRefresh(true);
                break;
            default:
                break;
        }
    }

    // Label Filter
    const handleLabelChange = (event) => {
        setVisualizationMode(event?.target?.value)
    }

    // Robot Filter
    const handleRobotChange = (event) => {
        setRobotSession(event?.target?.value)
    }

    return (
        <Paper ref={heightRef} style={{ position: 'relative', width: '100%', height: 'fit-content', padding: '0 1em', backgroundColor: mapBackgroundColor() }}>
            {/* Speed dial and custom tooltips */}
            <Grid container direction='column' alignContent='flex-end' sx={{ transform: 'translateZ(0px)', flexGrow: 1 }}>
                {canShowActions &&
                    <SpeedDial
                        ref={speedDialRef}
                        ariaLabel="Map configurations"
                        FabProps={{ size: 'small' }}
                        icon={<IconComponent iconName={'settings'} style={{ fontSize: '20px' }} />}
                        direction="left"
                        open={openSpeedDial}
                        onClick={handleSpeedDialOpen}
                    >
                        {actions.map((action) => {
                            return (
                                <SpeedDialAction
                                    key={action.name}
                                    icon={action.icon}
                                    tooltipTitle={action.name}
                                    tooltipPlacement='top'
                                    onClick={(e) => handleSpeedDialActionClick(e, action.operation)}
                                />
                            )
                        })}
                    </SpeedDial>

                }
                {openLabel &&
                    <Paper ref={labelRef} sx={{ position: 'absolute', right: '1em', top: '4em', padding: 1 }}>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="label-filter"
                                name="label-filter"
                                value={visualizationMode}
                                onChange={handleLabelChange}
                            >
                                <FormControlLabel value="ds_basic" control={<Radio size='small' />} label={t('cws_app.general.none', "None")} />
                                <FormControlLabel value="ds_aisle" control={<Radio size='small' />} label={t('cws_app.general.Aisles', "Aisles")} />
                                <FormControlLabel value="ds_category" control={<Radio size='small' />} label={t('cws_app.general.categories', "Cateogries")} />
                            </RadioGroup>
                        </FormControl>
                    </Paper>
                }
                {openRobot &&
                    <Paper ref={robotRef} sx={{ position: 'absolute', right: '5em', top: '4em', padding: 1 }}>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="robot-filter"
                                name="robot-filter"
                                value={robotSession}
                                onChange={handleRobotChange}
                            >
                                {storeMap?.sessions.map((session) => {
                                    return (
                                        <FormControlLabel value={session?.session} key={session?.floor_number} control={<Radio size='small' />} label={
                                            t('cws_app.digital_store.sel_floor_i', 'Floor {{floorIndex}}', { floorIndex: session?.floor_number })
                                        }
                                        />
                                    )
                                })}
                            </RadioGroup>
                        </FormControl>
                    </Paper>
                }
            </Grid>
            <StoreMap
                storeMap={storeMap.map_info}
                tasksData={taskData}
                groupingAlertsBy={storeMap.GROUPINGALERTSBY}
                categoriesOnMap={categoriesOnMap}
                visualizationMode={visualizationMode}
                handleCanvasClick={isCLickActive ? handleCanvasClick : () => { }}
                handleCanvasHover={isHoverActive ? handleCanvasHover : () => { }}
                selectedAisle={selectedAisle?.code}
                isZoomActive={isZoomActive}
                selectedDots={selectedDots}
                highlightedBoxes={highlightedBoxes}
            />

            <Box id="highlighter" style={highlightStyle}></Box>
            {(!isEmptyOrUndefined(selectedElement, 'dict') || !isEmptyOrUndefined(selectedAlerts, 'dict')) &&
                <Paper id="tooltipCanvas" sx={{ position: 'absolute', minWidth: '15em', p: 2, color: colorBasedOnTheme(theme, 'color-darkTheme'), backgroundColor: colorBasedOnTheme(theme, 'bgcolor-darkTheme') }}>
                    {/* Map products tooltips */}
                    {selectedElement?.aisle_name ?
                        <Grid container direction='column' spacing={2}>
                            <Grid container item justifyContent='flex-end' alignItems='center' sx={{ position: 'absolute', top: '-1em' }}>
                                <CloseButton setIsOpen={() => handleCanvasClick({}, true)} isColorbgAlwaysDark={true} />
                            </Grid>

                            <Grid container item justifyContent='center' mt={1}>
                                <Typography variant='h6'>{t('cws_app.general.aisle', 'Aisle')} {selectedElement?.client_aisle_name ? selectedElement?.client_aisle_name : selectedElement?.aisle_name}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='subtitle2'>{t('cws_app.digital_store.alerts', 'Alerts')}: {selectedElement.totalAlerts}</Typography>
                                <Typography variant='subtitle2'>{t('cws_app.digital_store.complete_alerts', 'Completed alerts')}: {selectedElement.completed}</Typography>
                            </Grid>
                            {hasRobot && selectedElement.tramo ?
                                <Grid item>
                                    <Typography variant='caption'>{t('cws_app.digital_store.linear', 'Linear')}: {(selectedElement.tramo - 1) * INTERVALAISLE}m - {selectedElement.tramo * INTERVALAISLE}m</Typography>
                                </Grid>
                                :
                                <Grid item>
                                    <Typography variant='caption'>{getChainMeaning(t, chain, 'landmark', 'landmark')}: {selectedElement.landmark}</Typography>
                                </Grid>
                            }
                        </Grid>
                        :
                        <Grid container direction='column' spacing={2}>
                            <Grid container item justifyContent='center'>
                                <Typography variant='h6'>{t('cws_app.general.aisle', 'Aisle')} {selectedAlerts?.client_aisle_name ? selectedAlerts?.client_aisle_name : selectedAlerts?.aisle_name}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='subtitle2'>{t('cws_app.digital_store.alerts', 'Alerts')}: {selectedAlerts.totalAlerts}</Typography>
                                <Typography variant='subtitle2'>{t('cws_app.digital_store.complete_alerts', 'Completed alerts')}: {selectedAlerts.completed}</Typography>
                            </Grid>
                            {hasRobot && selectedAlerts.tramo ?
                                <Grid item>
                                    <Typography variant='caption'>{t('cws_app.digital_store.linear', 'Linear')}: {(selectedAlerts.tramo - 1) * INTERVALAISLE}m -{selectedAlerts.tramo * INTERVALAISLE}m</Typography>
                                </Grid>
                                :
                                <Grid item>
                                    <Typography variant='caption'>{getChainMeaning(t, chain, 'landmark', 'landmark')}: {selectedAlerts.landmark}</Typography>
                                </Grid>
                            }
                        </Grid>
                    }
                </Paper>
            }
        </Paper>
    );
}
