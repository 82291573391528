import { useEffect, useState } from "react";

import {
	Box,
	CircularProgress,
	Grid,
	Typography,
	List,
	ListItem,
	ListItemText,
	ListItemAvatar,
	Divider,
} from '@mui/material';

import { useTranslation } from "react-i18next";
import { getConsolidatedTranslations } from 'core/utils/parsers';
import { format } from '../../utils/converters'

import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

import Carousel from 'react-material-ui-carousel'
//import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const iconHUA = {
	'detected_products': 'stock',
	'stock': 'bag-check-outline',
	'stockout': 'stock-out',
	'price_difference': 'price-difference',
	'spread_to_fill': 'grid-outline',
	'missing_label': 'remove-circle-outline',
	'seen_on_day': 'calendar-outline',
	'extra_label': 'price-difference'
}

export default function CustomCardContent(props) {
	const {
		selectedProduct,
		selectedStore,
		dateState,
		missingLabelInfo,
		misplacedSignedImages,
		currencySymbol,
		clientFormats,
	} = props;

	const { t, i18n } = useTranslation();

	const [cardDataHUA, setCardDataHua] = useState([]);
	const [productInfo, setProductInfo] = useState(null);

	useEffect(() => {
		if (missingLabelInfo) {
			setCardDataHua(['detected_products', 'stock', 'stockout', 'price_difference', 'spread_to_fill', 'missing_label', 'extra_label', 'seen_on_day']);
		} else {
			setCardDataHua(['detected_products', 'stock', 'stockout', 'price_difference', 'spread_to_fill', 'seen_on_day']);
		}
	}, [missingLabelInfo])

	// Parametrize the product info
	useEffect(() => {
		if (selectedProduct && selectedProduct?.texts) {
			let master_positions = '';
			if (selectedProduct?.store_info?.master_positions?.length > 1) {
				// Join the theoretical positions into one string for the misplaced label if it comes more than one position
				selectedProduct?.store_info?.master_positions.forEach((location, index) => {
					master_positions += location?.landmark ? location?.landmark + (index < selectedProduct?.store_info?.master_positions.length - 1 ? ', ' : '') : ''
				})
				master_positions = <div>{getConsolidatedTranslations(t, selectedStore?.chain_name, 'landmark', 'Walls', 'Landmark')}: {master_positions}</div>
			} else if (selectedProduct?.store_info?.master_positions?.length === 1) {
				// Pass a react node to the secondary prop of the ListItemText for the misplaced label if it comes only one position
				master_positions = <div>
					<div>{getConsolidatedTranslations(t, selectedStore?.chain_name, 'landmark', 'Walls', 'Landmark')}: {selectedProduct?.store_info?.master_positions[0]?.landmark !== null ? selectedProduct?.store_info?.master_positions[0]?.landmark : '-'}</div>
					<div>{t('cws_app.Walls.level', 'Level')}: {selectedProduct?.store_info?.master_positions[0]?.level !== null ? selectedProduct?.store_info?.master_positions[0]?.level : '-'}</div>
					<div>{t('cws_app.Walls.sequence', 'Sequence')}: {selectedProduct?.store_info?.master_positions[0]?.sequence !== null ? selectedProduct?.store_info?.master_positions[0]?.sequence : '-'}</div>
				</div>
			}
			const seenOnDay = format(dateState, i18n.language, clientFormats?.date_format);
			setProductInfo({
				price: { data: selectedProduct?.price > 0, icon: 'pricetag', secondary: currencySymbol + selectedProduct?.price, primary: getConsolidatedTranslations(t, selectedStore?.chain_name, 'price', 'Walls', 'Price') },
				ean: { data: selectedProduct?.ean.toString().length > 1, icon: 'barcode-outline', secondary: selectedProduct?.ean, primary: getConsolidatedTranslations(t, selectedStore?.chain_name, 'ean', 'Walls', 'Barcode') },
				item: { data: selectedProduct?.item.toString().length > 1, icon: 'disc-outline', secondary: selectedProduct?.item, primary: getConsolidatedTranslations(t, selectedStore?.chain_name, 'item', 'Walls', 'SKU') },
				name_category: { data: selectedProduct?.name_category, icon: 'apps-outline', secondary: selectedProduct?.name_category, primary: t('cws_app.general.sel_category') },
				landmark: { data: selectedProduct?.landmark, icon: 'location', secondary: selectedProduct?.landmark, primary: getConsolidatedTranslations(t, selectedStore?.chain_name, 'landmark', 'Walls', 'Landmark') },
				seen_on_day: { data: seenOnDay, icon: 'calendar-outline', secondary: seenOnDay, primary: t('cws_app.Walls.seen_on_day') },
				master_positions: { data: selectedProduct?.store_info?.master_positions?.length > 0, icon: 'location', secondary: master_positions, primary: t('cws_app.Walls.theoretical_positions', 'Theoretical Positions') },
			});
		}
	}, [selectedProduct])

	return (
		<Box sx={{ width: '100%' }}>
			{selectedProduct?.texts ?
				<Grid container alignItems={'center'}>
					<Grid item xs>
						<List dense sx={styles.rootList}>
							<Box sx={[{ height: selectedProduct?.misplaced_product ? 250 : selectedProduct?.store_info?.master_positions?.length > 0 ? 300 : 230 }, styles.boxList]}>
								{productInfo && Object.keys(productInfo).map((item, idx) => {
									return (
										<>
											{productInfo[item].data ?
												<ListItem key={item + '-' + idx} sx={styles.listItem}>
													<ListItemAvatar> <IconComponent iconName={productInfo[item].icon} style={styles.icon} /> </ListItemAvatar>
													<ListItemText
														primary={productInfo[item].primary}
														secondary={productInfo[item].secondary}
														secondaryTypographyProps={styles.secondaryText}
													/>
												</ListItem>
												: null
											}
										</>)
								})}
							</Box>
						</List>
					</Grid>
					{selectedProduct && selectedProduct?.misplaced_product ?
						<Grid item container xs justifyContent={'center'} alignItems={'center'}>
							{misplacedSignedImages?.length ?
								<Carousel id='carousel' height={100} sx={{ width: '150px', m: 0, p: 0 }} navButtonsAlwaysInvisible fullHeightHover={false} >
									{misplacedSignedImages.map((item, idx) => {
										return (
											<Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
												<img key={idx} src={item} alt='' style={{ maxHeight: 100, maxWidth: 140, borderRadius: '5px' }} />
											</Box>
										)
									})}
								</Carousel>
								:
								<Box sx={{ p: 1, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
									<CircularProgress m={1} />
									<Typography m={1} fontSize={10}>Loading...</Typography>
								</Box>
							}
						</Grid>
						: null
					}
				</Grid>

				: selectedProduct?.data ?
					<Grid container>
						<List dense sx={styles.rootList}>
							<ListItem sx={[styles.listItemLandmark, { my: 0 }]}>
								<ListItemAvatar>
									<IconComponent iconName={'location'} style={styles.icon} height={'1.3em'} />
								</ListItemAvatar>
								<ListItemText
									primary={
										getConsolidatedTranslations(t, selectedStore?.chain_name, 'landmark', 'Walls', 'landmark')
										+ ': ' + t(`cws_app.Walls.${selectedProduct.landmark_type}`, selectedProduct.landmark_type)
									}
								/>
							</ListItem>
							<Divider sx={styles.divider} />
							<Box sx={[{ height: cardDataHUA.length > 6 ? 230 : 180 }, styles.boxList]}>
								{cardDataHUA.map((item, idx) =>
									<ListItem key={item + '-' + idx} sx={styles.listItem}>
										<ListItemAvatar>
											<IconComponent iconName={iconHUA[item]} style={styles.icon} height={'1.2em'} />
										</ListItemAvatar>
										<ListItemText
											primary={t('cws_app.Walls.' + item.toLowerCase(), item)}
											secondary={
												item === 'seen_on_day' ? format(dateState, i18n.language, clientFormats?.date_format)
													: item === 'missing_label' ?
														(missingLabelInfo?.hasOwnProperty(selectedProduct.landmark) ? missingLabelInfo[selectedProduct.landmark] : 0)
														: item === 'detected_products' ?
															selectedProduct?.data['total']
															: selectedProduct?.data[item.toLowerCase()]
											}
											secondaryTypographyProps={styles.secondaryText}
										/>
									</ListItem>
								)}
							</Box>
						</List>
					</Grid>

					:
					<Grid container>
						<List dense sx={{ border: '1px solid #0000001f', borderTop: '0px', overflowY: 'auto' }}>
							<ListItem>
								<ListItemAvatar> <IconComponent iconName={"apps-outline"} style={styles.icon} /> </ListItemAvatar>
								<ListItemText
									primary={getConsolidatedTranslations(t, selectedStore?.chain_name, 'store_catalog', 'Walls', 'store_catalog')}
									secondary={selectedProduct?.catalog}
									secondaryTypographyProps={styles.secondaryText}
								/>
							</ListItem>
							<ListItem>
								<ListItemAvatar> <IconComponent iconName={"calendar-outline"} style={styles.icon} /> </ListItemAvatar>
								<ListItemText
									primary={t('cws_app.Walls.start_date')}
									secondary={format(new Date(selectedProduct?.start_date ?? null), i18n.language, clientFormats?.date_format)}
									secondaryTypographyProps={styles.secondaryText}
								/>
							</ListItem>
							<ListItem>
								<ListItemAvatar> <IconComponent iconName={"calendar-outline"} style={styles.icon} /> </ListItemAvatar>
								<ListItemText
									primary={t('cws_app.Walls.end_date')}
									secondary={format(new Date(selectedProduct?.end_date ?? null), i18n.language, clientFormats?.date_format)}
									secondaryTypographyProps={styles.secondaryText}
								/>
							</ListItem>
						</List>
					</Grid>
			}
		</Box >
	)
}


const styles = {
	rootList: {
		width: '100%',
		maxHeight: '13em',
		border: '1px solid #0000001f',
		borderTop: '0px',
		overflowY: 'auto',
	},
	divider: {
		borderTop: '1px solid #ffffff52',
		borderRadius: 1,
		width: '100%',
	},
	title: {
		fontSize: 10,
		fontWeight: 'bold',
	},
	body: {
		fontSize: 8,
	},
	bottomText: {
		fontSize: '14px',
		mb: 1
	},
	icon: {
		fontSize: 20,
		mx: 1
	},
	listItem: {
		width: 215,
	},
	listItemLandmark: {
		width: 300,
	},
	boxList: {
		width: '100%',
		display: 'flex',
		flexFlow: 'column wrap',
		gap: "0 0px",
		overflow: 'auto',
		my: 0
	},
	secondaryText: { color: '#ffffffc2' }
}
