// Reducers for most slices. 
// If you need something custom, add it to the corresponding file in /slices

export function handleRequest(state, { payload: { loadingName } }) {
	return {
		...state,
		[loadingName]: true,
	};
}

export function handleAvailable(state, { payload: { keyState, data, loadingName, aditionalStates = {} } }) {
	return {
		...state,
		[keyState]: data,
		[loadingName]: false,
		...aditionalStates,
	};
}

export function handleUnavailable(state, { payload: { keyState, loadingName, defaultState = [], aditionalStates = {} } }) {
	return {
		...state,
		[keyState]: defaultState,
		[loadingName]: false,
		...aditionalStates,
	};
}