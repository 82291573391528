import { useEffect, useState } from 'react';
import { Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Selector from '../../tools/Selector';
import { useTranslation } from 'react-i18next';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { isEmptyOrUndefined } from "core/utils/validators";
import InfiniteMultiSelector from '../../tools/InfiniteMultiSelector';
import { FormatToSentenceCase } from 'core/utils/parsers';

export default function RolesTable(props) {
  const {
    theme,
    selectedClient,
    profile,
    setProfile,
    newRole,
    setNewRole,
    newChain,
    setNewChain,
    suppliers,
    isLoadingSuppliers,
    userRoles,
    isLoadingUserRoles,
    chains,
    isLoadingChains,
    getStoreCategories,
    categories,
    isLoadingCategories,
    userStores,
    isLoadingUserStores,
    profileIDs,
    setProfileIDs,
  } = props;
  const { t } = useTranslation();
  const [newSupplier, setNewSupplier] = useState(null);
  const [newStores, setNewStores] = useState([]);
  const [newCategories, setNewCategories] = useState([]);
  const [stores, setStores] = useState([]);
  const [categoriesParsed, setCategoriesParsed] = useState([]);
  const [userRolesParsed, setUserRolesParsed] = useState([]);

  // Lifecycle methods

  // Format the user roles to be displayed in the selector in sentence case
  useEffect(() => {
    if (!isEmptyOrUndefined(userRoles, 'array')) {
      const parsedRoles = userRoles.map((role) => ({ ...role, role_name: FormatToSentenceCase(role.role_name) }));
      setUserRolesParsed(parsedRoles);
    }
  }, [userRoles]);

  useEffect(() => {
    if (!isEmptyOrUndefined(newStores, 'array') && newStores[0]?.store_id !== null && selectedClient?.client_id) {
      // Gets the categories only from the first store in the list, otherwise it's a mess
      getStoreCategories(newStores[0].store_id, selectedClient.client_id);
    }
  }, [newStores]);

  // Filter 'userStores' keeping only the stores that belongs to 'newChain' for the stores selector
  useEffect(() => {
    if (!isEmptyOrUndefined(newChain, 'dict')) {
      const filteredStores = userStores.filter((store) => store.chain_name === newChain.name);
      setStores(filteredStores);
    }
  }, [newChain]);

  // Category name parsed with its value
  useEffect(() => {
    if (!isEmptyOrUndefined(categories, 'array')) {
      let parsedCategories = categories.map((category) => ({ ...category, parsed_name: `(${category.value}) ${category.category_name}` }));
      parsedCategories = [{ value: null, category_name: 'All', parsed_name: 'All' }, ...parsedCategories.splice(1)];
      setCategoriesParsed(parsedCategories);
    }
  }, [categories]);

  // Methods

  const addRow = () => {
    // Add the same categories for all the stores
    const storesWithCategories = newStores.map(store => (
      {
        ...store,
        categories: newCategories,
      }
    ))
    const newData = {
      supplier_id: newSupplier?.id ? newSupplier?.id : null,
      role_id: newRole?.role_id,
      chain: newChain?.name,
      stores: storesWithCategories,
    }

    setProfile([...profile, newData]);
    setNewSupplier('');
    setNewRole('');
    setNewChain('');
    setNewStores([]);
    setNewCategories([]);
  };

  const deleteRow = ({ profile_id }) => {
    setProfileIDs([...profileIDs, profile_id]);
    setProfile(profile.filter((row) => row.profile_id !== profile_id));
  };

  return (
    <TableContainer>
      <Table>
        {/* Header */}
        <TableHead>
          <TableRow>
            <TableCell>{t('cws_app.general.Supplier', 'Supplier')}</TableCell>
            <TableCell>{t('cws_app.staff.Rol', 'Role')}</TableCell>
            <TableCell>{t('cws_app.general.chain', 'Chain')}</TableCell>
            <TableCell>{t('cws_app.general.Store', 'Store')}</TableCell>
            <TableCell>{t('cws_app.general.category', 'Category')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* Rows */}
          {profile?.map((row, index) => (
            <TableRow key={index}>
              <TableCell>
                {isEmptyOrUndefined(row.supplier_id, 'id') ?
                  ''
                  :
                  `(${row.supplier_id}) ${suppliers.find(supplier => supplier.id === row.supplier_id)?.name}`
                }
              </TableCell>
              <TableCell>({row.role_id}) {row.role_id === 0 ? 'Admin' : FormatToSentenceCase(userRoles.find(role => role.role_id === row.role_id)?.role_name)}</TableCell>
              <TableCell>{row.chain}</TableCell>
              <TableCell>
                {row?.stores?.length > 0 ?
                  row.stores.map((store) => store.parsed_name).join(', ')
                  :
                  row.store
                }
              </TableCell>
              <TableCell>
                <Grid container wrap='nowrap' alignItems='center'>
                  {row.category_name === 'All' ? 'All' :
                    row?.stores?.[0] && row?.stores[0]?.categories?.length > 0 ?
                      row.stores[0].categories.map((category) => category.category_name).join(', ')
                      :
                      row.main_category
                  }
                  <IconButton onClick={() => deleteRow(row)} sx={{ color: theme.palette.error.main, marginLeft: 'auto' }}>
                    <IconComponent
                      iconName={'close'}
                      style={{ fontSize: '20px' }}
                    />
                  </IconButton>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            {/* New Supplier selector */}
            <TableCell>
              <Selector
                options={suppliers}
                isLoading={isLoadingSuppliers}
                labelInput={'cws_app.management.select_supplier'}
                selectedElement={newSupplier}
                setSelectedElement={setNewSupplier}
                id={['id']}
                tooltipTitle='name'
              />
            </TableCell>
            {/* New Role selector */}
            <TableCell>
              <Selector
                required
                options={userRolesParsed}
                isLoading={isLoadingUserRoles}
                optionLabel='role_name'
                labelInput={'cws_app.staff.Rol'}
                selectedElement={newRole}
                setSelectedElement={setNewRole}
                tooltipTitle='role_name'
              />
            </TableCell>
            {/* New Chain selector */}
            <TableCell>
              <Selector
                required
                options={chains}
                isLoading={isLoadingChains}
                labelInput={'cws_app.general.chain'}
                selectedElement={newChain}
                setSelectedElement={setNewChain}
              />
            </TableCell>
            {/* New Stores selector */}
            <TableCell sx={{ minWidth: '20em' }}>
              <InfiniteMultiSelector
                inputLabel={t('cws_app.general.Stores', 'Stores')}
                objectName='parsed_name'
                options={stores}
                isLoading={isLoadingUserStores}
                disabled={isLoadingUserStores || chains?.length === 0}
                inputSelected={newStores}
                setInputSelectedOptions={setNewStores}
                objectId='parsed_name'
                canSelectAll={false}
                returnEmptyList={false}
              />
            </TableCell>
            {/* New Categories selector */}
            <TableCell sx={{ minWidth: '20em' }}>
              <Grid container alignItems='center' wrap='nowrap'>
                <Grid item xs={8}>
                  <InfiniteMultiSelector
                    inputLabel={t('cws_app.general.category', 'Category')}
                    objectName='parsed_name'
                    options={categoriesParsed}
                    isLoading={isLoadingCategories}
                    disabled={isLoadingCategories || !newStores}
                    inputSelected={newCategories}
                    setInputSelectedOptions={setNewCategories}
                    objectId='parsed_name'
                    canSelectAll={false}
                    returnEmptyList={false}
                  />
                </Grid>
                <Grid container item xs={4} justifyContent='flex-end'>
                  <IconButton
                    onClick={addRow}
                    sx={{ color: theme.palette.type === 'light' ? '' : 'lightgray' }}
                    disabled={!newRole || !newChain}
                  >
                    <IconComponent
                      iconName={'checkmark'}
                      style={{ fontSize: '20px', color: newRole && newChain ? theme.palette.success.main : '' }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
