import { connect } from 'react-redux';
import Translations from '../../../components/features/translations/Translations';

import {
  getTranslationsDictionaries,
  getTranslationsAppname,
  getTranslationsLanguages,
  getTranslationsDictionary,
  updateTranslations,
  addNewTranslations,
  deleteNewTranslations,
  patchNewTranslations,
} from 'core/slices/translations';

import { getChains } from 'core/slices/backend';

import {
  getDictionaries,
  getAppNames,
  getLanguages,
  getTranslation,
  getUpdateTranslation,
  getNewTranslation,
  isLoadingDictionaries,
  isLoadingAppNames,
  isLoadingLanguages,
  isLoadingTranslations,
  getChainsList,
  isLoadingChains,
} from 'core/selectors';

export default connect(
  state => ({
    dictionariesList: getDictionaries(state),
    appNameList: getAppNames(state),
    languageList: getLanguages(state),
    translationList: getTranslation(state),
    updateTranslationDict: getUpdateTranslation(state),
    newTranslationDict: getNewTranslation(state),
    isLoadingDictionaries: isLoadingDictionaries(state),
    isLoadingAppNames: isLoadingAppNames(state),
    isLoadingLanguages: isLoadingLanguages(state),
    isLoadingTranslations: isLoadingTranslations(state),
    chainsList: getChainsList(state),
    isLoadingChains: isLoadingChains(state),
  }),
  {
    getTranslationsDictionaries,
    getTranslationsAppname,
    getTranslationsLanguages,
    getTranslationsDictionary,
    updateTranslations,
    addNewTranslations,
    deleteNewTranslations,
    patchNewTranslations,
    getChains,
  },
)(Translations);
