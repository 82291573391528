import { createReducer } from './utils';

import {
    SUPPLIERS_REQUEST,
    SUPPLIERS_AVAILABLE,
    SUPPLIERS_UNAVAILABLE,
    SNACK_SET,
} from './constants';

const defaultState = {
    loading_update_supplier: false,
    loading_supplier_stores: false,
    supplierStores: [],
};

export const reducer = createReducer(defaultState, {
    [SUPPLIERS_REQUEST]: handleCustomRequest,
    [SUPPLIERS_AVAILABLE]: handleCustomAvailable,
    [SUPPLIERS_UNAVAILABLE]: handleCustomUnavailable,
});


function handleCustomRequest(state, { payload: { loadingName } }) {
    return {
        ...state,
        [loadingName]: true,
    };
}


function handleCustomAvailable(state, { payload: { keyState, loadingName, requestInfo, aditionalStates = {} } }) {
    return {
        ...state,
        [loadingName]: false,
        [keyState]: requestInfo,
        ...aditionalStates,
    };
}

function handleCustomUnavailable(state, { payload: { keyState, loadingName } }) {
    return {
        ...state,
        [keyState]: [],
        [loadingName]: false,
    };
}

export function updateActiveSubSupplier(supplier_id, active_sub) {
    return async (dispatch, _, { services: { dataSource } }) => {
        dispatch({ type: SUPPLIERS_REQUEST, payload: { loadingName: 'loading_update_supplier' } });
        try {
            const response = await dataSource.updateActiveSubSupplier(supplier_id, active_sub);
            dispatch({
                type: SUPPLIERS_AVAILABLE,
                payload: { loadingName: 'loading_update_supplier', },
            });
            if (response?.msg === 'success') {
                const snack = {
                    open: true,
                    message: 'Supplier state changed successfully.',
                    severity: 'success',
                };
                dispatch({ type: SNACK_SET, payload: { snack } });
            }
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: SUPPLIERS_UNAVAILABLE,
                payload: { error, loadingName: 'loading_update_supplier' },
            });
            const snack = {
                open: true,
                message: 'There was an error while changing the subscription for supplier',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    }
}

export function getSupplierStores(supplier_id = null) {
    return async (dispatch, _, { services: { dataSource } }) => {
        dispatch({ type: SUPPLIERS_REQUEST, payload: { loadingName: 'loading_supplier_stores' } });
        try {
            const suppliersResponse = await dataSource.getSupplierStores(supplier_id);
            dispatch({
                type: SUPPLIERS_AVAILABLE,
                payload: { keyState: 'supplierStores', loadingName: 'loading_supplier_stores', requestInfo: suppliersResponse.stores },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: SUPPLIERS_UNAVAILABLE,
                payload: { error, keyState: 'suppliersList', loadingName: 'loading_supplier_stores' },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}