import { Divider, Grid, Typography } from "@mui/material";
import StyledPaper from "../../tools/StyledPaper";

export default function Visualizations(props) {
  const { title } = props;
  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
    >
      <StyledPaper elevation={3} sx={{ maxHeight: 200, minHeight: 138, width: '100%', px: 2 }}>
        <Grid item xs={8}>
          <Typography variant="h6">{title}</Typography>
          <Divider />
        </Grid>
      </StyledPaper>
    </Grid>
  );
}
