import { Box, CircularProgress, IconButton, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { Suspense, useContext, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DigitalShelfContext } from '../../../contexts/DigitalShelf';
import { CustomTabPanel, a11yProps } from '../../utils/tabs';
import InteractiveMap from '../digitalStore/InteractiveMap';
import ProductMetadata from './ProductMetadata';
import { checkColor, COLORS } from '../../utils/colors';
import { getAisleVector, getIsHorizontal, getAislePadding, getAisleMeasures } from '../../utils/converters';

export default function WallMetadata(props) {
  const {
    parsedLandmarks,
    currencySymbol,
    onOpenChange,
    chainName,
    isLoadingMaps,
    storeMaps,
    isOpen,
  } = props;
  const { t } = useTranslation();
  const mapRef = useRef(null);
  const boxMetadataRef = useRef(null);

  const [tabIndex, setTabIndex] = useState(0);
  const [selectedDots, setSelectedDots] = useState([]);
  const [highlightedBoxes, setHighlightedBoxes] = useState([]);
  const [storeMap, setStoreMap] = useState([]);
  const [isStoreMapLoading, setIsStoreMapLoading] = useState(false);

  const {
    selectedFloor,
    selectedAisle,
    selectedProduct,
    productPosPercent,
    selectedLandmark,
  } = useContext(DigitalShelfContext);

  // Handlers
  const handleOpen = () => {
    onOpenChange(isOpen);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };


  // hooks
  useEffect(() => {
    if (!isLoadingMaps && storeMaps?.length > 1) {
      setIsStoreMapLoading(true);
      setStoreMap(storeMaps.find(map => map.floor_number === selectedFloor?.value));
      setTimeout(() => {
        setIsStoreMapLoading(false);
      }, 500);
    }
  }, [isLoadingMaps, storeMaps, selectedFloor]);

  useEffect(() => {
    if (storeMaps?.length === 1) {
      setIsStoreMapLoading(true);
      setStoreMap(storeMaps[0]);
      setTimeout(() => {
        setIsStoreMapLoading(false);
      }, 300);
    }
  }, [storeMaps]);

  useEffect(() => {
    if (productPosPercent) {
      const code = selectedAisle?.code ?? '';
      const aisleCoords = storeMap?.map_info?.mapped_aisles?.[code];
      const scaleValues = storeMap?.map_info?.scale_value ?? [1, 1];
      if (aisleCoords) {
        const {
          px_init: pixelInit,
          px_end: pixelEnd,
        } = aisleCoords;
        const aisleVector = getAisleVector({ pixelInit, pixelEnd });
        const [xProduct, yProduct] = aisleVector.map((v, i) => (v * productPosPercent + pixelInit[i]) * scaleValues[i]);
        const colorData = checkColor(selectedProduct);
        setSelectedDots([{
          pixel: [xProduct, yProduct],
          radius: 4,
          colorFill: colorData ?? 'rgba(30, 120, 56, 1)'
        },]);
      }
    } else {
      setSelectedDots([]);
    }
  }, [productPosPercent]);

  useEffect(() => {
    if (parsedLandmarks && parsedLandmarks[selectedLandmark?.landmark]) {
      let newHighlightedBoxes = [];
      const landmarkInfo = parsedLandmarks[selectedLandmark.landmark];
      const aisleCode = [landmarkInfo.orient, landmarkInfo.aisle_part].reduce((prev, curr) => prev && curr ? `${prev}-${curr}` : prev, landmarkInfo.aisle)
      const aisleCoords = storeMap?.map_info?.mapped_aisles?.[aisleCode];
      const scaleValues = storeMap?.map_info?.scale_value ?? [1, 1];

      if (aisleCoords) {
        let {
          landmarkPercentageInit,
          landmarkPercentageEnd,
        } = landmarkInfo;
        const {
          px_init: pixelInit,
          px_end: pixelEnd,
        } = aisleCoords;
        landmarkPercentageEnd = landmarkPercentageEnd || 1;
        const isHorizontal = getIsHorizontal(pixelEnd, pixelInit);
        const aisleVector = getAisleVector({ pixelInit, pixelEnd });
        const PADDING = getAislePadding();
        const pxInit = [
          isHorizontal ? (aisleVector[0] * landmarkPercentageInit + pixelInit[0]) * scaleValues[0] : pixelInit[0] * scaleValues[0],
          isHorizontal ? pixelInit[1] * scaleValues[1] : (aisleVector[1] * landmarkPercentageInit + pixelInit[1]) * scaleValues[1],
        ];
        const pxEnd = [
          isHorizontal ? (aisleVector[0] * landmarkPercentageEnd + pixelInit[0]) * scaleValues[0] : pixelEnd[0] * scaleValues[0],
          isHorizontal ? pixelEnd[1] * scaleValues[1] : (aisleVector[1] * landmarkPercentageEnd + pixelInit[1]) * scaleValues[1],
        ]
        const { width, height } = getAisleMeasures({ pixelInit: pxInit, pixelEnd: pxEnd, includePadding: true });

        pxInit[0] = pxInit[0] - (isHorizontal ? 0 : PADDING / 2);
        pxInit[1] = pxInit[1] - (isHorizontal ? PADDING / 2 : 0);
        const landmarkBBox = {
          pxInit: pxInit,
          pxEnd: pxEnd,
          colorFill: `${COLORS['hua'].color}AA`,
          strokeStyle: COLORS['hua'].color2,
        }
        landmarkBBox['height'] = height;
        landmarkBBox['width'] = width;

        newHighlightedBoxes.push(landmarkBBox);
      }

      setHighlightedBoxes(newHighlightedBoxes)
    } else if (!selectedLandmark?.landmark) {
      setHighlightedBoxes([]);
    }
  }, [selectedLandmark]);


  return (
    <Box
      key="wall-metadata"
      ref={boxMetadataRef}
      sx={{
        height: '100%',
        borderRadius: '5px',
        backgroundColor:
          '#1a242d',
        WebkitOverflowScrolling: 'touch !important',
        overflowX: 'auto',
        overflowY: 'hidden',
      }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex' }}>
        <Typography sx={{ fontSize: 'small', py: 'auto', pl: 1, mr: 2 }} >
          <Tooltip title={!isOpen ? 'Open Map' : 'Close Map'} placement="bottom">
            <IconButton onClick={handleOpen} sx={{ color: 'white' }}>
              <IconComponent
                iconName={!isOpen ? 'arrow-back' : 'arrow-forward'}
                sx={{ color: 'white' }}
              />
            </IconButton>
          </Tooltip>
        </Typography>
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="map tabs"
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab label={t('cws_app.general.info_metadata', 'Metadata')} {...a11yProps(0)}
            sx={{ color: 'white' }}
          />
          <Tab label={t('cws_app.general.map', 'Map')} {...a11yProps(1)}
            sx={{ color: 'white' }}
          />
        </Tabs>
      </Box>
      {isOpen &&
        <>
          <CustomTabPanel value={tabIndex} index={0} boxSx={{ px: 2, pt: 2, pb: 0 }}>
            <ProductMetadata
              currencySymbol={currencySymbol}
            />
          </CustomTabPanel>
          <CustomTabPanel value={tabIndex} index={1}>
            {(isLoadingMaps || isStoreMapLoading) ? (
              <Box sx={{ height: '20em', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                <CircularProgress color='secondary' />
              </Box>
            ) :
              storeMap?.map_info ? (
                <Box sx={{ height: '100%', p: 1, overflow: 'auto' }}>
                  <Suspense fallback={<div>Loading...</div>}>
                    <InteractiveMap
                      chain={chainName}
                      storeMap={storeMap}
                      hasRobot={storeMap.has_robot}
                      taskType={'stockout'}
                      setIsSidenavOpen={() => { }}
                      selectedAlert={null}
                      setSelectedAlert={() => { }}
                      setAisleLandmarkMap={() => { }}
                      setAislesList={() => { }}
                      robotSession={''}
                      setRobotSession={() => { }}
                      setRefresh={() => { }}
                      setHeight={() => { }}
                      heightRef={mapRef}
                      canShowActions={false}
                      selectedDots={selectedDots}
                      selectedAisle={selectedAisle}
                      highlightedBoxes={highlightedBoxes}
                      visualizationMode={'ds_custom_aisle'}
                      isZoomActive={true}
                      isHoverActive={false}
                      isCLickActive={false}
                    />
                  </Suspense>
                </Box>
              )
                : <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Typography variant="h6" sx={{ color: 'white' }}>
                    {t('cws_app.general.no_map', 'No map available')}
                  </Typography>
                </Box>
            }
          </CustomTabPanel>
        </>
      }
    </Box>
  );
}
