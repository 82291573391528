import * as React from 'react';
import { FormControl, Grid, FormLabel, RadioGroup, FormControlLabel, Radio, Paper, Autocomplete, TextField, Typography } from "@mui/material";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import StoreMap from '../../tools/StoreMap';
import DatePicker from '../../tools/DatePicker';
import moment from "moment";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap', // Permite que los chips se envuelvan a la siguiente línea
    '& > *': {
      margin: theme.spacing(0.5), // Espaciado entre los chips
    },
  },
}));

const motives = [
  'Inventory', 'Cleaning work', 'Maintenance work', 'Remodeling of the store', 'Legal holidays', 'Other motive'
];
const rescheduleType = ['Session', 'Aisle'];

export default function ResForm(props) {
  const {
    aisleList,
    pullData,
    ticketState,
    client,
    chain,
    getDetailedRobotSession,
    selectedType,
    session,
    storeCodes,
    resetForm,
    robotSessions,
    onResetComplete
  } = props

  const classes = useStyles();

  const [selectedMotive, setMotive] = useState('');
  const [initialDate, setInitialDate] = useState(new Date());
  const [finalDate, setFinalDate] = useState(null);
  const [resType, setResType] = useState(null);
  const [selectedAisles, setselectedAisles] = useState([]); // aisles to cancel in sesion navigation
  const [resultAisles, setResultAisles] = useState([]);
  const [comments, setComments] = useState('');
  const [multipleStores, setMultipleStores] = useState(false);
  const [selectedSessionId, setSelectedSessionId] = useState();

  const { t } = useTranslation();

  const handleMotive = (e) => {
    setMotive(e.target.value);
  }

  const handleType = (e) => {
    setResType(e.target.value)
  }

  useEffect(() => {
    if (selectedType?.name === 'Cancel Session'){
      setResType('Session');
    }
    else if (selectedType?.name === 'Remove/Add Aisle') {
      setResType('Aisle');
    }
  }, [selectedType])
  

  useEffect(() => {
    if (ticketState) {
      let ticket_form = {
        is_store_canceled: resType === 'Session' ? true : false,
        rescheduleType: selectedType,
        aisle_list: selectedAisles, // aisles to cancel
        result_aisle_list: resultAisles, // aisles to navigate after cancel
        selected_reason: selectedMotive,
        comments,
        target_date: moment(initialDate).format('YYYY-MM-DD'),
        stores: storeCodes
      }
      if (selectedSessionId) ticket_form['session_id'] = selectedSessionId;
      pullData(
        "robot_rescheduling",
        client.client_id,
        chain.chain_country_id,
        storeCodes,
        ticket_form,
        selectedType?.name
      )
    }
  }, [ticketState])

  useEffect(() => {
    if (storeCodes.length > 1 || storeCodes.length === 0 ) setMultipleStores(true);
    else {
      let parsed_date = initialDate ? moment(initialDate).format('YYYY-MM-DD') : null;
      if (storeCodes.length === 1 && robotSessions && parsed_date in robotSessions) {
        // get session id
        let result = robotSessions[parsed_date]["NO REQUESTS"].filter(item => item.robot_code === storeCodes[0]);
        if (result.length && 'session_id' in result[0]) setSelectedSessionId(result[0].session_id);
      }
      else setSelectedSessionId(null);
      setMultipleStores(false);
    }
  }, [storeCodes, initialDate, robotSessions])
  
  useEffect(() => {
    if (resetForm) {
      setInitialDate(new Date());
      setResType(null);
      setselectedAisles([]);
      setMotive('');
      setComments(null);
      onResetComplete();
    }
  }, [resetForm, onResetComplete])

  useEffect(() => {
    setResultAisles(aisleList.filter(item => !selectedAisles.includes(item)));
  }, [selectedAisles])
  
  useEffect(() => {
    if (selectedSessionId)
      getDetailedRobotSession(selectedSessionId);
  }, [selectedSessionId])


  return (
    <Grid item xs={12} md={multipleStores ? 12 : 4}>
      <Paper sx={{ width: '100%', px: 2, py: 3 }}>
        <Grid container p={1} spacing={1}>
          <Grid item xs={multipleStores ? 4 : 12}>
            <FormLabel id="demo-controlled-radio-buttons-group">Select date</FormLabel>
            <Grid container spacing={{ xs: 1 }}>
              <Grid item xs={6}>
                <DatePicker date={initialDate} setDate={setInitialDate} minDate={new Date()}/>
              </Grid>
              {/* <Grid item xs={6}>
                <DatePicker date={finalDate} setDate={setFinalDate} />
              </Grid> */}
            </Grid>
            <Grid item xs={12}>
              <FormControl required>
                <FormLabel id="demo-controlled-radio-buttons-group">Enable/Disable</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={resType}
                  onChange={handleType}
                >
                  {rescheduleType.map(item => {
                    return <FormControlLabel value={item} control={<Radio />} label={item}/>
                  })}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {(resType === 'Aisle') ?
                <Paper elevation={0} sx={{ px: 1, pt: '4px' }}>
                  <Autocomplete
                    multiple
                    id="tag-standard"
                    options={aisleList}
                    getOptionLabel={(option) => option.aisle_name}
                    onChange={(_, newValue) => {
                      setselectedAisles(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Pasillos"
                        placeholder='Pasillo'
                      />
                    )}
                  />
                </Paper>
                :
                null
              }
            </Grid>
            {(resType === 'Aisle' && session && 'aisles' in session) ?
              <Grid item xs={12}>
                Pasillos en la planificacion actual:
                <div className={classes.chipContainer}>
                  {session.aisles.map(item => <Chip label={item} />)}
                </div>
              </Grid>
              :
              null
            }
          </Grid>
          <Grid item xs={multipleStores ? 4 : 12}>
            <FormControl required>
              <FormLabel id="demo-radio-buttons-group-label">Pick your motive</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={selectedMotive}
                onChange={handleMotive}
              >
                {motives.map(item => {
                  return <FormControlLabel value={item} control={<Radio />} label={item}/>
                })}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={multipleStores ? 4 : 12}>
            <FormControl fullWidth>
              <FormLabel>Additional comments (optional)</FormLabel>
              <TextField id="outlined-basic" multiline rows={4} label="Comments" value={comments} variant="standard" onChange={(e) => setComments(e.target.value)} />
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}