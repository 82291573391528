import * as React from 'react';
import { useEffect, useState } from 'react';
import {
	Autocomplete,
	CircularProgress,
	Divider,
	Grid,
	InputAdornment,
	ListItem,
	ListItemButton,
	ListItemText,
	styled,
	TextField,
	Typography,
} from '@mui/material';

import { useTranslation } from 'react-i18next';

import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

const StyledTextField = styled(
	props => {
		return (<TextField {...props} />)
	}, { shouldForwardProp: props => props !== 'color' }
)(({ theme, searchbartheme }) => {
	const currentTheme = searchbartheme === 'default' ? theme.palette.type : searchbartheme;
	return ({
		// Textfield's border
		'& .MuiOutlinedInput-root': {
			background: currentTheme === 'light' ? theme.palette.common.white : '',
			color: currentTheme === 'light' ? theme.palette.text.primary : theme.palette.common.white,
			height: 'auto',
			'&:hover fieldset': {
				borderColor: theme.palette.grey[300],
			},
			'&.Mui-focused fieldset': {
				borderColor: theme.palette.grey[300],
			},
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: currentTheme === 'light' ? '' : theme.palette.common.white,
		},
		// 'X' adornment for clearing the input
		'& .MuiAutocomplete-clearIndicator': {
			color: currentTheme === 'light' ? theme.palette.text.primary : theme.palette.common.white,
			'&:hover': {
				backgroundColor: currentTheme === 'light' ? theme.palette.action.hover : theme.palette.grey[800],
			},
		},
		// Search icon
		'& .MuiInputAdornment-root': {
			color: theme.palette.grey[500],
		},
	})
});


const ResultsDisplay = ({ option, setProduct }) => {
	const { description, ean, item, aisle, last_time_seen } = option;
	let date = new Date(last_time_seen).toLocaleDateString();
	return (
		<>
			<ListItem alignItems="flex-start" disablePadding clickeable onClick={() => { setProduct(option) }} >
				<ListItemButton>
					<ListItemText
						primary={
							<Typography color="text.primary">{description}</Typography>
						}
						secondary={
							<Grid container direction="row" justifyContent="space-between">
								<Grid item>
									<Typography variant="caption" color="text.primary">{"SAP: "}</Typography>
									<Typography variant="caption" color="text.primary">{item}</Typography>
									&nbsp;&nbsp;&nbsp;&nbsp;
									{ean && ean.length > 1 ?
										<>
											<Typography variant="caption" color="text.primary">{"Barcode: "}</Typography>
											<Typography variant="caption" color="text.primary">{ean}</Typography>
										</>
										: null
									}
								</Grid>
								<Grid item>
									<Typography variant="caption" color="blue">{"Aisle: "}</Typography>
									<Typography variant="caption" color="text.primary">{aisle}</Typography>
									&nbsp;&nbsp;&nbsp;&nbsp;
									<Typography variant="caption" color="blue">{"Last time seen: "}</Typography>
									<Typography variant="caption" color="text.primary">{date}</Typography>
								</Grid>
							</Grid>
						}
					/>
				</ListItemButton>
			</ListItem>
			<Divider />
		</>
	);
}


export default function ShelfSearch(props) {
	const {
		aislesList,
		searchResults,
		isLoadingSearchResults,
		getSearchResults,
		selectedStore,
		supplier,
		product,
		setProduct,
		disabled,
		color = 'secondary',
		searchbartheme = 'default',
	} = props;

	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [controlledOtions, setControlledOptions] = useState([]);
	const loading = open && isLoadingSearchResults;

	useEffect(() => {
		if (searchResults?.length) {
			setControlledOptions(searchResults);
		} else {
			setControlledOptions([]);
		}
	}, [searchResults]);

	useEffect(() => {
		if (selectedStore && Object.keys(selectedStore).length > 0) {
			setControlledOptions([]);
		}
	}, [selectedStore]);

	const handleSelectProduct = (newValue) => {
		setProduct(newValue);
		setOpen(!open);
	}

	return (
		<Autocomplete
			disabled={disabled}

			fullWidth
			freeSolo

			value={product}
			onChange={(event, newValue) => {
				setProduct(newValue);
			}}
			inputValue={inputValue}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue);
			}}
			filterOptions={(x) => x}
			sx={{ minWidth: 300 }}

			open={open}
			onOpen={() => { setOpen(true) }}
			onClose={() => { setOpen(false) }}

			isOptionEqualToValue={(option, value) => (option?.ean === value?.ean) || (option?.item === value?.item) || (option?.texts === value?.description)}
			getOptionLabel={(option) => option?.description}

			options={controlledOtions}
			loading={loading}

			renderInput={(params) =>
				<StyledTextField {...params}
					variant="outlined"
					color={color}
					searchbartheme={searchbartheme}
					placeholder={disabled ? t('cws_app.general.sel_one_store') : t('cws_app.general.search')}
					InputProps={{
						...params.InputProps,
						startAdornment: (
							<>
								{loading ? <CircularProgress color="inherit" size={20} /> : null}
								{params.InputProps.endAdornment}
								<InputAdornment position="start">
									<IconComponent
										iconName={'search-outline'}
										style={{ fontSize: '20px' }}
									/>
								</InputAdornment>
							</>
						)
					}}
				/>
			}
			renderOption={(props, option) => (
				<React.Fragment>
					<ResultsDisplay option={option} setProduct={handleSelectProduct} />
				</React.Fragment>
			)}
			onKeyDown={(event) => {
				if (event.key === 'Enter') {
					// Prevent's default 'Enter' behavior.
					event.defaultMuiPrevented = true;
					if (event.target.value.length > 2 && selectedStore && Object.keys(selectedStore).length > 0) {
						getSearchResults(
							selectedStore?.store_code,
							supplier ? supplier : null,
							aislesList ? aislesList.map(aisleRow => aisleRow?.code).filter(aisleCode => aisleCode) : [],
							event.target.value,
							null,
							null
						);
					}
				}
			}}

		/>
	);
}
