export function isLoadingSession({ session: { loading } }) {
  return loading;
}

export function getUserInfo({ session: { userInfo } }) {
  return userInfo;
}

export function hasSession({ session: { loggedIn } }) {
  return loggedIn;
}

// SnackBar
export function getSnackAlert({ snackAlert: { snack } }) {
  return snack;
}

// General
export function isLoadingExcelDownload({ general: { loadingExcelDownload } }) {
  return loadingExcelDownload;
}

// Clients
export function isLoadingClients({ clients: { loading_clients } }) {
  return loading_clients;
}

export function getClientsList({
  clients: { clientList } = { clients: { clientList: [] } },
}) {
  return clientList;
}

export function isLoadingClientTypes({ clients: { loading_client_types } }) {
  return loading_client_types;
}

export function getClientTypes({ clients: { clientTypesList = [] } }) {
  return clientTypesList;
}

export function isLoadingClientInfo({ clients: { loading_client_info } }) {
  return loading_client_info;
}

export function getClientInfoObject({ clients: { clientInfo = {} } }) {
  return clientInfo;
}

export function getClientStoresList({ clients: { clientStores = [] } }) {
  return clientStores;
}

export function isLoadingClientStores({ clients: { loading_client_stores } }) {
  return loading_client_stores;
}
// TODO: delete on deprecation of cpg
export function getPBIReportsList({ clients: { clientLegacyReports = [] } }) {
  return clientLegacyReports;
}
// TODO: delete on deprecation of cpg
export function isLoadingPBIReports({ clients: { loading_client_legacy_reports } }) {
  return loading_client_legacy_reports;
}

export function getClientFormats({ clients: { clientFormats = {} } }) {
  return clientFormats;
}

export function isLoadingClientFormats({ clients: { loadingClientFormats } }) {
  return loadingClientFormats;
}

// Users
export function isLoadingUserInfo({ user: { loading_user_info } }) {
  return loading_user_info;
}

export function isLoadingUserStores({ user: { loading_user_stores } }) {
  return loading_user_stores;
}

export function isLoadingUserNotifications({
  user: { loading_user_notifications },
}) {
  return loading_user_notifications;
}

export function getUserClientList({ user: { clients } }) {
  return clients;
}

export function getSelectedClient({ user: { selectedClient } }) {
  return selectedClient;
}

export function getSectionPaths({ user: { sectionPaths } }) {
  return sectionPaths;
}

export function getSectionView({ user: { sectionView } }) {
  return sectionView;
}

export function userChains({ user: { chains } }) {
  return chains;
}

export function getUserStoresList({ user: { stores } }) {
  return stores;
}

export function getUserStoresAllList({ user: { stores } }) {
  return stores;
}

export function userMGMTStores({ user: { userMGMTStores } }) {
  return userMGMTStores;
}

export function getUserNotificationsList({ user: { notifications } }) {
  return notifications;
}

export function users({ user: { users } }) {
  return users;
}

export function isLoadingUsers({ user: { loadingUsers } }) {
  return loadingUsers;
}

export function userClients({ user: { userClients } }) {
  return userClients;
}

export function getIsLoadingUserClientList({ user: { isLoadingClients } }) {
  return isLoadingClients;
}

export function isLoadingUserClients({ user: { loadingUserClients } }) {
  return loadingUserClients;
}

export function postUserResponse({ user: { postUserResponse } }) {
  return postUserResponse;
}

export function isLoadingPostUser({ user: { loadingPostUser } }) {
  return loadingPostUser;
}

export function updateUserResponse({ user: { updateUserResponse } }) {
  return updateUserResponse;
}

export function isLoadingUpdateUser({ user: { loadingUpdateUser } }) {
  return loadingUpdateUser;
}

export function deleteUserResponse({ user: { deleteUserResponse } }) {
  return deleteUserResponse;
}

export function isLoadingDeleteUser({ user: { loadingDeleteUser } }) {
  return loadingDeleteUser;
}
// TODO: delete on deprecation of cpg
export function getUserLegacyDashboards({ user: { userLegacyDashboards = [] } }) {
  return userLegacyDashboards;
}

export function userSuppliers({ user: { userSuppliers } }) {
  return userSuppliers;
}

export function isLoadingUserSuppliers({ user: { loadingUserSuppliers } }) {
  return loadingUserSuppliers;
}

// Dashboards
export function isLoadingResumeDashboards({
  dashboards: { loading_dashboards_resume },
}) {
  return loading_dashboards_resume;
}

export function getDashboardsList({ dashboards: { dashboardsList } }) {
  return dashboardsList;
}

export function isLoadingDashboards({
  dashboards: { loading_dashboards },
}) {
  return loading_dashboards;
}

export function getDashboardInfo({ dashboards: { dashboardInfo } }) {
  return dashboardInfo;
}

export function isLoadingDashboard({ dashboards: { loading_dashboard } }) {
  return loading_dashboard;
}

export function loadingDashboardResume({
  dashboards: { loading_dashboards_resume },
}) {
  return loading_dashboards_resume;
}

export function getDashboardsResumeInfo({
  dashboards: { dashboardsResumeInfo },
}) {
  return dashboardsResumeInfo;
}

export function getLastResumeDashboardProcessed({
  dashboards: { lastResumeDashboardProcessed },
}) {
  return lastResumeDashboardProcessed;
}

// Store
export function isLoadingBrands({ store: { loading_brands } }) {
  return loading_brands;
}

export function isLoadingCategories({ store: { loading_categories } }) {
  return loading_categories;
}

// TODO: delete on deprecation of old selector (the new one comes with a slect all option)
export function getCategoriesList({ store: { storeCategories = [] } }) {
  return [{ value: null, category_name: 'All' }, ...storeCategories];
}

export function categories({ store: { categories } }) {
  return categories;
}

export function getBrandList({ store: { brandList = [] } }) {
  return brandList;
}

export function getNumberFormats({ store: { numberFormatting = [] } }) {
  return numberFormatting;
}

export function currencySymbol({ store: { numberCurrencySymbol } }) {
  return numberCurrencySymbol;
}

export function isLoadingNumberFormats({ store: { loading_number_formatting } }) {
  return loading_number_formatting;
}

export function isStoreFloorLoading({ store: { isStoreFloorLoading } }) {
  return isStoreFloorLoading;
}

export function getStoreFloor({ store: { storesFloor } }) {
  return storesFloor;
}

// Tasks
export function isLoadingTaskTypes({ tasks: { loading_task_types } }) {
  return loading_task_types;
}

export function taskTypes({ tasks: { task_types } }) {
  return task_types;
}

// Suppliers
export function isLoadingSupplierStores({
  suppliers: { loading_supplier_stores },
}) {
  return loading_supplier_stores;
}

export function getSupplierStoresList({ suppliers: { supplierStores = [] } }) {
  return supplierStores;
}

// Backend
export function isLoadingChains({ backend: { loading_chains } }) {
  return loading_chains;
}

export function getChainsList({ backend: { chainsList = [] } }) {
  return chainsList;
}

export function isLoadingTasks({ backend: { loading_tasks } }) {
  return loading_tasks;
}

export function getTasksList({ backend: { tasksList = [] } }) {
  return tasksList;
}

export function isLoadingProducts({ backend: { loading_products } }) {
  return loading_products;
}

export function getProductsList({ backend: { productsList } }) {
  return productsList;
}

// Documentation
export function isLoadingDocumentation({ documentation: { loading } }) {
  return loading;
}

export function getDocumentationBlobs({ documentation: { blobs } }) {
  return blobs;
}

export function isCredentialLoading({ documentation: { credentialLoading } }) {
  return credentialLoading;
}

// Digital Store
export function isLoadingMaps({ digitalStore: { isLoadingMaps } }) {
  return isLoadingMaps;
}

export function isLoadingMapInfo({ digitalStore: { loadingMapInfo } }) {
  return loadingMapInfo;
}

export function getStoreMapInfo({ digitalStore: { storeMapInfo } }) {
  return storeMapInfo;
}

export function getStoreMapsObject({ digitalStore: { storeMaps } }) {
  return storeMaps;
}

export function isPdfLoading({ digitalStore: { isPdfLoading } }) {
  return isPdfLoading;
}

export function isInvLoading({ digitalStore: { isInvLoading } }) {
  return isInvLoading;
}

export function isExcelLoading({ digitalStore: { isExcelLoading } }) {
  return isExcelLoading;
}

export function isLoadingAisleThumbnail({
  digitalStore: { loadingAisleThumbnail },
}) {
  return loadingAisleThumbnail;
}

export function aisleThumbnail({ digitalStore: { aisleThumbnail } }) {
  return aisleThumbnail;
}

export function isLoadingExcelByDate({ digitalStore: { loadingExcelByDate } }) {
  return loadingExcelByDate;
}

export function isLoadingResumeDStore({ digitalStore: { loadingResumeDStore } }) {
  return loadingResumeDStore;
}

export function getResumeDStoreData({ digitalStore: { resumeDStore } }) {
  return resumeDStore;
}

// Links
export function getLinkState({ link: { linkReceived } }) {
  return linkReceived;
}

// Walls
export function getWallsAislesData({ wall: { aislesData = [] } }) {
  return aislesData;
}

export function isLoadingAislesData({ wall: { loadingAisles } }) {
  return loadingAisles;
}

export function getWallImageData({ wall: { wallImageData } }) {
  return wallImageData;
}

export function isLoadingWallImageData({ wall: { loadingWallImageData } }) {
  return loadingWallImageData;
}

export function getWallLandmarks({ wall: { wallLandmarksData } }) {
  return wallLandmarksData;
}

export function isLoadingWallLandmarks({ wall: { loadingWallLandmarksData } }) {
  return loadingWallLandmarksData;
}

export function getAllLandmarks({ wall: { landmarkData } }) {
  return landmarkData;
}

export function isLoadingLandmark({ wall: { loadingLandmarkData } }) {
  return loadingLandmarkData;
}

export function getCoordsInfo({ wall: { coordsInfo } }) {
  return coordsInfo;
}

export function isLoadingCoordsInfo({ wall: { loadingCoordsInfo } }) {
  return loadingCoordsInfo;
}

export function coordsForExcel({ wall: { coordsForExcel } }) {
  return coordsForExcel;
}

export function loadingCoordsForExcel({ wall: { loadingCoordsForExcel } }) {
  return loadingCoordsForExcel;
}

export function misplacedSignedImages({ wall: { misplacedSignedImages } }) {
  return misplacedSignedImages;
}

export function loadingMisplacedSignedImages({ wall: { loadingMisplacedSignedImages } }) {
  return loadingMisplacedSignedImages;
}

export function getProductSearchResults({ wall: { searchResults } }) {
  return searchResults;
}

export function isLoadingProductSearchResults({
  wall: { loadingSearchResults },
}) {
  return loadingSearchResults;
}

export function getSelectedWallProduct({ wall: { selectedProduct } }) {
  return selectedProduct;
}

export function getExcelLandmarkList({ wall: { excelLandmarks } }) {
  return excelLandmarks;
}

export function isLoadingLandmarksExcel({ wall: { loadingLandmarksExcel } }) {
  return loadingLandmarksExcel;
}
export function getAislePromotionsList({ wall: { aislePromotions } }) {
  return aislePromotions;
}

export function isLoadingAislePromotions({ wall: { loadingAislePromotions } }) {
  return loadingAislePromotions;
}

export function getAisleLandmarkState({ wall: { aisleLandmarks } }) {
  return aisleLandmarks;
}

export function getMapInfo({ rescheduling: { storeMap } }) {
  return storeMap;
}

export function isLoadingResMap({ rescheduling: { isLoadingResMap } }) {
  return isLoadingResMap;
}

export function isLoadingNewTicket({ rescheduling: { isLoadingNewTicket } }) {
  return isLoadingNewTicket;
}

export function ticketMessageResponse({
  rescheduling: { ticketMessageResponse },
}) {
  return ticketMessageResponse;
}

export function getRobotSessions({ rescheduling: { robotSessions } }) {
  return robotSessions;
}

export function getSession({ rescheduling: { robotSession } }) {
  return robotSession;
}

export function isLoadingRobotSessions({
  rescheduling: { isLoadingRobotSessions },
}) {
  return isLoadingRobotSessions;
}

export function getTicketsData({ rescheduling: { ticketsData } }) {
  return ticketsData;
}

export function isLoadingTickets({ rescheduling: { isLoadingTickets } }) {
  return isLoadingTickets;
}

// Tracking List
export function getTrackingListData({ trackingList: { trackingListData } }) {
  return trackingListData;
}

export function isLoadingTrackingListData({
  trackingList: { loadingTrackingList },
}) {
  return loadingTrackingList;
}

export function getTrackingListDataItems({
  trackingList: { trackingListDataItems },
}) {
  return trackingListDataItems;
}

export function isLoadingTrackingListDataItems({
  trackingList: { loadingTrackingListItems },
}) {
  return loadingTrackingListItems;
}

export function isLoadingUpload({
  trackingList: { loadingUploadTrackingList },
}) {
  return loadingUploadTrackingList;
}

export function uploadResponse({ trackingList: { uploadResponse } }) {
  return uploadResponse;
}

export function isLoadingDelete({
  trackingList: { loadingDeleteTrackingList },
}) {
  return loadingDeleteTrackingList;
}

export function deleteResponse({ trackingList: { deleteResponse } }) {
  return deleteResponse;
}

export function getClientMailerContactList({ mailer: { mailerContacts } }) {
  return mailerContacts;
}

export function isLoadingMailerContacts({
  mailer: { loading_mailer_contacts },
}) {
  return loading_mailer_contacts;
}

export function getClientMailerTaskList({ mailer: { mailerTasks } }) {
  return mailerTasks;
}

export function isLoadingMailerTaskList({ mailer: { loading_mailer_tasks } }) {
  return loading_mailer_tasks;
}

export function getContactInfo({ mailer: { mailerContactInfo } }) {
  return mailerContactInfo;
}

export function isLoadingContactInfo({
  mailer: { loading_mailer_contact_info },
}) {
  return loading_mailer_contact_info;
}

// Products state

export function isLoadingProductsStates({ productsStates: { loading_productsStates } }) {
  return loading_productsStates;
}

export function productsStates({ productsStates: { productsStates } }) {
  return productsStates;
}

// Bruno users

export function brunoUsers({ bruno: { brunoUsers } }) {
  return brunoUsers;
}

export function isLoadingBrunoUsers({ bruno: { loadingBrunoUsers } }) {
  return loadingBrunoUsers;
}

export function deleteBrunoUserResponse({ bruno: { deleteResponse } }) {
  return deleteResponse;
}

export function isLoadingDeleteBrunoUser({ bruno: { loadingDeleteUser } }) {
  return loadingDeleteUser;
}

export function userRoles({ bruno: { userRoles } }) {
  return userRoles;
}

export function isLoadingUserRoles({ bruno: { loadingUserRoles } }) {
  return loadingUserRoles;
}

export function brunoFeatures({ bruno: { features } }) {
  return features;
}

export function isLoadingBrunoFeatures({ bruno: { loadingFeatures } }) {
  return loadingFeatures;
}

export function brunoUserProfile({ bruno: { userProfile } }) {
  return userProfile;
}

export function isLoadingBrunoUserProfile({ bruno: { loadingUserProfile } }) {
  return loadingUserProfile;
}

export function updateBrunoUserResponse({ bruno: { updateBrunoUser } }) {
  return updateBrunoUser;
}

export function isLoadingUpdateBrunoUser({
  bruno: { loadingUpdateBrunoUser },
}) {
  return loadingUpdateBrunoUser;
}

export function brunoUserFeatures({ bruno: { userFeatures } }) {
  return userFeatures;
}

export function isLoadingBrunoUserFeatures({ bruno: { loadingUserFeatures } }) {
  return loadingUserFeatures;
}

export function postBrunoUserResponse({ bruno: { postBrunoUserResponse } }) {
  return postBrunoUserResponse;
}

export function isLoadingPostBrunoUser({ bruno: { loadingPostBrunoUser } }) {
  return loadingPostBrunoUser;
}

export function getNotificationsList({ notifications: { notificationsList } }) {
  return notificationsList;
}

export function isLoadingNotificationsList({
  notifications: { loading_client_notifications_info },
}) {
  return loading_client_notifications_info;
}

export function getNotification({ notifications: { notification } }) {
  return notification;
}

export function isLoadingNotification({
  notifications: { loading_client_notification },
}) {
  return loading_client_notification;
}

// Products
export function productsRoles({ products: { productsRoles } }) {
  return productsRoles;
}

export function isLoadingProductsRoles({ products: { loadingProductsRoles } }) {
  return loadingProductsRoles;
}

// Translations
export function getDictionaries({ translations: { dictionaries } }) {
  return dictionaries;
}

export function getAppNames({ translations: { appNames } }) {
  return appNames;
}

export function getLanguages({ translations: { languages } }) {
  return languages;
}

export function getTranslation({ translations: { translations } }) {
  return translations;
}

export function getUpdateTranslation({ translations: { updateTranslations } }) {
  return updateTranslations;
}

export function getNewTranslation({ translations: { newTranslation } }) {
  return newTranslation;
}

export function isLoadingDictionaries({
  translations: { loading_dictionaries },
}) {
  return loading_dictionaries;
}

export function isLoadingAppNames({ translations: { loading_app_names } }) {
  return loading_app_names;
}

export function isLoadingLanguages({ translations: { loading_languages } }) {
  return loading_languages;
}

export function isLoadingTranslations({
  translations: { loading_translations },
}) {
  return loading_translations;
}
