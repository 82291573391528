import { useState, } from 'react'
import PropTypes from 'prop-types';

import {
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useTheme, styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next';

import Suppliers from '../../../redux/containers/features/suppliers/Suppliers';
import ClientManagement from '../../../redux/containers/userManagement/clients/ClientManagement';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {(value === index) && children}
    </Grid>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function ClientSuppliers(props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [viewMode, setViewMode] = useState({ editMode: false, createMode: false });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid pt={4} px={2}>
      {viewMode.createMode || viewMode.editMode ?
        <></>
        :
        <StyledPaper
          sx={{ p: 1, maxWidth: 320, }}
          style={{
            'border-top-left-radius': 8, 'border-top-right-radius': 8,
            'border-bottom-left-radius': 0, 'border-bottom-right-radius': 0,
          }}
        >
          <Grid container justifyContent="center">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              aria-label="full width tabs example"
            >
              <Tab label={
                <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>
                  {t('cws_app.general.client_admin', 'Clients')}
                </Typography>
              } {...a11yProps(0)} />
              <Tab label={
                <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>
                  {t('cws_app.general.supplier_admin', 'Suppliers')}
                </Typography>
              } {...a11yProps(1)} />
            </Tabs>
          </Grid>
        </StyledPaper>}
      <TabPanel value={value} index={0} dir={theme.direction}>
        <ClientManagement viewMode={viewMode} setViewMode={setViewMode} />
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <Suppliers />
      </TabPanel>
    </Grid>
  );
}

const StyledPaper = styled(props => {
  return (
    <Paper
      elevation={1}
      {...props}
    />
  )
}
)(({ theme }) => {
  return ({
    width: '100%',
    background: theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.paper.main,
      color: 'white',
    },
    '& .MuiTableCell-root': {
      background: theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
      color: theme.palette.type === 'light' ? 'black' : 'white',
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
    },
    '& .MuiOutlinedInput-root': {
      color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
      '& fieldset': {
        borderColor: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
      },
    },
  })
}
);
