import { connect } from 'react-redux';
import DocsModal from '../../../../components/features/documentation/DocsModal';

import { getUserInfo, isCredentialLoading } from 'core/selectors';
import { requestDocumentationCredentials } from 'core/slices/documentation';

export default connect(
  state => ({
    getUserInfo: getUserInfo(state),
    isCredentialLoading: isCredentialLoading(state),
  }),
  { requestDocumentationCredentials },
)(DocsModal);
