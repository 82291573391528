import { connect } from 'react-redux';
import Wall from '../../../../components/features/digitalShelf/Wall';

import { getNumberFormat } from 'core/slices/store';

import {
    setProductState,
    getExcelLandmarks,
    getDigitalShelfExcel,
    clearCoordsState,
    clearWallImageData,
    getMisplacedProductSignedImages,
    getCoordsForExcel
} from 'core/slices/wall';

import {
    getWallImageData,
    getCoordsInfo,
    getWallLandmarks,
    getWallsAislesData,
    getAislePromotionsList,

    isLoadingAislePromotions,
    isLoadingWallImageData,
    isLoadingCoordsInfo,
    isLoadingWallLandmarks,
    isLoadingLandmarksExcel,

    getExcelLandmarkList,
    getSelectedWallProduct,
    getSelectedClient,
    getAisleLandmarkState,
    misplacedSignedImages,
    loadingMisplacedSignedImages,
    coordsForExcel,
    loadingCoordsForExcel,
    currencySymbol,

    isLoadingMaps,
    getStoreMapsObject,
    isLoadingClientFormats,
    getClientFormats,
} from 'core/selectors';

export default connect(state => ({
    wallImageData: getWallImageData(state),
    isLoadingWallImage: isLoadingWallImageData(state),
    landmarkInfo: getCoordsInfo(state),
    isLoadingCoordsInfo: isLoadingCoordsInfo(state),
    wallLandmarks: getWallLandmarks(state),
    isLoadingLandmarks: isLoadingWallLandmarks(state),
    selectedProductState: getSelectedWallProduct(state),
    selectedClient: getSelectedClient(state),
    excelLandmarkList: getExcelLandmarkList(state),
    isLoadingLandmarksExcel: isLoadingLandmarksExcel(state),
    aislesData: getWallsAislesData(state),
    aislePromotions: getAislePromotionsList(state),
    isLoadingAislePromotions: isLoadingAislePromotions(state),
    aisleLandmarks: getAisleLandmarkState(state),
    misplacedSignedImages: misplacedSignedImages(state),
    loadingMisplacedSignedImages: loadingMisplacedSignedImages(state),
    coordsForExcel: coordsForExcel(state),
    loadingCoordsForExcel: loadingCoordsForExcel(state),
    currencySymbol: currencySymbol(state),
    isLoadingMaps: isLoadingMaps(state),
    storeMaps: getStoreMapsObject(state),

    clientFormats: getClientFormats(state),
    isLoadingClientFormats: isLoadingClientFormats(state),
}), {
    setProductState,
    getExcelLandmarks,
    getDigitalShelfExcel,
    clearCoordsState,
    clearWallImageData,
    getMisplacedProductSignedImages,
    getCoordsForExcel,
    getNumberFormat,
})(Wall);
