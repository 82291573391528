import React, { Component } from 'react';
import { Grid, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';

// Icons
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

const styles = {
  container: {
    borderRadius: 1,
    py: 2,
    px: 2,
  },
  text: {
    textAlign: 'justify',
  },
  icon: {
    color: 'white',
    marginRight: 16,
    width: 40,
    height: 40,
    paddingTop: 4,
  },
};

class Aside extends Component {
  constructor(props) {
    super(props);
    this.state = {
      icons: {
        notice: 'information-circle-outline',
        success: 'checkmark-circle-outline',
        warning: 'alert-circle-outline',
      },
      colors: {
        notice: '#8fbcd4',
        success: '#6ac174',
        warning: '#c97a7e',
      },
    };
  }

  render() {
    const { t, notification, notificationType } = this.props;
    const { icons, colors } = this.state;

    return (
      <Grid
        sx={[styles.container, { bgcolor: colors[notificationType] }]}
        container
        direction="row"
        alignItems="center">
        <Grid>
          <IconComponent
            iconName={icons[notificationType]}
            style={styles.icon}
          />
        </Grid>
        <Grid sx={{ flex: 1 }} alignItems="center">
          <Typography component={'div'}>
            <ReactMarkdown
              className="markdown_notification"
              children={t('cws_app.documentation_information.' + notification)}
            />
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default Aside;
