import { Base64 } from 'js-base64';

export class PdfExporter {
    constructor() { }

    handleDownloadPdf(pdfFile, pdfType) {
        // TODO: OJOOOO con el pdf_list debe venir parseado de antes
        // const robotNavigationData = pdfResponse.pdf_list
        if (pdfType === 'listFormat') { // external pdf generated
            const name = [],
                link = document.createElement("a");
            let reportName = '',
                bytesFile,
                trustedUrl;

            // data is already a JSON object, TODO: ojito con el pdf_l!st !!!!
            pdfFile.forEach((data) => {
                if (!("bytes_file" in data)) {
                    name.unshift(data["r_type"] + " " + data["store"]);
                    reportName = data["r_type"] + " " + data["store"];
                } else if ("bytes_file" in data) {
                    bytesFile = Base64.toUint8Array(data["bytes_file"]);
                    const blobFile = new Blob([bytesFile], { type: "application/pdf" });
                    trustedUrl = URL.createObjectURL(blobFile);
                }
            });

            link.href = trustedUrl;
            link.download = reportName;
            link.click();
        } else { // pdf reports generated by cpg
            const bytesFile = Base64.toUint8Array(pdfFile.bytes_file);
            const blobFile = new Blob([bytesFile], { type: "application/pdf" });
            const url = URL.createObjectURL(blobFile);
            const link = document.createElement("a");
            const reportName = pdfFile.pdf_name;

            link.href = url;
            link.download = reportName;
            link.click();
        }
    }
}
