import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

import { BarChart } from "@zippeditoolsjs/dashboards";

import { getConsolidatedTranslations } from 'core/utils/parsers';

export const dataFormatter = (number, metric_type, numberFormat, currencySymbol) => {
  let numberFormatted = numberFormat ? number.toLocaleString(numberFormat) : number;
  return metric_type === 'percent' ? `${numberFormatted} %`
    : metric_type === 'number' ? numberFormatted
      : `${currencySymbol} ${numberFormatted}`;
};

const EXPAND_WIDTH = 2.875;

export default function BarGraph(props) {
  const {
    colorsInput = ["blue"],
    indexInput = "name",
    keysInput = [],
    data = [],
    metric_type,
    expanded = false,
    selectedClientName = '',
    numberFormat = 'en-US',
    isStacked = false,
    currencySymbol
  } = props;

  const { t } = useTranslation();
  const [chartData, setChartData] = useState(data);
  const [categories, setCategories] = useState(keysInput);
  const [index, setIndex] = useState(indexInput);

  // Hooks

  useEffect(() => {
    if (data && keysInput && keysInput.length > 0 && data.length > 0 && indexInput) {
      const dataAux = data.map((rowInfo) => {
        const itemAux = {};
        Object.keys(rowInfo).forEach((keyName) => {
          itemAux[getConsolidatedTranslations(t, selectedClientName, keyName, 'general', keyName)] = rowInfo[keyName];
        });
        return itemAux;
      });
      const keysAux = keysInput.map((key) => {
        return getConsolidatedTranslations(t, selectedClientName, key, 'general', key);
      });
      const indexAux = getConsolidatedTranslations(t, selectedClientName, indexInput, 'general', indexInput);

      setChartData(dataAux);
      setCategories(keysAux);
      setIndex(indexAux);
    }
  }, [data, keysInput, indexInput, selectedClientName]);

  // handlers

  const handleExpandWidth = () => {
    return EXPAND_WIDTH * data.length
  }

  return (
    <Box sx={{ overflowX: expanded ? 'auto' : 'hidden' }}>
      <BarChart
        className="w-full h-[10em]"
        style={{ width: expanded ? `${handleExpandWidth()}em` : '', minWidth: '-webkit-fill-available' }}
        data={chartData}
        index={index}
        categories={categories}
        colors={colorsInput}
        valueFormatter={(number) => dataFormatter(number, metric_type, numberFormat, currencySymbol)}
        yAxisWidth={48}
        showXAxis={false}
        showLegend={false}
        stacked={isStacked}
      />
    </Box>
  );
}