import React, { Component } from 'react';
import { Grid, IconButton, Toolbar, LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import { LocalStorageService } from 'core/services/localStorage';

// My components
import MyMenu from './MyMenu';
import Notifications from './Notifications';
import ClientSelector from '../../../redux/containers/navbar/ClientSelector';

//Icons
import logo_zippedi_letters from '../../../../images/zippedi_only_letters_white.png';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

// Styled components
const AppBar = styled(MuiAppBar)(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 101,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const styles = {
  divider: {
    background: '#cacadc',
    marginTop: 8,
    marginBottom: 8,
    width: '-webkit-fill-available',
  },
  logo: {
    height: 28,
    width: 25,
    marginBottom: '0.5em',
  },
  logo_min: {
    height: 25,
    width: 25,
    marginBottom: '0.5em',
  },
  logo_letters: {
    height: 50,
    width: 120,
  },
};
const drawerWidth = 240;

class Navbar extends Component {
  constructor(props) {
    super(props);
    const localStorageService = new LocalStorageService();
    if (localStorageService.getToken() !== '') {
      props.getUserNotifications();
    }
    this.state = {
      username:
        props.getUserInfo?.name ? props.getUserInfo?.name
          : props.getUserInfo?.email ? props.getUserInfo?.email.split('@')[0].split('_').join(' ')
            : { name: '' },
      tenant: props.getUserInfo?.firebase?.tenant ? props.getUserInfo?.firebase?.tenant : '',
      notificationList: props.notificationsList,
      isLoadingUserNotifications: props.isLoadingUserNotifications,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.notificationsList !== this.props.notificationsList) {
      this.setState({
        notificationList: this.props.notificationsList,
        isLoadingUserNotifications: this.props.isLoadingUserNotifications,
      });
    }
    if (!prevProps.getUserClientList.length && !this.props.isLoadingClients && this.props.getUserClientList.slice(3).findIndex(x => x.signed_logo !== '/') === -1) {
      this.props.getUserClients(null, 'clients', 'isLoadingClients', true);;
    }
  }

  render() {
    const {
      open,
      handleDrawer,
      toggleTheme,
      isDarkTheme,
      isLoadingUserInfo,
      isLoadingClients,
      getUserClientList,
      toogleTranslation,
    } = this.props;
    return (
      <AppBar
        position="fixed"
        open={open}
        sx={{
          bgcolor: isDarkTheme ? 'paper.main' : 'primary.main',
          height: 70,
        }}>
        <Toolbar>
          {/* Menu open button */}
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
            sx={{
              marginRight: 4,
              ...(open && { display: 'none' }),
              color: 'white',
            }}>
            <IconComponent iconName={'menu-outline'} />
          </IconButton>

          <img
            style={styles.logo_letters}
            src={logo_zippedi_letters}
            alt="logo_zippedi_expanded"
          />

          {/* spacing */}
          <Grid sx={{ flexGrow: 1 }} />

          <ClientSelector
            clientsList={getUserClientList}
            isLoadingUserInfo={isLoadingUserInfo || isLoadingClients}
          />
          <Notifications
            notificationList={this.state.notificationList}
            isLoadingNotifications={this.state.isLoadingUserNotifications}
            getUserNotifications={this.props.getUserNotifications}
            postUserNotification={this.props.postUserNotification}
          />
          <MyMenu
            toggleTheme={toggleTheme}
            toogleTranslation={toogleTranslation}
            username={this.state.username}
            tenant={this.state.tenant}
            logOut={this.props.logOut} />
        </Toolbar>

        {isLoadingUserInfo ? <LinearProgress color="secondary" /> : null}
      </AppBar>
    );
  }
}

export default Navbar;
