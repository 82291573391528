import {
    Button,
    Divider,
    Grid,
    TextField,
    Typography,
    Paper,
} from "@mui/material";

import { styled } from "@mui/material/styles";

// My Components
import InfiniteMultiSelector from '../../tools/InfiniteMultiSelector';

export default function UserForm(props) {
    const {
        t,
        theme,
        clientStoresList,
        loadingClientStores,
        mailerTaskList,
        isloadingMailerTasks,
        mode,
        isloadingContactInfo,
        name,
        setName,
        mail,
        setMail,
        stores,
        setStores,
        tasks,
        setTasks,
        handleUpdateContactInfo,
    } = props;

    const textColor = theme.palette.type === 'light' ? 'black' : 'white';

    return (
    <StyledPaper sx={{ pt: 2, pb: 2, px: 4, mt: -1 }}>
        <Grid container direction={'column'} justifyContent={'space-between'}>
            <Grid item>
                <Typography sx={{ fontSize: '24px', fontWeight: '20px', py: 1, color: textColor }}>
                    { mode === 'delete' ? t('cws_app.mailer.delete_c')
                        : mode === 'edit' ? t('cws_app.mailer.edit_c')
                        : t('cws_app.mailer.create_c')
                    }
                </Typography>
                <Typography sx={{ py: 1, color: textColor }}>
                    {mode === 'delete' ? t('cws_app.mailer.sentence_delete')
                        : mode === 'edit' ? t('cws_app.mailer.sentence_edit')
                        : t('cws_app.mailer.sentence_create')
                    }
                </Typography>
            </Grid>
            <Divider sx={{ p: 1 }}/>
            <Grid item container xs={12} sx={{ mt: 2, mb: 1 }} spacing={1} >
                <Grid item xs>
                    <TextField
                        fullWidth
                        id="outlined-required"
                        variant="standard"
                        label={t('cws_app.general.name')}
                        required={mode === 'delete' ? false : true}
                        disabled={mode === 'delete' ? true : false}
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs>
                    <TextField
                        id="outlined-disabled"
                        variant="standard"
                        required={mode === 'delete' ? false : true}
                        disabled={mode === 'create' ? false : true}
                        value={mail}
                        label={t('cws_app.general.mail')}
                        onChange={(e) => {setMail(e.target.value)}}
                        fullWidth
                    />
                </Grid>
            </Grid>
            {(mode === 'create') || (mode === 'edit') ? <>
                <Grid width={'100%'} sx={{ my: 2, }}>
                    <InfiniteMultiSelector
                        inputVariant='standard'
                        inputLabel={t('cws_app.general.sel_store')}
                        options={clientStoresList}
                        isLoading={mode === 'edit' ? isloadingContactInfo : loadingClientStores }
                        inputSelected={stores}
                        setInputSelectedOptions={setStores}
                        objectName='parsed_name' objectId='store_id'
                        groupBy={'chain_name'}
                        canSelectAll={false}
                    />
                </Grid>
                <Grid width={'100%'} sx={{ my: 2, }}>
                    <InfiniteMultiSelector
                        inputVariant='standard'
                        inputLabel={t('cws_app.general.sel_task')}
                        options={mailerTaskList}
                        isLoading={mode === 'edit' ? isloadingContactInfo  : isloadingMailerTasks}
                        inputSelected={tasks}
                        setInputSelectedOptions={setTasks}
                        objectName='task_name' objectId='r_type'
                    />
                </Grid>
            </> : null }
        </Grid>
        <Grid item container xs={12} justifyContent={'flex-end'}>
            <Button variant="contained" color="primary" sx={{ my: 1 }}
                onClick={() => {handleUpdateContactInfo(mode)}}
            >
                { mode === 'delete' ? t('cws_app.general.delete') : t('cws_app.general.save') }
            </Button>
        </Grid>
    </StyledPaper>
)}

const StyledPaper = styled(
    props => {
        return <Paper elevation={1} {...props} />
    }
)(({ theme }) => {
    return ({
        width: '100%',
        background: theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
        color: theme.palette.type === 'light' ? 'black' : 'white',
        '& .MuiPaper-root': {
            backgroundColor: theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
            borderRadius: 3,
        },
        '& .MuiInputLabel-root': {
            color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
        },
    })}
);