import { Divider, Grid, Paper, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

const StyledPaper = styled(props => {
    return (
        <Paper
            elevation={1}
            {...props}
        />
    )
}
)(({ theme }) => {
    return ({
        width: '100%',
        background: theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.paper.main,
        color: 'white',
        '& .MuiPaper-root': {
            color: 'white',
        },
    })
}
);

export default function ClientMHeader(props) {
    const { totalClients = -25, clientsTypesCount = { 'home_improvement': 0, 'retail': 0, 'cpg': 0 } } = props;
    const { t } = useTranslation();

    return (
        <StyledPaper 
            style={{
                'border-top-left-radius': 0, 'border-top-right-radius': 8,
                'border-bottom-left-radius': 8, 'border-bottom-right-radius': 8,
            }}
        >
            <Grid
                container
                direction="row"
                mb={2}
            >
                <Grid xs={4}
                    display="flex"
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    py={2}
                    item>

                    <Typography variant="body1" textColor="text.secondary">{t(`cws_app.management.total_clients`, 'Total Clients')}</Typography>
                    <Typography variant="h3">{totalClients}</Typography>
                </Grid>

                <Divider orientation="vertical" flexItem style={{ background: '#cacadc' }} />

                <Grid xs
                    display="flex"
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    py={2}
                    item>
                    <Typography variant="body1">{t(`cws_app.management.clients_per_type`, 'Clients per type')}</Typography>
                    <Grid
                        container
                        direction="row"
                        display="flex"
                        justifyContent="center"
                        alignItems="center">
                        {Object.keys(clientsTypesCount).map((clientsType) =>
                        (<Grid xs
                            item
                            direction="column"
                            display="flex"
                            justifyContent="center"
                            alignItems="center">
                            <Typography variant="h5">{clientsTypesCount[clientsType]}</Typography>
                            <Typography variant="body2" style={{ color: '#cacadc' }}>{t(`cws_app.general.${clientsType}`, clientsType)}</Typography>
                        </Grid>
                        ))}
                    </Grid>

                </Grid>

            </Grid>
        </StyledPaper>
    );
}
