import { Base64 } from 'js-base64';

export class InvExporter {
    constructor() { }

    handleDownloadInv(invFile) {
        const bytesFile = Base64.toUint8Array(invFile.bytes_file);
        const blobFile = new Blob([bytesFile], { type: "text/plain" });
        const url = URL.createObjectURL(blobFile);
        const link = document.createElement("a");
        const reportName = invFile.inv_name;

        link.href = url;
        link.download = reportName;
        link.click();
    }
}
