/**
 * Parse an array of metrics to lists of strings with the format required by exceljs
 * @param 	{Array} 		data  							Data to be parsed
 * @param   {String} 		firstColumn 				Column to be used as header
 * @param 	{Boolean} 	isfirstCellEmpty  	First cell of the header is empty
 * @returns {Array} 												Parsed array
 * @example
 * formatToExcel([
    {
      store: 'J501',
      date: '2023-08-07',
      osa_category: 75.4,
      osa_suplier: 79
    },
    {
      store: 'J501',
      date: '2023-08-14',
      osa_category: 73.3,
      osa_suplier: 74
    }
  ], 'date', false) // [['date', 'osa_category', 'osa_suplier'], ['2023-08-07', 75.4, 79], ['2023-08-14', 73.3, 74]
 */
export function formatToExcel(data, firstColumn = 'date', isfirstCellEmpty = false) {
  let table = isfirstCellEmpty ? [['']] : [];

  if (data.length === 0) return [];

  // Remove duplicated columns (columns that have the same name or a dot in the name)
  const columns = Object.keys(data[0]).filter((item, index, self) => {
    return self.indexOf(item) === index && item.indexOf('.') === -1;
  });

  // First row of the table is the column names

  table.push(columns);

  // Make the firstColumn the first element of the array
  table = table.map(item => {
    const index = item.indexOf(firstColumn);
    if (index > -1) {
      item.splice(index, 1);
      item.unshift(firstColumn);
    }
    return item;
  });

  // Add the data to the table based on the order of the columns
  data.forEach(item => {
    const row = [];
    table[0].forEach(column => {
      row.push(item[column]);
    });
    table.push(row);
  });
  return table;
};

/**
 * Parse MUI's DataGrid table to an array of arrays with the format required by exceljs
 * The first element of the array is the header
 * @param 	{Array} 		columns  					Columns of the table
 * @param 	{Array} 		rows  	          Rows of the table
 * @param 	{Boolean} 	firstCell  	      First cell of the header
 * @returns {Array} 											Parsed array
 * @example
 * formatDataGridToExcel(
 *    ["osa_supplier.ALMACEN", "osa_supplier.BOTILLERÍA/GASEOSAS"],
 *    [
 *      {
 *         "id": 0,
 *         "store": "J501",
 *         "osa_category.ALMACEN": 82,
 *         "osa_category.BOTILLERÍA/GASEOSAS": 65,
 *       },
 *       {
 *         "id": 1,
 *         "store": "J502",
 *         "osa_category.ALMACEN": 82,
 *         "osa_category.BOTILLERÍA/GASEOSAS": "-",
 *       },
 *     ]) // [['', 'osa_supplier.ALMACEN', 'osa_supplier.BOTILLERÍA/GASEOSAS'], ['J501', 82, 65], ['J502', 82, 65]]
 */
export function formatDataGridToExcel(columns, rows, firstCell = '') {
  let columnsCopy = firstCell !== '' ? [firstCell] : [];
  columnsCopy = [...columnsCopy, ...columns.slice()]; // Copy the array to avoid modifying the original
  let table = [columnsCopy]

  rows.forEach(row => {
    let rowArray = [];
    columnsCopy.forEach(column => {
      rowArray.push(row[column]);
    });
    table.push(rowArray);
  });

  return table;
}


/**
* Parse an array of metrics to lists of strings with the format required by exceljs
* The first element of the array is the header
* @param 	{Array} 		data  							Data to be parsed
* @param 	{Boolean} 	firstCell         	First cell of the header
* @param 	{String} 		excelColumn 				Parameter to be used as headers
* @returns {Array} 												Parsed array
* @example
* formatToExcel([
  {
      id: "% Daily Navigation Coverage",
      data: [
          {x: '2023-08-07', y: 75.4},
          {x: '2023-08-14', y: 73.3}
      ]
  },
  {
      id: "% 14 Day Navigation Coverage",
      data: [
          {x: '2023-08-07', y: 79},
          {x: '2023-08-14', y: 74}
      ]
  }
  ]) // [['', '% Daily Navigation Coverage', '% 14 Day Navigation Coverage'], ['2023-08-07', 75.4, 79], ['2023-08-14', 73.3, 74]
*/
export function formatNivoToExcel(data, firstCell = '', excelColumn = 'id',) {
  let table = firstCell === '' ? [['']] : [[firstCell]];

  data?.forEach(dataRow => {
    table[0].push(dataRow[excelColumn]);
    dataRow['data'].forEach((row, index) => {
      if (table[index + 1] === undefined) {
        table[index + 1] = [row['x'], row['y']];
      } else {
        table[index + 1].push(row['y']);
      }
    })
  });
  return table;
};
