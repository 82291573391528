import { createReducer } from './utils';
import { handleRequest, handleAvailable, handleUnavailable } from '../reducerHandlers'
import {
    SNACK_SET,
    TASKS_REQUEST,
    TASKS_AVAILABLE,
    TASKS_UNAVAILABLE
} from './constants';


// Reducer

const defaultState = {
    task_types: [],
    loading_task_types: false
};

export const reducer = createReducer(defaultState, {
    [TASKS_REQUEST]: handleRequest,
    [TASKS_AVAILABLE]: handleAvailable,
    [TASKS_UNAVAILABLE]: handleUnavailable
});

// Actions

export function getTaskTypes(client_id) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const keyState = 'task_types'
        const loadingName = 'loading_task_types'
        dispatch({ type: TASKS_REQUEST, payload: { loadingName: loadingName } });
        try {
            const response = await dataSource.getTaskTypes(client_id);
            dispatch({
                type: TASKS_AVAILABLE,
                payload: { keyState: keyState, data: response, loadingName: loadingName },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: TASKS_UNAVAILABLE,
                payload: { keyState: keyState, loadingName: loadingName, defaultState: [] },
            });
            const snack = {
                open: true,
                message: 'There was an error getting the task types.',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}
