import React from "react";

import {
	Grid,
	Typography
} from "@mui/material";

import { useTranslation } from "react-i18next";

import WallSelector from '../../../redux/containers/features/digitalShelf/WallSelector';
import Wall from '../../../redux/containers/features/digitalShelf/Wall';

import StockSVG from '../../../../images/stock.svg';
import PriceDifferenceSVG from '../../../../images/price_difference.svg';
import StockOutAlertSVG from '../../../../images/stockout.svg';
import MisplacedProductSVG from '../../../../images/misplaced_product.svg';

export default function DigitalShelf(props) {
	const { t } = useTranslation();

	const [imgsDict, setImgsDict] = React.useState(null);
	const [stock, setStock] = React.useState();
	const [priceDifference, setPriceDifference] = React.useState();
	const [stockOut, setStockOut] = React.useState();
	const [spread, setSpread] = React.useState();
	const [misplaced, setMisplaced] = React.useState();

	// load svgs to use later
	React.useEffect(() => {
		if (!(stock?.stock) && StockSVG) {
			const stock = new Image();
			stock.src = StockSVG//iconDataUrl;
			stock.onload = () => { setStock(stock) }
		}
	}, []);
	React.useEffect(() => {
		if (!(priceDifference?.priceDiff) && PriceDifferenceSVG) {
			const priceDiff = new Image();
			priceDiff.src = PriceDifferenceSVG;
			priceDiff.onload = () => { setPriceDifference(priceDiff) }
		}
	}, []);

	React.useEffect(() => {
		if (!(stockOut?.stockout) && StockOutAlertSVG) {
			const stockout = new Image();
			stockout.src = StockOutAlertSVG;
			stockout.onload = () => { setStockOut(stockout) }
			const spread = new Image();
			spread.src = StockOutAlertSVG;
			spread.onload = () => { setSpread(spread) }
		}
	}, []);
	React.useEffect(() => {
		if (!(misplaced?.misplaced_product) && MisplacedProductSVG) {
			const misplaced = new Image();
			misplaced.src = MisplacedProductSVG;
			misplaced.onload = () => { setMisplaced(misplaced) }
		}
	}, []);

	// display loaded images with React.useEffect
	React.useEffect(() => {
		if (stock && priceDifference && stockOut && misplaced) {
			// TODO: replace extra_label icon later
			setImgsDict({
				stock: stock, price_difference: priceDifference,
				stockout: stockOut, misplaced_product: misplaced,
				extra_label: priceDifference, spread_to_fill: spread
			});
		}
	}, [stockOut, stock, priceDifference, misplaced]);

	return (
		<Grid container direction={'column'} px={2}>
			<Grid item sx={{ mt: 2, mb: 1 }}>
				<Typography sx={{ fontSize: '34px', }}>
					{t('cws_app.general.Digital_Shelf', "Digital Shelf")}
				</Typography>
			</Grid>
			<Grid item container direction={'row'} justifyContent={'flex-start'} alignItems={'center'}>
				<WallSelector />
				<Wall imgsDict={imgsDict} />
			</Grid>
		</Grid>
	);
}