import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import '../App.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

// zippedi tools js imports
import { ReduxLoginWeb as PrivateRoute } from '@zippeditoolsjs/web';
import { lightTheme, darkTheme } from '@zippeditoolsjs/web';
import { ZtoolSnackAlert as SnackAlert } from '@zippeditoolsjs/blocks'

import Bruno from './redux/containers/userManagement/bruno/Bruno';
// import ClientManagement from './redux/containers/userManagement/clients/ClientManagement';
import Dashboards from './redux/containers/features/dashboards/Dashboards';
import LastProductState from './redux/containers/features/lastProductState/LastProductState';
import DigitalStore from './redux/containers/features/digitalStore/DigitalStore';
import DocsLayout from './redux/containers/features/documentation/DocsLayout';
import Home from './redux/containers/home/Home';
import Layout from './redux/containers/navbar/Layout';
import Link from './redux/containers/link/Link';
import Search from './redux/containers/search/Search';
import DigitalShelf from './redux/containers/features/digitalShelf/DigitalShelf';
import TrackingList from './redux/containers/features/trackingList/TrackingList';
import ClientSuppliers from './components/userManagement/suppliers/ClientSuppliers';
import Mailer from './redux/containers/features/mailer/Mailer';
import Notifications from './redux/containers/features/notifications/Notifications';
import Translations from './redux/containers/translations/Translations';

// instantiate services and wire up dependencies
import createStore from 'core/store';
import HTTPService from 'core/services/http';
import DataSource from 'core/services/dataSource';
import AuthSource from 'core/services/authSource';

import { useTranslation, withTranslation } from 'react-i18next';
import Rescheduling from './redux/containers/features/rescheduling/Rescheduling';

import Users from './redux/containers/userManagement/users/Users';

// Contexts
import { DigitalShelfProvider } from './contexts/DigitalShelf';

const { REACT_APP_API_BASE_URL, REACT_APP_AUTH_BASE_URL, REACT_APP_BASE_URL, REACT_APP_AUTH_BACKEND_URL } =
  process.env;

const http = new HTTPService();

const dataSource = new DataSource({
  baseUrl: REACT_APP_API_BASE_URL,
  http,
});

const authSource = new AuthSource({
  baseUrl: REACT_APP_AUTH_BACKEND_URL,
  http,
});

const store = createStore({
  authSource,
  dataSource,
});

const App = () => {
  const [theme, setTheme] = useState('light');
  const isDarkTheme = theme === 'dark';
  const toggleTheme = () => setTheme(isDarkTheme ? 'light' : 'dark');

  const { i18n } = useTranslation();
  const toogleTranslation = lang => i18n.changeLanguage(lang);

  return (
    <Provider store={store}>
      <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
        <SnackAlert />
        <PrivateRoute
          appUrlLogin={REACT_APP_AUTH_BASE_URL}
          tenattype={'ext'}
          appBaseUrl={REACT_APP_BASE_URL}>
          <Router>
            <Layout
              isDarkTheme={isDarkTheme}
              toggleTheme={toggleTheme}
              toogleTranslation={toogleTranslation}>
              <Routes>
                {/* Main Routes */}
                <Route path="/" element={<Home />} />
                <Route path="/cpg/home" element={<Home />} />
                <Route path="/cpg/walls" element={
                  <DigitalShelfProvider>
                    <DigitalShelf />
                  </DigitalShelfProvider>
                } />
                <Route path="/cpg/trackingList" element={<TrackingList />} />
                <Route path="/cpg/digitalStore" element={<DigitalStore />} />
                <Route path="/cpg/robotRescheduling" element={<Rescheduling />} />
                <Route path="/cpg/lastProductState" element={<LastProductState />} />

                <Route exact path="/cpg/docs" element={<DocsLayout />} />

                {/* Assignment Routes */}
                <Route path="/cpg/dashboards" element={<Dashboards />} />
                <Route path="/cpg/admin/trackingList" element={<TrackingList />} />

                {/* User Accounts Routes */}
                {/* <Route path="/cpg/admin/clients" element={<ClientManagement />} /> */}
                <Route path="/cpg/admin/users" element={<Users />} />
                <Route path="/cpg/admin/clients" element={<ClientSuppliers />} />
                <Route path="/cpg/admin/bruno" element={<Bruno />} />

                {/* Mailer */}
                <Route path="/cpg/admin/mailer" element={<Mailer />} />

                {/* Settings */}
                <Route path="/cpg/admin/translations" element={<Translations />} />

                {/* Notifications */}
                <Route path="/cpg/admin/notifications" element={<Notifications />} />

                {/* Extras */}
                <Route path="/search" element={<Search />} />
                <Route path="/share" element={<Link />} />
              </Routes>
            </Layout>
          </Router>
        </PrivateRoute>
      </ThemeProvider>
    </Provider>
  );
};

export default withTranslation()(App);
