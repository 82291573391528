import React, { useEffect, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridToolbarContainer,
} from '@mui/x-data-grid';

// Functions
import { debounce } from '../../utils/debounce';

const formatColumns = (t, handleUpdateTranslation) => {
  const columns = [
    {
      field: 'base_word',
      headerName: t('cws_app.translations.t_base'),
      width: 400,
      align: 'left',
    },
    {
      field: 'translation_word',
      headerName: t('cws_app.translations.t_word'),
      width: 500,
      align: 'left',
      renderCell: params => {
        return (
          <TextField
            fullWidth
            required
            size="small"
            label={params.row.translation_word}
            defaultValue={params.row.translation_word}
            onChange={e =>
              handleUpdateTranslation(params.row.base_word, e.target.value)
            }
            variant="standard"
          />
        );
      },
    },
  ];

  return columns;
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter sx={{ width: '50%', p: 2 }} />
    </GridToolbarContainer>
  );
}
export default function TranslationTable(props) {
  const { t, translationList, isLoadingTranslations, updateTranslations } =
    props;
  const [columns, setColumns] = useState([]);

  const handleUpdateTranslation = debounce(updateTranslations, 500);

  useEffect(() => {
    setColumns(formatColumns(t, handleUpdateTranslation));
  }, []);

  return (
    <Grid>
      <DataGrid
        getRowId={row => row.id}
        rows={translationList}
        columns={columns}
        slots={{ toolbar: CustomToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        disableColumnFilter
        disableExport
        disableDensitySelector
        localeText={{
          toolbarFilters: t('cws_app.general.filters', 'Filters'),
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        autoreset={false}
        pageSizeOptions={[5, 10, 25]}
        disableColumnSelector={true}
        disableRowSelect={true}
        disableColumnMenu={true}
        loading={isLoadingTranslations}
        sx={{ width: '100%' }}
      />
    </Grid>
  );
}
