import { format as dateFnsFormat } from 'date-fns';
import { de, ja, es } from 'date-fns/locale';
const locales = { 'en': undefined, 'de': de, 'ja': ja, 'es': es };

export function convertMeterToPixel(xMeter, yMeter, scaleValue = 1, WALLQUALITYFACTOR = 2) {
  const sc_rgb = 380.2270100911458 * WALLQUALITYFACTOR;
  const xPixel = xMeter ? Math.round(scaleValue * sc_rgb * xMeter) : null;
  const yPixel = yMeter ? Math.round(scaleValue * (-sc_rgb * yMeter + 2100 * WALLQUALITYFACTOR / 2)) : null;
  return [xPixel, yPixel];
}

// \s is the regex for "whitespace", and g is the "global" flag, meaning match ALL \s (whitespaces) with "replaceWith".
export function replaceWhiteSpace(input, replaceWith = '') {
  return input.replace(/\s+/g, replaceWith)
}

export function getAisleVector({ pixelInit, pixelEnd }) {
  const x = pixelEnd[0] - pixelInit[0];
  const y = pixelEnd[1] - pixelInit[1];
  return [x, y];
}

export function getAisleLength({ pixelInit, pixelEnd }) {
  return Math.sqrt(Math.pow(pixelEnd[0] - pixelInit[0], 2) + Math.pow(pixelEnd[1] - pixelInit[1], 2));
}

export function getIsHorizontal(lowerRightPx, upperLeftPx) {
  return Math.abs(lowerRightPx[0] - upperLeftPx[0]) > Math.abs(lowerRightPx[1] - upperLeftPx[1]);
}

export function getAislePadding() {
  return 10;
}

export function getAisleMeasures({ pixelInit, pixelEnd, includePadding = false }) {
  const isHorizontal = getIsHorizontal(pixelEnd, pixelInit);
  const aisleVector = getAisleVector({ pixelInit, pixelEnd });
  const width = Math.abs(aisleVector[0]) + (includePadding ? (isHorizontal ? 0 : getAislePadding()) : 0);
  const height = Math.abs(aisleVector[1]) + (includePadding ? (isHorizontal ? getAislePadding() : 0) : 0);
  return { width, height };
}

export function getTopAisleCoords({ pixelInit, pixelEnd }) {
  return [Math.min(pixelInit[0], pixelEnd[0]), Math.min(pixelInit[1], pixelEnd[1])];
}

export function getRobotIndex({ session, floorIdentifier = 'Piso', defaultIndex = 1 }) {
  let robotIndex = session && session.includes(floorIdentifier) ? session.split(floorIdentifier)[1] : defaultIndex;
  robotIndex = parseInt(robotIndex ?? defaultIndex);
  return robotIndex;

}

export function checkFormat(formatLocale = null) {
  const formatPattern = formatLocale ? formatLocale.replaceAll('Y', 'y').replaceAll('D', 'd') : null;
  return formatPattern;
}

export function format(date, locale = null, formatLocale = null) {
  const formatPattern = checkFormat(formatLocale);
  return formatLocale ? dateFnsFormat(date, formatPattern) : dateFnsFormat(date, 'P', { 'locale': locales[locale] });
}
