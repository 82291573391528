import { Box, IconButton, Tooltip, Typography } from "@mui/material";

// Icons
import InfoIcon from '@mui/icons-material/Info';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

// components
import { useTranslation } from "react-i18next";

import { getConsolidatedTranslations } from 'core/utils/parsers';


export default function MetricTool(props) {
    const {
        metric_info,
        selectedClientName,
        expanded,
        setExpanded,
        isLineChart,
        setIsLineChart,
        noSwap = false,
    } = props;

    const { t } = useTranslation();

    return (
        <Box
            sx={{ display: 'flex', overflow: 'hidden' }}
            className="width-box"
        >
            {metric_info ?
                <Typography variant="subtitle1" sx={{ fontSize: 'smaller', py: 'auto', pl: 1, color: '#b4b4c9' }}>
                    <Tooltip title={
                        getConsolidatedTranslations(t, selectedClientName, metric_info, 'general', metric_info)
                    } placement="bottom">
                        <InfoIcon />
                    </Tooltip>
                </Typography>
                : null
            }

            <Typography variant="subtitle1" sx={{ fontSize: 'smaller', py: 'auto', pl: 1 }}>
                <Tooltip title={expanded ? t('cws_app.home.collapse_width', 'collapse') : t('cws_app.home.expand_width', 'Expand')} placement="bottom">
                    <IconButton onClick={() => setExpanded(!expanded)} sx={{ p: 0 }} disabled={isLineChart}>
                        <IconComponent
                            iconName={'resize'}
                            style={{ transform: 'rotate(45deg)', fontSize: 20 }}
                        />
                    </IconButton>
                </Tooltip>
            </Typography>
            {
                noSwap ? null :
                    <Typography variant="subtitle1" sx={{ fontSize: 'smaller', py: 'auto', pl: 1 }}>
                        <Tooltip title={isLineChart ? t('cws_app.home.change_bar_chart', 'Change to Bar Chart') : t('cws_app.home.change_line_chart', 'Change to Line Chart')} placement="bottom">
                            <IconButton onClick={() => setIsLineChart(!isLineChart)} sx={{ p: 0 }}>
                                {isLineChart ?
                                    <IconComponent
                                        iconName={'bar-chart'}
                                    /> :
                                    <IconComponent
                                        iconName={'analytics-outline'}
                                    />
                                }
                            </IconButton>
                        </Tooltip>
                    </Typography>
            }
        </Box>
    );
}