import React from 'react'

import {
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';

import { useTheme, styled } from '@mui/material/styles';

// icons
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InboxIcon from '@mui/icons-material/Inbox';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';

import { useTranslation } from 'react-i18next';


export default function NotificationList(props) {

  const {
    notificationsList,
    isLoadingNotifications,
    handleSetMode,
  } = props;

  const { t } = useTranslation();
  const theme = useTheme();
  const textColor = theme.palette.type === 'light' ? 'black' : 'white';

  return (
    <StyledPaper sx={{ p: 2, }}>
      <Grid container justifyContent={'space-between'} alignItems={'center'} mb={1}>
        <Grid item >
          <Typography sx={{ fontSize: '24px', color: textColor }}>
            {t('cws_app.notifications.sent_notif')}
          </Typography>
        </Grid>
        <Grid item >
          <IconButton onClick={() => { handleSetMode('create', null) }}>
            <LibraryAddIcon sx={{ color: textColor }} />
          </IconButton>
        </Grid>
      </Grid>
      <Divider p={1} />
      <Grid container
        justifyContent={'space-between'}
        p={{ sm: 1, md: 2 }}
        sx={{ maxHeight: '70vh', overflowY: 'auto', overflowX: 'hidden' }}
      >
        {!isLoadingNotifications && notificationsList?.length > 0 ?
          <>
            {notificationsList?.map((notification, index) => {
              let date = new Date(notification.creation_timestamp).toDateString();
              return (
                <Grid id={notification.notification_id} key={index}
                  item container xs={12} direction={'row'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  p={1}
                >
                  <Grid item xs={1} container>
                    <InboxIcon />
                  </Grid>
                  <Grid item xs={8} container direction={'column'}>
                    <Typography sx={{ fontSize: '17px', fontWeight: 'bold', color: textColor }}>
                      {notification.title}
                    </Typography>
                    <Typography sx={{ fontSize: '15px', fontWeight: '50%', color: textColor }} >
                      {t('cws_app.notifications.by', 'by')} {notification.redacted_by}
                    </Typography>
                    <Typography sx={{ fontSize: '13px', color: textColor, }}>
                    </Typography>
                    <Typography sx={{ fontSize: '13px', color: textColor }}
                    >
                      {date}
                    </Typography>
                  </Grid>
                  <Grid item xs container justifyContent={'center'}>
                    <IconButton color={'info'}
                      onClick={() => { handleSetMode('edit', notification) }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton color={'secondary'}
                      onClick={() => { handleSetMode('delete', notification) }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12} m={1}>
                    {!isLoadingNotifications && notificationsList?.length > 1 ?
                      <Divider />
                      : null
                    }
                  </Grid>
                </Grid>
              )
            })}
          </>
          :
          <Grid container
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography sx={{ fontSize: '22px', color: textColor }}>
              {t('cws_app.notifications.loading_notif', 'Loading notifications...')}
            </Typography>
            <CircularProgress color='secondary' />
          </Grid>
        }
      </Grid>
    </StyledPaper>
  )
}

const StyledPaper = styled(
  props => {
    return <Paper elevation={1} {...props} />
  }
)(({ theme }) => {
  return ({
    width: '100%',
    background: theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
    color: theme.palette.type === 'light' ? 'black' : 'white',
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
      borderRadius: 3,
    },
  })
}
);
