import React, { useContext } from 'react';
import { format } from '../../utils/converters';

import {
  Backdrop,
  Box,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Collapse,
  Button,
} from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

// Icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import { isEmptyOrUndefined } from "@zippeditoolsjs/blocks";

// My Components
import WallDrawer from './WallDrawer';
import { getChainMeaning } from 'core/utils/parsers';
import Modal from '../../tools/Modal';
import WallMetadata from './WallMetadata';
import { DigitalShelfContext } from '../../../contexts/DigitalShelf';

// constants
const RADIUSPLUS = 19;

export default function Wall(props) {
  const { t, i18n } = useTranslation();
  const {
    selectedClient,
    wallImageData,
    landmarkInfo,
    isLoadingCoordsInfo,
    isLoadingWallImage,
    wallLandmarks,
    getDigitalShelfExcel,
    selectedProductState,
    clearCoordsState,
    clearWallImageData,
    aislePromotions,
    aisleLandmarks,
    misplacedSignedImages: incomingMisplacedSignedImages,
    getMisplacedProductSignedImages,
    getCoordsForExcel,
    loadingCoordsForExcel,
    coordsForExcel,
    imgsDict = null,
    getNumberFormat,
    currencySymbol,
    isLoadingMaps,
    storeMaps,

    clientFormats,
  } = props;

  const wallImage = new Image();

  const colors = {
    stock: {
      color: '#349E15',
      focusedColor: '#ABF096',
      color2: '#143f07',
      text: '#fff',
    },
    stockout: {
      color: '#D80A3B',
      focusedColor: '#EA99AC',
      color2: '#60051b',
      text: '#fff',
    },
    spread_to_fill: {
      color: '#DB9105',
      focusedColor: '#FFD8AD',
      color2: '#7a6235',
      text: '#000',
    },
    price_difference: {
      color: '#FFD740',
      focusedColor: '#FFEDAB',
      color2: '#b79d00',
      text: '#000',
    },
    hua: {
      color: '#4065F6',
      focusedColor: '#91C8E4',
      color2: '#2E4890',
      text: '#fff',
    },
    promotion: {
      color: '#F6F4EB',
      focusedColor: '#fff',
      color2: '#ffdef0',
      text: '#000',
    },
    misplaced_product: {
      color: '#40F8FF',
      focusedColor: '#97FFF6',
      color2: '#DCFFFD',
      text: '#000',
    },
    extra_label: {
      color: '#FD8732',
      focusedColor: '#FDAF6F',
      color2: '#ffdef0',
      text: '#000',
    },
  };

  const [canvasHeight, setCanvasHeight] = useState(500);
  const [canvasWidth, setCanvasWidth] = useState(1000);
  const [originalWidth, setOriginalWidth] = useState(null);
  const [originalHeight, setOriginalHeight] = useState(null);
  const [zoom, setZoom] = useState(true);
  const [excelColumns, setExcelColumns] = useState(new Set());
  const [landmarksVisible, setLandmarksVisible] = useState(true);
  const [alertPills, setAlertPills] = useState({
    stock: 0,
    stockout: 0,
    spread_to_fill: 0,
    price_difference: 0,
    promotion: 0,
    hua: 0,
    misplaced_product: 0,
    extra_label: 0,
  });
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(
    new Set([
      'stock',
      'stockout',
      'spread_to_fill',
      'price_difference',
      'promotion',
      'hua',
      'misplaced_product',
      'extra_label',
    ]),
  );
  const [selectedCategories, setSelectedCategories] = useState(new Set());
  const isHoverOn = useRef(null);
  const selectedProductIndex = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [parsedLandmarks, setParsedLandmarks] = useState([]);


  // Used for not showing the tooltip when the user is hovering on it, but the productType is not selected
  const [selectedProductType, setSelectedProductType] = useState(null);
  const [isWallExtend, setIsWallExtend] = useState(true);
  const wallBoxRef = useRef(null);
  const wallViewRef = useRef(null);
  const wallScroll = useRef(null);

  // Context
  const {
    dateState,
    selectedStore,
    selectedProduct,
    setWallSignedUrl,
    setSelectedProduct,
    setMisplacedSignedImages,
    setZoomedWallImageStyle,
    setMissingLabelInfo,
    selectedAisle,
    setProductPosPercent,
    selectedLandmark,
    productSearched,
  } = useContext(DigitalShelfContext);

  useEffect(() => {
    setIsWallExtend(true);
  }, [productSearched]);

  useEffect(() => {
    if (wallLandmarks && wallLandmarks.length) {
      const parsedLandmarks = {}
      wallLandmarks.forEach((landmark, idx) => {
        if (landmark.hasOwnProperty('pixel_x')) {
          const x = (landmark.pixel_x > 0 ? landmark.pixel_x : 4);
          let nextX = 0;
          if (idx < wallLandmarks.length - 1) {
            nextX = wallLandmarks[idx + 1].pixel_x;
          } else {
            nextX = null;
          }
          const endX = x < nextX ? nextX : landmark.next_landmark_position ? landmark.next_landmark_position : 0;
          parsedLandmarks[landmark.landmark] = {
            ...landmark,
            px_init: x,
            px_end: endX,
            landmarkPercentageInit: x / originalWidth,
            landmarkPercentageEnd: endX / originalWidth,
            originalWallWidth: originalWidth,
          };
        }
      });
      setParsedLandmarks(parsedLandmarks);
    };
  }, [wallLandmarks]);

  useEffect(() => {
    if (incomingMisplacedSignedImages) {
      setMisplacedSignedImages(incomingMisplacedSignedImages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomingMisplacedSignedImages]);

  useEffect(() => {
    if (selectedProduct && selectedProduct?.misplaced_product) {
      getMisplacedProductSignedImages(selectedStore.store_code, selectedProduct.blob)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct])

  // recover missing label data
  useEffect(() => {
    setMissingLabelInfo(landmarkInfo?.missing_label_data);
  }, [landmarkInfo]);


  // load img data
  useEffect(() => {
    if (!isLoadingWallImage && Object.keys(wallImageData).length > 0) {
      wallImage.src = wallImageData.signed_url;
      wallImage.onload = () => {
        setWallSignedUrl(wallImageData.signed_url);
        let canvas_element = document.getElementById('wallScroll');
        if (canvas_element) {
          let canvas_height = canvas_element.offsetHeight;
          let newImgWidth =
            canvas_height * (wallImage.width / wallImage.height);

          setOriginalWidth(wallImage.naturalWidth);
          setOriginalHeight(wallImage.naturalHeight);

          setCanvasWidth(newImgWidth);
          setCanvasHeight(canvas_height);
        }
      };
    }
  }, [wallImageData]);

  // alert pills
  useEffect(() => {
    if (
      landmarkInfo &&
      Object.keys(landmarkInfo) &&
      landmarkInfo.coords &&
      landmarkInfo.coords.length
    ) {
      let excelCols = new Set();
      let pills = {
        stock: 0,
        zero_stock: 0,
        stockout: 0,
        spread_to_fill: 0,
        price_difference: 0,
        hua: 0,
        promotion: 0,
        misplaced_product: 0,
        extra_label: 0,
      };
      landmarkInfo.coords.forEach(coord => {
        if (coord.misplaced_product) {
          pills.misplaced_product++;
          excelCols.add('misplaced_product');
        }
        if (coord.stockout) {
          pills.stockout++;
          excelCols.add('stockout');
        }
        if (coord.spread_to_fill) {
          pills.spread_to_fill++;
          excelCols.add('spread_to_fill');
        }
        if (coord.price_difference) {
          pills.price_difference++;
          excelCols.add('price_difference');
        }

        if (coord.extra_label) {
          pills.extra_label++;
          excelCols.add('extra_label');
        } else pills.stock++;
      });
      wallLandmarks.forEach(l => {
        pills.hua++;
      });
      aislePromotions.forEach(l => {
        pills.promotion++;
      });
      setAlertPills(pills);
      setExcelColumns(excelCols);
    }
  }, [landmarkInfo]);

  // category filters
  useEffect(() => {
    if (
      landmarkInfo &&
      Object.keys(landmarkInfo) &&
      landmarkInfo.coords &&
      landmarkInfo.coords.length
    ) {
      let pills = [];
      landmarkInfo.coords.forEach(coord => {
        if (coord.name_category && !pills.includes(coord.name_category)) {
          pills.push(coord.name_category);
        }
      });
      pills.sort((a, b) => {
        if (a > b) return 1;
        return -1;
      });
      setCategoryFilters(pills);
    }
  }, [landmarkInfo]);

  // scroll to landmark
  useEffect(() => {
    if (
      canvasWidth &&
      originalWidth &&
      selectedLandmark &&
      selectedLandmark.pixel_x
    ) {
      let w = document.getElementById('wallScroll');
      let scale_x = canvasWidth / originalWidth;
      w.scrollTo(selectedLandmark.pixel_x * scale_x - 30, 0);
    }
  }, [selectedLandmark, canvasWidth, originalWidth]);

  // scroll to product
  useEffect(() => {
    if (
      canvasWidth &&
      originalWidth &&
      selectedProductState &&
      Object.keys(selectedProductState).length > 0 &&
      landmarkInfo?.coords?.length > 0
    ) {
      for (let index = 0; index < landmarkInfo.coords.length; index++) {
        const coord = landmarkInfo.coords[index];
        if (coord?.item === selectedProductState?.item) {
          const w = document.getElementById('wallScroll');
          const scale_x = canvasWidth / originalWidth;
          const posX = coord?.pixel_z ? coord.pixel_x : coord.facing_bottom_right_x;
          const scrollValue = posX * scale_x;
          w.scrollTo(scrollValue - 30, 0);
          handleProductPosPercent(posX, originalWidth);
          break;
        }
      }
    }
  }, [selectedProductState, landmarkInfo, canvasWidth, originalWidth]);

  useEffect(() => {
    clearCoordsState();
    clearWallImageData();
  }, [selectedStore]);

  // Get the type of alert of the selected product
  useEffect(() => {
    if (selectedProduct && Object.keys(selectedProduct).length > 0) {
      let productType = 'stock';
      if (selectedProduct.stockout) productType = 'stockout';
      else if (selectedProduct.spread_to_fill) productType = 'spread_to_fill';
      else if (selectedProduct.price_difference)
        productType = 'price_difference';
      else if (selectedProduct.extra_label) productType = 'extra_label';
      else if (selectedProduct.misplaced_product)
        productType = 'misplaced_product';
      else if (
        !selectedProduct.landmark_type &&
        selectedProduct.hasOwnProperty('pixel_z')
      )
        productType = 'promotion';
      else if (selectedProduct.landmark_type) productType = 'hua';
      setSelectedProductType(productType);
    }
  }, [selectedProduct]);

  // general pill selection
  const handleSelectionChanged = (value, selectedValues, setSelected) => {
    const newSet = new Set(selectedValues);
    if (newSet.has(value)) newSet.delete(value);
    else newSet.add(value);
    setSelected(newSet);
  };

  // Get the formatting (currency, numbers, data) based on the client_id
  useEffect(() => {
    if (!isEmptyOrUndefined(selectedClient, 'object')) {
      getNumberFormat(selectedClient?.client_id)
    }
  }, [selectedClient])

  // img product bugs
  const handleShowInfo = () => {
    setZoom(!zoom);
    setLandmarksVisible(!landmarksVisible);
  };

  // img zoom
  const handleZoom = () => {
    setLandmarksVisible(!landmarksVisible);

    setZoom(!zoom);
  };

  const zoomImageSquare = ({
    originalWidth,
    originalHeight,
    mouseX,
    mouseY,
  }) => {
    const viewerCanvasHeight =
      document.getElementById('wallScroll').offsetHeight; // s.windowHeight //insideWrapper.offsetHeight; // s.windowHeight - 413;
    const canvasWidth = document.getElementById('canvas').offsetWidth;
    const CENTER_Y = 10;

    const top = (-(mouseY - CENTER_Y) * originalHeight) / viewerCanvasHeight;
    const left = -((mouseX * originalWidth) / canvasWidth) + 314 / 2; // 314 is the width of the Box wich contains the zoomed image
    const newStyle = {
      zIndex: 1,
      width: `${originalWidth}px`,
      height: `${originalHeight}px`,
      display: 'block',
      position: 'relative',
      top: `${top}px`,
      left: `${left}px`,
    };
    setZoomedWallImageStyle(newStyle);
  };

  const getMousePos = event => {

    const x = event.clientX,
      y = event.clientY;
    let notFound = true;

    let coordsData = [
      ...(landmarkInfo.coords ?? []),
      ...(aislePromotions ?? []),
      ...(aisleLandmarks ?? []),
    ];
    if (coordsData && coordsData.length) {
      for (let idx = 0; idx < coordsData.length; idx++) {
        const coord = coordsData[idx];

        const scale_x = canvasWidth / originalWidth;
        const scale_y = canvasHeight / originalHeight;
        let px = 0,
          py = 0;
        let mouseIn = false,
          mouseInBBox = false;
        if (coord.hasOwnProperty('pixel_z')) {
          // icon coordinates, if it is a landmark black box py = 0 for the respective conditions
          px = coord.pixel_x * scale_x;
          py = coord.pixel_z * scale_y;

          // area where there is a landmark icon
          let conditionInLandmark =
            px - RADIUSPLUS <= x &&
            x <= px + RADIUSPLUS &&
            py - RADIUSPLUS <= y &&
            y <= py + RADIUSPLUS;
          // area where the landmark black box is located
          let conditionInBlackBox =
            px <= x && x <= px + RADIUSPLUS * 4.73 && y <= RADIUSPLUS * 4.73;
          // xor to check if the mouse is in the landmark icon or in the black box but not in both
          mouseIn = conditionInLandmark ^ conditionInBlackBox;
          mouseInBBox =
            px - RADIUSPLUS * 2 < x &&
            x <= px + RADIUSPLUS * 2 &&
            py - RADIUSPLUS * 2 < y &&
            y <= py + RADIUSPLUS * 2;
        } else {
          px =
            ((coord.facing_bottom_right_x + coord.facing_top_left_x) / 2) *
            scale_x -
            RADIUSPLUS / 2;
          py =
            ((coord.facing_bottom_right_y + coord.facing_top_left_y) / 2) *
            scale_y -
            RADIUSPLUS / 2;

          mouseIn =
            px <= x && x <= px + RADIUSPLUS && py <= y && y <= py + RADIUSPLUS;
          mouseInBBox =
            coord.facing_top_left_x * scale_x < x &&
            x <= coord.facing_bottom_right_x * scale_x &&
            coord.facing_top_left_y * scale_y < y &&
            y <= coord.facing_bottom_right_y * scale_y;
        }
        coord['isHoverOn'] = mouseIn || (mouseInBBox && coord.isHoverOn);
        if (
          mouseInBBox &&
          isHoverOn.current &&
          idx === selectedProductIndex.current
        ) {
          notFound = false;
          isHoverOn.current = true;
          selectedProductIndex.current = idx;
          setSelectedProduct(coord);
          // move tooltip to near px, py
          if (selectedStatus.has(selectedProductType)) {
          }
          zoomImageSquare({
            originalWidth,
            originalHeight,
            mouseX: x,
            mouseY: y,
          });

          break;
        } else if (
          mouseIn &&
          (!isHoverOn.current || idx !== selectedProductIndex.current)
        ) {
          notFound = false;
          isHoverOn.current = true;
          selectedProductIndex.current = idx;
          setSelectedProduct(coord);
          // move tooltip to near px, py
          if (selectedStatus.has(selectedProductType)) {
          }
          break;
        } else if (mouseIn && isHoverOn.current) {
          notFound = false;
          break;
        }
      }
    }
    if (notFound) {
      isHoverOn.current = false;
    }
  };

  const handleCanvasHover = event => {
    getMousePos(event);
  };

  const handleCanvasLeave = event => {
    isHoverOn.current = false;
  }

  const handleProductPosPercent = (posX, canvasWidth) => {
    const posPercent = posX / canvasWidth;
    setProductPosPercent(posPercent);
    if (isWallExtend && isHoverOn.current) setIsWallExtend(false);
  }

  const handleCanvasClick = (event, drw) => {
    const { posx: posX } = selectedProduct;
    const { canvasWidth } = event;
    handleProductPosPercent(posX, canvasWidth);
  };

  const handleExcelDownload = all_data => {
    if (all_data) {
      getCoordsForExcel(
        selectedStore.store_id,
        selectedClient.supplier_id,
        selectedAisle['session'],
        format(dateState, null, 'yyyy-MM-dd'),
      );
    } else {
      const columns = [
        'item',
        'ean',
        'price',
        'aisle',
        'landmark',
        'texts',
        'name_category',
        ...excelColumns,
      ];
      const columnsTranslated = [t('cws_app.Walls.date', 'Date'), ...columns].map(
        col => getChainMeaning(t, selectedStore?.chain_name, col),
      );
      const formattedDate = format(dateState, i18n.language, clientFormats.date_format);
      const data = landmarkInfo?.coords?.map(position => {
        return [
          formattedDate,
          ...columns.map(col => position[col]),
        ];
      });

      const filename = `Shelf ${selectedStore?.store_code}-(${selectedAisle?.code})-${formattedDate.replaceAll('/', '-')}`;
      getDigitalShelfExcel({
        excelData: [columnsTranslated, ...data],
        excelFileName: filename,
      });
    }
    setModalOpen(false);
  };

  useEffect(() => {
    if (coordsForExcel) {
      const columns = [
        'item',
        'ean',
        'price',
        'aisle',
        'landmark',
        'texts',
        'name_category',
        ...excelColumns,
      ];
      let columnsTranslated = [t('cws_app.Walls.date', 'Date'), ...columns].map(
        col => getChainMeaning(t, selectedStore?.chain_name, col),
      );
      const formattedDate = format(dateState, i18n.language, clientFormats.date_format);
      const data = coordsForExcel?.coords?.map(position => {
        return [
          formattedDate,
          ...columns.map(col => position[col]),
        ];
      });

      const filename = `Shelf ${selectedStore?.store_code}-${formattedDate.replaceAll('/', '-')}`;
      getDigitalShelfExcel({
        excelData: [columnsTranslated, ...data],
        excelFileName: filename,
      });
    }
  }, [coordsForExcel]);

  const checkLandmarksAvailable = () => {
    return landmarkInfo.coords && landmarkInfo.coords.length > 0;
  };

  const checkColor = product => {
    let color = colors['stock'].color;
    if (product.stockout) color = colors['stockout'].color;
    if (product.spread_to_fill) color = colors['spread_to_fill'].color;
    else if (product.price_difference) color = colors['price_difference'].color;
    else if (product.extra_label) color = colors['extra_label'].color;
    else if (product.misplaced_product)
      color = colors['misplaced_product'].color;
    else if (!product.landmark_type && product.hasOwnProperty('pixel_z'))
      color = colors['promotion'].color;
    return color;
  };

  const checkIconName = product => {
    let iconName = 'stock';
    if (product.hasOwnProperty('stockout')) {
      // promotions can be isolated with this condition
      if (product.stockout) iconName = 'stock-out';
      else if (product.price_difference) iconName = 'price-difference';
      else if (product.extra_label) iconName = 'price-difference';
      else if (product.misplaced_product) iconName = 'misplaced-product';
      else if (product.landmark_type) iconName = 'location';
      else if (product.spread_to_fill) iconName = 'stock-out';
    } else {
      iconName = 'promotion';
    }
    return iconName;
  };

  // on window resize
  const handleResize = (isWallExtend) => {
    if (wallBoxRef.current) {
      const SIDE_METADATA_SIZE = 60;
      const fullWidth = wallBoxRef.current?.offsetWidth;
      const REDUCE_FACTOR = 0.93;
      const newWidth = !isWallExtend ? (fullWidth ? fullWidth * REDUCE_FACTOR : '20') : (fullWidth - SIDE_METADATA_SIZE);

      if (fullWidth - newWidth < SIDE_METADATA_SIZE) {
        wallBoxRef.current.style.flexDirection = 'column';
        if (wallScroll.current?.style?.maxWidth !== undefined) wallScroll.current.style.maxWidth = `${fullWidth}px`;
        wallViewRef.current.style.width = `${fullWidth}px`;
      } else {
        wallBoxRef.current.style.flexDirection = 'row';
        if (wallScroll.current?.style?.maxWidth !== undefined) wallScroll.current.style.maxWidth = isWallExtend ? '100%' : `${fullWidth * 0.7}px`;
        wallViewRef.current.style.width = `${newWidth}px`;
      }
    }
  };
  useEffect(() => {
    handleResize(true);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [wallBoxRef]);

  const handleMetadataOpenChange = isOpen => {
    setIsWallExtend(isOpen);
    handleResize(isOpen);
  }

  return (
    <>
      <Modal
        open={modalOpen}
        setOpen={setModalOpen}
        title={t('cws_app.Walls.downloadModalTitle')}
        description={t('cws_app.Walls.downloadModalDescription')}>
        <Button
          variant="contained"
          sx={{ backgroundColor: 'gray' }}
          onClick={() => handleExcelDownload(false)}>
          {t('cws_app.Walls.shelfData')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleExcelDownload(true)}>
          {t('cws_app.Walls.allData')}
        </Button>
      </Modal>
      <Box ref={wallBoxRef} sx={{ mt: 1, display: 'flex', gap: '0.5em', flexDirection: 'row', width: '100%' }}>
        <Collapse
          in={isWallExtend}
          collapsedSize={wallBoxRef.current?.offsetWidth * 0.7 ?? '50em'}
          orientation="horizontal"
          sx={{ flex: 'none' }}
        >
          <Grid container ref={wallViewRef} sx={{ height: '100%' }}>
            <Grid xs={12} ref={wallScroll} id="wallScroll" item container sx={{ maxWidth: isWallExtend ? '100%' : `${wallBoxRef.current?.offsetWidth * 0.7}px`, maxHeight: '32em' }}>
              <Grid
                item
                direction={'row'}
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 2, sm: 8, md: 12 }}
                sx={{
                  height: '100%',
                  width: '100%',
                  borderRadius: '5px',
                  backgroundColor: '#1a242d',
                  WebkitOverflowScrolling: 'touch !important',
                  minHeight: '400px',
                  overflowX: 'auto',
                  overflowY: 'hidden',
                  position: 'relative',
                  display: 'flex',
                }}>
                {!isLoadingWallImage &&
                  Object.keys(wallImageData).length > 0 && //image loaded
                  !isLoadingCoordsInfo ? (
                  <>
                    {' '}
                    {zoom ? (
                      <>
                        {/* bug drawer wall canvas */}
                        <WallDrawer
                          aisle={selectedAisle}
                          landmark={selectedLandmark}
                          png={wallImageData.signed_url}
                          imageMetadata={wallImage}
                          coords={landmarkInfo.coords}
                          wallLandmarks={wallLandmarks}
                          canvasHeight={canvasHeight}
                          canvasWidth={canvasWidth}
                          originalWidth={originalWidth}
                          originalHeight={originalHeight}
                          landmarksVisible={landmarksVisible}
                          selectedStatus={selectedStatus}
                          selectedCategories={selectedCategories}
                          colors={colors}
                          product={selectedProductState}
                          handleCanvasHover={handleCanvasHover}
                          handleCanvasLeave={handleCanvasLeave}
                          handleCanvasClick={handleCanvasClick}
                          aislePromotions={aislePromotions}
                          aisleLandmarks={aisleLandmarks}
                          selectedAisle={selectedAisle}
                          selectedStore={selectedStore}
                          imgsDict={imgsDict}
                        />
                      </>
                    ) : (
                      <TransformWrapper>
                        <TransformComponent>
                          <img
                            alt=""
                            height={canvasHeight}
                            width={canvasWidth}
                            src={wallImageData.signed_url}
                          />
                        </TransformComponent>
                      </TransformWrapper>
                    )}{' '}
                  </>
                ) : null}
              </Grid>
            </Grid>
            {!isLoadingWallImage &&
              Object.keys(wallImageData).length > 0 &&
              !isLoadingCoordsInfo &&
              Object.keys(landmarkInfo).length > 0 ? (
              <Grid
                item
                xs={12}
                container
                sx={{ mt: 1 }}
                justifyContent="flex-start">
                <Grid
                  item
                  container
                  xs={12}
                  sm={'auto'}
                  alignContent="center"
                  justifyContent="center"
                  sx={{ mt: 1, mr: 1, p: 1, borderRadius: 2 }}
                  style={{ backgroundColor: '#1a242d' }}>
                  <Grid item xs>
                    <Tooltip title={t('cws_app.Walls.no_meta_info')}>
                      <IconButton
                        color="secondary"
                        size="large"
                        onClick={handleShowInfo}>
                        {landmarksVisible ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs>
                    <Tooltip title={t('cws_app.Walls.Mglass')}>
                      <IconButton
                        color="secondary"
                        size="large"
                        onClick={handleZoom}>
                        {zoom ? <SearchIcon /> : <SearchOffIcon />}
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  {checkLandmarksAvailable() ? (
                    <>
                      {' '}
                      {!loadingCoordsForExcel ? (
                        <Grid item xs>
                          <Tooltip title={t('cws_app.Walls.download_excel')}>
                            <IconButton
                              color="secondary"
                              size="large"
                              onClick={() => setModalOpen(true)}>
                              <InsertDriveFileIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      ) : (
                        <Grid
                          item
                          container
                          xs
                          alignContent="center"
                          justifyContent="center"
                          m={1}>
                          <CircularProgress color="secondary" size={24} sx={{}} />
                        </Grid>
                      )}
                    </>
                  ) : null}
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sm={6}
                  alignContent="center"
                  wrap="nowrap"
                  sx={{ mt: 1, p: 1, borderRadius: 2 }}
                  style={{
                    backgroundColor: '#1a242d',
                    width: '100%',
                    overflowX: 'auto',
                  }}>
                  {landmarkInfo.coords.length
                    ? Object.keys(alertPills).map(pillName => {
                      // Bugs filter (the ones at the bottom of the page)
                      if (alertPills[pillName] > 0) {
                        return (
                          <Grid item key={pillName}>
                            <Tooltip title={'Total: ' + alertPills[pillName]}>
                              <Chip
                                clickable
                                label={
                                  pillName === 'hua'
                                    ? t(
                                      'Chains.' +
                                      selectedStore?.chain_name +
                                      '.landmark',
                                    )
                                    : pillName === 'promotion'
                                      ? t('cws_app.digital_store.promotions')
                                      : t('cws_app.Walls.' + pillName)
                                }
                                variant="contained"
                                size="large"
                                sx={{
                                  m: 1,
                                  px: 1,
                                  color: colors[pillName].text,
                                  fontSize: '14px',
                                  fontWeight: '400',
                                }}
                                style={{
                                  backgroundColor: selectedStatus.has(pillName)
                                    ? colors[pillName].color
                                    : colors[pillName].color2,
                                }}
                                onClick={() => {
                                  handleSelectionChanged(
                                    pillName,
                                    selectedStatus,
                                    setSelectedStatus,
                                  );
                                }}
                              />
                            </Tooltip>
                          </Grid>
                        );
                      }
                      return null;
                    })
                    : null}
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sm={true}
                  wrap="nowrap"
                  spacing={2}
                  sx={{ mt: 1, ml: 1, pt: -1, pb: 1, borderRadius: 2 }}
                  style={{
                    backgroundColor: '#1a242d',
                    width: '100%',
                    overflowX: 'auto',
                  }}>
                  {categoryFilters && categoryFilters.length
                    ? categoryFilters.map(cat => {
                      return (
                        <Grid item key={cat}>
                          <Chip
                            clickable
                            label={cat}
                            variant="contained"
                            size="large"
                            sx={{
                              color: 'black',
                              fontSize: '14px',
                              fontWeight: '400',
                            }}
                            style={{
                              backgroundColor: !selectedCategories.has(cat)
                                ? '#CACADD'
                                : '#697075',
                            }}
                            onClick={() => {
                              handleSelectionChanged(
                                cat,
                                selectedCategories,
                                setSelectedCategories,
                              );
                            }}
                          />
                        </Grid>
                      );
                    })
                    : null}
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Collapse>
        <Box sx={{
          width: isWallExtend ? '60px' : `${Math.round(wallBoxRef.current?.offsetWidth * 0.3)}px`,
          webKitTransition: 'width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          transition: 'width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
        }}>
          <WallMetadata
            parsedLandmarks={parsedLandmarks}
            selectedAisle={selectedAisle}
            isLoadingMaps={isLoadingMaps}
            storeMaps={storeMaps}
            chainName={selectedStore?.chain_name}
            onOpenChange={handleMetadataOpenChange}
            isOpen={!isWallExtend}
            currencySymbol={currencySymbol}
            clientFormats={clientFormats}
          />
        </Box>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={isLoadingWallImage || isLoadingCoordsInfo}>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          direction="column">
          <Typography>
            {t('cws_app.Walls.loading_wall', 'Loading Wall Image')}
          </Typography>
          <CircularProgress color="secondary" />
        </Grid>
      </Backdrop>
    </>
  );
}
