import { useState, useEffect } from 'react'
import { CircularProgress, Grid } from '@mui/material';

import DataTable from './DataTable';

export default function Suppliers(props) {
  const {
    selectedClient,
    updateActiveSubSupplier,
    getUserSuppliers,
    suppliers,
  } = props;

  const [chainList, setChainList] = useState([]);

  useEffect(() => {
    if (suppliers && suppliers?.length > 0) {
      let newChainList = [];
      let chainSet = new Set();
      suppliers.forEach((item) => {
        let parsedChainList = item.chain_list_str.split(',');
        parsedChainList.forEach((chain) => {
          chainSet.add(chain.trim());
        });
      });
      chainSet.forEach((item, index) => {
        newChainList.push({ id: index, name: item });
      });
      setChainList(newChainList);
    }
  }, [suppliers]);

  useEffect(() => {
    getUserSuppliers(selectedClient.client_id, [], true);
  }, []);

  const changeRow = async (row) => {
    if (row && Object.keys(row)?.length > 0) {
      await updateActiveSubSupplier(row.id, row.active_sub);
      getUserSuppliers(selectedClient.client_id, [], true);
    }
  }

  return (
    <Grid container justify="center">
      {suppliers && (suppliers?.length > 0) ?
        <DataTable rows={suppliers} chains={chainList} changeRow={changeRow} />
        :
        <Grid item xs display="flex" justifyContent="center" sx={{ p: 4 }}>
          <CircularProgress color="secondary" />
        </Grid>
      }
    </Grid>
  );
}