import { connect } from 'react-redux';
import DocsImage from '../../../../components/features/documentation/DocsImage';

import { getDocumentationBlobs } from 'core/selectors';

export default connect(
  state => ({
    getDocumentationBlobs: getDocumentationBlobs(state),
  }),
  {},
)(DocsImage);
