import { useEffect, useState } from 'react';

import {
	Button,
	Box,
	ButtonGroup,
	CircularProgress,
	Grid,
	Modal,
	Paper,
	Typography,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { styled, useTheme } from '@mui/material/styles';
import { isEmptyOrUndefined } from "core/utils/validators";

// My Components
import UserForm from './UserForm';
import DataTable from './DataTable';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

export default function Mailer(props) {
	const { t } = useTranslation();

	const {
		selectedClient,
		getMailerContacts,
		mailerContactList,
		isloadingMailerContacts,
		clientStores,
		loadingClientStores,
		getClientStores,
		getMailerTasks,
		mailerTaskList,
		isloadingMailerTasks,
		getMailerContactInfo,
		mailerContactInfo,
		isloadingContactInfo,
		updateMailerContact,
		createMailerContact,
		deleteMailerContact,
	} = props;

	// modal
	const [open, setOpen] = useState(false);

	// selectors
	const [storesList, setStoresList] = useState([]);
	const [taskList, setTaskList] = useState([]);

	// contact info form
	const [mode, setMode] = useState('create');
	const [selectedContact, setSelectedContact] = useState(null);
	const [name, setName] = useState('');
	const [mail, setMail] = useState('');
	const [stores, setStores] = useState([]);
	const [tasks, setTasks] = useState([]);

	const theme = useTheme();
	const textColor = theme.palette.type === 'light' ? 'black' : 'white';

	useEffect(() => {
		if (selectedContact && Object.keys(selectedContact).length > 0) {
			setName(selectedContact.name);
			setMail(selectedContact.mail);
			if (mode === 'edit') {
				getMailerContactInfo(selectedContact.contact_id);
			}
		}
	}, [selectedContact]);

	// load selected tasks & stores for contact
	useEffect(() => {
		if (mailerContactInfo && !isEmptyOrUndefined(mailerContactInfo, 'dict') && !isEmptyOrUndefined(mailerContactInfo.task_access, 'array')) {
			let task_types = [...mailerContactInfo.task_access].map((task) => task.r_type);
			let user_tasks = [...mailerTaskList]
				.filter((task) => task_types.includes(task.r_type))
				.map((task) => ({ ...task, selected: true }));
			setTasks(user_tasks);

			let new_tasks = [...taskList].map((task) => {
				let new_task = { ...task };
				new_task.selected = task_types.includes(task.r_type);
				return new_task;
			});
			setTaskList(new_tasks);


			let user_stores = [...storesList]
				.filter((store) => mailerContactInfo.store_access.includes(store.store_code))
				.map((store) => ({ ...store, selected: true }));
			setStores(user_stores);

			let new_stores = [...storesList].map((store) => {
				let new_store = { ...store };
				new_store.selected = mailerContactInfo.store_access.includes(store.store_code);
				return new_store;
			});
			setStoresList(new_stores);
		}
	}, [mailerContactInfo]);

	useEffect(() => {
		if (selectedClient) {
			getClientStores(selectedClient.client_id);
			getMailerContacts();
			getMailerTasks();
		}
	}, [selectedClient]);

	const handleSetStores = () => {
		let newStores = [...clientStores].map((store) => {
			return { ...store, parsed_name: `${store.store_code} - ${store.chain_name} ${store.store_name}` }
		});
		setStoresList(newStores);
	}

	useEffect(() => {
		if (!loadingClientStores && clientStores?.length > 0) {
			handleSetStores();
		}
	}, [clientStores]);

	useEffect(() => {
		if (!isloadingMailerTasks && mailerTaskList?.length > 0) {
			setTaskList(mailerTaskList);
		}
	}, [mailerTaskList]);

	const handleCreateContact = async (name, mail, tasks, stores) => {
		await createMailerContact(name, mail, tasks, stores);
		getMailerContacts();
	}

	const handleUpdateContact = async (contact_id, name, tasks, stores) => {
		await updateMailerContact(contact_id, name, tasks, stores);
		getMailerContacts();
	}

	const handleDeleteContact = async (contact_id) => {
		await deleteMailerContact(contact_id);
		getMailerContacts();
	}

	const handleUpdateContactInfo = async (mode) => {
		if (mode === 'delete') {
			if (selectedContact && selectedContact?.contact_id) {
				await handleDeleteContact(selectedContact?.contact_id);
			}
		} else if (mode === 'create') {
			await handleCreateContact(name, mail, tasks, stores);
		} else if (mode === 'edit') {
			await handleUpdateContact(selectedContact?.contact_id, name, tasks, stores);
		}
		setOpen(false);
	}

	// form data when creating contact in 'create' mode
	useEffect(() => {
		if (mode === 'create') {
			setName('');
			setMail('');
			setStores([]);
			handleSetStores();
			setTasks([]);
			setTaskList(mailerTaskList);
		}
	}, [mode]);

	const handleButtonClick = () => {
		setMode('create');
		setOpen(true);
	}

	const handleClose = () => {
		if (open) {
			setOpen(false);
			setStores([]);
			setTasks([]);
		}
	}

	return (
		<>
			<Grid container pt={4} px={5}>
				<Grid item xs={12}>
					<Typography sx={{ fontSize: '34px', color: textColor }}>
						{t('cws_app.general.mailer_admin')}
					</Typography>
				</Grid>
				<Grid item container xs={12} sx={{ pt: 2, }} justifyContent={"center"} alignItems={'center'} >
					<Grid item xs={12}>
						<StyledPaper elevation={1} sx={{ p: 1 }}>
							<Grid container justifyContent={"center"} alignItems={"center"} sx={{ width: '100%' }}>
								<Grid item container xs={9} direction={'column'} sx={{ py: 2, px: 3 }}>
									<Typography sx={{ fontSize: '20px', }}>
										{t('cws_app.general.contact')}
									</Typography>
									<Typography sx={{ fontSize: '14px', }}>
										{t('cws_app.mailer.see_which')}
									</Typography>
								</Grid>
								<Grid item container xs sx={{ p: 1 }} justifyContent={'flex-end'} >
									<Button variant={'contained'} color={'primary'} onClick={handleButtonClick}
										startIcon={
											<IconComponent
												iconName={'add'}
												style={{ fontSize: '20px' }}
											/>
										}
									> {t('cws_app.management.add_user')} </Button>
								</Grid>
							</Grid>
						</StyledPaper>
					</Grid>
					<Grid item xs sx={{ mt: -1, }}>
						{!isloadingMailerContacts && mailerContactList?.length > 0 ?
							<DataTable
								t={t}
								theme={theme}
								setMode={setMode}
								setOpen={setOpen}
								mailerContactList={mailerContactList}
								setSelectedContact={setSelectedContact}
							/>
							:
							<Grid container direction={"column"} alignItems={"center"} justifyContent={"center"}>
								<Grid item xs={12} sx={{ p: 2 }}>
									<Typography sx={{ py: 3 }}> {t('cws_app.mailer.loading_contacts', "Loading Mailer Contacts")} </Typography>
								</Grid>
								<Grid item xs={12} sx={{ px: 2, pb: 3 }}>
									<CircularProgress color='secondary' />
								</Grid>
							</Grid>
						}
					</Grid>
				</Grid>
			</Grid>
			<Modal
				open={open}
				onClose={handleClose}
			>
				<Box sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-40%, -50%)',
					width: 700,
				}}
				>
					<DarkHeaderPaper elevation={1} sx={{ p: 2 }}>
						<ButtonGroup color={'secondary'} variant="text" aria-label="text button group">
							<Button
								onClick={() => setMode('create')}
								variant={mode === 'create' ? 'contained' : 'text'}
							>
								{t('cws_app.general.create')}
							</Button>
							<Button
								variant={mode === 'edit' ? 'contained' : 'text'}
							>
								{t('cws_app.general.edit')}
							</Button>
							<Button
								variant={mode === 'delete' ? 'contained' : 'text'}
							>
								{t('cws_app.general.delete')}
							</Button>
						</ButtonGroup>
					</DarkHeaderPaper>
					<UserForm
						t={t}
						theme={theme}
						clientStoresList={storesList}
						loadingClientStores={loadingClientStores}
						mailerTaskList={taskList}
						isloadingMailerTasks={isloadingMailerTasks}
						mode={mode}
						isloadingContactInfo={isloadingContactInfo}
						name={name}
						setName={setName}
						mail={mail}
						setMail={setMail}
						stores={stores}
						setStores={setStores}
						tasks={tasks}
						setTasks={setTasks}
						handleUpdateContactInfo={handleUpdateContactInfo}
					/>
				</Box>
			</Modal>
		</>
	)
}

const StyledPaper = styled(props => {
	return <Paper elevation={1} {...props} />
}
)(({ theme }) => {
	return ({
		width: '100%',
		background: theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
		color: theme.palette.type === 'light' ? 'black' : 'white',
		'& .MuiPaper-root': {
			backgroundColor: theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.paper.main,
			borderRadius: 3,
			color: 'white',
		},
		'& .MuiInputLabel-root': {
			color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
		},
		'& .MuiOutlinedInput-root': {
			color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
			'& fieldset': {
				borderColor: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
			},
		},
	})
}
);

const DarkHeaderPaper = styled(
	props => {
		return <Paper elevation={1} {...props} />
	}
)(({ theme }) => {
	return ({
		width: '100%',
		background: theme.palette.type === 'light' ? theme.palette.primary.main : '#D6C3C9',
	})
}
);