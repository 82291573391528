import {
    Button, Divider, FormControl, Grid, IconButton, InputLabel,
    MenuItem, Select, LinearProgress
} from "@mui/material";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteMultiSelector from "./InfiniteMultiSelector";

// TODO: change by zippedi icon
import DeleteIcon from '@mui/icons-material/Delete';


export default function Scopes(props) {
    const {
        isLoading,
        inputForms,
        handleScopeChange,
        inputBasicFormsCant = inputForms.length
    } = props;
    const { t } = useTranslation();
    const [forms, setInputForms] = useState([]);
    const formsCant = useState(inputBasicFormsCant)[0];

    useEffect(() => {
        if (inputForms && formsCant !== 0 && inputForms.length) {
            const formsTemp = inputForms.slice();
            formsTemp.forEach(row => {
                row['selectedValue'] = row.selectedValue || {};
            })
            setInputForms(formsTemp);
        }
    }, [inputForms, formsCant])

    useEffect(() => {
        handleScopeChange(forms)
    }, [forms])

    const handleRenderValue = (selected, optionName) => {
        return selected[optionName]
    }

    const handleChange = (event, index) => {
        const formsTemp = forms.slice();
        formsTemp[index].selectedValue = event.target.value;
        setInputForms(formsTemp);
    };

    const handleMultiChange = (selectedOption, index) => {
        const formsTemp = forms.slice();
        formsTemp[index].optionSelectedIds = selectedOption;
    };

    const handleAddRow = () => {
        const formsTemp = [];
        for (let indexForm = 0; indexForm < formsCant; indexForm++) {
            formsTemp.push({
                selectedValue: {},
                ...forms[indexForm]
            })
        }
        setInputForms(forms.concat(formsTemp))
    }

    const handleDeleteRow = (index) => {
        if (forms.length > 2) {
            const toDelete = index - (index % formsCant);
            const formsTemp = forms.slice();
            formsTemp.splice(toDelete, formsCant);
            setInputForms(formsTemp);
        } else {
            alert(t('cws_app.management.scope_alert', 'No puedes dejar el formulario sin scopes'));
        }
    }

    return (
        <Grid container sx={{ p: 4 }} rowSpacing={1}>
            <Grid container item xs={12} sx={{ p: 4, border: '1px solid #cacadc', borderStyle: 'dashed', maxHeight: '20rem', minHeight: '13rem', overflowY: 'auto', }} rowSpacing={1}>
                {isLoading ?
                    <LinearProgress color="secondary" sx={{ borderRadius: '10rem', mt: 2, width: '100%' }} /> :
                    <>
                        {
                            forms.map((inputForm, index) => {
                                const optionName = inputForm.optionName ? inputForm.optionName : 'name';
                                let formComponent = (<></>);

                                if (inputForm.componentType === 'multiSelect') {
                                    formComponent = (
                                        <InfiniteMultiSelector
                                            inputLabel={inputForm.name}
                                            options={inputForm.options}
                                            objectId={inputForm.optionId}
                                            objectName={inputForm.optionName}
                                            setInputSelectedOptions={(selectedOption) => { handleMultiChange(selectedOption, index) }}
                                            inputSelected={inputForm.optionSelectedIds} />
                                    )
                                } else if (inputForm.componentType === 'simpleSelect') {
                                    formComponent = (
                                        <FormControl sx={{ minWidth: '100%', pr: 2 }}>
                                            <InputLabel id="chain-select-label">{inputForm.name}</InputLabel>
                                            <Select
                                                labelId="chain-select-label"
                                                id="chain-select"
                                                value={inputForm.selectedValue}
                                                defaultValue={inputForm.selectedValue}
                                                onChange={(e) => { handleChange(e, index) }}
                                                renderValue={selected => handleRenderValue(selected, optionName)}
                                                label={inputForm.name}
                                            >
                                                {!inputForm.isRequired && <MenuItem value=""><em>-</em></MenuItem>}
                                                {inputForm.options.map((option) => (<MenuItem value={option} sx={{ width: '100%' }} >{option[optionName]}</MenuItem>))}
                                            </Select>
                                        </FormControl>)
                                }

                                return (
                                    <>
                                        <Grid item xs={12 / formsCant - 1} key={`scopes-form-${index}`}>
                                            {formComponent}
                                        </Grid>
                                        {
                                            index % formsCant ?
                                                <Grid item xs={1}>
                                                    <IconButton aria-label="delete-scope" onClick={() => { handleDeleteRow(index) }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Grid> :
                                                null
                                        }
                                    </>
                                )
                            })
                        }
                    </>
                }
            </Grid>

            <Grid item xs={12}>
                <Divider flexItem style={{ background: '#cacadc', marginBottom: '1rem' }} />
            </Grid>
            <Grid item xs={12}>
                <Button variant="outlined" disableElevation color="primary" onClick={handleAddRow}>
                    {t('cws_app.management.add_row', 'add_row')}
                </Button>
            </Grid>
        </Grid>
    );
}
