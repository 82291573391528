import { EncrDecrService } from './crypto';

/**
  * @ngdoc constant
  * @name LOCALSTORAGE_PATHS
  * @description
  * Constant to save the paths where the objects will be saved. All the paths must be written in this constant to have a better control of the paths.
  * @property {string} home_store is the key, and value 'h_St'is the path to save in the local storage
*/
const LOCALSTORAGE_PATHS = {
    'home_store': 'h_St',
    'dsh_store': 'dsh_St',
    'dsh_store_floor': 'dsh_St_fl',
}

/**
 * @ngdoc service
 * @name localStorageService
 * @description
 * Service to save and get objects from the local storage
 */
export class LocalStorageService {
    constructor() {
        this.EncrDecr = new EncrDecrService();
        this.paths = LOCALSTORAGE_PATHS;
    }

    /**
     * get the token to decrypt/encrypt the object
     * @return  {string}            Token to decrypt/encrypt the object. null if the token is not found
    */
    getToken() {
        const token = localStorage.getItem('rekol');
        return token ? token : '';
    }

    /**
     * save the object into the local storage
     * @param  	{string} 				pathCode 			Parameter to get the path
     * @param  	{object} 				objectInput 		Object to be saved
     * @param  	{boolean} 				canEncrypt 			Flag to encrypt the object
     * @return  {void}
    */
    save(pathCode, objectInput, canEncrypt = true) {
        if (pathCode) {
            if (canEncrypt) {
                const encryptedInput = this.EncrDecr.set_object(this.getToken(), objectInput);
                localStorage.setItem(pathCode, encryptedInput);
            } else {
                localStorage.setItem(pathCode, objectInput);
            }
        }
    }

    /**
     * get the object from the local storage
     * @return  {object}            Object saved in the local storage. null if the object is not found
     * @param  	{boolean} 				canDecrypt 			Flag to decrypt the object
     * @param  	{string} 				pathCode 			Parameter to get the path
     * @return  {object}            Object saved in the local storage. null if the object is not found
    */
    get(pathCode, canEncrypt = true) {
        if (canEncrypt) {
            try {
                return this.EncrDecr.get_object(this.getToken(), localStorage.getItem(pathCode));
            } catch (error) {
                return null;
            }
        }
        return localStorage.getItem(pathCode);
    }
}

