
import { isEmptyOrUndefined } from "core/utils/validators";

export default function UserRestricted(selectedClient, allowedTypes) {
    let returnValue = true;

    if (!isEmptyOrUndefined(allowedTypes, 'array') && !isEmptyOrUndefined(selectedClient, 'dict')) {
        if (allowedTypes.includes('zippedi') && selectedClient.client_id === -1) {
            returnValue = false;
        } else if (allowedTypes.includes('supplier') && selectedClient.supplier_id) {
            returnValue = false;
        }
    }
    return returnValue;
}