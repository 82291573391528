import { Box, Button, Grid, Paper, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import StoreMap from '../../tools/StoreMap';
import ResMap from "./ResMap";
import ResForm from "./ResForm";
import Selector from "../../tools/Selector";
import MultiSelector from "../../tools/MultiSelector";
import SendIcon from '@mui/icons-material/Send';

const schedulingTypes = [
  {name: 'New Session'},
  {name: 'Cancel Session'},
  {name: 'Change Time'},
  {name: 'Remove/Add Aisle'}
]

export default function SchedulingPanel(props) {
  const {
    aisleList,
    chain,
    client,
    getStoreMapInfo,
    isLoadingResMap,
    robotSessions,
    session,
    getDetailedRobotSession,
    storeMapInfo,
    storesFiltered,
    ticketMessageResponse,
    isLoadingNewTicket,
    postRescheduleTicket
  } = props;
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState();
  const [selectedStores, setSelectedStores] = useState([]);
  const [resetForm, setResetForm] = useState(false);
  const [ticketState, setTicketState] = useState(false);

  useEffect(() => {
    if (ticketMessageResponse?.code === 200)
      setResetForm(true);
  }, [ticketMessageResponse])

  useEffect(() => {
    if (selectedStores.length === 1) {
      getStoreMapInfo(selectedStores[0]);
    }
  }, [selectedStores])  

  const handleResetComplete = () => {
    setResetForm(false)
  }

  const pullFormData = (ticket_type, client_id, chain_country_id, store_codes, ticket_form, selected_type) => {
    // send request to post new ticket
    postRescheduleTicket(ticket_type, client_id, chain_country_id, store_codes, ticket_form, selected_type)
    setTicketState(false);
  }

  return (
    <Grid container justifyContent="flex-start" alignItems="flex-start" pt={2} spacing={2}>
      <Grid item xs={12} sm={6}>
        {/* Filters */}
        {/* <Paper sx={{ width: '100%', px: 2, py: 3 }}> */}
          <Grid container justifyContent="flex-start" alignItems="flex-start" columnSpacing={1}>
            <Grid item xs={12}>
              <Grid container justifyContent="flex-start" spacing={2}>
                <Grid item xs={6}>
                    <Selector labelInput={'Scheduling Type'} options={schedulingTypes} optionLabel={'name'}
                          setSelectedElement={setSelectedType} selectedElement={selectedType} />
                </Grid>
                <Grid item xs={6}>
                  <MultiSelector inputLabel={t('cws_app.general.sel_store')} options={storesFiltered} inputSelected={selectedStores}
                    setInputSelectedOptions={setSelectedStores} objectName={'parsed_name'} objectId={'store_code'} inputVariant='filled' />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        {/* </Paper> */}
      </Grid>
      { selectedType !== '' && selectedStores.length > 0 ? 
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="flex-start" spacing={2}>
            <ResForm
              aisleList={aisleList}
              chain={chain}
              client={client}
              getDetailedRobotSession={getDetailedRobotSession}
              onResetComplete={handleResetComplete}
              pullData={pullFormData}
              resetForm={resetForm}
              robotSessions={robotSessions}
              selectedType={selectedType}
              session={session}
              storeCodes={selectedStores}
              ticketState={ticketState}
            />
            { selectedStores.length === 1 ?
              <Grid item xs={12} sm={6} md={8}>
                <Grid container sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' }, overflowX: 'auto' }}>
                { isLoadingResMap ? 
                  <LinearProgress sx={{ width: '98%', mx: 'auto' }} color="secondary" />
                  :
                  <>
                    <ResMap storeMap={storeMapInfo} />
                  </>
                }
                </Grid>
              </Grid>
              :
              null
            }
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Button variant="contained">
                  {t('cws_app.general.back', 'Back')}
                </Button>
                <Button variant="contained" disabled={isLoadingNewTicket} endIcon={<SendIcon />} onClick={()=> setTicketState(true)}>
                  {t('cws_app.general.send', 'Send')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        :
        null
      }
    </Grid>
  )
}