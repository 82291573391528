import React, {
    createContext,
    forwardRef,
    useContext,
    useRef,
    useEffect,
    useState,
    useMemo,
} from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList } from 'react-window';
import { Box, LinearProgress, ListItemText, MenuItem, Tooltip } from "@mui/material";

const LISTBOX_PADDING = 3; // px

function customRenderRow({ objectName, objectId, InputTitleHeader }) {
    return function renderRow(props) {
        const { data, index, style } = props;
        const dataSet = data[index];
        const inlineStyle = {
            ...style,
            top: style.top + LISTBOX_PADDING,
        };

        if (dataSet.hasOwnProperty('group')) {
            return (<>{ dataSet?.group?.length > 0 ?
                    <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
                        {dataSet.group}
                    </ListSubheader>
					:
					<ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
						{InputTitleHeader}
					</ListSubheader>
				} </>)
        }
        return (
            <MenuItem style={style} {...dataSet[0]} value={dataSet[1][objectId]}>
                <Tooltip title={dataSet[1][objectName]}>
                    <ListItemText primary={dataSet[1][objectName]} />
                </Tooltip>
            </MenuItem>
        );
    }
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
    const outerProps = useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
    const ref = useRef(null);
    useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
function getListBoxComponent({ objectId, objectName, InputTitleHeader }) {
    return forwardRef(function ListboxComponent(props, ref) {
        const { children, ...other } = props;
        const itemData = [];
        children.forEach((item) => {
            itemData.push(item);
            itemData.push(...(item.children || []));
        });

        const theme = useTheme();
        const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
            noSsr: true,
        });
        const itemCount = itemData.length;
        const itemSize = smUp ? 36 : 48;

        const getChildSize = (child) => {
            if (child.hasOwnProperty('group')) {
                return 48;
            }

            return itemSize;
        };

        const getHeight = () => {
            if (itemCount > 8) {
                return 8 * itemSize;
            }
            return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
        };

        const gridRef = useResetCache(itemCount);

        return (
            <div ref={ref}>
                <OuterElementContext.Provider value={other}>
                    <VariableSizeList
                        itemData={itemData}
                        height={getHeight() + 2 * LISTBOX_PADDING}
                        width="100%"
                        ref={gridRef}
                        outerElementType={OuterElementType}
                        innerElementType="ul"
                        itemSize={(index) => getChildSize(itemData[index])}
                        overscanCount={5}
                        itemCount={itemCount}
                    >
                        {customRenderRow({ objectId, objectName, InputTitleHeader })}
                    </VariableSizeList>
                </OuterElementContext.Provider>
            </div>
        );
    });
}

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});

export default function InfiniteSelector(props) {
    const {
        isLoading,
        inputVariant,
        inputPlaceHolder = '',
        inputLabel = 'Select',
        options = [],
        InputTitleHeader = 'Seleccionar Opciones',
        objectName = 'name',
        objectId = 'id',
        inputSelected = {},
        handleSelect,
        groupBy,
    } = props;

    const [selectedOption, setSelectedOption] = useState({});
    const [optionsParsed, setOptionsParsed] = useState(options);
    const [groups, setGroups] = useState([]);

	useEffect(() => {
		if (options && options.length) {
			let optionsTemp = [];
			let groups = [];
			options.forEach(row => {
				if(groupBy?.length > 0 && !groups.includes(row[groupBy])){
					groups.push(row[groupBy]);
					optionsTemp.push({ 
						[objectId]: row[groupBy], [objectName]: row[groupBy], selected: false,
					});
				}
			});
			setGroups(groups);
		}
	}, [options]);

    useEffect(() => {
        if (inputSelected && Object.keys(inputSelected).length > 0) {
            setSelectedOption(inputSelected);
        }
    }, [inputSelected, selectedOption, setSelectedOption])

    useEffect(() => {
        if (options && options.length) {
            setOptionsParsed(options);
        }
    }, [objectId, objectName, options])


    const handleChange = (event, newValue) => {
        handleSelect(newValue);
        setSelectedOption(newValue);
    }

    const ListboxComponent = useMemo(() => {
        return getListBoxComponent({ objectName, objectId, InputTitleHeader });
    }, [groups]);
    ListboxComponent.propTypes = {
        children: PropTypes.node,
    };

    return (
        <>
            {!isLoading && <Box sx={{ position: 'relative', width: '100%', height: '4px', visibility: 'hidden' }} />}
            {isLoading && <LinearProgress sx={{ width: '100%', mx: 'auto', top: '-4px' }} color="secondary" />}
            <Autocomplete
                disableClearable
                disableListWrap
                value={selectedOption}
                id="single-infinite-select"
                onChange={handleChange}
                options={optionsParsed}
                getOptionLabel={(option) => option[objectName]}
                // defaultValue={selectedOption}
                PopperComponent={StyledPopper}
                ListboxComponent={ListboxComponent}
                groupBy={(option) => groupBy?.length > 0 ? option[groupBy]: option[0]}
                renderInput={(params) => <TextField {...params} variant={inputVariant} label={inputLabel} placeholder={inputPlaceHolder} />}
                renderOption={(props, option, state) => [props, option, state.index]}
                renderGroup={(params) => params}
            />
        </>
    );
}
