import { Autocomplete, InputAdornment, styled, TextField } from '@mui/material'
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { isEmptyOrUndefined } from "core/utils/validators";

const StyledTextField = styled(
  props => {
    return (<TextField {...props} />)
  }, { shouldForwardProp: props => props !== 'color' }
)(({ theme, searchbartheme }) => {
  const currentTheme = searchbartheme === 'default' ? theme.palette.type : searchbartheme;
  return ({
    // Textfield's border
    '& .MuiOutlinedInput-root': {
      background: currentTheme === 'light' ? theme.palette.common.white : '',
      color: currentTheme === 'light' ? theme.palette.text.primary : theme.palette.common.white,
      height: 'auto',
      '&:hover fieldset': {
        borderColor: theme.palette.grey[300],
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.grey[300],
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: currentTheme === 'light' ? '' : theme.palette.common.white,
    },
    // 'X' adornment for clearing the input
    '& .MuiAutocomplete-clearIndicator': {
      color: currentTheme === 'light' ? theme.palette.text.primary : theme.palette.common.white,
      '&:hover': {
        backgroundColor: currentTheme === 'light' ? theme.palette.action.hover : theme.palette.grey[800],
      },
    },
    // Search icon
    '& .MuiInputAdornment-root': {
      color: theme.palette.grey[500],
    },
  })
});

export default function SearchBar(props) {
  const {
    value,
    setValue,
    inputValue,
    setInputValue,
    options = [],
    id = [], // Add a list of one or more values to use as IDs, joined by a hyphen (-). If the array is empty, optionLabel is used as the ID
    optionLabel = 'name',
    placeholder = 'cws_app.general.search',
    searchbartheme = 'default',
    color = 'secondary',
    sx = {},
    fullWidth = false,
    disableClearable = false,
    disabled = false,
    defaultValue = null,  // There was a warning because the defualt value (eg.: null, [], '', etc.) didn't match any of the selections
  } = props;
  const { t } = useTranslation();
  const customIDs = isEmptyOrUndefined(id, 'array') ? [optionLabel] : id;

  return (
    <Autocomplete
      // Extras
      freeSolo
      fullWidth={fullWidth}
      disableClearable={disableClearable}
      disabled={disabled}
      id="custom-searchbar"
      sx={{ ...sx, height: 'fit-content' }}
      // To handle the dropdown list
      value={value}
      onChange={(_, newValue) => {
        setValue(newValue);
      }}
      isOptionEqualToValue={(option, value) => value[optionLabel] === defaultValue ? true : option[optionLabel] === value[optionLabel]}
      getOptionLabel={(option) => {
        // When a user presses 'enter' on the input text do nothing, i.e. return the same inputValue
        if (typeof option === 'string') {
          return option
        }
        return option ? option[optionLabel] : ''
      }}
      options={options}
      // To handle the user input text
      inputValue={inputValue}
      onInputChange={(_, newValue) => {
        setInputValue(newValue);
      }}
      // Custom renders
      renderOption={(props, option) => {
        const keys = customIDs.map(id => {
          return (option[id])
        })
        return (
          <li {...props} key={keys.join('-')}>
            {option[optionLabel]}
          </li>
        );
      }}
      renderInput={(params) =>
        <StyledTextField {...params}
          variant="outlined"
          color={color}
          searchbartheme={searchbartheme}
          placeholder={t(placeholder, 'Search')}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                {params.InputProps.endAdornment}
                <InputAdornment position="start">
                  <IconComponent
                    iconName={'search-outline'}
                    style={{ fontSize: '20px' }}
                  />
                </InputAdornment>
              </>
            )
          }}
        />
      }
    />
  )
}
