import React from 'react';
import {
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';

//Icons
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

const styles = {
  container: {
    spacing: 2,
    my: 1,
    px: 4,
  },
  form: {
    maxHeight: '15vh',
    overflowX: 'hidden',
    overflowY: 'auto',
  }
};

export default function TranslationForm(props) {
  const {
    t,
    addNewTranslations,
    newTranslationDict,
    deleteNewTranslations,
    textColor,
  } = props;

  return (
    <Grid item xs={12} container sx={styles.container}>
      {Object.keys(newTranslationDict).length > 0 && (
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 'bold', fontSize: '20px', color: textColor }}>
            {t('cws_app.translations.add_translations')}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} sx={styles.form}>
        {Object.keys(newTranslationDict).map(id => (
          <Grid key={id} item xs={12} container spacing={1} justifyContent="space-between" alignItems="center" sx={{ p: 1 }}>
            <Grid item xs>
              <TextField fullWidth size="small" label={t("cws_app.translations.new_code")} value={newTranslationDict[id]['key']}
                onChange={e =>
                  addNewTranslations(
                    e.target.value,
                    newTranslationDict[id]['value'],
                    id,
                  )
                }
              />
            </Grid>
            <Grid item xs>
              <TextField fullWidth size="small" label={t("cws_app.translations.new_value")} value={newTranslationDict[id]['value']}
                onChange={e =>
                  addNewTranslations(
                    newTranslationDict[id]['key'],
                    e.target.value,
                    id,
                  )
                }
              />
            </Grid>
            <Grid item xs>
              <IconButton
                onClick={() => {
                  deleteNewTranslations(id);
                }}>
                <IconComponent iconName={'trash'} style={{ fontSize: 20 }} />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Grid>

    </Grid>
  );
}
