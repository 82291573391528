import { connect } from "react-redux";
import CustomPage from "../../../components/features/documentation/CustomPage";

import {
    getSelectedClient
} from 'core/selectors';

export default connect(
    state => ({
        selectedClient: getSelectedClient(state),
    }),
    {}
)(CustomPage);
