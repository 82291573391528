import React, {
	createContext,
	forwardRef,
	useContext,
	useRef,
	useMemo,
	useEffect,
	useState,
} from 'react';

// components
import {
	TextField,
	useMediaQuery,
	ListSubheader,
	Popper,
	Checkbox,
	LinearProgress,
	ListItemText,
	MenuItem,
	Paper,
	Tooltip,
	Box,
} from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import { VariableSizeList } from 'react-window';

// icons
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const LISTBOX_PADDING = 3; // px

const StyledPaper = styled(props => {
	return (
		<Paper
			{...props}
		/>
	)
}
)(({ theme }) => {
	return ({
		width: '100%',
		background: theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
	})
}
);
const StyledTextField = styled(props => {
	return (<TextField {...props} />)
})(({ theme }) => {
	return ({
		"& .MuiInputLabel-root": {
			color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.87)',
		},
		"& .MuiFilledInput-root": {
			background: theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
			color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.87)',
			height: 'auto',
		},
		'&:hover': {
			backgroundColor: 'rgba(255, 255, 255, 0.17)',
		},
		'&.Mui-focused': {
			backgroundColor: 'rgba(255, 255, 255, 0.17)',
		},
	})
});

function customRenderRow({ objectName, objectId, InputTitleHeader, selectGroup }) {
	return function renderRow(props) {
		const { data, index, style } = props;
		const dataSet = data[index];
		const inlineStyle = {
			...style,
			top: style.top + LISTBOX_PADDING,
		};

		if (dataSet.hasOwnProperty('group')) {
			let allSelected = dataSet.children.find(c => c[1].selected === false) ? false : true;
			return (
				<>{dataSet?.group?.length > 0 ?
					<MenuItem style={style} key={dataSet.key} sx={{ px: 0 }}>
						<Checkbox
							icon={icon}
							checkedIcon={checkedIcon}
							checked={allSelected}
							onChange={() => selectGroup(dataSet.group, allSelected)}
						/>
						<ListItemText primary={dataSet.group} />
					</MenuItem>
					:
					<ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
						{InputTitleHeader}
					</ListSubheader>
				} </>
			);
		}
		dataSet[0]['aria-selected'] = dataSet[1]['selected']
		return (
			<MenuItem style={style} {...dataSet[0]} value={dataSet[1][objectId]}>
				<Checkbox
					icon={icon}
					checkedIcon={checkedIcon}
					checked={dataSet[1]['selected']}
					inputProps={{
						'aria-label': dataSet[1][objectName],
					}} />
				<Tooltip title={dataSet[1][objectName]}>
					<ListItemText primary={dataSet[1][objectName]} />
				</Tooltip>
			</MenuItem>
		);
	}
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
	const outerProps = useContext(OuterElementContext);
	return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
	const ref = useRef(null);
	useEffect(() => {
		if (ref.current != null) {
			ref.current.resetAfterIndex(0, true);
		}
	}, [data]);
	return ref;
}

// Adapter for react-window
function getListBoxComponent({ objectId, objectName, InputTitleHeader, selectGroup }) {
	return forwardRef(function ListboxComponent(props, ref) {
		const { children, ...other } = props;
		const itemData = [];
		children.forEach((item) => {
			itemData.push(item);
			itemData.push(...(item.children || []));
		});

		const theme = useTheme();
		const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
			noSsr: true,
		});
		const itemCount = itemData.length;
		const itemSize = smUp ? 36 : 48;

		const getChildSize = (child) => {
			if (child.hasOwnProperty('group')) {
				return 48;
			}

			return itemSize;
		};

		const getHeight = () => {
			if (itemCount > 8) {
				return 8 * itemSize;
			}
			return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
		};

		const gridRef = useResetCache(itemCount);

		return (
			<div ref={ref}>
				<OuterElementContext.Provider value={other}>
					<VariableSizeList
						itemData={itemData}
						height={getHeight() + 2 * LISTBOX_PADDING}
						width="auto"
						ref={gridRef}
						outerElementType={OuterElementType}
						innerElementType="ul"
						itemSize={(index) => getChildSize(itemData[index])}
						overscanCount={5}
						itemCount={itemCount}
						style={{ marginRight: 10 }}
					>
						{customRenderRow({ objectId, objectName, InputTitleHeader, selectGroup })}
					</VariableSizeList>
				</OuterElementContext.Provider>
			</div>
		);
	});
}

const StyledPopper = styled(Popper)({
	[`& .${autocompleteClasses.listbox}`]: {
		boxSizing: 'border-box',
		'& ul': {
			padding: 0,
			margin: 0,
		},
	},
});

export default function InfiniteMultiSelector(props) {
	const {
		isLoading,
		inputVariant = 'outlined',
		inputPlaceHolder = '',
		inputLabel = 'Select',
		inputElevation = 0,
		options = [],
		canSelectAll = true,
		InputTitleHeader = 'Select Options',
		objectName = 'name',
		objectId = 'id',
		inputSelected,
		setInputSelectedOptions,
		xStyle,
		groupBy,
		disabled = false,
		required = false,
		returnEmptyList = true,
	} = props;

	const [selectedOptions, setSelectedOptions] = useState([]);
	const [optionsParsed, setOptionsParsed] = useState([]);
	const [groups, setGroups] = useState([]);

	useEffect(() => {
		if (options && options.length) {
			let groups = [];
			options.forEach(row => {
				if (groupBy?.length > 0 && !groups.includes(row[groupBy])) {
					groups.push(row[groupBy]);
				}
			});
			setGroups(groups);
		}
	}, [options]);

	useEffect(() => {
		if (inputSelected && options && options.length) {
			let optionsTemp = options.map(row => {
				row['selected'] = false;
				return row;
			});
			inputSelected.forEach(inputRow => {
				const optionRow = optionsTemp.find(row => row[objectId] === inputRow[objectId]);
				inputRow['selected'] = true;
				if (optionRow) optionRow['selected'] = true;
			});
			selectedOptions.forEach(selectedRow => {
				const optionRow = optionsTemp.find(row => row[objectId] === selectedRow[objectId]);
				selectedRow['selected'] = true;
				if (optionRow) optionRow['selected'] = true;
			});
			// keep order of selected options
			optionsTemp = [
				...optionsTemp.filter(({ selected }) => selected),
				...optionsTemp.filter(({ selected }) => !selected)
			];
			setOptionsParsed(canSelectAll ? [{ [objectId]: 'all', [objectName]: 'Select All' }, ...optionsTemp] : optionsTemp);
			const selectedValues = [...inputSelected, ...selectedOptions];
			const deduplicateValue = Object.values(
				selectedValues.reduce(
					(bef, curr)=> {
						return bef?.[objectId] ? bef : {...bef, [curr[objectId]]: curr}}, {})
			);
			
			setSelectedOptions(deduplicateValue);
		}
	}, [inputSelected, options])

	useEffect(() => {
		// update dropdown on selection
		if (selectedOptions.length > 0) {
			let optionsTemp = canSelectAll ? optionsParsed.slice(1) : optionsParsed; //remove 'select all' if exists
			optionsTemp = [
				...optionsTemp.filter(({ selected }) => selected),
				...optionsTemp.filter(({ selected }) => !selected)
			];
			setOptionsParsed(canSelectAll ? [{ [objectId]: 'all', [objectName]: 'Select All' }, ...optionsTemp] : optionsTemp);
		}
	}, [selectedOptions])

	const handleRenderValue = (selected) => {
		const MAX_LENGTH = 2;
		const MAX_STR_LENGTH = 22;
		let previewString = selected
			.slice(0, MAX_LENGTH)
			// eslint-disable-next-line eqeqeq
			.flatMap(obj => obj != undefined ? [obj[objectName]] : [])
			.join(", ")
		return previewString.length > MAX_STR_LENGTH ? `${previewString.slice(0, MAX_STR_LENGTH)}... ` : previewString;
	}

	const handleChange = (event, value) => {
		const {
			target: { ariaLabel, textContent }
		} = event;
		const lastSelectedValue = value.slice(-1)[0];
		if (lastSelectedValue?.[objectId] === 'all' || (textContent === 'Select All')) {
			handleSelectAll(lastSelectedValue.selected);
			lastSelectedValue['selected'] = false;
		} else if (value.length === 0) {
			const optionsTemp = structuredClone(options);
			setOptionsParsed(optionsTemp);
			setSelectedOptions(value);
			setInputSelectedOptions(value);
		} else if (lastSelectedValue?.selected && lastSelectedValue[objectName] !== textContent) {
			// lastSelectedValue['selected'] = false;
			let optionsTemp = structuredClone(optionsParsed);
			if (textContent) {
				const optionRow = optionsTemp.find(row => row[objectName] === textContent);
				optionRow['selected'] = false;
			} if (ariaLabel) {
				value = value.filter(row => row[objectName] !== ariaLabel);
				const optionRow = optionsTemp.find(row => row[objectName] === ariaLabel);
				optionRow['selected'] = false;
			}
			setOptionsParsed(optionsTemp);
			setSelectedOptions(value);
			setInputSelectedOptions(value);
		} else if (lastSelectedValue?.selected) {
			const optionsTemp = structuredClone(optionsParsed);
			const optionRow = optionsTemp.find(row => row[objectName] === textContent);
			value = value.filter(row => row[objectName] !== textContent);
			optionRow['selected'] = false;
			setOptionsParsed(optionsTemp);
			setSelectedOptions(value);
			setInputSelectedOptions(value);
			// setInputSelectedOptions([]);
		} else {
			lastSelectedValue['selected'] = true;
			setSelectedOptions(value);
		}
	};

	const handleSelectAll = (isRemoveOption) => {
		setSelectedOptions(isRemoveOption ? [] : optionsParsed);
	}

	const handleOnClose = () => {
		if (returnEmptyList) {
			setInputSelectedOptions(optionsParsed.length !== selectedOptions.length ? selectedOptions : []);
		} else {
			setInputSelectedOptions(selectedOptions);
		};
	};

	const selectGroup = (groupName, allSelected) => {
		let optionsTemp = structuredClone(optionsParsed);
		optionsTemp.forEach(row => {
			if (row[groupBy] === groupName) {
				row.selected = !allSelected;
			}
		});
		setOptionsParsed(optionsTemp);
		setSelectedOptions(optionsTemp.filter(row => row.selected));
		setInputSelectedOptions(optionsTemp.filter(row => row.selected));
	}

	// useMemo prevents the auto scroll up when selecting an option, updates when < groups > is updated
	const ListboxComponent = useMemo(() => {
		return getListBoxComponent({ objectName, objectId, InputTitleHeader, groups, selectGroup });
	}, [groups]);

	return (
		<>
			<StyledPaper elevation={inputElevation} sx={xStyle}>
				<Autocomplete
					multiple
					disabled={disabled}
					value={selectedOptions}
					limitTags={0}
					id={objectId}
					disableCloseOnSelect
					renderTags={handleRenderValue}
					onChange={handleChange}
					options={optionsParsed}
					getOptionLabel={(option) => option[objectName]}
					isOptionEqualToValue={(option, value) => option[objectName] === value[objectName]}
					PopperComponent={StyledPopper}
					ListboxComponent={ListboxComponent}
					onClose={handleOnClose}
					groupBy={(option) => groupBy?.length > 0 ? option[groupBy] : option[0]}
					renderInput={(params) => <StyledTextField {...params} variant={inputVariant} label={inputLabel} placeholder={inputPlaceHolder} required={required} />}
					renderOption={(props, option, state) => [props, option, state.index]}
					renderGroup={(params) => params}
				/>
			</StyledPaper>
			{!isLoading && <Box sx={{ position: 'relative', width: '100%', height: '4px', visibility: 'hidden' }} />}
			{isLoading && <LinearProgress sx={{ width: '100%', mx: 'auto', top: '-5px' }} color="secondary" />}
		</>
	);
}
