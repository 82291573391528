import * as React from "react";
import { useEffect, useState } from "react";
import { Grid, Paper, Typography, LinearProgress, Button } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import { useTranslation } from 'react-i18next';
import Selector from "../../tools/Selector";
import MultiSelector from "../../tools/MultiSelector";
import ResCalendar from "./Calendar";
import DatePanel from "./DatePanel";
import ResMap from "./ResMap";
import SchedulingPanel from "./SchedulingPanel";
import moment from "moment";


export default function Rescheduling(props) {
  const {
    userStores,
    isLoadingResMap,
    getUserStores,
    selectedClient,
    storeMapInfo,
    getStoreMapInfo,
    postRescheduleTicket,
    isLoadingNewTicket,
    ticketMessageResponse,
    chainsList,
    getChains,
    robotSessions,
    robotSession,
    getRobotSessionsInfo,
    getDetailedRobotSession,
    isLoadingRobotSessions,
    ticketsData,
    getRescheduleTickets,
    isLoadingTickets
  } = props;

  const { t } = useTranslation();

  const [date, setDate] = useState(new Date());
  const [selectedChain, setChainState] = useState();
  const [selectedStore, setStoreState] = useState();
  const [selectedStores, setStores] = useState([]);
  const [dateInfo, setDateInfo] = useState(null);
  const [aisleList, setAisleList] = useState([]);
  const [ticketState, setTicketState] = useState(false);
  const [storesFiltered, setStoresList] = useState([]);
  const [sessionEvents, setSessionEvents] = useState([]);

  const [include_map, session, mapInfoRequest] = [true, null, null]; //TODO: use this params when we using cache
  const CANVASWIDTH = 1000;

  useEffect(() => {
    let events_resume = [];
    if (robotSessions) {
      Object.keys(robotSessions).forEach(date => {
        let start = moment(date);
        Object.keys(robotSessions[date]).forEach(source => {
          events_resume.push({
            start: start.toDate(), //.add(33, 'days').toDate(),
            end: start.toDate(),
            data: robotSessions[date][source],
            title: `${source} (${robotSessions[date][source].length})`
          })
        })
      })
    }
    if (ticketsData) {
      Object.keys(ticketsData.tickets_response_calendar_dict).forEach(date => {
        let start = moment(date);
        Object.keys(ticketsData.tickets_response_calendar_dict[date]).forEach(source => {
          events_resume.push({
            start: start.toDate(), //.add(33, 'days').toDate(),
            end: start.toDate(),
            data: ticketsData.tickets_response_calendar_dict[date][source],
            title: `${source} (${ticketsData.tickets_response_calendar_dict[date][source].length})`
          })
        })
      })
    }
    if (events_resume.length > 0) setSessionEvents(events_resume);

  }, [robotSessions, ticketsData])


  useEffect(() => {
    if (selectedClient) {
      getUserStores(selectedClient.client_id);
    }
  }, [getUserStores, selectedClient]);


  useEffect(() => {
    if (date && selectedStores.length) {
      let start = moment().subtract(70, 'days').format('YYYY-MM-DDTHH:mm:ss');
      let until = moment().add(10, 'days').format('YYYY-MM-DDTHH:mm:ss');
      getRobotSessionsInfo(selectedStores, start, until);
      // make store_ids array
      let store_ids = userStores.filter(item => selectedStores.includes(item.store_code)).map(store => store.store_id);
      // get ticket information
      // (ticket_type, store_ids, client_id, chain_country_id, supplier_id, ticket_date)
      getRescheduleTickets('robot_rescheduling', selectedStores, -1, selectedChain?.chain_country_id, null, '2022-02-14T00:00:00');
    }
  }, [selectedStores])

  useEffect(() => {
    getChains();
  }, [dateInfo])

  useEffect(() => {
    if (userStores && selectedChain) {
      setStoresList(userStores.filter((item) => item.chain_name === selectedChain.name))
    }
  }, [selectedChain])

  useEffect(() => {
    if (storeMapInfo) {
      let parsed_list = Object.keys(storeMapInfo.client_aisles_names).map(item => {
        return {
          'aisle_name': item,
          'client_name': storeMapInfo.client_aisles_names[item]
        }
      })
      parsed_list.sort((a, b) => a.aisle_name.localeCompare(b.aisle_name, undefined, { numeric: true, sensitivity: 'base' }))
      setAisleList(parsed_list);
    }
  }, [storeMapInfo])

  const displayDateInfo = (data) => {
    if (dateInfo && data.start.toDateString() === dateInfo.start.toDateString()) setDateInfo(null);
    else {
      let test_date = moment(data.start).format('YYYY-MM-DD')
      data['baldur'] = robotSessions[test_date] ? robotSessions[test_date] : {}
      data['tickets'] = ticketsData['tickets_response_calendar_dict']?.[test_date]
      setDateInfo(data)
    }
  }

  return (
    <Grid container justifyContent="center" alignItems="flex-start" pt={4} px={2} spacing={2}>
      <Grid item xs={12}>
        {/* Filters */}
        <Paper sx={{ width: '100%', px: 2, py: 3 }}>
          <Grid container justifyContent="left" alignItems="flex-start" columnSpacing={1}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6}>
                  <Selector labelInput={'Cadena'} options={chainsList} optionLabel={'name'}
                    setSelectedElement={setChainState} selectedElement={selectedChain} />
                </Grid>
                <Grid item xs={6}>
                  <MultiSelector inputLabel={t('cws_app.general.sel_store')} options={storesFiltered} inputSelected={selectedStores}
                    setInputSelectedOptions={setStores} objectName={'parsed_name'} objectId={'store_code'} inputVariant='filled' />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={dateInfo ? 8 : 12}>
        {isLoadingRobotSessions ?
          <LinearProgress sx={{ width: '98%', mx: 'auto' }} color="secondary" />
          : null
        }
        <Paper sx={{ width: '100%', px: 2, py: 3 }}>
          <ResCalendar events={sessionEvents} func={displayDateInfo} />
        </Paper>
        <Typography variant="body2" display="block" gutterBottom>
          *{t('cws_app.robot_rescheduling.hint_calendar_text')}
        </Typography>
      </Grid>

      {
        dateInfo ?
          <Grid item xs={4}>
            <DatePanel dateInfo={dateInfo} />
          </Grid>
          : null
      }
      <Grid item xs={12}>
        <SchedulingPanel
          aisleList={aisleList}
          chain={selectedChain}
          client={selectedClient}
          getStoreMapInfo={getStoreMapInfo}
          getDetailedRobotSession={getDetailedRobotSession}
          isLoadingResMap={isLoadingResMap}
          robotSessions={robotSessions}
          session={robotSession}
          storeCodes={selectedStores}
          storeMapInfo={storeMapInfo}
          storesFiltered={storesFiltered}
          ticketMessageResponse={ticketMessageResponse}
          isLoadingNewTicket={isLoadingNewTicket}
          postRescheduleTicket={postRescheduleTicket}
        />
      </Grid>
    </Grid>
  )
}