import { connect } from 'react-redux';
import Suppliers from '../../../../components/userManagement/suppliers/Suppliers';

import { getUserSuppliers } from 'core/slices/users';
import { updateActiveSubSupplier } from 'core/slices/suppliers';

import {
  getSelectedClient,
  isLoadingUserSuppliers,
  userSuppliers,
} from 'core/selectors';

export default connect(state => ({
  selectedClient: getSelectedClient(state),
  suppliers: userSuppliers(state),
  isLoadingSuppliers: isLoadingUserSuppliers(state),
}), {
  updateActiveSubSupplier,
  getUserSuppliers,
})(Suppliers);
