import { Calendar, momentLocalizer } from "react-big-calendar";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import React, { Component } from "react";

export default function ResCalendar(props) {
  const {events, func} = props
  const {t} = useTranslation();
  const localizer = momentLocalizer(moment);

  const onSelectSlot = (slotInfo) => {
    if (slotInfo.action === "click") {
      func(slotInfo)
    }
    return false;
  };

  return (
    <Calendar
      localizer={localizer}
      defaultDate={new Date()}
      defaultView="month"
      events={events}
      style={{ height: "50vh" }}
      onSelectSlot={onSelectSlot}
      selectable
    />
  )
}