import { connect } from 'react-redux';
import LastProductState from '../../../../components/features/lastProductState/LastProductState';

import { getUserStores, getUserSuppliers } from 'core/slices/users';
import { getStoreCategories } from 'core/slices/store';
import { getProductsStates } from 'core/slices/productsStates';

import {
  isLoadingUserStores,
  getUserStoresAllList,
  getSelectedClient,
  userChains,
  isLoadingCategories,
  categories,
  userSuppliers,
  isLoadingUserSuppliers,
  isLoadingProductsStates,
  productsStates
} from 'core/selectors';

export default connect(state => ({
  isLoadingUserStores: isLoadingUserStores(state),
  selectedClient: getSelectedClient(state),
  userChains: userChains(state),
  userStores: getUserStoresAllList(state),
  categories: categories(state),
  isLoadingCategories: isLoadingCategories(state),
  suppliers: userSuppliers(state),
  isLoadingSuppliers: isLoadingUserSuppliers(state),
  isLoadingProductsStates: isLoadingProductsStates(state),
  productsStates: productsStates(state),
}), {
  getUserStores,
  getStoreCategories,
  getUserSuppliers,
  getProductsStates
})(LastProductState);
