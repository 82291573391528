import { connect } from 'react-redux';
import TrackingList from '../../../../components/features/trackingList/TrackingList';
import { getChains } from 'core/slices/backend';
import { getSupplierStores } from 'core/slices/suppliers';
import { getClientStores } from 'core/slices/clients';
import { getUserSuppliers } from 'core/slices/users';
import {
  deleteTrackingList,
  uploadTrackingList,
  getTrackinglist,
  getTrackinglistItems,
  getTrackingListExcel,
  postTrackingListStores
} from 'core/slices/trackingList';
import {
  getChainsList,
  getSelectedClient,
  isLoadingChains,
  isLoadingUpload,
  getTrackingListData,
  isLoadingTrackingListData,
  getTrackingListDataItems,
  isLoadingTrackingListDataItems,
  getClientStoresList,
  isLoadingClientStores,
  uploadResponse,
  isLoadingDelete,
  deleteResponse,
  getSupplierStoresList,
  isLoadingSupplierStores,
  userSuppliers,
  isLoadingUserSuppliers,
} from 'core/selectors';

export default connect(state => ({
  trackingListData: getTrackingListData(state),
  loadingTrackingListData: isLoadingTrackingListData(state),
  trackingListDataItems: getTrackingListDataItems(state),
  loadingTrackingListDataItems: isLoadingTrackingListDataItems(state),
  clientStores: getClientStoresList(state),
  loadingClientStores: isLoadingClientStores(state),
  selectedClient: getSelectedClient(state),
  chains: getChainsList(state),
  isLoadingChains: isLoadingChains(state),
  isLoadingUpload: isLoadingUpload(state),
  uploadResponse: uploadResponse(state),
  isLoadingDelete: isLoadingDelete(state),
  deleteResponse: deleteResponse(state),
  supplierStores: getSupplierStoresList(state),
  isLoadingSupplierStores: isLoadingSupplierStores(state),
  suppliers: userSuppliers(state),
  isLoadingSuppliers: isLoadingUserSuppliers(state),
}), {
  getClientStores,
  getTrackinglist,
  getTrackinglistItems,
  getTrackingListExcel,
  postTrackingListStores,
  getChains,
  uploadTrackingList,
  deleteTrackingList,
  getSupplierStores,
  getUserSuppliers,
})(TrackingList);
