import { Collapse, Grid, Paper, Typography } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import React from "react";

export default function DatePanel(props) {
  const {
    dateInfo
  } = props

  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Paper sx={{ overflow: 'auto', width: '100%', px: 2, py: 3, height: '55vh' }}>
      <Typography sx={{ mt: 1, mb: 2 }} variant="h6" component="div">
        {dateInfo.slots[0].toDateString()}
      </Typography>
      <List >
        { dateInfo?.tickets ?
          Object.keys(dateInfo.tickets).map(source => {
            return (
              <div>
                <ListItemButton disablePadding onClick={handleClick}>
                  <ListItemText primary={`${source} - ${dateInfo.tickets[source].length}`} />
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  {dateInfo.tickets[source].map(item => {
                    return (
                      <List component="div" disablePadding>
                        <ListItemText sx={{ pl: 2}} primary={`${item.form_ticket.stores} - Type: ${'rescheduleType' in item.form_ticket ? item.form_ticket.rescheduleType.name : '"-"'} - Store canceled: ${item.form_ticket.is_store_canceled} - Reason: ${item.form_ticket.selected_reason}`} />
                      </List>
                    )
                  })
                  }
                </Collapse>
              </div>
            )
          })
          :
          null
        }
        { dateInfo?.baldur ?
          Object.keys(dateInfo.baldur).map(source => {
            return (
              <div>
                <ListItemButton disablePadding onClick={handleClick}>
                  <ListItemText primary={`${source} - ${dateInfo.baldur[source].length}`} />
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  {dateInfo.baldur[source].map(item => {
                    return (
                      <List component="div" disablePadding>
                        <ListItemText sx={{ pl: 2}} primary={`${item.robot_code} - Regular time: ${item.start_datetime.split(' ')[1]}`} />
                      </List>
                    )
                  })
                  }
                </Collapse>
              </div>
            )
          })
          :
          null
        }
      </List>
    </Paper>
  )
}