import { useState, useEffect } from 'react'
import { Grid, Paper, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next';

import { isEmptyOrUndefined } from "@zippeditoolsjs/blocks";

import { colorBasedOnTheme } from '../../utils/colors'
import DataTable from './DataTable';
import TopComponents from './TopComponents';
import CircularLoader from '../../tools/CircularLoader';

export default function TrackingList(props) {
  const {
    selectedClient,
    getClientStores,
    clientStores,
    loadingTrackingListData,
    trackingListData,
    getTrackinglist,
    getTrackingListExcel,
    getTrackinglistItems,
    trackingListDataItems,
    loadingTrackingListDataItems,
    postTrackingListStores,
    getChains,
    chains,
    isLoadingChains,
    uploadTrackingList,
    isLoadingUpload,
    uploadResponse,
    deleteTrackingList,
    isLoadingDelete,
    deleteResponse,
    getSupplierStores,
    supplierStores,
    getUserSuppliers,
    suppliers,
    isLoadingSuppliers,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  // Table states
  const [tableData, setTableData] = useState([]);
  const [storesNames, setStoresNames] = useState({});
  // Top components states
  const [selectedChain, setSelectedChain] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [trackingListID, setTrackingListID] = useState(null);

  // Lifecycle methods

  // Get tracking list id, products and actionable
  useEffect(() => {
    if (!isEmptyOrUndefined(selectedSupplier, 'object')) {
      getTrackinglist(selectedSupplier?.id);
      getSupplierStores(selectedSupplier?.id);
    }
  }, [selectedSupplier]);

  // Get more info about the tracking list products
  useEffect(() => {
    if (!isEmptyOrUndefined(trackingListData, 'object') && trackingListData.tracking_list_id) {
      setTrackingListID(trackingListData.tracking_list_id);
      getTrackinglistItems(trackingListData.tracking_list_id);
    }
  }, [trackingListData]);

  useEffect(() => {
    if (supplierStores && supplierStores.length > 0) {
      let newStoresNames = {};
      supplierStores.forEach((item) => {
        newStoresNames[item.store_code] = item.name;
      });
      setStoresNames(newStoresNames);
    }
  }, [supplierStores]);

  useEffect(() => {
    if (trackingListDataItems?.length > 0 && trackingListData?.products.length > 0) {
      let newTableData = [];
      trackingListData.products.forEach((item) => {
        trackingListDataItems.forEach((item2) => {
          if (item.item === item2.item && item.ean === item2.ean) {
            newTableData.push({ ...item, active: item2.active });
          }
        });
      });
      let stores = Object.keys(storesNames);
      newTableData.forEach((row) => {
        let storesTL = Object.keys(row.active);
        let newActive = {};
        stores.forEach((store) => {
          if (!storesTL.includes(store)) {
            newActive[store] = false;
          } else {
            newActive[store] = row.active[store];
          }
        });
        row.active = newActive;
      });
      setTableData(newTableData);
    }

  }, [trackingListData, trackingListDataItems])

  // Methods

  return (
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} pt={4} px={2}>
      {/* Page title */}
      <Grid item xs={4} sm={8} md={12}>
        <Typography variant='h4' sx={{ color: colorBasedOnTheme(theme, 'color') }}>{t('cws_app.general.tracking_list_admin', 'Tracking Lists')}</Typography>
      </Grid>
      {/* Selectors, upload and delete buttons (only for zippedians) */}
      {selectedClient?.supplier_id === null &&
        <Grid item xs={4} sm={8} md={12}>
          <TopComponents
            theme={theme}
            selectedClient={selectedClient}
            getChains={getChains}
            chains={chains}
            isLoadingChains={isLoadingChains}
            selectedChain={selectedChain}
            setSelectedChain={setSelectedChain}
            getUserSuppliers={getUserSuppliers}
            suppliers={suppliers}
            isLoadingSuppliers={isLoadingSuppliers}
            selectedSupplier={selectedSupplier}
            setSelectedSupplier={setSelectedSupplier}
            uploadTrackingList={uploadTrackingList}
            isLoadingUpload={isLoadingUpload}
            uploadResponse={uploadResponse}
            deleteTrackingList={deleteTrackingList}
            isLoadingDelete={isLoadingDelete}
            deleteResponse={deleteResponse}
            trackingListID={trackingListID}
            storesLength={supplierStores?.length}
            loadingTrackingListData={loadingTrackingListData}
            loadingTrackingListDataItems={loadingTrackingListDataItems}
          />
        </Grid>}
      {/* Tracking list table */}
      {trackingListDataItems?.length > 0
        && trackingListData?.products.length > 0
        && tableData?.length > 0
        && (!isEmptyOrUndefined(selectedSupplier, 'object') || selectedSupplier?.id !== null)
        && !loadingTrackingListData
        && !loadingTrackingListDataItems
        ?
        <Grid item xs={4} sm={8} md={12}>
          <DataTable
            rows={tableData}
            trackingListId={trackingListID}
            storesNames={storesNames}
            postTrackingListStores={postTrackingListStores}
            getTrackingListExcel={getTrackingListExcel}
          />
        </Grid>
        : (loadingTrackingListData || loadingTrackingListDataItems) ?
          <Grid item container justifyContent="center" alignItems="center" xs={4} sm={8} md={12}>
            <CircularLoader />
          </Grid>
          // Select information message
          : (!isEmptyOrUndefined(selectedSupplier, 'object') && trackingListDataItems?.length === 0) ?
            <Grid item xs={4} sm={8} md={12}>
              <Paper sx={{ p: 2 }}>
                <Typography textAlign={'center'}> {t('cws_app.tracking.empty_tracking_list', "There aren't any products in this tracking list.")}</Typography>
              </Paper>
            </Grid>
            :
            // No information to display message
            <Grid item xs={4} sm={8} md={12}>
              <Paper sx={{ p: 2 }}>
                <Typography textAlign={'center'}>{t('cws_app.general.select_information_to_display', 'Select Information to Display')}</Typography>
              </Paper>
            </Grid>
      }
    </Grid>
  )
}
