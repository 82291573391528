import { createReducer } from './utils';

import {
    MAILER_REQUEST,
    MAILER_AVAILABLE,
    MAILER_UNAVAILABLE,
    SNACK_SET,
} from './constants';

const defaultState = {
    loading_mailer_contact_info: false,
    loading_mailer_contacts: false,
    loading_mailer_tasks: false,
    mailerContacts: [],
    mailerTasks: [],
    mailerContactInfo: {},
};

export const reducer = createReducer(defaultState, {
    [MAILER_REQUEST]: handleCustomRequest,
    [MAILER_AVAILABLE]: handleCustomAvailable,
    [MAILER_UNAVAILABLE]: handleCustomUnavailable,
});

function handleCustomRequest(state, { payload: { loadingName } }) {
    return {
        ...state,
        [loadingName]: true,
    };
}

function handleCustomAvailable(state, { payload: { keyState, loadingName, requestInfo, aditionalStates = {} } }) {
    return {
        ...state,
        [loadingName]: false,
        [keyState]: requestInfo,
        ...aditionalStates,
    };
}

function handleCustomUnavailable(state, { payload: { keyState, loadingName } }) {
    return {
        ...state,
        [keyState]: [],
        [loadingName]: false,
    };
}

export function createMailerContact(name, email, tasks, stores){
    return async (dispatch, _, { services: { dataSource } }) => {
        dispatch({ type: MAILER_REQUEST, payload: { loadingName: 'loading_mailer_contact_info' } });
        try {
            const message = await dataSource.createMailerContact(name, email, tasks, stores);
            const snack = {
                open: true,
                message: 'contact created',
                severity: 'success',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: MAILER_UNAVAILABLE,
                payload: { error, loadingName: 'loading_mailer_contact_info' },
            });
            const snack = {
                open: true,
                message: 'Error in creating mailer contact',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    }
}

export function updateMailerContact(contact_id, name, tasks, stores) {
    return async (dispatch, _, { services: { dataSource } }) => {
        dispatch({ type: MAILER_REQUEST, payload: { loadingName: 'loading_mailer_contact_info' } });
        try {
            const message = await dataSource.updateMailerContact(contact_id, name, tasks, stores);
            const snack = {
                open: true,
                message: 'contact updated',
                severity: 'success',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: MAILER_UNAVAILABLE,
                payload: { error, loadingName: 'loading_mailer_contact_info' },
            });
            const snack = {
                open: true,
                message: 'Error in updating mailer contact',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    }
}

export function deleteMailerContact(contact_id) {
    return async (dispatch, _, { services: { dataSource } }) => {
        dispatch({ type: MAILER_REQUEST, payload: { loadingName: 'loading_mailer_contact_info' } });
        try {
            const message = await dataSource.deleteMailerContact(contact_id);
            const snack = {
                open: true,
                message: 'contact deleted',
                severity: 'success',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: MAILER_UNAVAILABLE,
                payload: { error, loadingName: 'loading_mailer_contact_info' },
            });
            const snack = {
                open: true,
                message: 'Error in deleting mailer contact',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    }
}

export function getMailerContacts() {
    return async (dispatch, _, { services: { dataSource } }) => {
        dispatch({ type: MAILER_REQUEST, payload: { loadingName: 'loading_mailer_contacts' } });
        try {
            const response = await dataSource.getMailerContacts();
            dispatch({
                type: MAILER_AVAILABLE,
                payload: { keyState: 'mailerContacts', loadingName: 'loading_mailer_contacts', requestInfo: response },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: MAILER_UNAVAILABLE,
                payload: { error, keyState: 'mailerContacts', loadingName: 'loading_mailer_contacts' },
            });
            const snack = {
                open: true,
                message: 'Error in getting mailer contacts',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    }
}

export function getMailerTasks() {
    return async (dispatch, _, { services: { dataSource } }) => {
        dispatch({ type: MAILER_REQUEST, payload: { loadingName: 'loading_mailer_tasks' } });
        try {
            const response = await dataSource.getMailerTasks();
            dispatch({
                type: MAILER_AVAILABLE,
                payload: { keyState: 'mailerTasks', loadingName: 'loading_mailer_tasks', requestInfo: response },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: MAILER_UNAVAILABLE,
                payload: { error, keyState: 'mailerTasks', loadingName: 'loading_mailer_tasks' },
            });
            const snack = {
                open: true,
                message: 'Error in getting mailer tasks',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    }
}

export function getMailerContactInfo(contact_id){
    return async (dispatch, _, { services: { dataSource } }) => {
        dispatch({ type: MAILER_REQUEST, payload: { loadingName: 'loading_mailer_contact_info' } });
        try {
            const response = await dataSource.getMailerContactInfo(contact_id);
            dispatch({
                type: MAILER_AVAILABLE,
                payload: { keyState: 'mailerContactInfo', loadingName: 'loading_mailer_contact_info', requestInfo: response },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: MAILER_UNAVAILABLE,
                payload: { error, keyState: 'mailerContactInfo', loadingName: 'loading_mailer_contact_info' },
            });
            const snack = {
                open: true,
                message: 'Error in getting mailer contact info',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    }
}