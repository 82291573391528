export const CREDENTIALS_REQUEST = 'CREDENTIALS_REQUEST';
export const CREDENTIALS_AVAILABLE = 'CREDENTIALS_AVAILABLE';
export const CREDENTIALS_UNAVAILABLE = 'CREDENTIALS_UNAVAILABLE';
export const CREDENTIALS_STORE = 'CREDENTIALS_STORE';
export const CREDENTIALS_CLEAR = 'CREDENTIALS_CLEAR';

export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
export const LOG_OUT_REQUEST = 'LOG_OUT_REQUEST';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const LOG_OUT_FAILURE = 'LOG_OUT_FAILURE';

export const SNACK_SET = 'SNACK_SET';

export const CUSTOM_AVAILABLE_EXCEL = 'CUSTOM_AVAILABLE_EXCEL';

// General
export const GENERAL_REQUEST = 'GENERAL_REQUEST';
export const GENERAL_AVAILABLE = 'GENERAL_AVAILABLE';
export const GENERAL_UNAVAILABLE = 'GENERAL_UNAVAILABLE';

// Dashboards
export const DASHBOARDS_REQUEST = 'DASHBOARDS_REQUEST';
export const DASHBOARDS_AVAILABLE = 'DASHBOARDS_AVAILABLE';
export const DASHBOARDS_UNAVAILABLE = 'DASHBOARDS_UNAVAILABLE';

export const DASHBOARDS_GENERAL_REQUEST = 'DASHBOARDS_GENERAL_REQUEST';
export const DASHBOARDS_GENERAL_AVAILABLE = 'DASHBOARDS_GENERAL_AVAILABLE';
export const DASHBOARDS_GENERAL_UNAVAILABLE = 'DASHBOARDS_GENERAL_UNAVAILABLE';

// CLIENTS
export const CLIENTS_REQUEST = 'CLIENTS_REQUEST';
export const CLIENTS_AVAILABLE = 'CLIENTS_AVAILABLE';
export const CLIENTS_UNAVAILABLE = 'CLIENTS_UNAVAILABLE';

export const CLIENT_REQUEST = 'CLIENT_REQUEST';
export const CLIENT_AVAILABLE = 'CLIENT_AVAILABLE';
export const CLIENT_UNAVAILABLE = 'CLIENT_UNAVAILABLE';

// USERS
export const USERS_REQUEST = 'USERS_REQUEST';
export const USERS_AVAILABLE = 'USERS_AVAILABLE';
export const USERS_UNAVAILABLE = 'USERS_UNAVAILABLE';

export const USERS_CACHE = 'USERS_CACHE';
export const USERS_CUSTOM_REQUEST = 'USERS_CUSTOM_REQUEST';
export const USERS_CUSTOM_AVAILABLE = 'USERS_CUSTOM_AVAILABLE';
export const USERS_CUSTOM_UNAVAILABLE = 'USERS_CUSTOM_UNAVAILABLE';
export const USER_CUSTOM_DASHBOARDS_AVAILABLE = 'USER_CUSTOM_DASHBOARDS_AVAILABLE';

export const USERS_STORES_REQUEST = 'USERS_STORES_REQUEST';
export const USERS_STORES_AVAILABLE = 'USERS_STORES_AVAILABLE';
export const USERS_STORES_UNAVAILABLE = 'USERS_STORES_UNAVAILABLE';

export const USER_CLIENT_SELECT = 'CLIENT_SELECT';

export const USER_REQUEST = 'USER_REQUEST';
export const USER_AVAILABLE = 'USER_AVAILABLE';
export const USER_UNAVAILABLE = 'USER_UNAVAILABLE';

// STORES
export const STORES_REQUEST = 'STORES_REQUEST';
export const STORES_AVAILABLE = 'STORES_AVAILABLE';
export const STORES_UNAVAILABLE = 'STORES_UNAVAILABLE';
export const STORE_FLOOR_AVAILABLE = 'STORE_FLOOR_AVAILABLE';

// TASKS
export const TASKS_REQUEST = 'TASKS_REQUEST';
export const TASKS_AVAILABLE = 'TASKS_AVAILABLE';
export const TASKS_UNAVAILABLE = 'TASKS_UNAVAILABLE';

// DOCUMENTATION
export const DOCUMENTATION_SUCCESS = 'DOCUMENTATION_SUCCESS';
export const DOCUMENTATION_FAILURE = 'DOCUMENTATION_FAILURE';
export const DOCUMENTATION_LOADING = 'DOCUMENTATION_LOADING';

export const CREDENTIAL_SUCCESS = 'CREDENTIAL_SUCCESS';
export const CREDENTIAL_FAILURE = 'CREDENTIAL_FAILURE';
export const CREDENTIAL_LOADING = 'CREDENTIAL_LOADING';

// DIGITAL STORE
export const DIGITAL_STORE_REQUEST = 'DIGITAL_STORE_REQUEST';
export const DIGITAL_STORE_AVAILABLE = 'DIGITAL_STORE_AVAILABLE';
export const DIGITAL_STORE_UNAVAILABLE = 'DIGITAL_STORE_UNAVAILABLE';
export const DIGITAL_STORE_AVAILABLE_PDF = 'DIGITAL_STORE_AVAILABLE_PDF';
export const DIGITAL_STORE_AVAILABLE_INV = 'DIGITAL_STORE_AVAILABLE_INV';
export const DIGITAL_STORE_AVAILABLE_EXCEL = 'DIGITAL_STORE_AVAILABLE_EXCEL';
export const DIGITAL_STORE_AVAILABLE_DATED_EXCEL =
  'DIGITAL_STORE_AVAILABLE_DATED_EXCEL';

// BACKEND
export const BACKEND_REQUEST = 'BACKEND_REQUEST';
export const BACKEND_AVAILABLE = 'BACKEND_AVAILABLE';
export const BACKEND_UNAVAILABLE = 'BACKEND_UNAVAILABLE';

// SUPPLIERS
export const SUPPLIERS_REQUEST = 'SUPPLIERS_REQUEST';
export const SUPPLIERS_AVAILABLE = 'SUPPLIERS_AVAILABLE';
export const SUPPLIERS_UNAVAILABLE = 'SUPPLIERS_UNAVAILABLE';

// LINKS
export const LINK_REQUEST = 'LINK_REQUEST';
export const LINK_AVAILABLE = 'LINK_AVAILABLE';
export const LINK_UNAVAILABLE = 'LINK_UNAVAILABLE';

// WALLS
export const WALL_REQUEST = 'WALL_REQUEST';
export const WALL_AVAILABLE = 'WALL_AVAILABLE';
export const WALL_UNAVAILABLE = 'WALL_UNAVAILABLE';
export const DIGITAL_SHELF_AVAILABLE_EXCEL = 'DIGITAL_SHELF_AVAILABLE_EXCEL';

//RESCHEDULING
export const RESCHEDULE_REQUEST = 'RESCHEDULE_REQUEST';
export const RESCHEDULE_AVAILABLE = 'RESCHEDULE_AVAILABLE';
export const RESCHEDULE_UNAVAILABLE = 'RESCHEDULE_UNAVAILABLE';

// TRACKING LIST
export const TRACKING_LIST_REQUEST = 'TRACKING_LIST_REQUEST';
export const TRACKING_LIST_AVAILABLE = 'TRACKING_LIST_AVAILABLE';
export const TRACKING_LIST_UNAVAILABLE = 'TRACKING_LIST_UNAVAILABLE';
export const TRACKING_LIST_AVAILABLE_EXCEL = 'TRACKING_LIST_AVAILABLE_EXCEL';

// PRODUCTS STATES
export const PRODUCTS_STATES_REQUEST = 'PRODUCTS_STATES_REQUEST';
export const PRODUCTS_STATES_AVAILABLE = 'PRODUCTS_STATES_AVAILABLE';
export const PRODUCTS_STATES_UNAVAILABLE = 'PRODUCTS_STATES_UNAVAILABLE';

// BRUNO USERS
export const BRUNO_REQUEST = 'BRUNO_REQUEST';
export const BRUNO_AVAILABLE = 'BRUNO_AVAILABLE';
export const BRUNO_UNAVAILABLE = 'BRUNO_UNAVAILABLE';

// MAILER
export const MAILER_REQUEST = 'MAILER_REQUEST';
export const MAILER_AVAILABLE = 'MAILER_AVAILABLE';
export const MAILER_UNAVAILABLE = 'MAILER_UNAVAILABLE';

// NOTIFICATIONS
export const NOTIFICATIONS_REQUEST = 'NOTIFICATIONS_REQUEST';
export const NOTIFICATIONS_AVAILABLE = 'NOTIFICATIONS_AVAILABLE';
export const NOTIFICATIONS_UNAVAILABLE = 'NOTIFICATIONS_UNAVAILABLE';


// PRODUCTS
export const PRODUCTS_REQUEST = 'PRODUCTS_REQUEST';
export const PRODUCTS_AVAILABLE = 'PRODUCTS_AVAILABLE';
export const PRODUCTS_UNAVAILABLE = 'PRODUCTS_UNAVAILABLE';


// TRANSLATIONS
export const TRANSLATIONS_REQUEST = 'TRANSLATIONS_REQUEST';
export const TRANSLATIONS_AVAILABLE = 'TRANSLATIONS_AVAILABLE';
export const TRANSLATIONS_UNAVAILABLE = 'TRANSLATIONS_UNAVAILABLE';
