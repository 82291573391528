import { createReducer } from './utils';
import { ExcelExporter } from '../services/excelExporter';

import {
    BACKEND_REQUEST,
    BACKEND_AVAILABLE,
    BACKEND_UNAVAILABLE,
    CUSTOM_AVAILABLE_EXCEL,
    SNACK_SET,
} from './constants';

const defaultState = {
    loading_tasks: false,
    loading_chains: false,
    loading_products: false,
    products_list: [],
    chainsList: [],
    taskList: [],
};

export const reducer = createReducer(defaultState, {
    [BACKEND_REQUEST]: handleCustomRequest,
    [BACKEND_AVAILABLE]: handleCustomAvailable,
    [BACKEND_UNAVAILABLE]: handleCustomUnavailable,
    [CUSTOM_AVAILABLE_EXCEL]: handleCustomAvailableExcel,
});


function handleCustomRequest(state, { payload: { loadingName } }) {
    return {
        ...state,
        [loadingName]: true,
    };
}

function handleCustomAvailable(state, { payload: { keyState, loadingName, requestInfo, aditionalStates = {} } }) {
    const x = {
        ...state,
        [loadingName]: false,
        [keyState]: requestInfo,
        ...aditionalStates,
    };
    return x
}

function handleCustomAvailableExcel(state, { payload: { loadingName, excelData, excelFileName, excelDate } }) {
    const x = {
        ...state,
        [loadingName]: false,
    };
    const excelExporter = new ExcelExporter();
    excelExporter.exportAsExcelFile({ excelData, excelFileName, excelDate })
    return x
}

function handleCustomUnavailable(state, { payload: { keyState, loadingName } }) {
    return {
        ...state,
        [keyState]: [],
        [loadingName]: false,
    };
}

export function getProducts(product_id = null) {
    return async (dispatch, _, { services: { dataSource } }) => {
        dispatch({ type: BACKEND_REQUEST, payload: { loadingName: 'loading_products' } });
        try {
            const productsResponse = await dataSource.getProducts(product_id);
            dispatch({
                type: BACKEND_AVAILABLE,
                payload: {
                    keyState: 'productsList',
                    loadingName: 'loading_products',
                    requestInfo: productsResponse.products_list,
                },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: BACKEND_UNAVAILABLE,
                payload: { error, keyState: 'productsList', loadingName: 'loading_products' },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getTasks(task_id = null) {
    return async (dispatch, _, { services: { dataSource } }) => {
        dispatch({ type: BACKEND_REQUEST, payload: { loadingName: 'loading_tasks' } });
        try {
            const tasksResponse = await dataSource.getTasks(task_id);
            dispatch({
                type: BACKEND_AVAILABLE,
                payload: { keyState: 'tasksList', loadingName: 'loading_tasks', requestInfo: tasksResponse.tasks_list },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: BACKEND_UNAVAILABLE,
                payload: { error, keyState: 'tasksList', loadingName: 'loading_tasks' },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getChains(chain_id = null) {
    return async (dispatch, _, { services: { dataSource } }) => {
        dispatch({ type: BACKEND_REQUEST, payload: { loadingName: 'loading_chains' } });
        try {
            const chainsResponse = await dataSource.getChains(chain_id);
            dispatch({
                type: BACKEND_AVAILABLE,
                payload: { keyState: 'chainsList', loadingName: 'loading_chains', requestInfo: chainsResponse.chains_list },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: BACKEND_UNAVAILABLE,
                payload: { error, keyState: 'chainsList', loadingName: 'loading_chains' },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getShareOfShelfExcel({ supplier_id, rubro_name, excelDate }) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loading_excel_sos'
        // const keyState = 'dashboardInfo'

        dispatch({ type: BACKEND_REQUEST, payload: { loadingName } });
        try {
            const response = await dataSource.getShareOfShelfExcel({ supplier_id, rubro_name, excelDate });
            dispatch({
                type: CUSTOM_AVAILABLE_EXCEL,
                payload: { loadingName: loadingName, excelData: response.excel_data, excelFileName: 'SHARE OF SHELF' },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: BACKEND_UNAVAILABLE,
                payload: { error, loadingName },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}
