import { Button, Divider, Grid, Fab, Paper, Typography, Box, Drawer, IconButton } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Fragment, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

// Icons
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

const StyledPaper = styled(props => {
    return (
        <Paper
            elevation={0}
            sx={{ pb: 2 }}
            {...props}
        />
    )
}
)(({ theme }) => {
    return ({
        background: theme.palette.type === 'light' ? theme.palette.primary.paper : theme.palette.paper.main,
        color: theme.palette.type === 'light' ? theme.palette.primary.main : 'white',
        '& .MuiPaper-root': {
            color: theme.palette.type === 'light' ? theme.palette.primary.main : 'white',
        },
    })
}
);

export default function QuickAccess({ open, setOpen, sectionsView, getShareOfShelfExcel, supplier_id, drawerWidth = 350 }) {
    const { t } = useTranslation();
    let navigate = useNavigate();

    const handleClick = (view) => {
        const path = view.path;
        const isExternalPath = view.isExternalPath;

        if (isExternalPath) {
            window.location.href = path;
        } else if (!isExternalPath && path) {
            navigate(path);
        } else {
            getShareOfShelfExcel({ supplier_id });
        }
    }

    return (
        <>
            <Box item xs={12} sx={{ mx: 0, mb: 0, mt: 1, height: '1.1em', position: 'relative', left: '0.85em', top: '2em' }} onClick={() => setOpen(!open)}>
                <IconButton size="small" disableElevation sx={{ backgroundColor: 'white' }} aria-label="go_back">
                    {open ?
                        <ArrowForwardIosRoundedIcon /> :
                        <ArrowBackIosRoundedIcon />
                    }
                </IconButton>
            </Box>
            <Box
                sx={{
                    width: open ? drawerWidth : 0
                    , overflow: 'hidden'
                    , transition: 'width 0.25s'
                }}
                role="presentation"
            >
                <StyledPaper>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-end"
                        mb={2}
                        sx={{ visibility: open ? 'visible' : 'hidden', transition: 'visibility 0.3s' }}
                    >
                        {sectionsView.map((view, index) => (
                            <Fragment key={index}>
                                <Grid item xs={12} ml={2} mt={2}>
                                    <Typography variant="h4">{t(`cws_app.general.${view.description}`)}</Typography>
                                </Grid>
                                <Grid item xs={12} ml={2}>
                                    <Typography sx={{ fontWeight: 500, fontSize: 'smaller', color: '#74748a' }} gutterBottom>{t(`cws_app.general.${view.description}_brief_summary`)}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {view?.preview_image ? <img src={view.preview_image} alt={view.title} style={{ maxWidth: '-webkit-fill-available', borderRadius: '4px', margin: '10px' }} /> : <></>}
                                </Grid>
                                <Grid item xs={12} my={2}>
                                    <Button variant="outlined" sx={{ display: 'flex', mx: 'auto' }} onClick={() => { handleClick(view) }}>{view?.path ? t('cws_app.general.go') : t('cws_app.general.Download')}</Button>
                                </Grid>
                                <Divider variant="middle" style={{ background: '#1a242d4d', width: '100%' }} />
                            </Fragment>

                        ))}
                    </Grid>
                </StyledPaper>
            </Box>
        </>
    );
}
