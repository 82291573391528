import {
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import React from 'react';
import Subsection from '../../../redux/containers/features/navbar/Subsection';
import { Link as RouterLink } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

// Icons
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// Styled components
const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const drawerWidth = 240;

const styles = {
  itemButton: {
    minHeight: 48,
    px: 2.5,
  },
  itemIcon: {
    minWidth: 0,
    justifyContent: 'center',
  },
  icon: {
    color: 'white',
    fontSize: '25',
  },
  text: {
    color: 'white',
    fontSize: 14,
    fontWeigh: 400,
  },
  divider: {
    background: '#cacadc',
    marginTop: 8,
    marginBottom: 8,
    width: '-webkit-fill-available',
  },
};

export default function SideMenu({ open, handleDrawer, DrawerHeader, isDarkTheme, sections }) {
  const { t } = useTranslation();

  return (
    <Drawer
      variant="permanent"
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: isDarkTheme ? 'paper.main' : 'primary.main',
        },
      }}>
      {/* Menu close button */}
      <DrawerHeader>
        <IconButton onClick={handleDrawer}>
          {!open ? (
            <ChevronRightIcon style={styles.icon} />
          ) : (
            <ChevronLeftIcon style={styles.icon} />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider style={{ background: open ? '#131b22' : 'inherit' }} />

      <div className="yScrollBarWrapper-white" style={{ overflowX: 'hidden' }}>
        {/* Views navigation */}
        <List>
          {sections.general.basic &&
            Object.values(sections.general.basic).map((viewInfo, index) => (
              viewInfo?.subSection?.length ?
                <Subsection
                  styles={styles}
                  forceClose={!open}
                  key={viewInfo.description + viewInfo.path}
                  isNewView={viewInfo.description.includes('new') ? true : false}
                  isLegacyView={viewInfo.description === 'dashboards' ? true : false} // TODO: delete on deprecation of cpg
                  subSections={viewInfo.subSection}
                  subSectionIcon={viewInfo.icon_name}
                  subSectionDescription={viewInfo.description} // TODO: delete on deprecation of cpg
                  subSectionName={t(
                    `cws_app.general.${viewInfo.description}`,
                  )}
                  secondarySectionDescription={t(
                    `cws_app.general.${viewInfo.description}_description`,
                    viewInfo.description === 'dashboards' ? 'Power BI' : 'Secondary section sublist', // TODO: delete on deprecation of cpg
                  )}
                  canShowItemscolapsed={false}
                />
                :
                viewInfo.isExternalPath ?
                  <ListItem
                    key={viewInfo.description}
                    disablePadding
                    sx={{ display: 'block' }}>
                    <Link href={viewInfo.path} sx={{ textDecoration: 'none' }}>
                      <ListItemButton
                        sx={[
                          styles.itemButton,
                          { justifyContent: open ? 'flex-end' : 'center' },
                        ]}>
                        <ListItemIcon
                          sx={[styles.itemIcon, { mr: open ? 3 : 'auto' }]}>
                          <viewInfo.IconTag style={styles.icon} />
                        </ListItemIcon>
                        <ListItemText
                          style={styles.text}
                          primary={t(
                            `cws_app.general.${viewInfo.description}`,
                          )}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                  :
                  <RouterLink key={`RL-${viewInfo.description}`} to={viewInfo.path} style={{ textDecoration: 'none' }}>
                    <ListItem
                      disablePadding
                      sx={{ display: 'block' }}>
                      <ListItemButton
                        sx={[
                          styles.itemButton,
                          { justifyContent: open ? 'flex-end' : 'center' },
                        ]}>
                        <ListItemIcon
                          sx={[styles.itemIcon, { mr: open ? 3 : 'auto' }]}>
                          <viewInfo.IconTag style={styles.icon} />
                        </ListItemIcon>
                        <ListItemText
                          style={styles.text}
                          primary={t(
                            `cws_app.general.${viewInfo.description}`,
                          )}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </RouterLink>
            ))
          }

          <Divider variant="middle" style={styles.divider} />

          {Object.keys(sections.admin).length ?
            Object.entries(sections.admin).map(([sectionName, section]) => (
              <Subsection
                styles={styles}
                forceClose={!open}
                key={sectionName}
                subSections={section}
                subSectionName={t(
                  `cws_app.general.${sectionName}`,
                )}
                secondarySectionDescription={t(
                  `cws_app.general.${sectionName}_description`,
                  'Section sublist',
                )}
              />
            )) : null
          }
        </List>
      </div>
    </Drawer>
  );
}
