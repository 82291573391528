import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@mui/material';

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export default function FeaturesTransferList(props) {
  const {
    theme,
    feature,
    left,
    setLeft,
    right,
    setRight,
    featuresToSave,
    setFeaturesToSave,
  } = props;
  const { t } = useTranslation();
  const [checked, setChecked] = useState([]);
  const [openSublistLeft, setOpenSublistLeft] = useState([]);
  const [openSublistRight, setOpenSublistRight] = useState([]);
  const [inputValueLeft, setInputValueLeft] = useState('');
  const [inputValueRight, setInputValueRight] = useState('');

  // Lifecycle methods

  useEffect(() => {
    if (left) {
      setOpenSublistLeft(left.map(() => false));
    }
  }, [left]);

  useEffect(() => {
    if (right) {
      setOpenSublistRight(right.map(() => false));
    }
  }, [right]);

  // Methods

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  // Toggle all stores in the list
  const handleToggleAll = (items) => {
    const newChecked = [...checked];
    const isAllChecked = checkChains(items);
    items.forEach(item => {
      if (isAllChecked) {
        item?.stores?.forEach(store => {
          const currentIndex = newChecked.indexOf(store);
          if (currentIndex !== -1) {
            newChecked.splice(currentIndex, 1);
          }
        })
      } else {
        item?.stores?.forEach(store => {
          const currentIndex = newChecked.indexOf(store);
          if (currentIndex === -1) {
            newChecked.push(store);
          }
        })
      }
    })
    setChecked(newChecked);
  };

  // Check/uncheck all stores in all chains (add/remove all stores from the list 'checked')
  const handleChainToggle = (value) => {
    let newChecked = [...checked];
    const isAllChecked = checkStores(value);
    if (isAllChecked) {
      value?.stores?.forEach(store => {
        const currentIndex = newChecked.indexOf(store);
        if (currentIndex !== -1) {
          newChecked.splice(currentIndex, 1);
        }
      })
    } else {
      value?.stores?.forEach(store => {
        const currentIndex = newChecked.indexOf(store);
        if (currentIndex === -1) {
          newChecked.push(store);
        }
      })
    }
    setChecked(newChecked);
  }

  // Check if all stores are checked in all chains
  const checkChains = (items) => {
    const isAllChecked = items?.map(chain => {
      return checkStores(chain);
    });
    if (isAllChecked.includes(false) || isAllChecked.length === 0) {
      return false;
    }
    return true;
  }

  // Check if all stores are checked in the chain, i.e. the store is in the list 'checked'
  const checkStores = (value) => {
    const isAllChecked = value?.stores?.every(store => checked.includes(store));
    return isAllChecked;
  }

  // Send items to the right list
  const handleCheckedRight = () => {
    const newLeft = []
    const newRight = right
    let newFeaturesToSave = featuresToSave;

    left.forEach(chain => {
      const storesToAdd = []
      const storesToLeave = []
      // Differentiate between stores that are checked and stores that are not
      chain.stores.forEach(store => {
        if (checked.includes(store)) {
          const newStore = {
            ...store,
            chain: chain.chain_name,
            store: store.store_code,
            feature_id: feature.feature_id,
          }
          storesToAdd.push(newStore);
        } else {
          storesToLeave.push(store);
        }
      });
      if (storesToAdd.length > 0) {
        // Get the index of the chain in the right list (if it exists)
        let rightIndex = -1
        newRight.forEach((rightChain, index) => {
          if (rightChain.chain_name === chain.chain_name) {
            rightIndex = index;
          }
        });
        // If the chain is already in the right list, add the stores to the excisting chain
        if (rightIndex != null && rightIndex !== -1) {
          const newChain = {
            ...chain,
            stores: storesToAdd.concat(newRight[rightIndex].stores),
          }
          newRight[rightIndex] = newChain;
        } else {
          newRight.push({
            ...chain,
            stores: storesToAdd,
          });
        }
        // Add the new items to the overall list of features (featuresToSave)
        newFeaturesToSave.push(...storesToAdd);
      }
      if (storesToLeave.length > 0) {
        newLeft.push({
          ...chain,
          stores: storesToLeave,
        });
      }
    });

    setRight(newRight);
    setLeft(newLeft);
    setChecked([]);
    setFeaturesToSave(newFeaturesToSave);
  };

  // Send items to the left list
  const handleCheckedLeft = () => {
    const newRight = []
    const newLeft = left
    let newFeaturesToSave = featuresToSave.filter((featureToSave) => featureToSave.feature_id !== feature.feature_id);

    right.forEach(chain => {
      const storesToAdd = []
      const storesToLeave = []
      // Differentiate between stores that are checked and stores that are not
      chain.stores.forEach(store => {
        if (checked.includes(store)) {
          const newStore = {
            ...store,
            chain: chain.chain_name,
            store: store.store_code,
            feature_id: feature.feature_id,
          }
          storesToAdd.push(newStore);
        } else {
          storesToLeave.push(store);
        }
      });
      if (storesToAdd.length > 0) {
        // Get the index of the chain in the left list (if it exists)
        let leftIndex = -1
        newLeft.forEach((leftChain, index) => {
          if (leftChain.chain_name === chain.chain_name) {
            leftIndex = index;
          }
        });
        // If the chain is already in the left list, add the stores to the excisting chain
        if (leftIndex != null && leftIndex !== -1) {
          const newChain = {
            ...chain,
            stores: storesToAdd.concat(newLeft[leftIndex].stores),
          }
          newLeft[leftIndex] = newChain;
        } else {
          newLeft.push({
            ...chain,
            stores: storesToAdd,
          });
        }
      }
      if (storesToLeave.length > 0) {
        newRight.push({
          ...chain,
          stores: storesToLeave,
        });
        // Add only the stores to keep in the 'right' list in the overall list of features (featuresToSave)
        newFeaturesToSave.push(...storesToLeave);
      }
    });

    setLeft(newLeft);
    setRight(newRight);
    setChecked([]);
    setFeaturesToSave(newFeaturesToSave);
  };

  // Open and close sublists
  const handleOpenSublist = (e, index, openSublist, setOpenSublist) => {
    e.stopPropagation();
    const newOpenSublists = [...openSublist];
    newOpenSublists[index] = !openSublist[index];
    setOpenSublist(newOpenSublists);
  }

  // Intersect 'left' and 'checked', where left is the list of chains and checked is the list of stores
  const leftChecked = () => {
    const leftCheckedList = []
    left?.forEach(chain => {
      const checkedStores = intersection(checked, chain?.stores);
      if (checkedStores?.length > 0) {
        leftCheckedList.push(...checkedStores);
      }
    });
    return leftCheckedList
  }

  // Intersect 'right' and 'checked', where right is the list of chains and checked is the list of stores
  const rightChecked = () => {
    const rightCheckedList = []
    right?.forEach(chain => {
      const checkedStores = intersection(checked, chain?.stores);
      if (checkedStores?.length > 0) {
        rightCheckedList.push(...checkedStores);
      }
    });
    return rightCheckedList
  }

  const customList = (title, items, openSublist, setOpenSublist, inputValue, setInputValue) => {
    const numberOfStores = items?.reduce((acc, chain) => {
      return acc + chain?.stores?.length;
    }, 0);
    const numberOfCheckedStores = items?.reduce((acc, chain) => {
      return acc + chain?.stores?.filter(store => checked.includes(store))?.length;
    }, 0);
    // Filter the stores in items based on inputValue
    let filteredItems = items?.filter(chain => chain.stores.length > 0);
    filteredItems = filteredItems?.map(chain => {
      const filteredStores = chain?.stores?.filter(store => {
        return store?.parsed_name?.toLowerCase().includes(inputValue?.toLowerCase());
      })
      return {
        ...chain,
        stores: filteredStores,
      }
    })


    return (
      <Card>
        {/* Select all checkbox and display number of selected items by list */}
        <CardHeader
          sx={{ px: 2, py: 1 }}
          avatar={
            <Checkbox
              onClick={() => handleToggleAll(filteredItems)}
              checked={checkChains(filteredItems)}
              // indeterminate={
              //   numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
              // }
              disabled={filteredItems.length === 0}
              inputProps={{
                'aria-label': 'all items selected',
              }}
            />
          }
          title={title}
          subheader={`${numberOfCheckedStores}/${numberOfStores} ${t('cws_app.general.stores_selected', 'stores selected')}`}
        />
        <Divider />
        {/* Search bar */}
        <TextField
          fullWidth
          variant='standard'
          id='search'
          label={t('cws_app.general.search', 'Search')}
          value={inputValue}
          onChange={(event) => {
            setInputValue(event.target.value);
          }}
          sx={{ mx: '2em', mt: '0.5em' }}
        />
        <List
          sx={{
            width: 400,
            height: 230,
            bgcolor: 'background.paper',
            overflow: 'auto',
          }}
          dense
          component="div"
          role="list"
        >
          {/* Chains loop */}
          {filteredItems.map((value, index) => {
            if (value.stores.length === 0) return null; // If the chain has no stores, don't display it
            const labelId = `transfer-list-all-item-${value['chain_country_id']}-label`;
            return (
              <Box key={Math.random()}>
                <ListItemButton
                  key={labelId}
                  role="listitem"
                  onClick={() => handleChainToggle(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checkStores(value)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`${value['chain_name']}`} />
                  <IconButton
                    aria-label="expand"
                    onClick={(e) => handleOpenSublist(e, index, openSublist, setOpenSublist)}
                  >
                    <IconComponent
                      iconName={openSublist[index] ? 'chevron-up' : 'chevron-down'}
                      style={{ fontSize: '20px' }}
                    />
                  </IconButton>
                </ListItemButton>
                {/* Stores loop */}
                {openSublist[index] &&
                  value?.stores.map((store) => {
                    const labelId = `transfer-list-all-item-${store['store_code']}-label`;
                    return (
                      <ListItemButton
                        key={store['store_code']}
                        role="listitem"
                        onClick={handleToggle(store)}
                        sx={{ marginLeft: '3em' }}
                      >
                        <ListItemIcon>
                          <Checkbox
                            checked={checked.indexOf(store) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={`${store['parsed_name']}`} />
                      </ListItemButton>
                    )
                  })
                }
              </Box>
            );
          })}
        </List>
      </Card>
    )
  };

  return (
    <Grid container item justifyContent='space-between' alignItems='center' sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' }, overflowY: 'scroll', p: 1 }}>
      <Grid item>{customList(t('cws_app.general.choices', 'Choices'), left, openSublistLeft, setOpenSublistLeft, inputValueLeft, setInputValueLeft)}</Grid>
      <Grid item>
        <Grid container direction='column' alignItems='center'>
          <Button
            sx={{ m: 0.5 }}
            variant='outlined'
            size='small'
            onClick={handleCheckedRight}
            disabled={leftChecked().length === 0}
            aria-label='move selected right'
          >
            &gt;
          </Button>
          <Button
            sx={{ m: 0.5 }}
            variant='outlined'
            size='small'
            onClick={handleCheckedLeft}
            disabled={rightChecked().length === 0}
            aria-label='move selected left'
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(t('cws_app.general.chosen', 'Chosen'), right, openSublistRight, setOpenSublistRight, inputValueRight, setInputValueRight)}</Grid>
    </Grid>
  );
}
