import React, { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import CircularLoader from './CircularLoader'
import useCanvas from './useCanvas'

export default function Canvas(props) {
  const { draw, loading, src, alt = 'Canvas', width = '100%', height = 'auto', containerID = 'containerID', ...rest } = props
  const canvasRef = useCanvas(draw)[0]
  const { t } = useTranslation();

  // TODO: Add lazy loading to the children of Suspense for it to work
  return (
    <>
      {loading ? <CircularLoader />
        :
        <Suspense fallback={<CircularLoader />}>
          {src ?
            <Grid container position='relative' width='100%' id={containerID} sx={{ overflowX: 'scroll', overflowY: 'hidden' }}>
              <img src={src} alt={alt} />
              <canvas
                ref={canvasRef}
                {...rest}
                tabIndex='1'
                width={width}
                height={height}
                style={{ 'outline': 'none', position: 'absolute' }}
              />
            </Grid>
            :
            <Typography>{t('cws_app.general.image_not_found', 'Image not found')}</Typography>
          }
        </Suspense>
      }
    </>
  )
}
