import { useEffect, useState } from "react";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

// Icons
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';

import { BarChart } from '@zippeditoolsjs/dashboards';

// components
import StyledPaper from "../../tools/StyledPaper";
import AreaGraph from './AreaGraph';
import BarGraph, { dataFormatter } from './BarGraph';
import MetricTool from './MetricTool';

import {
	Card,
	Flex,
	Metric, Text
} from "@tremor/react";
import { isEmptyOrUndefined } from "@zippeditoolsjs/blocks";
import { getConsolidatedTranslations, toSnakeCase } from 'core/utils/parsers';
import AlertBox from '../../tools/AlertBox';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { formatToExcel } from '../../utils/parsers';
import { format } from '../../utils/converters';


export default function Metrics(props) {
	const { title, value, subtitle, value_subtitle
		, chart_data_by_date, chart_index_by_date, chart_title_by_date
		, trendFlag, metric_type, chart_data, chart_index, chart_title
		, metric_info, selectedClientName = '', totalComponents, openQuickAccess
		, numberFormat = 'en-US', vis_type = 'bar', downloadExcel, isLoadingExcelDownload
		, metricStyle = {}, currencySymbol, clientFormats,
	} = props;
	const { t, i18n } = useTranslation();
	const [expanded, setExpanded] = useState(false);
	const [isLineChart, setIsLineChart] = useState(false);
	const [data, setData] = useState([]);

	useEffect(() => {
		const parsed_chart_data = chart_data ? JSON.parse(chart_data) : [];
		if (!isEmptyOrUndefined(parsed_chart_data, 'array') && vis_type === 'kpi_bar_metric') {
			const data = parsed_chart_data.map(row => {
				const parsedRow = {};
				for (const [key, value] of Object.entries(row)) {
					const parsedKey = t(`cws_app.dashboards.${key}`, key);
					parsedRow[parsedKey] = value;
				}
				return parsedRow;
			});
			setData(data);
		}
	}, [chart_data, vis_type]);


	const excelDataExtractor = () => {
		const excelDataList = [];
		if (vis_type === 'kpi_metric') {
			excelDataList.push(formatToExcel(JSON.parse(chart_data_by_date ?? '[{}]')));
			excelDataList.push(formatToExcel(JSON.parse(chart_data ?? '[{}]'), 'store'));
		} else if (vis_type === 'kpi_bar_metric') {
			const chartData = data ?? [];
			chartData?.map(row => {
				delete row.x
				return row;
			});
			excelDataList.push(formatToExcel(chartData));
		}
		return excelDataList;
	}

	const handleIndividualDownload = () => {
		const excelDataList = excelDataExtractor();
		const excelDate = format(new Date(), i18n.language, clientFormats.date_format);
		downloadExcel(excelDataList, toSnakeCase(title.slice(0, 28)), false, true, [], excelDate);
	}

	const checkDownloadDisabled = () => {
		const excelDataList = excelDataExtractor();
		return excelDataList.length === 0;
	}

	return (
		<Grid item xs={12} md={12}
			lg={expanded ? 12 : 12 / totalComponents >= 4 ? 12 / totalComponents : openQuickAccess ? 6 : 4}
			xl={expanded ? 12 : 12 / totalComponents >= 4 ? 12 / totalComponents : 4}>
			<StyledPaper
				elevation={6} sx={{ maxHeight: 285, minHeight: 138 }}>
				<Card className="w-full h-full card-animation" style={{ background: 'transparent' }}>
					<Flex alignItems="start">
						{/* TITLE */}
						<Box sx={{ width: 'auto', maxWidth: '65%' }}>
							<Text>
								<Tooltip title={t(`cws_app.general.${title}`, title)} enterDelay={300} placement="top">
									<Typography variant="subtitle2" className="one-line-text">
										{getConsolidatedTranslations(t, selectedClientName, title, 'general', title)}
									</Typography>
								</Tooltip>
								{
									vis_type !== 'kpi_bar_metric' && (chart_data || chart_data_by_date) ?
										<Metric>
											{numberFormat && numberFormat.length > 0 ? value.toLocaleString(numberFormat) : value}{metric_type === 'percent' ? '%' : ''}
										</Metric>
										: null
								}
							</Text>
						</Box>

						<Box sx={{ flexGrow: 1 }} />
						<Box sx={{ display: 'flex', justifyContent: 'flex-end', maxWidth: '10em' }}>
							{/* FLAGS */}
							<Box sx={{ display: 'flex', overflow: 'hidden' }}>
								<IconButton loading={isLoadingExcelDownload} disabled={checkDownloadDisabled()} onClick={() => handleIndividualDownload()} sx={{ p: 0, top: '-2px' }}>
									<IconComponent
										iconName={'download-outline'}
										style={{ fontSize: '24px' }}
									/>
								</IconButton>
							</Box>

							{trendFlag === 'worsens' ?
								<CancelRoundedIcon color="error" />
								: trendFlag === 'improves' ?
									<CheckCircleRoundedIcon color="success" size="xs" />
									: trendFlag === 'stays_same' ?
										<RemoveCircleRoundedIcon color="warning" />
										: null
							}
							{/* TOOLS */}
							<MetricTool
								metric_info={metric_info}
								selectedClientName={selectedClientName}
								expanded={expanded}
								setExpanded={setExpanded}
								isLineChart={isLineChart}
								setIsLineChart={setIsLineChart}
								noSwap={vis_type === 'kpi_bar_metric'}
							/>
						</Box>


					</Flex>
					{chart_data && !isLineChart && vis_type !== "kpi_bar_metric" ?
						<BarGraph
							selectedClientName={selectedClientName}
							indexInput={chart_index}
							metric_type={metric_type}
							keysInput={[chart_title]}
							data={JSON.parse(chart_data)}
							expanded={expanded}
							numberFormat={numberFormat}
							colorsInput={JSON.parse(metricStyle)?.colors}
							currencySymbol={currencySymbol}
						/>
						: !isEmptyOrUndefined(data, 'array') && vis_type === "kpi_bar_metric" ?
							<BarChart
								className="h-40 mt-8"
								data={data}
								index="x"
								categories={Object.keys(data?.[0]).filter(key => key !== 'x')}
								valueFormatter={(number) => dataFormatter(number, metric_type, numberFormat)}
								layout={'vertical'}
								stack={true}
								showYAxis={false}
								yAxisHeight={40}
								colors={JSON.parse(metricStyle)?.colors}
							/>
							: chart_data_by_date ?
								<AreaGraph
									selectedClientName={selectedClientName}
									indexInput={chart_index_by_date}
									metric_type={metric_type}
									keysInput={[chart_title_by_date]}
									data={JSON.parse(chart_data_by_date)}
									inputFormat={clientFormats?.partial_date_format}
									numberFormat={numberFormat}
									colorsInput={JSON.parse(metricStyle)?.colors}
								/> :
								<AlertBox
									content={t('cws_app.general.No_data', 'No data to display.')}
								/>
					}

				</Card>
			</StyledPaper >
		</Grid >
	);
}
