import { useEffect, useState } from 'react'
import { Grid } from '@mui/material'

import { isEmptyOrUndefined, Selector } from "@zippeditoolsjs/blocks";

import DatePicker from '../../tools/DatePicker'
import PageLink from '../../tools/PageLink';
import { translateValueInList } from 'core/utils/parsers';
import { ZIPPEDI_CLIENT_ID } from 'core/utils/constants';
import { checkFormat } from '../../utils/converters';

export default function MapSelectors(props) {
	const {
		getUserStores,
		isLoadingUserStores,
		isLoadingTaskTypes,
		isLoadingCategories,
		isLoadingMaps,
		userStores,
		selectedClient,
		getTaskTypes,
		taskTypes,
		getStoreCategories,
		storeCategories,
		t,
		linkReceived,
		date,
		setDate,
		selectedStore,
		setSelectedStore,
		selectedCategory,
		setSelectedCategory,
		selectedStoreTaskType,
		setSelectedStoreTaskType,
		setIsSidenavOpen,

		setRobotSession,
		setSelectedAlert,
		setGroupColumnInfo,
		supplier,
		setSupplierState,
		getUserSuppliers,
		suppliers,
		isLoadingSuppliers,
		clientFormats,
	} = props;
	const [translatedTasks, setTranslatedTasks] = useState([]);
	// Lifecycle methods

	// Reset everything if the client or date changes
	useEffect(() => {
		setIsSidenavOpen(false);
		setRobotSession(null);
		setSelectedAlert(null);
		setGroupColumnInfo(null);
	}, [selectedClient, date]);

	// Get stores
	useEffect(() => {
		if (!isEmptyOrUndefined(selectedClient, 'object')) {
			getUserStores(selectedClient.client_id);
			getTaskTypes(selectedClient.client_id)
		}
	}, [selectedClient]);

	// Get tasks and categories of the store
	useEffect(() => {
		setSelectedCategory({ value: null, category_name: 'All' });
		// Reset if the store changes
		if (selectedStoreTaskType?.task_id) {
			const taskInStore = taskTypes.find(task => task['task_id'] === selectedStoreTaskType['task_id']);
			if (!taskInStore) {
				setSelectedStoreTaskType(null);
			}
		}
		setIsSidenavOpen(false);
		setRobotSession(null);
		setSelectedAlert(null);
		setGroupColumnInfo(null);
	}, [selectedStore])

	// Translate the tasks depending on the chain
	useEffect(() => {
		if (!isEmptyOrUndefined(selectedStore, 'object') && !isEmptyOrUndefined(taskTypes, 'array')) {
			setTranslatedTasks(translateValueInList(taskTypes, t, selectedStore['chain_name'], 'task_name', 'digital_store'))
			getStoreCategories(selectedStore['store_id'], selectedClient.client_id, false, supplier?.id || selectedClient?.supplier_id)
		}
	}, [selectedStore, taskTypes])

	// Reset if the task changes
	useEffect(() => {
		setIsSidenavOpen(false);
		setRobotSession(null);
		setSelectedAlert(null);
		setGroupColumnInfo(null);
	}, [selectedStoreTaskType, supplier])

	// Set selectors if the state comes from a shared link
	useEffect(() => {
		if (!isEmptyOrUndefined(linkReceived, 'object')) {
			if (linkReceived.date) {
				setDate(new Date(linkReceived['date']))
			} else if (linkReceived.rawDate) {
				const rawDate = linkReceived.rawDate;
				setDate(new Date(`${rawDate.slice(0, 4)}/${rawDate.slice(4, 6)}/${rawDate.slice(6, 8)}`));
			}
		}
	}, [linkReceived])
	useEffect(() => {
		if (!isEmptyOrUndefined(linkReceived, 'object') && !isEmptyOrUndefined(userStores, 'array')) {
			if (linkReceived.selectedStore) {
				setSelectedStore(linkReceived['selectedStore'])
			} else if (linkReceived.storeCode) {
				const store = userStores.find(store => store['store_code'] === linkReceived['storeCode']);
				if (store) {
					setSelectedStore(store)
				}
			}
		}
	}, [linkReceived, userStores])
	useEffect(() => {
		if (!isEmptyOrUndefined(linkReceived, 'object') && !isEmptyOrUndefined(translatedTasks, 'array')) {
			if (linkReceived.selectedStoreTaskType) {
				setSelectedStoreTaskType(linkReceived['selectedStoreTaskType'])
			} else if (linkReceived.taskName) {
				const task = translatedTasks.find(task => task['task_name'] === linkReceived['taskName']);
				if (task) {
					setSelectedStoreTaskType(task)
				}
			}
		}
	}, [linkReceived, translatedTasks])
	useEffect(() => {
		if (!isEmptyOrUndefined(linkReceived, 'object') && !isEmptyOrUndefined(storeCategories, 'array')) {
			setSelectedCategory(linkReceived['selectedCategory'])
		}
	}, [linkReceived, storeCategories])

	useEffect(() => {
		setSupplierState(selectedClient?.supplier_id ? { name: selectedClient.name, id: selectedClient.supplier_id } : null);
		if (!isEmptyOrUndefined(selectedStore, 'object')) {
			getUserSuppliers(selectedClient.client_id, [selectedStore.chain_name]);
		}
	}, [selectedStore])

	// Set the supplier_id with the selected client's id when the client is not zippedi
	useEffect(() => {
		if (selectedClient?.client_id !== ZIPPEDI_CLIENT_ID) {
			setSupplierState(selectedClient?.supplier_id ? { name: selectedClient.name, id: selectedClient.supplier_id } : null);
		}
	}, [selectedClient?.client_id])

	// Methods
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={12}>
				<Grid container spacing={{ xs: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
					<Grid item xs={4} sm={2} md={2}>
						<DatePicker date={date} setDate={setDate} disabled={isLoadingMaps} inputFormat={checkFormat(clientFormats?.date_format ?? 'yyyy-MM-dd')} />
					</Grid>
					{/* Store selector */}
					<Grid item xs={4} sm={4} md={4}>
						<Selector
							label={t("cws_app.general.sel_store")}
							options={userStores}
							name={'parsed_name'}
							loading={isLoadingUserStores}
							value={selectedStore}
							setValue={setSelectedStore}
							disabled={isLoadingMaps}
							disableClearable={false}
						/>
					</Grid>
					{/* Task selector */}
					<Grid item xs={12} md={3}>
						<Selector
							label={t("cws_app.general.sel_task")}
							options={translatedTasks}
							name={'parsed_name'}
							loading={isLoadingTaskTypes}
							value={selectedStoreTaskType}
							setValue={setSelectedStoreTaskType}
							disabled={!selectedStore || isLoadingMaps}
							disableClearable={false}
						/>
					</Grid>
					{/* Supplier selector */}
					{!selectedClient?.supplier_id ?
						<Grid item xs={12} md={3} lg={3} px={1}>
							<Selector
								label={t("cws_app.general.select_supplier")}
								options={suppliers}
								name={'name'}
								loading={isLoadingSuppliers}
								value={supplier}
								setValue={setSupplierState}
								disabled={isEmptyOrUndefined(selectedStore, 'object') || isEmptyOrUndefined(suppliers, 'array') || isLoadingSuppliers || isLoadingUserStores || isLoadingTaskTypes || isLoadingCategories || isLoadingMaps}
								disableClearable={false}
							/>
						</Grid>
						: null
					}
					{/* Category selector */}
					{!isEmptyOrUndefined(storeCategories, 'array') && !isEmptyOrUndefined(selectedStoreTaskType, 'object') &&
						<Grid item xs={12} md={3}>
							<Selector
								label={t("cws_app.general.sel_category")}
								options={storeCategories}
								name={'category_name'}
								loading={isLoadingCategories}
								value={selectedCategory}
								setValue={setSelectedCategory}
								disabled={isLoadingMaps}
								disableClearable={false}
							/>
						</Grid>
					}
					{/* Link button */}
					<Grid container item xs={12} md justifyContent={{ xs: "flex-start", md: "flex-end" }} alignItems='center'>
						<PageLink
							currentState={{
								date: date,
								selectedStore: selectedStore,
								selectedStoreTaskType: selectedStoreTaskType,
								selectedCategory: selectedCategory
							}}
							view='/cpg/digitalStore'
							text={t('cws_app.general.link', 'Link')}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}
