import { useEffect, useState } from 'react';
import { Button, Dialog, DialogContent, Grid, Typography, useTheme } from '@mui/material'
import { colorBasedOnTheme } from '../../utils/colors'
import { useTranslation } from 'react-i18next';
import UsersTable from './UsersTable';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import UserStepper from './UserStepper';
import { isEmptyOrUndefined } from "core/utils/validators";

export default function Bruno(props) {
  const {
    selectedClient,
    getBrunoUsers,
    brunoUsers,
    isLoadingBrunoUsers,
    deleteBrunoUser,
    deleteResponse,
    isLoadingDelete,
    getUserProfile,
    userProfile,
    isLoadingUserProfile,
    getUserRoles,
    userRoles,
    isLoadingUserRoles,
    getChains,
    chains,
    isLoadingChains,
    getStoreCategories,
    categories,
    isLoadingCategories,
    getBrunoFeatures,
    brunoFeatures,
    isLoadingBrunoFeatures,
    getUserFeatures,
    userFeatures,
    isLoadingUserFeatures,
    getUserStores,
    userStores,
    isLoadingUserStores,
    updateBrunoUser,
    updateUserResponse,
    isLoadingUpdateUser,
    postBrunoUser,
    postUserResponse,
    isLoadingPostUser,
    getUserSuppliers,
    suppliers,
    isLoadingSuppliers,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  // Add user states
  const [open, setOpen] = useState(false);

  // Lifecycle methods

  useEffect(() => {
    getBrunoUsers();
    getUserSuppliers(selectedClient.client_id);
    getUserRoles();
    getChains();
    getBrunoFeatures();
  }, [selectedClient]);

  useEffect(() => {
    if (!isEmptyOrUndefined(selectedClient, 'dict')) {
      getUserStores(selectedClient.client_id);
    }
  }, [selectedClient]);

  // Methods

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} pt={4} px={2}>
      {/* Page title */}
      <Grid container item xs={12} sm={12} md={12} justifyContent='space-between'>
        <Typography variant='h4' sx={{ color: colorBasedOnTheme(theme, 'color') }}>{t('cws_app.general.bruno_U', 'Bruno Users')}</Typography>
        {/* Add user button */}
        <Button
          variant='contained'
          startIcon={
            <IconComponent
              iconName={'add'}
              style={{ fontSize: '20px' }}
            />
          }
          onClick={() => setOpen(true)}
        >
          {t('cws_app.management.add_user', 'Add User')}
        </Button>
        {/* Add user dialog */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="edit-dialog-title"
          aria-describedby="edit-dialog-description"
          maxWidth='lg'
          fullWidth
        >
          <DialogContent>
            <UserStepper
              theme={theme}
              selectedClient={selectedClient}
              suppliers={suppliers}
              isLoadingSuppliers={isLoadingSuppliers}
              userRoles={userRoles}
              isLoadingUserRoles={isLoadingUserRoles}
              chains={chains}
              isLoadingChains={isLoadingChains}
              getStoreCategories={getStoreCategories}
              categories={categories}
              isLoadingCategories={isLoadingCategories}
              brunoFeatures={brunoFeatures}
              isLoadingBrunoFeatures={isLoadingBrunoFeatures}
              userStores={userStores}
              isLoadingUserStores={isLoadingUserStores}
              postBrunoUser={postBrunoUser}
              postUserResponse={postUserResponse}
              isLoadingPostUser={isLoadingPostUser}
            />
          </DialogContent>
        </Dialog>
      </Grid>
      {/* Users table */}
      <Grid item xs={4} sm={8} md={12}>
        <UsersTable
          theme={theme}
          selectedClient={selectedClient}
          brunoUsers={brunoUsers}
          isLoadingBrunoUsers={isLoadingBrunoUsers}
          deleteBrunoUser={deleteBrunoUser}
          deleteResponse={deleteResponse}
          isLoadingDelete={isLoadingDelete}
          getUserProfile={getUserProfile}
          userProfile={userProfile}
          isLoadingUserProfile={isLoadingUserProfile}
          suppliers={suppliers}
          isLoadingSuppliers={isLoadingSuppliers}
          userRoles={userRoles}
          isLoadingUserRoles={isLoadingUserRoles}
          chains={chains}
          isLoadingChains={isLoadingChains}
          getStoreCategories={getStoreCategories}
          categories={categories}
          isLoadingCategories={isLoadingCategories}
          brunoFeatures={brunoFeatures}
          isLoadingBrunoFeatures={isLoadingBrunoFeatures}
          getUserFeatures={getUserFeatures}
          userFeatures={userFeatures}
          isLoadingUserFeatures={isLoadingUserFeatures}
          userStores={userStores}
          isLoadingUserStores={isLoadingUserStores}
          updateBrunoUser={updateBrunoUser}
          updateUserResponse={updateUserResponse}
          isLoadingUpdateUser={isLoadingUpdateUser}
        />
      </Grid>
    </Grid>
  )
}
