import { Grid, Typography, Button, IconButton } from "@mui/material";
import { useEffect, useState } from "react";

import ClientMHeader from './ClientMHeader'
import ClientMTable from './ClientMTable'
import { useTranslation } from 'react-i18next';
import { ArrowBarLeft } from 'react-bootstrap-icons';
import ClientForm from './ClientForm';

export default function ClientManagement(props) {
  const { clientList, userStores, isLoadingUserStores, getUserStores,
    selectedClient,
    chainsList, isLoadingChains, getChains,
    clientTypesList, getClientsTypes, isLoadingClientTypes,
    isLoadingTasks, tasksList, getTasks,
    productsList, isLoadingProducts, getProducts,
    clientInfo, getClientInfo, isLoadingClientInfo,
    postNewClient, patchClient,
    viewMode, setViewMode,
    getUserSuppliers,
    suppliers,
  } = props;

  const [totalClients, setTotalClients] = useState(0);
  const [clientsTypesCount, setClientsTypesCount] = useState({});

  const [clientsTypes, setClientsTypes] = useState(clientTypesList);
  const [chains, setChains] = useState(chainsList);
  const [stores, setStores] = useState(userStores);

  const [editClient, seteditClient] = useState({ userStores });

  useEffect(() => {
    if (selectedClient && !isLoadingUserStores && userStores?.length <= 1) {
      getUserStores(selectedClient.client_id);
    } else if (JSON.stringify(stores) !== JSON.stringify(userStores)) {
      setStores(userStores)
    }
  }, [getUserStores, selectedClient, userStores, isLoadingUserStores]);

  useEffect(() => {
    if (!isLoadingProducts && !productsList) {
      getProducts();
    }
  }, [getProducts, isLoadingProducts, productsList]);

  useEffect(() => {
    if (!isLoadingChains && !chainsList?.length) {
      getChains();
    } else if (JSON.stringify(chainsList) !== JSON.stringify(chains)) {
      setChains(chainsList)
    }
  }, [getChains, isLoadingChains, chainsList]);

  useEffect(() => {
    if (!isLoadingTasks && !tasksList?.length) {
      getTasks();
    }
  }, [getTasks, isLoadingTasks, tasksList]);

  useEffect(() => {
    if (!isLoadingClientTypes && !clientTypesList?.length) {
      getClientsTypes();
    } else if (JSON.stringify(clientTypesList) !== JSON.stringify(clientsTypes)) {
      setClientsTypes(clientTypesList)
    }
  }, [getClientsTypes, isLoadingClientTypes, clientTypesList]);

  useEffect(() => {
    getUserSuppliers(selectedClient.client_id, [], true);
  }, []);


  useEffect(() => {
    if (clientList && clientList?.length > 0) {
      const clientsTypesCountTemp = {}
      setTotalClients(clientList?.length);
      clientList.forEach(clientInfo => {
        if (clientsTypesCountTemp[clientInfo.client_type]) {
          clientsTypesCountTemp[clientInfo.client_type] += 1;
        } else {
          clientsTypesCountTemp[clientInfo.client_type] = 1;
        }
      });
      setClientsTypesCount(clientsTypesCountTemp);
    }
  }, [clientList]);

  useEffect(() => {
  }, [clientInfo, isLoadingClientInfo]);

  const { t } = useTranslation();

  const handleViewMode = ({ editMode = false, createMode = false }) => setViewMode({ editMode, createMode });

  const handleEditClient = (client) => {
    seteditClient(client);
    getClientInfo(client.client_id);
  }

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      {/* tittle and buttons header */}
      {viewMode.createMode || viewMode.editMode ?
        <>
          <Grid container item xs={12} sx={{ padding: 0 }}>
            <IconButton aria-label="back-arrow" onClick={() => handleViewMode({})}>
              <ArrowBarLeft />
            </IconButton>
            <Typography variant='h6'>{viewMode.createMode ? t('cws_app.management.add_client') : t('cws_app.management.edit_client')}</Typography>
          </Grid>
        </>
        : null
      }
      {viewMode.createMode ?
        <Grid item xs={12}>
          <ClientForm
            inputChains={chains}
            inputStores={stores}
            inputTaskOptions={tasksList}
            handleViewMode={handleViewMode}
            inputProductOptions={productsList}
            inputClientTypeOptions={clientsTypes}
            inputSupplierOptions={suppliers}
            postNewClient={postNewClient}
          />
        </Grid>
        : viewMode.editMode ?
          <Grid item xs={12}>
            <ClientForm
              inputChains={chains}
              inputStores={stores}
              inputTaskOptions={tasksList}
              handleViewMode={handleViewMode}
              inputProductOptions={productsList}
              inputClientTypeOptions={clientsTypes}
              inputSupplierOptions={suppliers}
              isLoading={isLoadingClientInfo}

              InputEditClientInfo={clientInfo}
              inputClientName={editClient.name}
              inputSelectedClientTypeName={editClient.client_type}
              inputIsActive={editClient.is_active}
              inputSelectedSupplier={editClient.supplier_id}

              patchClient={patchClient}
              isEditMode={viewMode.editMode}
            />
          </Grid>
          :
          // Normal watch mode
          <>
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs>
                  <ClientMHeader totalClients={totalClients} clientsTypesCount={clientsTypesCount} />
                </Grid>
                <Grid item xs={1}>
                  <Button variant="outlined" disableElevation color="primary" onClick={() => handleViewMode({ createMode: true })}>
                    {t('cws_app.management.add_client', 'Add Client')}
                  </Button>
                </Grid>

              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ClientMTable rows={clientList} handleViewMode={handleViewMode} handleEditClient={handleEditClient} />
            </Grid>
          </>
      }
    </Grid>
  );
}
