import { connect } from 'react-redux';
import ClientSelector from '../../../components/features/navbar/ClientSelector';

import { getSelectedClient } from 'core/selectors';
import { selectClient } from 'core/slices/users';
import { getClientFormats } from 'core/slices/clients';

export default connect(state => ({
    preSelectedClient: getSelectedClient(state),
}), {
    selectClient,
    getClientFormats,
})(ClientSelector);
