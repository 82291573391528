import {
    Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Grid, InputLabel,
    MenuItem, Paper, Select, TextField, Typography, LinearProgress,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteSelector from "../../tools/InfiniteSelector";
import InfiniteMultiSelector from "../../tools/InfiniteMultiSelector";
import Scopes from "../../tools/Scopes";
import UploadBlob from "./uploadBlob"
import { getConsolidatedTranslations } from 'core/utils/parsers';

const StyledPaper = styled(props => {
    return (
        <Paper
            elevation={1}
            {...props}
        />
    )
}
)(({ theme }) => {
    return ({
        width: '100%',
        background: theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
    })
}
);


export default function ClientForm(props) {
    const {
        inputChains,
        inputStores,
        handleViewMode,
        inputSelectedSupplier = '',
        inputClientTypeOptions = [],
        inputSupplierOptions = [],
        inputProductOptions = [],
        inputTaskOptions = [],
        isLoading,

        InputEditClientInfo,
        inputClientName = '',
        inputIsActive = true,
        inputSelectedClientTypeName,

        postNewClient,
        patchClient,
        isEditMode,
    } = props;
    const { t } = useTranslation();

    const [taskOptions, setTaskOptions] = React.useState(inputTaskOptions);
    const [selectedTasks, setSelectedTasks] = React.useState();
    const [supplierOptions, setSupplierOptions] = React.useState(inputSupplierOptions);
    const clientTypeOptions = React.useState(inputClientTypeOptions)[0];
    const [selectedSupplier, setSelectedSupplier] = React.useState();
    const [selectedClientTypeId, setClientTypeId] = React.useState(inputSelectedClientTypeName);
    const [productOptions, setProductOptions] = React.useState(inputProductOptions);
    const [isActiveStatus, setIsActiveStatus] = React.useState(inputIsActive);
    const [clientLogo, setClientLogo] = React.useState([]);

    const [clientName, setClientName] = React.useState(inputClientName);

    const [editClientInfo, setEditClientInfo] = React.useState([]);
    const [inputForms, setInputForms] = React.useState([
        {
            name: 'Chain',
            componentType: 'simpleSelect',
            isRequired: true,
            optionId: 'chain_country_id',
            optionName: 'name',
            options: inputChains,
            selectedValue: {}
        },
        {
            name: 'Stores',
            componentType: 'multiSelect',
            isRequired: false,
            optionId: 'store_id',
            optionName: 'parsed_name',
            options: inputStores,
            optionSelectedIds: []
        },
    ]);
    const [scopeClients, setScopeClients] = React.useState([]);

    useEffect(() => {
        if (inputTaskOptions) {
            const inputTaskOptionsTemp = inputTaskOptions.slice();
            inputTaskOptionsTemp.forEach(row => row['parsedName'] = getConsolidatedTranslations(t, inputClientName, row.task_name, 'digital_store', row.task_name));
            setTaskOptions(inputTaskOptionsTemp);
        }
    }, [inputTaskOptions, inputClientName, t])

    useEffect(() => {
        if (!isLoading && InputEditClientInfo?.scopes) {
            setEditClientInfo(InputEditClientInfo);
        }
    }, [InputEditClientInfo, isLoading, setEditClientInfo])


    useEffect(() => {
        if (!isLoading) {
            if (editClientInfo && editClientInfo.products && productOptions && productOptions.length > 0) {
                const productOptionsTemp = productOptions.slice();
                const editProducts = editClientInfo.products;
                const editDashboards = editClientInfo.dashboards ? editClientInfo.dashboards : [];
                for (let i = 0; i < editProducts.length; i++) {
                    for (let j = 0; j < productOptionsTemp.length; j++) {
                        const productOption = productOptionsTemp[j];
                        if (productOption.subOptionName === "dashboard_name") productOption.subOptions.forEach(row => { row.is_active = editDashboards.some(dashboard => row.dashboard_id === dashboard.dashboard_id) })

                        if (productOption.product_id === editProducts[i].product_id) {
                            productOption.is_active = true;
                            break
                        }
                    }
                }
                setProductOptions(productOptionsTemp);
            }
            if (editClientInfo && editClientInfo.tasks && taskOptions && taskOptions.length > 0) {
                const editTasks = editClientInfo.tasks;
                if (editTasks) {
                    const editTasksTemp = editTasks.slice();
                    editTasksTemp.forEach(row => row['parsedName'] = getConsolidatedTranslations(t, inputClientName, row.task_name, 'digital_store', row.task_name));
                    setSelectedTasks(editTasksTemp);
                }
            }
            if (editClientInfo && editClientInfo.scopes) {
                const editForm = [];
                for (let i = 0; i < editClientInfo.scopes.length; i++) {
                    const scope = editClientInfo.scopes[i];
                    const chainSelected = { client_scopes_id: scope.client_scopes_id, chain_country_id: scope.chain_country_id, name: scope.chain_name }
                    if (scope.store_code) {
                        // TODO: Improve this, because create one store by scope
                        editForm.push(
                            {
                                name: 'Chain',
                                componentType: 'simpleSelect',
                                isRequired: true,
                                optionId: 'chain_country_id',
                                optionName: 'name',
                                options: inputChains,
                                selectedValue: chainSelected,
                            },
                            {
                                name: 'Stores',
                                componentType: 'multiSelect',
                                isRequired: false,
                                optionId: 'store_id',
                                optionName: 'parsed_name',
                                options: inputStores,
                                optionSelectedIds: [scope]
                            },
                        );
                    } else {
                        editForm.push(
                            {
                                name: 'Chain',
                                componentType: 'simpleSelect',
                                isRequired: true,
                                optionId: 'chain_country_id',
                                optionName: 'name',
                                selectedValue: chainSelected,
                                options: inputChains,
                            },
                            {
                                name: 'Stores',
                                componentType: 'multiSelect',
                                isRequired: false,
                                optionId: 'store_id',
                                optionName: 'parsed_name',
                                options: inputStores,
                                optionSelectedIds: []
                            });
                    }
                }
                setInputForms(editForm)
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editClientInfo, isLoading])

    useEffect(() => {
        if (inputSelectedClientTypeName) {
            const clientType = clientTypeOptions.find(clientType => clientType.name === inputSelectedClientTypeName);
            setClientTypeId(clientType.client_type_id)
        }
    }, [inputSelectedClientTypeName, clientTypeOptions])

    useEffect(() => {
        if (inputSupplierOptions && inputSupplierOptions.length) {
            setSupplierOptions(inputSupplierOptions);
        }
    }, [setSupplierOptions, inputSupplierOptions])

    useEffect(() => {
        if (inputSelectedSupplier !== undefined && inputSupplierOptions && inputSupplierOptions.length) {
            setSelectedSupplier(inputSupplierOptions.find(row => row.id === inputSelectedSupplier));
        }
    }, [setSelectedSupplier, inputSelectedSupplier, inputSupplierOptions])

    const handleSupplierChange = (selectedSupplier) => {
        setSelectedSupplier(selectedSupplier);
    };

    const handleScopeChange = (scopes) => {
        setScopeClients(scopes);
    };

    const handleClientTypeChange = (event) => {
        const {
            target: { value }
        } = event;
        setClientTypeId(value);
    };

    const handleStatusChange = (event, index, subIndex = null) => {
        if (index !== undefined) {
            const productOptionsTemp = productOptions.slice();
            if (subIndex !== null) {
                productOptionsTemp[index].subOptions[subIndex].is_active = !productOptionsTemp[index].subOptions[subIndex].is_active;
            } else {
                productOptionsTemp[index].is_active = !productOptionsTemp[index].is_active;
            }
            setProductOptions(productOptionsTemp);
        }
    };

    const handleCreateClient = () => {
        const clientProducts = []
        let clientDashboards = []
        productOptions.forEach(product => {
            if (product.is_active) {
                clientProducts.push(product.product_id);
                if (product.product_name === 'dashboards') {
                    clientDashboards = product.subOptions.filter(dashboard => dashboard.is_active).map(dashboard => dashboard.dashboard_id);
                }
            }
        });
        const parsedScopes = [];
        try {
            for (let i = 1; i < scopeClients.length; i += 2) {
                const row = scopeClients[i];
                const optionSelectedIds = row.optionSelectedIds;
                if (row.optionSelectedIds.length) {
                    parsedScopes.push({ chain_country_id: optionSelectedIds[0].chain_country_id, stores_id: optionSelectedIds.map(row => row.store_id) })
                } else {
                    parsedScopes.push({ chain_country_id: scopeClients[i - 1].selectedValue.chain_country_id })
                }
            }
        } catch (e) {
            console.error(e);
            alert("Scopes can not be empty");
        }

        const file_logo = clientLogo[0]?.file;
        const fileForm = new FormData();
        try {
            fileForm.append(
                "file_logo",
                file_logo,
                file_logo.name
            )
        } catch (e) { }
        fileForm.append("scopes", JSON.stringify(parsedScopes));
        fileForm.append("client_name", clientName);
        fileForm.append("client_is_active", isActiveStatus);
        fileForm.append("client_type_id", selectedClientTypeId);
        fileForm.append("task_access_ids", JSON.stringify(selectedTasks.map(taks => taks.task_id)));
        fileForm.append("product_access_ids", JSON.stringify(clientProducts));
        fileForm.append("dashboard_access_ids", JSON.stringify(clientDashboards));
        fileForm.append("client_supplier_id", selectedSupplier?.id);

        postNewClient(fileForm);
    }


    const handleEditClient = () => {
        const clientProducts = []
        let clientDashboards = []
        productOptions.forEach(product => {
            if (product.is_active) {
                clientProducts.push(product.product_id);
                if (product.product_name === 'dashboards') {
                    clientDashboards = product.subOptions.filter(dashboard => dashboard.is_active).map(dashboard => dashboard.dashboard_id);
                }
            }
        });
        let deleteScopes = JSON.parse(JSON.stringify(editClientInfo.scopes));
        const parsedScopes = [];
        try {
            for (let i = 1; i < scopeClients.length; i += 2) {
                const scope = scopeClients[i];
                const optionSelectedIds = scope.optionSelectedIds;
                if (scope.optionSelectedIds.length) {
                    parsedScopes.push({ client_scopes_id: optionSelectedIds[0]?.client_scopes_id, chain_country_id: optionSelectedIds[0].chain_country_id, stores_id: optionSelectedIds.map(row => row.store_id) })
                    if (optionSelectedIds[0]?.client_scopes_id) {
                        deleteScopes = deleteScopes.filter(row => row.client_scopes_id !== optionSelectedIds[0]?.client_scopes_id);
                    }
                } else {
                    const optionSelectedId = scopeClients[i - 1]?.selectedValue;
                    parsedScopes.push({ client_scopes_id: optionSelectedId?.client_scopes_id, chain_country_id: optionSelectedId.chain_country_id })
                    if (optionSelectedId?.client_scopes_id) {
                        deleteScopes = deleteScopes.filter(row => row.client_scopes_id !== optionSelectedId?.client_scopes_id);
                    }
                }
            }
        } catch (e) {
            console.error(e);
            alert("Scopes can not be empty");
        }

        const file_logo = clientLogo[0]?.file;
        const fileForm = new FormData();
        try {
            fileForm.append("file_logo", file_logo, file_logo.name)
        } catch (e) { }

        fileForm.append("scopes", JSON.stringify(parsedScopes));
        fileForm.append("client_id", editClientInfo.client_id);
        fileForm.append("client_name", clientName);
        fileForm.append("client_is_active", isActiveStatus);
        fileForm.append("client_type_id", selectedClientTypeId);
        fileForm.append("task_access_ids", JSON.stringify(selectedTasks.map(taks => taks.task_id)));
        fileForm.append("product_access_ids", JSON.stringify(clientProducts));
        fileForm.append("dashboard_access_ids", JSON.stringify(clientDashboards));
        fileForm.append("deleteScopes", JSON.stringify(deleteScopes));
        fileForm.append("client_supplier_id", selectedSupplier?.id);

        patchClient(fileForm);
        goBack();
    }

    const goBack = () => {
        // TODO: send to first page and refresh VALIDAAAAAAAAAR!!!
        handleDrainForms();
        handleViewMode({});
    }

    const handleDrainForms = () => {
        setSelectedTasks([]);
        setSelectedSupplier({});
        setClientTypeId();
        setClientLogo([]);
        setEditClientInfo([]);
        setScopeClients([]);
        // To Validate
        setProductOptions([]);
        setIsActiveStatus(inputIsActive);
        setClientName(inputClientName);
        setInputForms(
            {
                name: 'Chain',
                componentType: 'simpleSelect',
                isRequired: true,
                optionId: 'chain_country_id',
                optionName: 'name',
                options: inputChains,
                selectedValue: {}
            },
            {
                name: 'Stores',
                componentType: 'multiSelect',
                isRequired: false,
                optionId: 'store_id',
                optionName: 'parsed_name',
                options: inputStores,
                optionSelectedIds: []
            }
        );
    }


    const handleDisabledButton = () => {
        return !clientName || selectedClientTypeId === undefined || selectedTasks === undefined;
        // return false;
    }

    return (
        <Grid
            container
            direction="row"
            spacing={2}
            mt={1}
        >
            {/* Basic Information */}
            <Grid container item xs={12} md={8}>
                <StyledPaper style={{ height: '100%' }}>
                    <Grid container p={2} columnSpacing={4} rowSpacing={1}>
                        <Grid item xs={12} mb={4}>
                            <Typography variant="h6">{t('cws_app.general.basic_info')}</Typography>
                            {isLoading ?
                                <LinearProgress color="secondary" sx={{ borderRadius: '10rem' }} /> :
                                <Divider flexItem style={{ background: '#cacadc' }} />
                            }
                        </Grid>
                        {/* CLient name */}
                        <Grid item xs={6} mb={4}>
                            <TextField value={clientName} onChange={(e) => setClientName(e.target.value)} sx={{ width: '100%' }} id="outlined-cli" label={t('cws_app.management.client_name')} variant="outlined" />
                        </Grid>
                        {/* Supplier option */}
                        <Grid item xs={6}>
                            <InfiniteSelector
                                inputLabel={t('cws_app.management.select_supplier')}
                                options={supplierOptions}
                                inputSelected={selectedSupplier}
                                handleSelect={handleSupplierChange}
                                objectName='name' objectId='id' />
                        </Grid>
                        {/* Client Type */}
                        <Grid item xs={6}>
                            <FormControl sx={{ minWidth: '100%' }}>
                                <InputLabel id="client-type-select-label">{t('cws_app.management.select_client_type')}</InputLabel>
                                <Select
                                    labelId="client-type-select-label"
                                    id="client-type-select"
                                    value={selectedClientTypeId}
                                    onChange={handleClientTypeChange}
                                    label={t('cws_app.management.select_client_type')}
                                >
                                    {clientTypeOptions.map((clientType, index) => (
                                        <MenuItem key={clientType.name} value={clientType.client_type_id}>{clientType.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* Active Status */}
                        <Grid item xs={2}>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={isActiveStatus} onChange={(event) => setIsActiveStatus(event.target.checked)} name={t('cws_app.management.active_status')} />}
                                    label={t('cws_app.management.active_status')}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs mb={1}>
                            {/* <Button variant="outlined" disableElevation color="primary">
                                {t('cws_app.management.upload_logo', 'Upload_logo')}
                            </Button> */}
                            <UploadBlob
                                buttonTxt={t('cws_app.management.upload_logo', 'Upload_logo')}
                                images={clientLogo}
                                setImages={setClientLogo} />
                        </Grid>
                        {/* Client Scopes */}
                        <Grid item xs={12} my={3}>
                            <Typography variant="h6" >{t('cws_app.general.client_scope')}</Typography>
                            <Divider flexItem style={{ background: '#cacadc' }} />
                        </Grid>
                        <Grid item xs={12} mb={2}>
                            {!inputForms.length ?
                                <LinearProgress color="secondary" sx={{ borderRadius: '10rem' }} /> :
                                <Scopes isLoading={isLoading} inputForms={inputForms} handleScopeChange={handleScopeChange} inputBasicFormsCant={2} />
                            }
                        </Grid>
                    </Grid>
                </StyledPaper>
            </Grid>

            {/* Products, Tasks and buttons */}
            <Grid
                container
                item
                xs={12}
                md={4}
                direction="row"
            >
                <StyledPaper>
                    <Grid item xs={12} sx={{ px: 2, pt: 2 }}>
                        <Typography variant="h6">{t('cws_app.general.products')}*</Typography>
                        <Divider flexItem style={{ background: '#cacadc', marginBottom: '1rem' }} />
                    </Grid>
                    <Grid item xs={12} sx={{ px: 2, maxHeight: '25rem', overflowY: 'auto' }}>
                        {isLoading ?
                            <LinearProgress color="secondary" sx={{ borderRadius: '10rem', mt: 3 }} /> :
                            <>
                                {productOptions.map((product, index) => {
                                    const subOptions = product.subOptions;
                                    let subSections = (<></>);
                                    if (subOptions.length) {
                                        const subOptionName = product.subOptionName;
                                        subSections = subOptions.map((subOption, subIndex) => {
                                            return (
                                                <Box sx={{ marginLeft: '1rem' }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox checked={subOption?.is_active} onChange={(event) => handleStatusChange(event, index, subIndex)} name={subOption[subOptionName]} />
                                                        }
                                                        label={subOption[subOptionName]}
                                                    />
                                                </Box>
                                            )
                                        })
                                    }
                                    return (
                                        <Fragment key={`products-form-${index}`}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={product.is_active} onChange={(event) => handleStatusChange(event, index)} name={t(`cws_app.general.${product.product_name}`, product.product_name)} />
                                                }
                                                label={t(`cws_app.general.${product.product_name}`, product.product_name)}
                                                sx={{ 'width': '100%' }}
                                            />
                                            {subSections}
                                        </Fragment>
                                    )
                                })}
                            </>
                        }

                    </Grid>
                    <Grid item xs={12} sx={{ px: 2, pb: 2 }}>
                        <Typography variant="h6" mt={3}>{t('cws_app.general.tasks_admin')}*</Typography>
                        <Divider flexItem style={{ background: '#cacadc', marginBottom: '1.5rem' }} />

                        {isLoading ?
                            <LinearProgress color="secondary" sx={{ borderRadius: '10rem', mt: 3 }} /> :
                            <InfiniteMultiSelector
                                inputLabel={t('cws_app.general.tasks_admin')}
                                options={taskOptions}
                                inputSelected={selectedTasks}
                                setInputSelectedOptions={setSelectedTasks}
                                objectName='parsedName'
                                objectId='task_id' />
                        }

                    </Grid>
                </StyledPaper>

                <Grid item xs={6} display="flex" justifyItems="flex-start" alignItems={'self-end'}>
                    <Button variant="outlined" disableElevation color="secondary" sx={{ mt: 3, minWidth: '11em', maxHeight: '3rem' }} onClick={goBack}>
                        {t('cws_app.general.cancel', 'Cancel')}
                    </Button>
                </Grid>
                <Grid item xs={6} display="flex" justifyContent="flex-end" alignItems={'self-end'}>
                    <Button
                        disabled={isLoading || handleDisabledButton()}
                        variant="contained"
                        disableElevation
                        color="primary"
                        sx={{ mt: 3, minWidth: '11em', maxHeight: '3rem' }}
                        onClick={isEditMode ? handleEditClient : handleCreateClient}>
                        {t('cws_app.general.save', 'Save')}
                    </Button>
                </Grid>

            </Grid>

        </Grid>
    );
}
