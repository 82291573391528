import { connect } from 'react-redux';
import Home from '../../../components/features/home/Home';

import { downloadExcel } from 'core/slices/general';
import { getDashboards, getDashboardResume } from 'core/slices/dashboards';
import { getStoreBrands, getStoreCategories, getNumberFormat } from 'core/slices/store';
import { getShareOfShelfExcel } from 'core/slices/backend';
import { getUserStores, getUserSuppliers } from 'core/slices/users';

import {
    getBrandList, getCategoriesList, isLoadingDashboards, getDashboardsResumeInfo,
    loadingDashboardResume, getDashboardsList, getSectionView, getSelectedClient,
    getUserStoresAllList, isLoadingBrands, isLoadingCategories, isLoadingResumeDashboards,
    isLoadingExcelDownload,
    isLoadingUserStores,
    userSuppliers,
    isLoadingUserSuppliers,
    isLoadingClientFormats,
    getClientFormats,
} from 'core/selectors';

export default connect(state => ({
    isLoadingUserStores: isLoadingUserStores(state),
    isLoadingResumeDashboards: isLoadingResumeDashboards(state),
    isLoadingDashboards: isLoadingDashboards(state),
    loadingDashboardResume: loadingDashboardResume(state),
    dashboardsResume: getDashboardsResumeInfo(state),
    dashboardsList: getDashboardsList(state),
    sectionsView: getSectionView(state),
    userStores: getUserStoresAllList(state),
    selectedClient: getSelectedClient(state),
    isLoadingBrands: isLoadingBrands(state),
    isLoadingCategories: isLoadingCategories(state),
    storeCategories: getCategoriesList(state),
    brandList: getBrandList(state),
    isLoadingExcelDownload: isLoadingExcelDownload(state),
    suppliers: userSuppliers(state),
    isLoadingSuppliers: isLoadingUserSuppliers(state),
    clientFormats: getClientFormats(state),
    isLoadingClientFormats: isLoadingClientFormats(state),
}), {
    downloadExcel,
    getDashboards,
    getUserStores,
    getStoreBrands,
    getShareOfShelfExcel,
    getStoreCategories,
    getDashboardResume,
    getNumberFormat,
    getUserSuppliers,
})(Home);
