import { connect } from 'react-redux';
import Dashboards from '../../../../components/features/dashboards/Dashboards';

import { downloadExcel } from 'core/slices/general';
import { getDashboard } from 'core/slices/dashboards';
import { getUserStores, getUserSuppliers } from 'core/slices/users';
import { getStoreCategories } from 'core/slices/store';

import {
  isLoadingDashboard,
  isLoadingExcelDownload,
  getDashboardInfo,
  isLoadingUserStores,
  getUserStoresAllList,
  getSelectedClient,
  userChains,
  isLoadingCategories,
  categories,
  userSuppliers,
  isLoadingUserSuppliers,
  isLoadingClientFormats,
  getClientFormats,
} from 'core/selectors';

export default connect(state => ({
  isLoadingDashboard: isLoadingDashboard(state),
  isLoadingUserStores: isLoadingUserStores(state),
  selectedClient: getSelectedClient(state),
  userChains: userChains(state),
  userStores: getUserStoresAllList(state),
  dashboardInfo: getDashboardInfo(state),
  isLoadingExcelDownload: isLoadingExcelDownload(state),
  categories: categories(state),
  isLoadingCategories: isLoadingCategories(state),
  suppliers: userSuppliers(state),
  isLoadingSuppliers: isLoadingUserSuppliers(state),
  clientFormats: getClientFormats(state),
  isLoadingClientFormats: isLoadingClientFormats(state),
}), {
  downloadExcel,
  getDashboard,
  getUserStores,
  getStoreCategories,
  getUserSuppliers,
})(Dashboards);
